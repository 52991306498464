import * as React from 'react';
import Button from '@mui/material/Button';
import {Stack} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CreateCAPItem from "./CreateCAPItem";
import {useDispatch} from "react-redux";
import {useSaveCAPItemMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function AddNewCapItemButton({permissions, addNewCapItem, setAddNewCapItem, closedOrDone, cap}) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();

    const [saveCAPItem] = useSaveCAPItemMutation();

    async function handleCreateCAPItem(item) {
        try {
            await saveCAPItem({capId: cap.id, capItem: item}).unwrap()
            dispatch(openSnackbar({message: "CAP Item created successfully", severity: "success"}));
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "CAP Item creation failed", severity: "error"}));
        }
    }

    if (!permissions.has("WRITE_CAP")) return null;

    const style = {position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',};

    return (
        <Stack direction="row" justifyContent="end" sx={{mt: 2}} alignItems="flex-start">
            {setAddNewCapItem &&
                <Button variant="outlined" color="primary" size="large" style={{marginTop: 5, marginLeft: 30}}
                        disabled={addNewCapItem || closedOrDone || !permissions.has("WRITE_CAP")} onClick={handleOpen}
                        startIcon={<AddCircleIcon/>}>
                    Add new cap item
                </Button>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CreateCAPItem permissions={permissions} handleClose={handleClose}
                                   save={handleCreateCAPItem}/>
                </Box>
            </Modal>
        </Stack>

    );
}
