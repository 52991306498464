import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useSaveInvestigationItemMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";


export default function AddInvestigationItemAddExternal({investigation, setOpen}) {

    const dispatch = useDispatch();
    const [saveInvestigationItem] = useSaveInvestigationItemMutation();
    const [sending, setSending] = useState(false);

    const validationSchema = Yup.object().shape({
        fromRole: Yup.string().required('External role/description is required'),
        fromName: Yup.string().required('Assessor name is required'),
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Text is required')
    });

    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {
        setSending(true);

        try {
            await saveInvestigationItem({
                investigationId: investigation.id,
                complaintId: investigation.complaintId,
                investigationItem: data
            }).unwrap();
            dispatch(openSnackbar({message: "Successfully saved investigation item", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to save investigation item", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }
    };
    return (
        <>
            <Typography variant="h5" sx={{mt: 2}}>Add External Opinion manually</Typography>

            <br/>
            Please enter the role and name of the external stakeholder.
            <TextField
                required sx={{minWidth: 200, mt: 1}} fullWidth
                id="fromRole"
                name="fromRole"
                label="Role/Description of the external"
                {...register('fromRole')}
                error={!!errors.fromRole}
            />
            <Typography variant="inherit" color="error">
                {errors.fromRole?.message}
            </Typography>


            <TextField
                required sx={{minWidth: 200, mt: 1}} fullWidth
                id="fromName"
                name="fromName"
                label="External Name"
                {...register('fromName')}
                error={!!errors.fromName}
            />
            <Typography variant="inherit" color="error">
                {errors.fromName?.message}
            </Typography>

            <Typography variant="h6" sx={{mt: 2}} color="black">Opinion</Typography>
            Add the opinion of the external.

            <TextField sx={{minWidth: 200, mt: 1}}
                       required fullWidth
                       id="title"
                       name="title"
                       label="Assessment Title"
                       {...register('title')}
                       error={!!errors.title}
            />
            <Typography variant="inherit" color="error">
                {errors.title?.message}
            </Typography>
            <TextField sx={{minWidth: 200, mt: 1}} fullWidth required
                       id="description"
                       name="description"
                       label="Assessment Text"
                       multiline rows={6}
                       {...register('description')}
                       error={!!errors.description}
            />
            <Typography variant="inherit" color="error">
                {errors.description?.message}
            </Typography>

            <LoadingButton
                fullWidth sx={{minWidth: 200, mt: 1}}
                loading={sending}
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
            >
                Add Assessment
            </LoadingButton>
        </>
    );
}
