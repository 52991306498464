import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {hasBrand} from "../../../../utils/users";
import {
    useUpdateComplaintAdditionalPolicyMutation,
    useUpdateComplaintMainPolicyMutation
} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import {useDispatch} from "react-redux";

export default function ChosePolicy({complaint, permissions, isInternal, user}) {

    const dispatch = useDispatch();

    const [mainPolicy, setMainPolicy] = useState(complaint.mainPolicy ? complaint.mainPolicy : "");
    const [additionalPolicy, setAdditionalPolicy] = useState(complaint.additionalPolicy ? complaint.additionalPolicy : "");

    const [updateComplaintMainPolicy] = useUpdateComplaintMainPolicyMutation();
    const [updateComplaintAdditionalPolicy] = useUpdateComplaintAdditionalPolicyMutation();

    const handleChangeLegalCategory = async (event) => {
        setMainPolicy(event.target.value);
        try {
            await updateComplaintMainPolicy({complaintId: complaint.id, mainPolicy: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Main Policy updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Main Policy update failed", severity: "error"}));
        }
    };

    const handleChangeAdditionalPolicy = async (event) => {
        setAdditionalPolicy(event.target.value);
        try {
            await updateComplaintAdditionalPolicy({
                complaintId: complaint.id,
                additionalPolicy: event.target.value
            }).unwrap()
            dispatch(openSnackbar({message: "Main Policy updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Main Policy update failed", severity: "error"}));
        }
    };

    if (!isInternal || !hasBrand(user, {id: "bsh"})) return null;

    function additionalPolicyLabel(additionalPolicy) {
        switch (additionalPolicy) {
            case "ADDITIONAL_POLICY_1" :
                return "TFEU (Treaty on the Functioning of the European Union)";
            case "ADDITIONAL_POLICY_2" :
                return "US antitrust law";
            case "ADDITIONAL_POLICY_3" :
                return "UK Bribery Act";
            case "ADDITIONAL_POLICY_4" :
                return "US Foreign Corrupt Practices Act (FCPA)";
            case "ADDITIONAL_POLICY_5" :
                return "3rd EU Money Laundering Directive (Directive 2005/60/EC)";
            case "ADDITIONAL_POLICY_6" :
                return "4th EU Money Laundering Directive (Directive (EU) 2015/849)";
            case "ADDITIONAL_POLICY_7" :
                return "5th EU Money Laundering Directive";
            case "ADDITIONAL_POLICY_8" :
                return "EU Directive on Combating Terrorism";
            case "ADDITIONAL_POLICY_9" :
                return "USA Patriot Act";
            case "ADDITIONAL_POLICY_10" :
                return "ISO 37001 on Anti-Bribery Management Systems";

            default:
                return additionalPolicy;
        }
    }

    function mainPolicyLabel(mainPolicy) {
        if (mainPolicy === "LKSG_AND_WBRL") return "LKSG + WBRL"
        return mainPolicy;
    }

    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>Select policies</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box sx={{mt: 2}}>
                    <FormControl variant="outlined" component="span">
                        <InputLabel id="mainPolicy-label">Legal</InputLabel>
                        <Select
                            labelId="mainPolicy-label"
                            id="mainPolicy"
                            value={mainPolicy}
                            onChange={handleChangeLegalCategory}
                            renderValue={(mainPolicy) => mainPolicyLabel(mainPolicy)}
                            label="Main Policy"
                            disabled={!permissions.has("UPDATE_COMPLAINT_CATEGORY")}
                            sx={{minWidth: 120, marginRight: 2}}
                        >
                            <MenuItem key={"LKSG"} value={"LKSG"}>
                                {mainPolicyLabel("LKSG")}
                            </MenuItem>
                            <MenuItem key={"WBRL"} value={"WBRL"}>
                                {mainPolicyLabel("WBRL")}
                            </MenuItem>
                            <MenuItem key={"LKSG_AND_WBRL"} value={"LKSG_AND_WBRL"}>
                                {mainPolicyLabel("LKSG_AND_WBRL")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel id="additionalPolicy-label">Additional involved policies</InputLabel>
                        <Select
                            labelId="additionalPolicy-label"
                            id="additionalPolicy"
                            value={additionalPolicy}
                            onChange={handleChangeAdditionalPolicy}
                            renderValue={(additionalPolicy) => additionalPolicyLabel(additionalPolicy)}
                            label="Additional involved policies"
                            disabled={!permissions.has("UPDATE_COMPLAINT_CATEGORY")}
                            sx={{minWidth: 120}}
                        >
                            {[...Array(10).keys()]
                                .map((index) =>
                                    <MenuItem key={`ADDITIONAL_POLICY_${index + 1}`}
                                              value={`ADDITIONAL_POLICY_${index + 1}`}>
                                        {additionalPolicyLabel(`ADDITIONAL_POLICY_${index + 1}`)}
                                    </MenuItem>)

                            }

                        </Select>
                    </FormControl>
                </Box>

            </Box>
        </>
    );
}
