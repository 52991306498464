import React, {useMemo, useState} from 'react';
import {MaterialReactTable, useMaterialReactTable,} from 'material-react-table';
import {Box, Button, IconButton, Tooltip,} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import {
    useCreateUserMutation,
    useDeleteUserMutation,
    useGetBrandsQuery,
    useGetUsersQuery,
    useUpdateUserMutation
} from "../../app/apiSlice";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import {brandLabel, roleLabel} from "../dashboard/labels";
import {EditCellMultipleSelect} from "./EditCellMultipleSelect";

const UsersTable = () => {
    const [validationErrors, setValidationErrors] = useState({});
    //keep track of rows that have been edited
    const [editedUsers, setEditedUsers] = useState({});

    function eligibleSubBrands(complaintBrands) {
        let currentBrands = complaintBrands || [];
        let currentSubBrandIds = brands.filter(brand => currentBrands.includes(brand.id))
            .flatMap(brand => brand.subBrands);
        return brands.filter(brand => currentSubBrandIds.includes(brand.id));
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'firstName',
                header: 'First Name',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateRequired(event.currentTarget.value)
                            ? 'Required'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
            {
                accessorKey: 'lastName',
                header: 'Last Name',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateRequired(event.currentTarget.value)
                            ? 'Required'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
            {
                accessorKey: 'email',
                header: 'Email',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'email',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateEmail(event.currentTarget.value)
                            ? 'Incorrect Email Format'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
            {
                Cell: ({renderedCellValue, row}) => {
                    return row.original.brands && row.original.brands.map((value) => (
                        <Chip size="small" key={value} label={brandLabel(value, brands)} sx={{margin: 0.5,}}
                              variant="outlined"/>
                    ))
                },
                Edit: ({cell, column, row, table}) => <EditCellMultipleSelect row={row} column={column} table={table}
                                                                              label="Brands"
                                                                              items={brands}/>,
                accessorKey: 'brands',
                header: 'Brands',
            },
            {
                Cell: ({renderedCellValue, row}) => {
                    return row.original.subBrands && row.original.subBrands.map((value) => (
                        <Chip size="small" key={value} label={brandLabel(value, brands)} sx={{margin: 0.5,}}
                              variant="outlined"/>
                    ))
                },
                Edit: ({cell, column, row, table}) => <EditCellMultipleSelect row={row} column={column} table={table}
                                                                              label="Sub-Brands"
                                                                              items={eligibleSubBrands(row._valuesCache["brands"])}/>,
                accessorKey: 'subBrands',
                header: 'Sub Brands',
            },
            {
                Cell: ({renderedCellValue, row}) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap',}}>
                        {row.original.roles && row.original.roles.map((role) => (
                            <Chip size="small" key={role} label={roleLabel(role)} sx={{margin: 2,}} variant="outlined"/>
                        ))}
                    </Box>
                ),
                accessorKey: 'roles',
                header: 'Roles',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateRequired(event.currentTarget.value)
                            ? 'Required'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
        ],
        [editedUsers, validationErrors],
    );

    const {
        data: fetchedUsers = [],
        isLoading: isLoadingUsers,
        isFetching: isFetchingUsers,
        error: isLoadingUsersError
    } = useGetUsersQuery();

    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();


    const [createUser, {isPending: isCreatingUser},] = useCreateUserMutation()
    const [updateUser, {isPending: isUpdatingUsers},] = useUpdateUserMutation();
    const [deleteUser, {isPending: isDeletingUser},] = useDeleteUserMutation();

    const handleCreateUser = async ({values, table}) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        await createUser({...values, roles: values.roles?.split(", ")});
        table.setCreatingRow(null); //exit creating mode
    };

    const handleUpdateUser = async ({values, table, row}) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        await updateUser({...row.original, ...values});
        table.setEditingRow(null); //exit creating mode
    };

    //DELETE action
    const openDeleteConfirmModal = (row) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            deleteUser(row.original.id);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: fetchedUsers,
        createDisplayMode: 'row',
        editDisplayMode: 'row',
        enableEditing: true,
        enableRowActions: true,
        positionActionsColumn: 'last',
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingUsersError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateUser,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleUpdateUser,
        renderRowActions: ({row}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true);
                }}
            >
                Create New User
            </Button>
        ),
        state: {
            isLoading: isLoadingUsers || brandsLoading,
            isSaving: isCreatingUser || isUpdatingUsers || isDeletingUser,
            showAlertBanner: isLoadingUsersError || brandsError,
            showProgressBars: isFetchingUsers,
        },
        initialState: {
            columnVisibility: {id: false}
        },


    });

    return <MaterialReactTable table={table}/>;
};

export default UsersTable;

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );

function validateUser(user) {
    if (user.brands === "") user.brands = [];
    if (user.subBrands === "") user.subBrands = [];
    return {
        firstName: !validateRequired(user.firstName)
            ? 'First Name is Required'
            : '',
        lastName: !validateRequired(user.lastName) ? 'Last Name is Required' : '',
        email: !validateEmail(user.email) ? 'Incorrect Email Format' : '',
    };
}
