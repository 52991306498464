import React from "react";
import {useRouteMatch} from "react-router-dom";
import {LocationForm} from "../components/location/LocationForm";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../components/common/LoadingGIF";
import NotAuthorized from "../components/common/NotAuthorized";
import Error from "../components/common/Error";
import {useGetBrandsQuery, useGetLocationQuery} from "../app/apiSlice";


export const Location = () => {

    const match = useRouteMatch();

    const {
        data: location,
        isLoading: locationLoading,
        error: locationError
    } = useGetLocationQuery(match.params.location);
    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();

    if (locationLoading || brandsLoading) return <LoadingGIF/>;
    if (locationError && locationError.message.includes('403')) return <NotAuthorized/>;
    if (brandsError && brandsError.message.includes('403')) return <NotAuthorized/>;
    if (locationError || brandsError) return <Error/>;

    return (
        <>
            <LocationForm location={location} brands={brands}/>
        </>
    );
};

export default withAuthenticationRequired(Location, {
    onRedirecting: () => <LoadingGIF/>,
});
