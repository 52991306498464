import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import InvestigationTab from "./tabs/investigation/InvestigationTab";
import CAPTab from "./tabs/cap/CAPTab";
import PlausiCheck from "./tabs/plausicheck/PlausiCheck";
import ComplaintTimeline from "./timeline/ComplaintTimeline";
import ComplainantConversation from "./tabs/conversation/ComplainantConversation";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExternalsTab from "./tabs/externals/ExternalsTab";
import LoadingGIF from "../common/LoadingGIF";
import ActivityTab from "./tabs/activity/ActivityTab";
import {useGetCAPQuery, useGetMeQuery, useGetInvestigationQuery} from "../../app/apiSlice";
import Error from "../common/Error";
import {Stack} from "@mui/material";
import theme from "../theme";

function TabPanel({children, value, index, ...other}) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function VerticalTabs({complaint, statusInfo, tab, permissions}) {

    const [value, setValue] = useState(tab.tab);
    const [title, setTitle] = useState(complaint.title || `Complaint C${complaint.id}`);

    const {data: me, isLoading: loadingMe, error: errorMe} = useGetMeQuery();
    const {data: cap, isLoading: loadingCAP} = useGetCAPQuery(complaint.id);
    const {data: investigation, isLoading: loadingInvestigation} = useGetInvestigationQuery(complaint.id);

    if (loadingMe) return <LoadingGIF/>;
    if (errorMe) return <Error/>;
    const isInternal = me && me.internal;
    if (isInternal && loadingCAP) return <LoadingGIF/>;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function getComplaintFactsLabel() {
        return "Complaint Facts"
    }

    function getComplainantConversationLabel() {
        return "Complainant Conversation"
    }

    // todo good value when closed as not plausible
    function getInvestigationLabel() {
        if (tab.investigation === "done")
            return <span>Investigation <CheckCircleIcon/></span>
        return "Investigation"
    }

    function getCAPLabel() {
        if (tab.cap === "done")
            return <span>CAP <CheckCircleIcon/></span>
        return "CAP"
    }

    return (
        <div sx={{flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,}}>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                    <Typography variant="h3" component="h1" sx={{mt: 2}} align="left">
                        {title ? title : "New Complaint"}
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom sx={{mt: 0}} align="left">
                        C{complaint.id}
                    </Typography>
                </Stack>
                <ComplaintTimeline complaint={complaint}/>
            </Stack>
            <AppBar position="static" color="default" sx={{mt: 1, minWidth: 1152}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={getComplainantConversationLabel()} {...a11yProps(0)} />
                    <Tab label={getComplaintFactsLabel()} {...a11yProps(1)} />
                    <Tab label={getInvestigationLabel()} {...a11yProps(2)} />
                    <Tab label={getCAPLabel()} {...a11yProps(3)} disabled={!permissions.has("READ_CAP")}/>
                    {isInternal && <Tab label={"Externals"} {...a11yProps(4)} />}
                    {isInternal && <Tab label={"Activity"} {...a11yProps(5)} />}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ComplainantConversation complaint={complaint} permissions={permissions}/>
            </TabPanel>
            <TabPanel index={value} value={1}>
                <PlausiCheck complaint={complaint} permissions={permissions} isInternal={isInternal} me={me}
                             setTab={setValue} title={title} setTitle={setTitle} statusInfo={statusInfo}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <InvestigationTab complaint={complaint} setTab={setValue} statusInfo={statusInfo} me={me}
                                  isInternal={isInternal} permissions={permissions} investigation={investigation}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <CAPTab complaint={complaint} statusInfo={statusInfo} isInternal={isInternal} user={me} cap={cap}
                        permissions={permissions} setTab={setValue}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ExternalsTab complaint={complaint}/>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <ActivityTab complaint={complaint} investigation={investigation} cap={cap}/>
            </TabPanel>

        </div>
    );
}
