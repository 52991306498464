import * as React from 'react';
import Typography from "@mui/material/Typography";

export default function EditableText({disabled, save, item, align, variant, pt, pl, text}) {

    return <Typography contentEditable={!disabled}
                       align={align ? align : "inherit"}
                       variant={variant}
                       color="black"
                       pt={pt} pl={pl}
                       suppressContentEditableWarning={true}
                       onBlur={e => {
                           save({...item, title: e.currentTarget.textContent,})
                       }}>{text}</Typography>;
}
