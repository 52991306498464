import React, {useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import NotAuthorized from "../components/common/NotAuthorized";
import LoadingGIF from "../components/common/LoadingGIF";
import Box from "@mui/material/Box";
import Error from "../components/common/Error";
import {useGetBrandsQuery} from "../app/apiSlice";
import BrandTable from "../components/admin/BrandTable";

export const Brands = () => {

    const {data: brands, isLoading: loading, isSuccess, error: error} = useGetBrandsQuery();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (isSuccess) {
            setRows(JSON.parse(JSON.stringify(brands)));
        }
    }, [brands]);

    if (loading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (error || !rows) return <Error/>;

    return (
        <Box sx={{mt: 2}}>
            <BrandTable/>
        </Box>
    );
};

export default withAuthenticationRequired(Brands, {
    onRedirecting: () => <LoadingGIF/>,
});
