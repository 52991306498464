import React, {useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import LoadingGIF from "../../common/LoadingGIF";
import SurveyTable from "./SurveyTable";
import {useGetReportSchemasQuery, useSaveReportSchemaMutation} from "../../../app/apiSlice";
import NotAuthorized from "../../common/NotAuthorized";
import Error from "../../common/Error";

const Surveys = () => {

    const {data: schemas, isLoading: schemasloading, error: schemasError} = useGetReportSchemasQuery();

    if (schemasloading) return <LoadingGIF/>;
    if (schemasError && schemasError.status === 403) return <NotAuthorized/>;
    if (schemasError) return <Error/>;

    return (
        <>
            <Stack direction="column" spacing={3} sx={{p: 3}}>
                <Typography variant="h3" component="span">
                    Surveys
                </Typography>
                <SurveyTable schemas={schemas}/>
            </Stack>

        </>);
};

export default withAuthenticationRequired(Surveys, {
    onRedirecting: () => <LoadingGIF/>,
});
