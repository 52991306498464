import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import AddInvestigationToDo from "./AddInvestigationToDo";
import EditInvestigationToDo from "./EditInvestigationToDo";
import Typography from "@mui/material/Typography";
import HelpPopUp from "../../../common/HelpPopUp";
import {Stack} from "@mui/material";
import {useDispatch} from "react-redux";
import {useCheckInvestigationTodoMutation, useUncheckInvestigationTodoMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function InvestigationTodos({investigation}) {

    const dispatch = useDispatch();
    const [checkInvestigationTodo] = useCheckInvestigationTodoMutation();
    const [uncheckInvestigationTodo] = useUncheckInvestigationTodoMutation();

    const handleToggle = (value) => async () => {
        if (value.checked) {
            try {
                await uncheckInvestigationTodo({
                    investigationId: investigation.id,
                    complaintId: investigation.complaintId,
                    data: value
                }).unwrap();
                dispatch(openSnackbar({message: "Successfully unchecked action item", severity: "success"}));
            } catch (err) {
                dispatch(openSnackbar({message: "Failed to uncheck action item", severity: "error"}));
            }
        } else {
            try {
                await checkInvestigationTodo({
                    investigationId: investigation.id,
                    complaintId: investigation.complaintId,
                    data: value
                }).unwrap();
                dispatch(openSnackbar({message: "Successfully checked action item", severity: "success"}));
            } catch (err) {
                dispatch(openSnackbar({message: "Failed to check action item", severity: "error"}));
            }
        }

    };

    if (!investigation) return null;

    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>
                List of Actions Items
                <HelpPopUp text={"Start investigation the complaint by creating a list of action items."}/>
            </Typography>
            {investigation.todos &&
                <List sx={{width: '100%', maxWidth: 600, bgcolor: 'background.paper'}}>
                    {investigation.todos.map((value) => {
                        const labelId = `checkbox-list-label-${value.id}`;

                        return (
                            <ListItem
                                key={value.id}
                                secondaryAction={<EditInvestigationToDo investigation={investigation} todo={value}
                                                                        key={value.id}
                                                                        history={investigation.history.filter(y => y.investigationTodoId === value.id)}/>}
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={value.checked}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{'aria-labelledby': labelId}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value.title}
                                                  secondary={value.description}/>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>}

            <Stack direction="row" justifyContent="end">
                <AddInvestigationToDo investigation={investigation}/>
            </Stack>
        </>
    );
}
