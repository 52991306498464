export const complaintSteps = [
    {
        stepName: "welcome",
        title: "welcome.title",
        text: "welcome.text",
        buttons: [
            {label: "welcome.buttons.start", icon: "ArrowRightAltIcon", goto: "start"},
            {label: "welcome.buttons.learnMore", icon: "InfoOutlinedIcon", goto: "info"}]
    }, {
        stepName: "info",
        previousStep: "welcome",
        title: "info.title",
        links: [
            {
                type: "youtube",
                url: "info.links.youtube.url",
                icon: "YouTubeIcon",
                label: "info.links.youtube.label"
            },
            {type: "link", url: "info.links.faqs.url", icon: "QuestionAnswerIcon", label: "info.links.faqs.label"}],
        text: "info.text",
        buttons: [{label: "info.buttons.start", icon: "ArrowRightAltIcon", goto: "start"}, {
            label: "info.buttons.moreQuestions", goto: "questions"
        }]
    }, {
        stepName: "questions",
        previousStep: "info",
        text: "questions.text",
        field: {
            name: "questionsByComplainant",
            type: "array",
            label: "questions.field.label",
            submit: {
                label: "questions.field.submit.label", icon: "SendIcon", goto: "afterQuestions"
            }
        }
    }, {
        stepName: "afterQuestions",
        previousStep: "questions",
        text: "afterQuestions.text",
        buttons: [{label: "afterQuestions.buttons.start", icon: "ArrowRightAltIcon", goto: "start"}, {
            label: "afterQuestions.buttons.moreQuestions",
            goto: "questions"
        }]
    }, {
        stepName: "start",
        previousStep: "welcome",
        text: "start.text",
        links: [
            {type: "link", url: "start.links.url", label: "start.links.label"}],
        buttons: [{label: "start.buttons.no", icon: "DoNotDisturbOnIcon", goto: "dataAgreementNo"}, {
            label: "start.buttons.yes", icon: "CheckIcon", goto: "dataAgreementYes",
            update: {field: "dataAgreement", value: true}
        }]
    }, {
        stepName: "dataAgreementNo",
        previousStep: "start",
        text: "dataAgreementNo.text",
        buttons: [{label: "dataAgreementNo.buttons.no", icon: "DoNotDisturbOnIcon", goto: "start"}, {
            label: "dataAgreementNo.buttons.yes",
            icon: "CheckIcon",
            goto: "dataAgreementNoGoodbye",
        }]
    }, {
        stepName: "dataAgreementNoGoodbye",
        previousStep: "welcome",
        text: "dataAgreementNoGoodbye.text",
        buttons: [{label: "dataAgreementNoGoodbye.buttons.startOver", icon: "ArrowRightAltIcon", goto: "welcome"},]
    }, {
        stepName: "dataAgreementYes",
        previousStep: "start",
        text: "dataAgreementYes.text",
        buttons: [{
            label: "dataAgreementYes.buttons.anonymous",
            icon: "VerifiedUserIcon",
            goto: "gender",
            update: {field: "anonymous", value: true}
        },
            {label: "dataAgreementYes.buttons.withName", icon: "GppMaybeIcon", goto: "nameInput",}]
    }, {
        stepName: "nameInput",
        previousStep: "dataAgreementYes",
        text: "nameInput.text",
        field: {
            name: "complainantName",
            type: "text",
            label: "nameInput.field.label",
            submit: {
                label: "nameInput.field.submit.label", icon: "SendIcon", goto: "gender"
            }
        }
    }, {
        stepName: "gender",
        previousStep: "dataAgreementYes",
        text: "gender.text",
        field: {
            name: "genderText",
            type: "text",
            label: "gender.field.label",
            submit: {
                label: "gender.field.submit.label", icon: "SendIcon", goto: "age"
            }
        },
    }, {
        stepName: "age",
        previousStep: "gender",
        text: "age.text",
        field: {
            name: "ageText",
            type: "text",
            label: "age.field.label",
            submit: {
                label: "age.field.submit.label", icon: "SendIcon", goto: "factory"
            }
        },
    }, {
        stepName: "factory",
        previousStep: "age",
        text: "factory.text",
        field: {
            name: "location",
            type: "text",
            label: "factory.field.label",
            submit: {
                label: "factory.field.submit.label", icon: "SendIcon", goto: "dateAndTime"
            }
        },
    }, {
        stepName: "dateAndTime",
        previousStep: "factory",
        text: "dateAndTime.text",
        buttons: [{label: "dateAndTime.buttons.skip", icon: "SkipNextIcon", goto: "problem",}],
        field: {
            name: "complaintDateString",
            type: "text",
            label: "dateAndTime.field.label",
            submit: {
                label: "dateAndTime.field.submit.label", icon: "SendIcon", goto: "problem"
            }
        },
    }, {
        stepName: "problem",
        previousStep: "dateAndTime",
        text: "problem.text",
        field: {
            name: "complaintText",
            type: "text",
            label: "problem.field.label",
            condition: {
                type: "MIN_LENGTH", value: 15, goto: "textTooShort"
            },
            submit: {
                label: "problem.field.submit.label", icon: "SendIcon", goto: "uploads", create: true
            }
        },
    }, {
        stepName: "textTooShort",
        previousStep: "problem",
        text: "textTooShort.text",
        buttons: [{label: "textTooShort.buttons.ok", icon: "CheckCircleIcon", goto: "problem",}]
    }, {
        stepName: "uploads",
        previousStep: "problem",
        text: "uploads.text",
        field: {
            name: "files",
            type: "files",
            submit: {
                label: "uploads.field.submit.label", goto: "uploadMore"
            }
        },
        buttons: [{label: "uploads.buttons.skip", icon: "SkipNextIcon", goto: "morePeople",},]
    }, {
        stepName: "uploadMore",
        previousStep: "uploads",
        text: "uploadMore.text",
        buttons: [{label: "uploadMore.buttons.no", icon: "DoNotDisturbOnIcon", goto: "morePeople",}, {
            label: "uploadMore.buttons.yes", icon: "CheckIcon", goto: "uploads",
        }]
    }, {
        stepName: "morePeople",
        previousStep: "uploadQuestion",
        text: "morePeople.text",
        buttons: [{label: "morePeople.buttons.skip", icon: "SkipNextIcon", goto: "factoryEmployeeCheck",},
            {label: "morePeople.buttons.no", icon: "DoNotDisturbOnIcon", goto: "factoryEmployeeCheck",},
            {label: "morePeople.buttons.yes", icon: "CheckIcon", goto: "factoryEmployeeCheck",}]
    }, {
        stepName: "factoryEmployeeCheck",
        previousStep: "morePeople",
        text: "factoryEmployeeCheck.text",
        buttons: [
            {
                label: "factoryEmployeeCheck.buttons.yes",
                icon: "CheckIcon",
                goto: "timeAtCompany",
                update: {field: "complainantWorksAtLocation", value: true}
            },
            {
                label: "factoryEmployeeCheck.buttons.skip", icon: "SkipNextIcon", goto: "timeAtCompany",

            },
            {label: "factoryEmployeeCheck.buttons.no", icon: "DoNotDisturbOnIcon", goto: "relationToCompany",}]
    }, {
        stepName: "timeAtCompany",
        previousStep: "factoryEmployeeCheck",
        text: "timeAtCompany.text",
        field: {
            name: "timeAtFactoryText",
            type: "text",
            label: "timeAtCompany.field.label",
            submit: {
                label: "timeAtCompany.field.submit.label", icon: "SendIcon", goto: "remedy"
            }
        },
        buttons: [{label: "relationToCompany.buttons.skip", icon: "SkipNextIcon", goto: "remedy",}]
    }, {
        stepName: "relationToCompany",
        previousStep: "factoryEmployeeCheck",
        text: "relationToCompany.text",
        field: {
            name: "relationToCompany",
            type: "text",
            label: "relationToCompany.field.label",
            submit: {
                label: "relationToCompany.field.submit.label", icon: "SendIcon", goto: "remedy"
            }
        },
        buttons: [{label: "relationToCompany.buttons.skip", icon: "SkipNextIcon", goto: "remedy",},]
    }, {
        stepName: "remedy",
        previousStep: "relationToCompany",
        text: "remedy.text",
        field: {
            name: "remedySuggestionByComplainant",
            type: "text",
            label: "remedy.field.label",
            submit: {
                label: "remedy.field.submit.label", icon: "SendIcon", goto: "internalComplaintQuestion"
            }
        },
        buttons: [{
            label: "remedy.buttons.skip",
            icon: "SkipNextIcon",
            goto: "internalComplaintQuestion",
        }]
    }, {
        stepName: "internalComplaintQuestion",
        previousStep: "remedy",
        text: "internalComplaintQuestion.text",
        buttons: [
            {
                label: "internalComplaintQuestion.buttons.yes", icon: "CheckIcon",
                goto: "internalComplaintDescription",
                update: {field: "complainantComplainedAlreadyInternally", value: true}
            },
            {
                label: "internalComplaintQuestion.buttons.no",
                icon: "DoNotDisturbOnIcon",
                goto: "code",
            }]
    }, {
        stepName: "internalComplaintDescription",
        previousStep: "internalComplaintQuestion",
        text: "internalComplaintDescription.text",
        field: {
            name: "internalComplaintDescriptions",
            type: "array",
            label: "internalComplaintDescription.field.label",
            submit: {
                label: "internalComplaintDescription.field.submit.label",
                icon: "SendIcon",
                goto: "internalComplaintRemedy"
            }
        },
        buttons: [{
            label: "internalComplaintDescription.buttons.skip",
            icon: "SkipNextIcon",
            goto: "internalComplaintRemedy",
        }]
    }, {
        stepName: "internalComplaintRemedy",
        previousStep: "internalComplaintDescription",
        text: "internalComplaintRemedy.text",
        field: {
            name: "internalComplaintRemedy",
            type: "text",
            label: "internalComplaintRemedy.field.label",
            submit: {
                label: "internalComplaintRemedy.field.submit.label", icon: "SendIcon", goto: "code"
            }
        },
        buttons: [{label: "internalComplaintRemedy.buttons.skip", icon: "SkipNextIcon", goto: "code",}]
    }, {
        stepName: "code",
        previousStep: "explanation",
        text: "code.text",
        toast: "code.toast",
        buttons: [{label: "code.buttons.ok", icon: "CheckCircleIcon", goto: "code2",}]
    }, {
        stepName: "code2",
        previousStep: "code",
        text: "code2.text",
        buttons: [{label: "code.buttons.ok", icon: "CheckCircleIcon", goto: "code3",}]
    }, {
        stepName: "code3",
        previousStep: "code2",
        text: "code3.text",
        toast: "code3.toast",
        buttons: [{label: "code3.buttons.ok", icon: "CheckCircleIcon", goto: "phoneNumbers",}]
    }, {
        stepName: "phoneNumbers",
        previousStep: "code3",
        text: "phoneNumbers.text",
        field: {
            name: "phoneNumbers",
            type: "phoneNumbersArray",
            label: "phoneNumbers.field.label",
            submit: {
                label: "phoneNumbers.field.submit.label", icon: "SendIcon", goto: "emailAddresses"
            }
        },
        buttons: [{label: "skip", icon: "SkipNextIcon", goto: "emailAddresses",}]
    }, {
        stepName: "emailAddresses",
        previousStep: "phoneNumbers",
        text: "emailAddresses.text",
        field: {
            name: "emailAddresses",
            type: "array",
            label: "emailAddresses.field.label",
            submit: {
                label: "emailAddresses.field.submit.label", icon: "SendIcon", goto: "nextSteps"
            }
        },
        buttons: [{label: "skip", icon: "SkipNextIcon", goto: "nextSteps",}]
    }, {
        stepName: "nextSteps",
        previousStep: "emailAddresses",
        text: "nextSteps.text",
        buttons: [{label: "nextSteps.buttons.ok", icon: "CheckCircleIcon", goto: "anythingElse",}]
    }, {
        stepName: "anythingElse",
        previousStep: "nextSteps",
        text: "anythingElse.text",
        buttons: [{label: "anythingElse.buttons.yes", icon: "CheckIcon", goto: "somethingElse",},
            {label: "anythingElse.buttons.no", icon: "DoNotDisturbOnIcon", goto: "theEnd",}]
    }, {
        stepName: "somethingElse",
        previousStep: "anythingElse",
        text: "somethingElse.text",
        field: {
            name: "additionalComments",
            type: "array",
            label: "somethingElse.field.label",
            submit: {
                label: "somethingElse.field.submit.label", icon: "SendIcon", goto: "theEnd"
            }
        },
        buttons: [{label: "somethingElse.buttons.skip", icon: "SkipNextIcon", goto: "theEnd",}]
    }, {
        stepName: "theEnd",
        previousStep: "anythingElse",
        title: "theEnd.title",
        text: "theEnd.text"
    }

];
