import React from "react";
import Container from '@mui/material/Container';
import Highlight from "../components/Highlight";
import LoadingGIF from "../components/common/LoadingGIF";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Grid from "@mui/material/Grid";

export const ProfileComponent = () => {
    const {user} = useAuth0();

    return (
        <Container>
            <Grid container spacing={5} sx={{padding: 5}}>
                <Grid item>
                    <img
                        src={user.picture}
                        alt="Profile"
                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                    />
                </Grid>
                <Grid item>
                    <h2>{user.name}</h2>
                    <p className="lead text-muted">{user.email}</p>
                </Grid>
            </Grid>


            <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
        </Container>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <LoadingGIF/>,
});
