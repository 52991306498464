import React from "react";
import MessageByComplainant from "../components/messages/MessageByComplainant";
import MessageByHandler from "../components/messages/MessageByHandler";
import {useTranslation} from "react-i18next";
import MessageByAtlat from "../components/messages/MessageByAtlat";


export const AllMessages = ({data, text2Speech}) => {

    const {t} = useTranslation("complaintForComplainant"); // TODO CS-227

    function allMessages() {

        let messages = [<MessageByComplainant key={data.id} message={data} text2Speech={text2Speech}/>];

        if (data.originalAdditionalComments)
            for (let i = 0; i < data.originalAdditionalComments.length; i++) {
                let translatedDescription = (data.translatedAdditionalComments && data.translatedAdditionalComments.length > i) ?
                    (t("additionalComment") + ": " + data.translatedAdditionalComments[i]) : null;
                let text = data.originalAdditionalComments[i];
                messages.push(<MessageByComplainant key={"additionalComment_" + i} text2Speech={text2Speech} message={{
                    ...data,
                    translatedDescription: translatedDescription,
                    text: text
                }}/>)
            }

        if (data.files)
            for (let i = 0; i < data.files.length; i++) {
                messages.push(<MessageByComplainant key={"file_" + i} message={data} text2Speech={text2Speech}
                                                    file={data.files[i]}/>)
            }

        for (let i = 0; i < data.messages.length; i++) {
            let message = data.messages[i];
            if (message.type === "COMPLAINANT") {
                messages.push(<MessageByComplainant key={message.id} message={message} text2Speech={text2Speech}/>)
            } else if (message.type === "USER") {
                messages.push(<MessageByHandler key={message.id} message={message} text2Speech={text2Speech}/>)
            } else if (message.type === "RETRIBUTION_CHECKIN") {
                messages.push(<MessageByAtlat key={message.id} message={message} text2Speech={text2Speech}/>)
            }
        }

        return <>{messages}</>;
    }


    return (
        <>
            {allMessages()}
        </>
    );
};

export default AllMessages;
