import React, {useEffect, useState} from 'react';
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import IconButton from "@mui/material/IconButton";
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOff";

function Speech({
                    id = null,
                    text,
                    style = {},
                    startBtn = <IconButton color="primary" aria-label="start text to speech">
                        <RecordVoiceOverIcon></RecordVoiceOverIcon>
                    </IconButton>,
                    stopBtn = <IconButton color="primary" aria-label="stop text to speech">
                        <VoiceOverOffIcon></VoiceOverOffIcon>
                    </IconButton>,
                    pitch = 5,
                    rate = 5,
                    volume = 10,
                    lang = ''
                }) {
    const [speechIcon, setSpeechIcon] = useState(startBtn);
    const [speechId, setSpeechId] = useState(null);

    useEffect(() => {
        window.speechSynthesis?.cancel()
    }, []);

    if (!text) return null;

    function reset() {
        setSpeechId(null)
        setSpeechIcon(startBtn)
    }

    function newSpeech() {
        setSpeechId(id)
        setSpeechIcon(stopBtn)

        if (Array.isArray(text)) text = text.join(' ');
        text = text.replace('<0>', ' ');
        text = text.replace('<\/0>', ' ');

        const utterance = new window.SpeechSynthesisUtterance(text?.replace(/\s/g, ' '))
        utterance.pitch = pitch / 5;
        utterance.rate = rate / 5;
        utterance.volume = volume / 10;
        utterance.lang = lang;
        utterance.onend = reset;
        utterance.onerror = reset;

        const voices = window.speechSynthesis.getVoices();

        if (lang === "en-US" || lang === "en") {
            let speechSynthesisVoices = voices.filter((voice) => voice.lang.indexOf('en-US') === 0)
                .filter(voice => voice.voiceURI === "Samantha");
            utterance.voice = speechSynthesisVoices.length > 0 ? speechSynthesisVoices[0] : null;
        }

        try {
            window.speechSynthesis.cancel();
            window.speechSynthesis.speak(utterance);
        } catch (err) {
            console.log(err);
        }
    }

    function speech() {
        if (!window.speechSynthesis) return alert('Browser not supported! Try some other browser');
        // window.speechSynthesis is an API which enables to convert text into speech
        if (!window.speechSynthesis.speaking) return newSpeech(); // window.speechSynthesis.speaking checks it window.speechSynthesis is speaking or not
        try {
            window.speechSynthesis.cancel();
            speechId === id ? reset() : newSpeech();
        } catch (err) {
            console.log(err)
        }
    }


    return <span role='button' style={style} onClick={speech}>{speechIcon}</span>
}

export default Speech;
