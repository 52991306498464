import * as React from 'react';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormControl from "@mui/material/FormControl";
import {Checkbox, FormControlLabel, FormGroup, InputLabel, Select, Stack, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import LoadingButton from "@mui/lab/LoadingButton";
import {hasBrand} from "../../../../utils/users";
import {useDispatch} from "react-redux";
import {useInviteToCommentOnCAPMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import {MailOutline} from "@mui/icons-material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function InviteToComment({
                                            item,
                                            cap,
                                            user,
                                            disabled,
                                            permissions,
                                            isInternal,
                                            complaint,
                                            team
                                        }) {
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();

    const [inviteToCommentOnCAP] = useInviteToCommentOnCAPMutation();

    const validationSchema = Yup.object().shape({
        user: Yup.string().required('User to invite to comment is required'),
        note: Yup.string().required('Note is required'),
    });

    const {register, handleSubmit, formState: {errors}, reset, control} = useForm({
        resolver: yupResolver(validationSchema)
    });

    if (disabled || !complaint || !team) return null;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const usersWithComplaintBrand = team.users.filter((user) => hasBrand(user, complaint.brand))
        .filter(userWithComplaintBrand => userWithComplaintBrand.id !== user.id);
    const activeExternals = complaint.externals.filter(external => !external.invitationRevoked)
        .filter(external => external.id !== user.id);
    const thereAreInternalTeamMembers = usersWithComplaintBrand && usersWithComplaintBrand.length > 0;
    const thereAreActiveExternals = activeExternals && activeExternals.length > 0;
    let defaultUser;
    if (thereAreInternalTeamMembers) defaultUser = usersWithComplaintBrand[0].id;
    else if (thereAreActiveExternals) defaultUser = activeExternals[0].id;

    let select = [];
    if (thereAreInternalTeamMembers) {
        select.push(<ListSubheader key={"internalheader"}>Internal</ListSubheader>)
        const map = usersWithComplaintBrand.map((userWithComplaintBrand) => (
            <MenuItem value={userWithComplaintBrand.id} key={"internal" + userWithComplaintBrand.id}>
                {`${userWithComplaintBrand.firstName} ${userWithComplaintBrand.lastName} - ${userWithComplaintBrand.email}`}
            </MenuItem>));
        select.push(...map);
    }
    if (thereAreActiveExternals) {
        select.push(<ListSubheader key={"externalheader"}>External</ListSubheader>);
        const map1 = activeExternals.map((external) => (
            <MenuItem value={external.invitedUser.id}
                      key={"external" + external.invitedUser.id}>
                {`${external.invitedUser.firstName} ${external.invitedUser.lastName} - ${external.invitedUser.email}`}
            </MenuItem>));
        select.push(...map1);
    }

    if (!isInternal) return null;
    if (!(thereAreInternalTeamMembers || thereAreActiveExternals)) return null;
    if (!permissions.has("WRITE_CAP")) return null;

    const onSubmit = async (data) => {
        setSending(true);

        try {
            await inviteToCommentOnCAP({
                capId: cap.id,
                capItemId: item.id,
                userId: data.user,
                note: data.note,
                inviterInCC: data.inviterInCC
            }).unwrap()
            dispatch(openSnackbar({message: "Invitation to comment created successfully", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Invitation to comment creation failed", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }
    };

    const {ref, ...rest} = register('user')

    return (
        <div>
            <Button variant="text" color="primary" size="large" disabled={disabled} onClick={handleOpen}
                    startIcon={<MailOutline/>}>
                Invite to comment
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>

                        <Typography variant="h6">Invite Someone to Comment</Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                            {item.title}
                        </Typography>

                        <Typography variant="body1" sx={{mt: 2}}>
                            Whom do you want to invite to comment?
                        </Typography>
                        <FormControl sx={{minWidth: 250, mt: 1}}>
                            <InputLabel htmlFor="selectUserToInviteToComment">User to invite to comment</InputLabel>
                            <Select
                                labelId="selectUserToInviteToComment"
                                id="user"
                                name="user"
                                label="User to invite to comment"
                                inputRef={ref}  {...rest}
                                defaultValue={defaultUser}
                                error={!!errors.user}
                            >
                                {select}
                            </Select>
                        </FormControl>
                        <Typography variant="inherit" color="error">
                            {errors.user?.message}
                        </Typography>

                        <Typography variant="body1" sx={{mt: 2}}>
                            Why do you invite them to comment?
                        </Typography>
                        <TextField
                            sx={{mt: 1}}
                            fullWidth
                            id="note"
                            name="note"
                            multiline
                            rows={3}
                            label="Note"
                            {...register('note')}
                            error={!!errors.note}
                        />
                        <Typography variant="inherit" color="error">
                            {errors.note?.message}
                        </Typography>

                        <FormGroup sx={{mt: 1}}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        control={control}
                                        name="inviterInCC"
                                        id="inviterInCC"
                                        defaultValue="false"
                                        inputRef={register()}
                                        render={({field: {onChange}}) => (
                                            <Checkbox
                                                color="primary"
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        )}
                                    />
                                }
                                label={`Send copy of invitation mail to ${user.email}`}/>
                        </FormGroup>

                        <Stack direction="row" spacing={2} justifyContent="end" sx={{mt: 3}}>
                            <LoadingButton
                                loading={sending}
                                variant='contained'
                                color='primary'
                                onClick={handleSubmit(onSubmit)}
                            >
                                Invite to comment
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
