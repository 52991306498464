import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {Stack} from "@mui/material";
import LoadingGIF from "../components/common/LoadingGIF";
import NotAuthorized from "../components/common/NotAuthorized";
import Error from "../components/common/Error";
import {useGetLinksQuery, useLazyOpenPageQuery, useLinkClickedMutation} from "../app/apiSlice";
import {useRouteMatch} from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import {Helmet} from "react-helmet";

const Links = ({preview, name}) => {

    const match = useRouteMatch();

    const {
        data: links,
        isLoading: loading,
        isSuccess,
        error: error
    } = useGetLinksQuery(name ? name : (match.params.name ? match.params.name : 'default'));
    const [triggerOpenPage] = useLazyOpenPageQuery();

    const [linkWasClicked, setLinkWasClicked] = useState(false);

    const [linkClicked] = useLinkClickedMutation();
    const [linksDef, setLinksDef] = useState([]);

    useEffect(() => {
        if (!preview) triggerOpenPage("links");
    }, []);
    useEffect(() => {
        if (isSuccess) {
            setLinksDef(links.current);
        }
    }, [links]);

    if (loading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (error) return <Error/>;

    async function clicked(link) {
        if (!preview) {
            try {
                setLinkWasClicked(true);
                linkClicked(link);
            } catch (err) {
            }
        }
        window.open(link, "_self");
    }

    return (<Box>
        <Helmet
            onChangeCClientState={() => {
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', linksDef.subtitle);
                }
                const metaOGDescription = document.querySelector('meta[name="og:description"]');
                if (metaOGDescription) {
                    metaOGDescription.setAttribute('content', linksDef.subtitle);
                }
                const metaOGTitle = document.querySelector('meta[name="og:title"]');
                if (metaOGTitle) {
                    metaOGTitle.setAttribute('content', linksDef.title ? linksDef.title : "");
                }
            }}>

            >
            <title> {linksDef.title ? linksDef.title : ""}</title>
            <meta name="og:description" property="og:description" content={linksDef.subtitle}/>
            <meta name="og:title" property="og:title" content={linksDef.title ? linksDef.title : ""}/>
        </Helmet>
        <Stack direction="row" justifyContent="center" sx={{mt: 2}}>
            <Box className="logo"/>
        </Stack>
        <Typography variant="h6" gutterBottom component="h1" align={"center"}>
            {linksDef.title ? linksDef.title : ""}
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div" align={"center"}>
            {linksDef.subtitle}
        </Typography>

        <Stack direction="column" spacing={1} sx={{mt: 2, width: "90%", maxWidth: "450px", marginX: "auto"}}>
            {linksDef && linksDef.links && linksDef.links.map((link) => (
                <LoadingButton loading={linkWasClicked} variant="contained" fullWidth
                               key={link.label}
                               onClick={() => clicked(link.url)}>
                    <span> {link.label}</span>
                </LoadingButton>))}


        </Stack>

    </Box>);
};

export default Links;
