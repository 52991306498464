import React, {useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../common/LoadingGIF";
import NotAuthorized from "../common/NotAuthorized";
import Error from "../common/Error";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Stack, TextField} from "@mui/material";
import {useGetReportSchemasQuery, useSaveReportSchemaMutation} from "../../app/apiSlice";
import Grid from "@mui/material/Grid";
import {useDispatch} from "react-redux";
import {openSnackbar} from "../../app/snackbarSlice";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import UploadBotFile from "./UploadBotFile";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import MapAnswerToReply from "./MapAnswerToReply";

const ReportPrep = () => {

    const dispatch = useDispatch();

    const [reportSchema, setReportSchema] = useState({});
    const {data: schemas, isLoading: schemasloading, error: schemasError} = useGetReportSchemasQuery();

    const [saveReportSchema] = useSaveReportSchemaMutation();
    const [selectedSchema, setSelectedSchema] = useState(null);

    if (schemasloading) return <LoadingGIF/>;
    if (schemasError && schemasError.status === 403) return <NotAuthorized/>;
    if (schemasError) return <Error/>;

    const onSubmit = async () => {
        try {
            await saveReportSchema(reportSchema).unwrap()
            dispatch(openSnackbar({message: "Report Schema Saved", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to save report schema", severity: "error"}));
        }
    }

    const handleChange = (event) => {
        setSelectedSchema(event.target.value);
    };

    function answerIsNotMapped(answer) {
        return !answer.mappedByDefaultReply && !answer.mappedByHand;
    }

    function answersChips(item) {

        let chips = [];


        if (item.answers?.[Symbol.iterator]) {


            for (let answer of item.answers) {

                let color = "default";

                if (answerIsNotMapped(answer)) color = "error";
                if (answer.mappedByHand) color = "info";

                chips.push(<Chip key={answer.text} color={color} avatar={<Avatar>{answer.count}</Avatar>}
                                 label={answer.text}/>)

            }
        }


        return chips;
    }

    function createMappings(item) {
        let mappings = [];
        if (item.answers?.[Symbol.iterator]) {
            for (let answer of item.answers) {
                if (answerIsNotMapped(answer)) {
                    mappings.push(
                        <MapAnswerToReply answer={answer} schemaName={selectedSchema.name} item={item}/>
                    );

                }
            }
        }
        return mappings;
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between" sx={{p: 3}}>
                <Typography variant="h3" component="span">
                    Report Prep
                </Typography>
            </Stack>
            <Box sx={{minWidth: 120, p: 3}}>
                <FormControl fullWidth>
                    <InputLabel id="schema-label">Schema Name</InputLabel>
                    <Select
                        labelId="schema-label"
                        id="schema"
                        value={selectedSchema}
                        label="Schema Name"
                        onChange={handleChange}
                    >
                        {schemas.map(schema => <MenuItem key={schema.id} value={schema}>{schema.id}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>

            <Grid container spacing={2} p={3}>
                <Grid item xs={8}>
                    <Box sx={{minWidth: 120}}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Report Schema Name"
                            value={reportSchema.name}
                            onChange={(e) => setReportSchema({...reportSchema, id: e.target.value})}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={onSubmit}>Save</Button>
                    <Button variant="outlined" onClick={() => setReportSchema({})}>Cancel</Button>

                </Grid>

            </Grid>

            {selectedSchema &&
                <Card sx={{m: 3}}>
                    <CardHeader
                        title={<Typography variant="h6">{selectedSchema.id}</Typography>}
                    />
                    <CardContent>

                        <UploadBotFile meta={{"schemaName": selectedSchema.id}}
                                       label={"Upload Proto Bot Definition"}/>

                        <br/>

                        {selectedSchema.items && selectedSchema.items.map((item) => <div>
                            <Typography variant="h5">{item.name}</Typography>

                            Question (English)
                            <br/>
                            {item.question.en}
                            <br/>
                            {item.question.my && <>Question (Burmese) <br/></>}

                            {item.question.my && item.question.my}
                            <br/>
                            Quick Replies (English)
                            <br/>
                            {item.quickReply.en.map((reply => <Chip key={reply} label={reply}>{reply}</Chip>))}
                            <br/>
                            {item.quickReply.my && <>Quick Replies (Burmese) <br/></>}
                            {item.quickReply.my && item.quickReply.my.map((reply => <Chip key={reply}
                                                                                          label={reply}>{reply}</Chip>))}
                            <br/>
                            Actual Replies
                            <br/>
                            {item.answers && answersChips(item)}
                            <br/>
                            {item.answers && createMappings(item)}

                        </div>)}
                    </CardContent>
                </Card>}
        </>);
};

export default withAuthenticationRequired(ReportPrep, {
    onRedirecting: () => <LoadingGIF/>,
});
