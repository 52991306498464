import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import Highlight from "../Highlight";
import {useSaveAnswerMappingMutation} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";
import {useDispatch} from "react-redux";


export default function MapAnswerToReply({answer, schemaName, item}) {

    const dispatch = useDispatch();
    const [selectedReply, setSelectedReply] = useState(null);
    const [saveAnswerMapping] = useSaveAnswerMappingMutation();


    const onSubmit = async () => {
        try {
            await saveAnswerMapping({"name": schemaName,
                "itemName" : item.name,"answer": answer.text, "reply":selectedReply}).unwrap()
            dispatch(openSnackbar({message: "Answer mapping Saved", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to save answer mapping", severity: "error"}));
        }
    }

    return (
        <Grid container spacing={2} p={1} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5}>
                {answer.text} ({answer.unmappedAnswer?.language})
                - {answer.unmappedAnswer?.translation} (translated)
            </Grid>
            <Grid item xs={5}>
                <Box sx={{minWidth: 120, p: 3}}>
                    <FormControl fullWidth>
                        <InputLabel id="reply-label">Reply</InputLabel>
                        <Select
                            labelId="reply-label"
                            id="reply"
                            value={selectedReply}
                            label="Reply"
                            onChange={(event) => {
                                setSelectedReply(event.target.value);
                            }}
                        >
                            {item.quickReply.en.map(reply => <MenuItem
                                value={reply}>{reply}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={onSubmit} variant="contained">Map To Reply</Button>
            </Grid>
        </Grid>);


}
