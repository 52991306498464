import React from 'react';
import InvestigationTodos from "./InvestigationTodos";
import InvestigationItems from "./InvestigationItems";
import InvestigationStatusInfo from "./InvestigationStatusInfo";
import {setTabInfo, tabs} from "../../../../utils/tabs";
import Alert from '@mui/material/Alert';


export default function InvestigationTab({complaint, setTab, statusInfo, permissions, me, isInternal, investigation}) {
    setTabInfo("investigation", complaint);

    if (!investigation)
        return <Alert variant="outlined" severity="warning"
                      onClick={() => setTab(tabs.facts)}>
            The investigation has not been started. Please start the investigation from the Complaint Facts tab.
        </Alert>;

    return (
        <>
            <InvestigationStatusInfo statusInfo={statusInfo} complaint={complaint} setTab={setTab}/>

            {(isInternal) &&
                <InvestigationTodos investigation={investigation}/>}

            {investigation && (permissions.has("READ_ALL_INVESTIGATION_ITEMS") || permissions.has("READ_OWN_INVESTIGATION_ITEMS")) &&
                <InvestigationItems complaint={complaint} investigation={investigation} isInternal={isInternal}
                                    setTab={setTab} permissions={permissions} user={me}/>}
        </>
    );
}
