import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import {useFieldArray, useForm} from "react-hook-form";
import Typography from "@mui/material/Typography";
import {Stack, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {useCreateLinksDefinitionMutation, useUpdateLinksDefinitionMutation} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";

export const LinksMgmtForm = ({links, id, name, create, setNewLinks}) => {

    const dispatch = useDispatch();
    const [updateLinksDefinition] = useUpdateLinksDefinitionMutation();
    const [createLinksDefinition] = useCreateLinksDefinitionMutation();
    const [sending, setSending] = useState(false);
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(),
        subtitle: Yup.string().required(),
        links: Yup.array()
            .of(
                Yup.object().shape({
                    url: Yup.string().required(),
                    label: Yup.string().required(),
                })
            )
            .min(1)
            .required(),
    });

    const {
        control,
        register,
        formState: {errors},
        clearErrors,
        setValue, reset,
        unregister,
        handleSubmit,
        trigger
    } = useForm({
        defaultValues: links,
        resolver: yupResolver(validationSchema)
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "links"
    });

    const onSubmit = async (data, e) => {
        console.log("submit");
        e?.preventDefault();
        setSending(true);

        try {
            console.log(name)
            console.log(data)
            if (create) await createLinksDefinition({name: name, data}).unwrap();
            else await updateLinksDefinition({id: id, data}).unwrap();
            dispatch(openSnackbar({message: "Link definition saved successfully", severity: "success"}));
            setSending(false);
            reset(links);
            if (setNewLinks) setNewLinks(false)
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to save link definition", severity: "error"}));
            setSending(false);
            reset(links);
        }
    };

    const onErrors = async (data, e) => {
        console.log("onErrors");
        console.log(data);
        console.log(e);

    }

    const isInitalRender = React.useRef(true);
    const appendLink = () => {
        append({label: "", url: ""});

        if (errors.links?.type === "min") {
            console.log(errors)
            clearErrors("links"); // always clear errors when there is add action.
        }
    };

    useEffect(() => {
        if (!fields.length && !isInitalRender.current) {
            trigger("links");
        }

        if (isInitalRender.current) {
            isInitalRender.current = false;
        }
    }, [fields, register, setValue, unregister, trigger]);

    useEffect(() => {
        reset(links);
    }, [links]);

    return (
        <Box sx={{mt: 2}}>

            <Typography variant="h6">Links Definition</Typography>

            <TextField
                sx={{mt: 1}}
                fullWidth
                id="title"
                name="title"
                label="title"
                {...register('title')}
                error={!!errors.title}
            />
            <Typography variant="inherit" color="error">
                {errors.title?.message}
            </Typography>

            <TextField
                sx={{mt: 1}}
                fullWidth
                id="subtitle"
                name="subtitle"
                label="subtitle"
                {...register('subtitle')}
                error={!!errors.subtitle}
            />
            <Typography variant="inherit" color="error">
                {errors.subtitle?.message}
            </Typography>

            {fields.map((question, questionIndex) => (
                <div key={question.id}>
                    <TextField
                        sx={{mt: 1}} fullWidth label="url"
                        {...register(`links[${questionIndex}].url`)}
                        control={control}
                        defaultValue=""
                    />

                    <TextField
                        sx={{mt: 1}} fullWidth label="label"
                        {...register(`links[${questionIndex}].label`)}
                        control={control}
                        defaultValue=""
                    />

                    <Button sx={{mt: 1}}
                            variant='contained'
                            color='primary'
                            type="button"
                            onClick={() => {
                                remove(questionIndex);
                                trigger();
                            }}
                    >
                        Remove link
                    </Button>
                </div>
            ))}

            <Typography variant="inherit" color="error">
                {errors.links?.message}
            </Typography>

            <Button sx={{mt: 1}}
                    variant='contained'
                    color='primary'
                    type="button" onClick={appendLink}>
                Add Link
            </Button>

            <Stack direction="row" spacing={2} justifyContent="end" sx={{mt: 3}}>
                <LoadingButton
                    loading={sending}
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit(onSubmit, onErrors)}
                >
                    Save
                </LoadingButton>
            </Stack>
        </Box>
    );
};
