import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import InvestigationItemComment from "./InvestigationItemComment";
import CollapsibleSection from "../../../common/CollapsibleSection";
import {useDispatch} from "react-redux";
import {useCreateInvestigationCommentMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function InvestigationComments({item, investigation, permissions, user, isInternal}) {

    const [comment, setComment] = useState("");
    const dispatch = useDispatch();

    const [createInvestigationComment] = useCreateInvestigationCommentMutation();

    if (!isInternal) return null;
    if (!item.id) return null;

    async function handleReply() {
        try {
            await createInvestigationComment({
                investigationId: investigation.id,
                complaintId: investigation.complaintId,
                investigationItemId: item.id,
                comment: comment
            }).unwrap()
            dispatch(openSnackbar({message: "Investigation comment created successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Investigation comment creation failed", severity: "error"}));
        }
    }


    function comments() {

        return <>
            {permissions.has("WRITE_INVESTIGATION_COMMENTS") &&
                <>
                    {item.comments.map(comment => {
                        return <InvestigationItemComment key={comment.id} comment={comment}/>
                    })}
                    <Paper>
                        <Grid container sx={{p: 2}}>
                            <Grid item xs={8} sm={10}>
                                <Typography variant="span" gutterBottom>
                                    <TextField id="standard-basic" label="Your comment" value={comment} multiline
                                               rows={4} fullWidth
                                               onInput={e => setComment(e.target.value)}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Button
                                    variant="contained"
                                    sx={{p: 2, ml: 1,}}
                                    endIcon={<Avatar src={user.picture} style={{width: 30, height: 30}}/>}
                                    onClick={handleReply}
                                >
                                    Comment
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </>}
        </>;
    }

    const numberOfComments = (item.comments && item.comments.length);

    return (<CollapsibleSection title={<>{`${numberOfComments} Comments`}</>} children={comments()}/>)
}
