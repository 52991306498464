import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VerifyLocation from "./VerifyLocation";
import VerifyDateAndTimeTab from "./VerifyDateAndTimeTab";
import {ComplaintFactsCategory} from "./ComplaintFactsCategory";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import HelpPopUp from "../../../common/HelpPopUp";
import RejectComplaint from "./RejectComplaint";
import {Alert, Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import {statusLabel} from "../../labels";
import {setTabInfo, tabs} from "../../../../utils/tabs";
import {ComplaintFactsSummary} from "./ComplaintFactsSummary";
import {ComplaintFactsSeverity} from "./ComplaintFactsSeverity";
import DownloadComplaintPDFButton from "../investigation/DownloadComplaintPDFButton";
import SendComplaintPDFWithEmailButton from "../investigation/SendComplaintPDFWithEmailButton";
import File from "../../../common/file/File";
import ChoseResponsiblePerson from "./ChoseResponsiblePerson";
import ChosePolicy from "./ChosePolicy";
import CollapsibleSection from "../../../common/CollapsibleSection";
import {useDispatch} from "react-redux";
import {
    useGetFileQuery,
    useGetLocationNamesQuery,
    useUpdateComplaintQuestionsAnsweredMutation, useUpdateComplaintStatusMutation,
    useUpdateComplaintTitleMutation
} from "../../../../app/apiSlice";
import LoadingGIF from "../../../common/LoadingGIF";
import Error from "../../../common/Error";
import {openSnackbar} from "../../../../app/snackbarSlice";
import SelectSubBrand from "./SelectSubBrand";


const PlausiCheck = ({complaint, statusInfo, permissions, title, setTitle, me, setTab, isInternal}) => {
    setTabInfo("facts", complaint);

    const {data: locations, isFetching: loadingLocations, error: locationsError} = useGetLocationNamesQuery();
    const [updateComplaintTitle] = useUpdateComplaintTitleMutation();
    const [updateComplaintQuestionsAnswered] = useUpdateComplaintQuestionsAnsweredMutation();
    const [updateComplaintStatus] = useUpdateComplaintStatusMutation();

    const [questionsAnswered, setQuestionsAnswered] = useState(complaint.questionsAnswered);

    const dispatch = useDispatch();


    if (loadingLocations) return <LoadingGIF/>;
    if (locationsError) return <Error/>;


    async function startInvestigation() {
        let newStatus = null;
        if (complaint.status === "OPEN") {
            newStatus = `UNDER_INVESTIGATION`;
        }
        if (newStatus)
            try {
                await updateComplaintStatus({complaintId: complaint.id, status: newStatus}).unwrap()
                dispatch(openSnackbar({message: "Complaint status updated successfully", severity: "success"}));
            } catch (err) {
                dispatch(openSnackbar({message: "Complaint status update failed", severity: "error"}));
            }
    }

    const handleTitleChange = async (event) => {
        setTitle(event.target.value);
        try {
            await updateComplaintTitle({complaintId: complaint.id, title: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Complaint title updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint title update failed", severity: "error"}));
        }
    };

    const handleQuestionsAnsweredChange = async (event) => {
        setQuestionsAnswered(event.target.checked);
        try {
            await updateComplaintQuestionsAnswered({
                complaintId: complaint.id,
                questionsAnswered: event.target.checked
            }).unwrap()
            dispatch(openSnackbar({message: "Complaint questions answered updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint questions answered update failed", severity: "error"}));
        }
    }

    function complaintStatus(statusInfo) {

        if (!statusInfo) return null;

        if (statusInfo.type === "REJECTED") {
            return (
                <Alert variant="outlined" severity="error" sx={{mt: 2}}>
                    The complaint has been closed
                    by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()} {new Date(statusInfo.date).toLocaleTimeString()} and
                    will not be investigated.
                    {complaint.status === "CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION" && " The reason" +
                        " is that the complainant has not been reachable for clarification. "}
                    {complaint.status === "CLOSED_NOT_PLAUSIBLE" && " The reason is that the complaint is not" +
                        " plausible. "}
                    {complaint.status === "CLOSED_REASON_NOT_ELIGIBLE" && " The reason is that the reason" +
                        " for the complaint is not eligible. "}
                    {statusInfo.comment && <>Additional comment by {statusInfo.statusInfoUser}:
                        <i>{statusInfo.comment}</i></>}
                </Alert>
            );
        }

        if (statusInfo.type === "START_INVESTIGATION") {
            return (
                <Alert variant="outlined" severity="info" sx={{mt: 2}}>
                    The complaint has been accepted and the investigation has been started
                    by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()} {new Date(statusInfo.date).toLocaleTimeString()}.
                </Alert>
            );
        }
    }

    return (
        <div>
            {permissions.has("READ_TITLE") &&
                <TextField sx={{width: "100%"}}
                           id="complaintTitle"
                           label="Complaint Title"
                           defaultValue={title}
                           onBlur={handleTitleChange}
                           placeholder="complaint title"
                           variant="outlined"
                           disabled={!permissions.has("UPDATE_COMPLAINT_TITLE")}
                />}

            {(isInternal) && <>
                <Typography variant="h6" sx={{mt: 3}}>
                    Complaint PDF
                    <HelpPopUp text={"Download and share the complaint summary PDF."}/>
                </Typography>
                <DownloadComplaintPDFButton complaint={complaint} user={me}/>
                <span style={{marginLeft: 10}}><SendComplaintPDFWithEmailButton complaint={complaint} user={me}/></span>
            </>}

            <CollapsibleSection title={"Complaint Data"} titleVariant={"h5"} mt={3} children={
                <>
                    {complaint.anonymous &&
                        <Typography variant="body2" gutterBottom sx={{mt: 3}}>
                            This complaint has been filed anonymously.
                        </Typography>}
                    {!complaint.anonymous &&
                        <Typography variant="body2" gutterBottom sx={{mt: 3}}>
                            This complaint has not been filed anonymously. The name of the complainant is not shown to
                            protect
                            their identity.
                        </Typography>}

                    {permissions.has("READ_ORIGINAL_COMPLAINT_MESSAGE") && complaint.originalTextLanguage !== "en" && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Translated complaint text
                            <HelpPopUp text="If you have questions or this is too vague, head to the complainant conversation tab and ask
                            the complainant for more details."/>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.translatedDescription}
                        </Typography>
                    </>}
                    {permissions.has("READ_ORIGINAL_COMPLAINT_MESSAGE") && complaint.originalTextLanguage === "en" && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Original complaint text
                            <HelpPopUp text="If you have questions or this is too vague, head to the complainant conversation tab and ask
                            the complainant for more details."/>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.text}
                        </Typography>
                    </>}

                    {isInternal && complaint.files && complaint.files.length > 0 && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Files shared by complainant
                        </Typography>
                        <Grid container spacing={1}> {complaint.files && complaint.files.map((file, index) => (
                            <Grid item key={index}>
                                <File complaintId={complaint.id} file={file} mayUpdate={false}  useGetFileQuery={useGetFileQuery}/>
                            </Grid>))}
                        </Grid>
                    </>}

                    {isInternal && complaint.originalAdditionalComments && complaint.originalAdditionalComments.length > 0 &&
                        (!complaint.translatedAdditionalComments || complaint.translatedAdditionalComments.length === 0) && <>
                            <Typography variant="h6" sx={{mt: 2}}>
                                Additional Comments
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {complaint.originalAdditionalComments.map(comment => {
                                    return <li key={comment.id}>{comment}</li>
                                })}
                            </Typography>
                        </>}

                    {isInternal && complaint.translatedAdditionalComments && complaint.translatedAdditionalComments.length > 0 && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Translated Additional Comments
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.translatedAdditionalComments.map(comment => {
                                return <li key={comment.id}>{comment}</li>
                            })}
                        </Typography>
                    </>}

                    {isInternal && complaint.translatedQuestionsByComplainant && complaint.translatedQuestionsByComplainant.length > 0 && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Translated Questions By Complainant
                        </Typography>
                        <Stack direction="row" alignItems="flex-end">
                            <Typography variant="body2" gutterBottom>
                                {complaint.translatedQuestionsByComplainant.map(comment => {
                                    return <li key={comment.id}>{comment}</li>
                                })}
                            </Typography>
                            <Button variant="outlined" sx={{ml: 5,}}
                                    onClick={() => setTab(tabs.conversation)}>answer</Button>
                            <FormGroup sx={{ml: 5,}}>
                                <FormControlLabel control={<Checkbox checked={questionsAnswered}
                                                                     onChange={handleQuestionsAnsweredChange}/>}
                                                  label="Mark question(s) as answered"/>
                            </FormGroup>
                        </Stack></>}

                    {isInternal && complaint.originalQuestionsByComplainant && complaint.originalQuestionsByComplainant.length > 0
                        && (!complaint.translatedQuestionsByComplainant || complaint.translatedQuestionsByComplainant.length === 0) && <>
                            <Typography variant="h6" sx={{mt: 2}}>
                                Questions By Complainant
                            </Typography>
                            <Stack direction="row" alignItems="flex-end">
                                <Typography variant="body2" gutterBottom>
                                    {complaint.originalQuestionsByComplainant.map(comment => {
                                        return <li key={comment.id}>{comment}</li>
                                    })}
                                </Typography>
                                <Button variant="outlined" sx={{ml: 5,}}
                                        onClick={() => setTab(tabs.conversation)}>answer</Button>
                                <FormGroup sx={{ml: 5,}}>
                                    <FormControlLabel control={<Checkbox checked={complaint.questionsAnswered}
                                                                         onChange={handleQuestionsAnsweredChange}/>}
                                                      label="Mark question(s) as answered"/>
                                </FormGroup>
                            </Stack></>}

                    {isInternal && complaint.translatedOtherPeopleWithSameProblem && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Translated Other People With the Same Problem
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.translatedOtherPeopleWithSameProblem}
                        </Typography>
                    </>}

                    {isInternal && complaint.originalOtherPeopleWithSameProblem && !complaint.translatedOtherPeopleWithSameProblem && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Other People With the Same Problem
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.originalOtherPeopleWithSameProblem}
                        </Typography>
                    </>}

                    {isInternal && complaint.translatedInternalComplaintDescriptions && complaint.translatedInternalComplaintDescriptions.length > 0 && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Translated Description of Internal Complaint Mechanism
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.complainantComplainedAlreadyInternally && "The complainant indicated that they did use" +
                                " the internal complaint mechanism."}
                            {!complaint.complainantComplainedAlreadyInternally && "The complainant indicated that they did" +
                                " not use the internal complaint mechanism."}
                            {complaint.translatedInternalComplaintDescriptions.map(comment => {
                                return <li key={comment.id}>{comment}</li>
                            })}
                        </Typography>
                    </>}

                    {isInternal && complaint.translatedInternalComplaintDescriptions && complaint.translatedInternalComplaintDescriptions.length > 0 &&
                        (!complaint.originalInternalComplaintDescriptions || complaint.originalInternalComplaintDescriptions.length === 0) && <>
                            <Typography variant="h6" sx={{mt: 2}}>
                                Description of Internal Complaint Mechanism
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {complaint.complainantComplainedAlreadyInternally && "The complainant indicated that they did use" +
                                    " the internal complaint mechanism."}
                                {!complaint.complainantComplainedAlreadyInternally && "The complainant indicated that they did" +
                                    " not use the internal complaint mechanism."}
                                {complaint.originalInternalComplaintDescriptions.map(comment => {
                                    return <li key={comment.id}>{comment}</li>
                                })}
                            </Typography>
                        </>}

                    {isInternal && complaint.translatedRemedySuggestionByComplainant && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Translated Remedy Suggestion By Complainant
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.translatedRemedySuggestionByComplainant}
                        </Typography>
                    </>}

                    {isInternal && complaint.originalRemedySuggestionByComplainant && !complaint.translatedRemedySuggestionByComplainant && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Remedy Suggestion By Complainant
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {complaint.originalRemedySuggestionByComplainant}
                        </Typography>
                    </>}

                    {permissions.has("READ_STATUS") && <>
                        <Typography variant="h6" sx={{mt: 2}}>
                            Status
                        </Typography>
                        <Typography variant="body2"
                                    gutterBottom> {statusLabel(complaint.status)}
                        </Typography>
                    </>}

                    <SelectSubBrand complaint={complaint} me={me} isInternal={isInternal}/>
                    <VerifyLocation complaint={complaint} locations={locations} permissions={permissions}/>
                    <VerifyDateAndTimeTab complaint={complaint} permissions={permissions}/>
                    <ChoseResponsiblePerson complaint={complaint} permissions={permissions} isInternal={isInternal}
                                            user={me}/>

                </>
            }/>

            <CollapsibleSection title={"Evaluation"} titleVariant={"h5"} mt={3} children={
                <>
                    <ChosePolicy complaint={complaint} permissions={permissions} isInternal={isInternal} user={me}/>
                    <ComplaintFactsCategory complaint={complaint} permissions={permissions}/>
                    <ComplaintFactsSummary complaint={complaint} permissions={permissions}/>
                    <ComplaintFactsSeverity complaint={complaint} permissions={permissions}/>

                    {complaint.status === "OPEN" &&
                        <Grid container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center" sx={{paddingTop: 2}}
                              columnSpacing={4}>

                            <Grid item>
                                <RejectComplaint complaint={complaint} permissions={permissions}/>
                            </Grid>

                            <Grid item>
                                <Button variant="contained"
                                        color="primary"
                                        onClick={startInvestigation}
                                        disabled={!permissions.has("UPDATE_COMPLAINT_STATUS")}>
                                    Plausible - Start Investigation
                                </Button>
                            </Grid>
                        </Grid>}
                    {complaint.status !== "OPEN" && complaintStatus(statusInfo)}
                </>
            }/>


        </div>
    );
}

export default PlausiCheck;
