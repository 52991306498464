import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import {dateAndTime} from "../../../common/dates";
import {useDispatch} from "react-redux";
import {useReplyToInvitationToCommentMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function ReplyToInvitationToComment({invitationToComment, user, capId, capItemId}) {

    const [invitedComment, setInvitedComment] = useState("");
    const dispatch = useDispatch();

    const [replyToInvitationToComment] = useReplyToInvitationToCommentMutation();

    async function handleReplyToInvitationToComment() {
        try {
            await replyToInvitationToComment({
                capId: capId,
                capItemId: capItemId,
                invitationId: invitationToComment.id,
                reply: invitedComment
            }).unwrap()
            dispatch(openSnackbar({
                message: "Reply to invitation to comment created successfully",
                severity: "success"
            }));
        } catch (err) {
            dispatch(openSnackbar({message: "Reply to invitation to comment creation failed", severity: "error"}));
        }
    }

    return (
        <Paper sx={{mt: 2}} key={invitationToComment.id}>
            <Grid container sx={{p: 2}}>
                <Grid item xs={12} sx={{pb: 1}}>
                    <Typography variant="span">
                        <b>{`${invitationToComment.invitingUser.firstName} ${invitationToComment.invitingUser.lastName} invited you to comment on ${dateAndTime(invitationToComment.cdate)}:`}
                            <br/>
                            "{invitationToComment.note}" - Please answer.
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                    <Typography variant="span" gutterBottom>
                        <TextField id="standard-basic" label="Your reply" value={invitedComment} multiline
                                   rows={4} fullWidth
                                   onInput={e => setInvitedComment(e.target.value)}/>
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <Button
                        variant="contained"
                        sx={{p: 2, ml: 1,}}
                        endIcon={<Avatar src={user.picture} style={{width: 30, height: 30}}/>}
                        onClick={handleReplyToInvitationToComment}
                    >
                        Reply
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}
