import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import {countries} from "../../../countries/countries"
import HelpPopUp from "../../../common/HelpPopUp";
import Box from "@mui/material/Box";
import {useDispatch} from 'react-redux'
import {openSnackbar} from "../../../../app/snackbarSlice";
import {useUpdateComplaintLocationMutation} from "../../../../app/apiSlice";

export default function VerifyLocation({complaint, locations, permissions}) {

    const dispatch = useDispatch();

    const [updateComplaintLocation] = useUpdateComplaintLocationMutation();

    const [country, setCountry] = useState(complaint.location ? complaint.location.country : "");
    const [location, setLocation] = useState((complaint.location && locations) ?
        locations.find(l => l.id === complaint.location.id) : (complaint.location ? complaint.location : {}));

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleChangeLocation = async (event) => {
        setLocation(event.target.value);
        try {
            await updateComplaintLocation({complaintId: complaint.id, locationId: event.target.value.id}).unwrap()
            dispatch(openSnackbar({message: "Location updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Location update failed", severity: "error"}));
        }
    }

    const label = (country) => {
        return countries[country];
    };

    if (!permissions.has("READ_LOCATION")) return null;

    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>Location <HelpPopUp
                text="This is the location that the complainant wrote in the original complaint.
                Please choose the matching location from the master data."/></Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box>
                    <Typography variant="h6" color="black" sx={{mb: 3}}>Written by
                        complainant {complaint.translatedLocationNameGivenByComplainant ? "(translated)" : ""}</Typography>
                    <Typography
                        component="span">{complaint.translatedLocationNameGivenByComplainant ?
                        complaint.translatedLocationNameGivenByComplainant :
                        complaint.locationNameGivenByComplainant}</Typography>
                </Box>
                {permissions.has("UPDATE_COMPLAINT_LOCATION") &&
                    <Box sx={{ml: 5}}>
                        <Typography variant="h6" color="black" sx={{mb: 1}}>Please select.</Typography>
                        <FormControl variant="outlined" component="span">
                            <InputLabel id="country-label">Country</InputLabel>
                            <Select
                                labelId="country-label"
                                id="country"
                                value={country}
                                onChange={handleChangeCountry}
                                label="Country"
                                disabled={!permissions.has("UPDATE_COMPLAINT_LOCATION")}
                                sx={{minWidth: 120, marginRight: 2}}
                            >
                                {
                                    [...new Set(locations.map(location => location.country))]
                                        .sort()
                                        .map((country) => (
                                            <MenuItem key={country} value={country}>
                                                {label(country)}
                                            </MenuItem>
                                        ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined">
                            <InputLabel id="location-label">Location</InputLabel>
                            <Select
                                labelId="location-label"
                                id="location"
                                value={location}
                                onChange={handleChangeLocation}
                                label="Location"
                                disabled={!permissions.has("UPDATE_COMPLAINT_LOCATION")}
                                sx={{minWidth: 120}}
                            >
                                {
                                    locations
                                        .filter(location => location.country === country)
                                        .map(location =>
                                            <MenuItem key={location.id} value={location}>
                                                {location.name}
                                            </MenuItem>
                                        )
                                }
                            </Select>
                        </FormControl>
                    </Box>}
                {!permissions.has("UPDATE_COMPLAINT_LOCATION") &&
                    <Box sx={{ml: 5}}>
                        <Typography variant="h6" color="black" sx={{mb: 1}}>Factory</Typography>
                        <FormControl variant="outlined" component="span">
                            <InputLabel id="country-label">Country</InputLabel>
                            <Select
                                labelId="country-label"
                                id="country"
                                value={country}
                                label="Country"
                                disabled={!permissions.has("UPDATE_COMPLAINT_LOCATION")}
                                sx={{minWidth: 120, marginRight: 2}}
                            >
                                <MenuItem key={country} value={country}>
                                    {label(country)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined">
                            <InputLabel id="location-label">Location</InputLabel>
                            <Select
                                labelId="location-label"
                                id="location"
                                value={location}
                                label="Location"
                                disabled={!permissions.has("UPDATE_COMPLAINT_LOCATION")}
                                sx={{minWidth: 120}}
                            >
                                <MenuItem key={location ? location.id : 0} value={location}>
                                    {location ? location.name : ""}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>}
            </Box>
        </>
    );
}
