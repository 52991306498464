import React from 'react';
import CAPForm from "./CAPForm";
import {setTabInfo, tabs} from "../../../../utils/tabs";
import Alert from '@mui/material/Alert';


export default function CAPTab({complaint, statusInfo, setTab, isInternal, permissions, user, cap}) {
    setTabInfo("cap", complaint);

    if (!cap)
        return <Alert variant="outlined" severity="warning"
                      onClick={() => setTab(tabs.facts)}>
            A CAP can only be started when the complaint is admitted as plausible in the Complaints Facts tab.
        </Alert>;

    if (!permissions.has("READ_CAP")) return null;

    return (
        <CAPForm cap={cap} complaint={complaint} statusInfo={statusInfo} isInternal={isInternal}
                 permissions={permissions} user={user}/>
    );
}
