import React, {useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import NotAuthorized from "../../components/common/NotAuthorized";
import LoadingGIF from "../../components/common/LoadingGIF";
import Box from "@mui/material/Box";
import Error from "../../components/common/Error";
import {useGetTemplateQuery, useGetTranslationsQuery} from "../../app/apiSlice";
import Highlight from "../Highlight";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import {InputAdornment, Stack, TextField} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as MuiIcons from "@mui/icons-material";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const DynamicIcon = ({icon, ...props}) => {
    if (!icon) return <></>;
    if (MuiIcons[icon.slice(0, -4)]) {
        return React.createElement(MuiIcons[icon.slice(0, -4)], props);
    } else {
        return <></>
    }
}
const Template = () => {

    const match = useRouteMatch();

    const {data: template, isLoading: loading, isSuccess, error: error} = useGetTemplateQuery(match.params.id);
    const {
        data: translations,
        isLoading: loadingTranslations,
        isSuccess: translationsIsSuccess,
        error: translationsError
    } = useGetTranslationsQuery();
    const [stepNames, setStepNames] = useState([]);
    const [selectedStep, setSelectedStep] = useState(null);

    useEffect(() => {
        if (isSuccess && template && template.steps) {
            setStepNames(template.steps.map(step => step.stepName));
            setSelectedStep(template.steps[0]);
        }
    }, [template]);

    if (loading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (error || !selectedStep) return <Error/>;

    function buttons(step) {
        if (!step) return null;
        if (!step.buttons) return null;
        return <>
            <Typography variant="h5" sx={{mt: 2}}>Buttons</Typography>
            {step.buttons.map(button => <Card sx={{mt: 2, p: 2}}>
                <CardHeader title={<Typography variant="h6">{button.label}</Typography>}/>
                <TextField fullWidth sx={{mt: 2}} id={button.label} name={"label"}
                           label="label" value={button.label} disabled/>
                {translations && translations.map(translation => <TextField fullWidth sx={{mt: 2}}
                                                                            id={button.label + "." + translation.locale}
                                                                            name={"label_" + translation.locale}
                                                                            label={"label " + translation.locale}
                                                                            value={translation.messages[button.label]}
                                                                            disabled/>)}

                {template.type !== "WHATSAPP" && <TextField sx={{m: 2}} id={button.icon} name={"icon"}
                                                            label="icon" value={button.icon} disabled
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <DynamicIcon key={button.icon}
                                                                                     icon={button.icon}
                                                                                     color="primary"></DynamicIcon>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                />}

                <FormControl sx={{mt: 2}}>
                    <InputLabel id="goto-select-label">Go To</InputLabel>
                    <Select
                        labelId="goto-select-label"
                        id="goto-select"
                        value={button.goto}
                        label="Go To"
                        //onChange={handleChange}
                    >
                        {stepNames.map(name => <MenuItem value={name}>{name}</MenuItem>)}
                    </Select>
                </FormControl>

                {button.update &&<>
                    <FormControl sx={{mt: 2}}>
                        <InputLabel id="update-field-select-label">Update Field</InputLabel>
                        <Select
                            labelId="update-field-select-label"
                            id="update-field-select"
                            value={button.update.field}
                            label="Update Field"
                            //onChange={handleChange}
                        >
                            {updateFields.map(field => <MenuItem value={field.name}>{field.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <TextField fullWidth sx={{mt: 2}} id={button.update} name={"update field value"}
                               label="update field value" value={button.update.value} disabled/>
                </>}

            </Card>)}
        </>;
    }

    function links(step) {
        if (!step) return null;
        if (!step.links) return null;
        return <>
            <Typography variant="h5" sx={{mt: 2}}>Links</Typography>
            {step.links.map(link => <Card sx={{mt: 2, p: 2}}>
                <CardHeader title={<Typography variant="h6">{link.label}</Typography>}/>
                <TextField fullWidth sx={{mt: 2}} id={link.label} name={"label"}
                           label="label" value={link.label} disabled/>
                {translations && translations.map(translation => <TextField fullWidth sx={{mt: 2}}
                                                                            id={link.label + "." + translation.locale}
                                                                            name={"label_" + translation.locale}
                                                                            label={"label " + translation.locale}
                                                                            value={translation.messages[link.label]}
                                                                            disabled/>)}

                {template.type !== "WHATSAPP" && <TextField fullWidth sx={{mt: 2}} id={link.icon} name={"icon"}
                                                            label="icon" value={link.icon} disabled
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <DynamicIcon key={link.icon} icon={link.icon}
                                                                                     color="primary"></DynamicIcon>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                />}
                <TextField fullWidth sx={{mt: 2}} id={link.url} name={"url"}
                           label="url" value={link.url} disabled/>
                {translations && translations.map(translation => <TextField fullWidth sx={{mt: 2}}
                                                                            id={link.url + "." + translation.locale}
                                                                            name={"url_" + translation.locale}
                                                                            label={"url " + translation.locale}
                                                                            value={translation.messages[link.url]}
                                                                            disabled/>)}
                {template.type !== "WHATSAPP" && <TextField sx={{mt: 2}} id={link.type} name={"type"}
                                                            label="type" value={link.type} disabled/>}

            </Card>)}
        </>;
    }

    const fields = [
        {"name": "questionsByComplainant", "type": "array"},
        {"name": "complainantName", "type": "text"},
        {"name": "location", "type": "text"},
        {"name": "complaintDateString", "type": "text"},
        {"name": "complaintText", "type": "text"},
        {"name": "files", "type": "files"},
        {"name": "otherPeopleWithSameProblem", "type": "text"},
        {"name": "relationToCompany", "type": "text"},
        {"name": "remedySuggestionByComplainant", "type": "text"},
        {"name": "internalComplaintDescriptions", "type": "array"},
        {"name": "internalComplaintRemedy", "type": "text"},
        {"name": "phoneNumbers", "type": "phoneNumbersArray"},
        {"name": "emailAddresses", "type": "array"},
        {"name": "additionalComments", "type": "array"},
        {"name": "additionalFiles", "type": "files"},
    ];

    const updateFields = [
        {"name": "dataAgreement", "type": "boolean"},
        {"name": "anonymous", "type": "boolean"},
        {"name": "complainantWorksAtLocation", "type": "boolean"},
        {"name": "complainantComplainedAlreadyInternally", "type": "boolean"},
    ];

    function field(step) {
        if (!step) return null;
        if (!step.field) return null;
        return <>
            <Typography variant="h5" sx={{mt: 2}}>Field</Typography>
            <Card sx={{mt: 2, p: 2}}>
                <CardHeader title={<Typography variant="h6">{step.field.name}</Typography>}/>

                <FormControl fullWidth>
                    <InputLabel id={step.field.name + ".select.label"}>Field Name</InputLabel>
                    <Select
                        labelId={step.field.name + ".select.label"}
                        id={step.field.name + ".select"}
                        value={step.field.name}
                        label="Field Name"
                        //     onChange={handleChange}
                    >
                        {fields.map(field => <MenuItem value={field.name}>{field.name}</MenuItem>)}

                    </Select>
                </FormControl>

                <TextField fullWidth sx={{mt: 2}} id={step.field.type} name={"type"}
                           label="type" value={step.field.type} disabled/>
                <TextField fullWidth sx={{mt: 2}} id={step.field.label} name={"label"}
                           label="label" value={step.field.label} disabled/>
                {translations && translations.map(translation =>
                    <TextField fullWidth sx={{mt: 2}}
                               id={step.field.label + "." + translation.locale}
                               name={"field.label_" + translation.locale}
                               label={"field.label " + translation.locale}
                               value={translation.messages[step.field.label]}
                               disabled/>)}

                {step.field.submit && <>
                    {template.type !== "WHATSAPP" &&
                        <TextField fullWidth sx={{mt: 2}} id={step.field.submit.label} name={"label"}
                                   label="label" value={step.field.submit.label}
                                   disabled/>}
                    {template.type !== "WHATSAPP" &&
                        <TextField fullWidth sx={{mt: 2}} id={step.field.submit.icon} name={"icon"}
                                   label="icon" value={step.field.submit.icon} disabled
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <DynamicIcon key={step.field.submit.icon}
                                                            icon={step.field.submit.icon}
                                                            color="primary"></DynamicIcon>
                                           </InputAdornment>
                                       ),
                                   }}
                        />}
                    {template.type !== "WHATSAPP" &&
                        <TextField fullWidth sx={{mt: 2}} id={step.field.submit.create} name={"create"}
                                   label="create" value={step.field.submit.create} disabled/>}

                    <FormControl sx={{mt: 2}}>
                        <InputLabel id="goto-select-label">Go To</InputLabel>
                        <Select
                            labelId="goto-select-label"
                            id="goto-select"
                            value={step.field.submit.goto}
                            label="Go To"
                            //onChange={handleChange}
                        >
                            {stepNames.map(name => <MenuItem value={name}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>

                </>}

                {step.field.condition && <>
                    <TextField fullWidth sx={{mt: 2}} id={step.field.condition.type} name={"condition type"}
                               label="condition type" value={step.field.condition.type} disabled/>

                    <TextField fullWidth sx={{mt: 2}} id={step.field.condition.value} name={"condition value"}
                               label="condition value" value={step.field.condition.value} disabled/>

                    <FormControl sx={{mt: 2}}>
                        <InputLabel id="condition-goto-select-label">Condition Go To</InputLabel>
                        <Select
                            labelId="condition-goto-select-label"
                            id="condition-goto-select"
                            value={step.field.condition.goto}
                            label="Condition Go To"
                            //onChange={handleChange}
                        >
                            {stepNames.map(name => <MenuItem value={name}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </>}

            </Card>
        </>;
    }

    function gif(step) {
        if (!step) return null;
        if (!step.gif) return null;
        return <>
            <Typography variant="h5" sx={{mt: 2}}>GIF</Typography>
            <Card sx={{mt: 2, p: 2}}>
                <CardHeader title={<Typography variant="h6">{step.gif.alt}</Typography>}/>

                <TextField fullWidth sx={{mt: 2}} id={step.gif.url} name={"url"}
                           label="url" value={step.gif.url} disabled/>

                {translations && translations.map(translation => <TextField fullWidth sx={{mt: 2}}
                                                                            id={selectedStep.stepName + ".gif.url." + translation.locale}
                                                                            name={"gif.url_" + translation.locale}
                                                                            label={"gif.url " + translation.locale}
                                                                            value={translation.messages[step.gif.url]}
                                                                            disabled/>)}

                <TextField fullWidth sx={{mt: 2}} id={step.gif.alt} name={"alt"}
                           label="alt" value={step.gif.alt} disabled/>
                {translations && translations.map(translation => <TextField fullWidth sx={{mt: 2}}
                                                                            id={selectedStep.stepName + ".gif.alt." + translation.locale}
                                                                            name={"gif.alt_" + translation.locale}
                                                                            label={"gif.alt " + translation.locale}
                                                                            value={translation.messages[step.gif.alt]}
                                                                            disabled/>)}

            </Card>
        </>;
    }

    const drawerWidth = 240;
    let language = "en";
    return (
        <Box p={3} sx={{mt: 2}}>

            <Box sx={{display: 'flex'}}>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                    }}
                >
                    <Toolbar/>
                    <List>
                        {template.steps.map((step, index) => (
                            <ListItem key={step.stepName} disablePadding onClick={() => setSelectedStep(step)}>
                                <ListItemButton>
                                    <ListItemText primary={step.stepName}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{flexGrow: 1, bgcolor: 'background.default', p: 1}}
                >
                    <Toolbar/>

                    <Typography component="h2" variant="h5" color="primary" gutterBottom>
                        {template.name}
                    </Typography>

                    <Stack spacing={2} direction="row">
                        <Chip label={"ID: " + template.id} color="primary"/>
                        <Chip label={"Created: " + new Date(template.date).toLocaleDateString()} color="primary"/>
                        <Chip label={"Version: " + template.version} color="primary"/>
                        <Chip label={"Type: " + template.type} color="primary"/>
                        {template.brands.map(brand => <Chip label={brand} color="secondary"></Chip>)}
                    </Stack>

                    <Card sx={{mt: 2}} key={selectedStep.stepName}>
                        <CardHeader title={<Typography variant="h4">{selectedStep.stepName}</Typography>}/>
                        <CardContent>
                            {template.type !== "WHATSAPP" &&
                                <TextField fullWidth id={selectedStep.stepName + ".title"} name={"title"}
                                           label="title" value={selectedStep.title} disabled/>}

                            {template.type !== "WHATSAPP" && translations && translations.map(translation => <TextField
                                fullWidth sx={{mt: 2}}
                                id={selectedStep.stepName + ".title." + translation.locale}
                                name={"title_" + translation.locale}
                                label={"title " + translation.locale}
                                value={translation.messages[selectedStep.title]}
                                disabled/>)}

                            <TextField fullWidth sx={{mt: 2}} id={selectedStep.stepName + ".text"} name={"text"}
                                       label="text" value={selectedStep.text} disabled/>

                            {translations && translations.map(translation => <TextField fullWidth sx={{mt: 2}}
                                                                                        id={selectedStep.stepName + ".text." + translation.locale}
                                                                                        name={"text_" + translation.locale}
                                                                                        label={"text " + translation.locale}
                                                                                        value={translation.messages[selectedStep.text]}
                                                                                        disabled/>)}

                            <FormControl fullWidth sx={{mt: 2}}>
                                <InputLabel id="previous-step-select-label">Previous Step</InputLabel>
                                <Select
                                    labelId="previous-step-select-label"
                                    id="previous-step-select"
                                    value={selectedStep.previousStep}
                                    label="Previous Step"
                                    //onChange={handleChange}
                                >
                                    {stepNames.map(name => <MenuItem value={name}>{name}</MenuItem>)}
                                </Select>
                            </FormControl>

                            {template.type !== "WHATSAPP" &&
                                <TextField fullWidth sx={{mt: 2}} id={selectedStep.stepName + ".toast"} name={"toast"}
                                           label="toast" value={selectedStep.toast} disabled/>}


                            {buttons(selectedStep)}
                            {links(selectedStep)}
                            {field(selectedStep)}
                            {gif(selectedStep)}

                        </CardContent>


                        <Highlight>
                            {JSON.stringify(selectedStep, null, 2)}
                        </Highlight>
                    </Card>

                </Box>
            </Box>
        </Box>
    )
        ;
};

export default withAuthenticationRequired(Template, {
    onRedirecting: () => <LoadingGIF/>,
});
