export function determineTab(complaint) {
    if (!complaint || !complaint.status) return {tab: parseInt(localStorage.getItem(`tab_${complaint.id}`)) || 0};

    let tab, facts, conversation, investigation, cap;

    switch (complaint.status) {
        case "OPEN" :
            tab = 1;
            break;
        case "UNDER_INVESTIGATION" :
            tab = 2;
            facts = "done"
            break;
        case "CAP??": // todo
            tab = 3;
            break;
        case "DONE" :
            tab = 3;
            break;
        default:
            tab = 1;
    }

    if (localStorage.getItem(`tab_${complaint.id}`)) {
        tab = parseInt(localStorage.getItem(`tab_${complaint.id}`));
    } else {
        localStorage.setItem(`tab_${complaint.id}`, tab);
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const tabFromUrl = queryParameters.get("tab");
    if (tabFromUrl === "cap") tab = 3;

    return {
        "facts": facts,
        "conversation": conversation,
        "investigation": investigation,
        "cap": cap,
        "tab": tab
    }

}

export const tabs = {
    conversation: 0,
    facts: 1,
    investigation: 2,
    cap: 3,
    external: 4,
    activity: 5,
}

export function setTabInfo(tabName, complaint) {
    localStorage.setItem(`tab_${complaint.id}`, tabs[tabName])
}