import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import NotAuthorized from "../../components/common/NotAuthorized";
import LoadingGIF from "../../components/common/LoadingGIF";
import Box from "@mui/material/Box";
import Error from "../../components/common/Error";
import {
    useGetBrandsQuery,
    useGetComplaintsWithoutBrandQuery, useGetMeQuery,
} from "../../app/apiSlice";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {CardActionArea} from "@mui/material";
import AssignBrand from "./AssignBrand";

const AssignBrands = () => {

    const {data: me, isLoading: meLoading, error: meError} = useGetMeQuery();
    const {data: complaints, isLoading: loading, isSuccess, error: error} = useGetComplaintsWithoutBrandQuery();
    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();


    if (loading || meLoading || brandsLoading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (meError && meError.status === 403) return <NotAuthorized/>;
    if (brandsError && brandsError.status === 403) return <NotAuthorized/>;
    if (error || meError || brandsError) return <Error/>;

    return (
        <Box sx={{mt: 2, p: 2}}>

            <Typography variant="h3" component="h1" gutterBottom>
                Assign Brands
            </Typography>

            {complaints && complaints.map(complaint =>

                <Card sx={{mt: 1, p: 1, maxWidth: "550px"}} key={complaint.id}>
                    <CardActionArea>
                        <CardHeader
                            title={<Typography variant="h5">Complaint C{complaint.id}</Typography>}
                        />
                        <CardContent>
                            <AssignBrand complaint={complaint} brands={brands}/>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
        </Box>
    );
};

export default withAuthenticationRequired(AssignBrands, {
    onRedirecting: () => <LoadingGIF/>,
});
