import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {url} from "../urls"
import {sec} from "./security";

function isUnsecuredEndpoint(api) {
    if (api.endpoint === "sendSupportEmail") return true;
    if (api.endpoint === "openPage") return true;
    if (api.endpoint === "getLinks") return true;
    if (api.endpoint === "checkin") return true;
    if (api.endpoint === "getComplaintByCode") return true;
    if (api.endpoint === "getComplaintCode") return true;
    if (api.endpoint === "updateComplaint") return true;
    if (api.endpoint === "createComplaint") return true;
    if (api.endpoint === "addMessageToComplaintByComplainant") return true;
    return api.endpoint === "linkClicked";

}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${url}/api`,
        prepareHeaders: async (headers, api) => {
            if (isUnsecuredEndpoint(api)) return headers;
            const access_token = await sec.getAccessTokenSilently()();
            if (access_token) {
                headers.set('authorization', `Bearer ${access_token}`)
            }
            return headers;
        },
    }),
    tagTypes: ['Complaint', 'Complaints', 'Investigation', 'CAPs', 'CAP', 'Brands', 'Brand', 'Users', 'User',
        'Location', 'Locations', 'Roles', 'Permissions', 'Links', 'File', 'Files', 'Team', 'Template', 'Templates',
        'Translations', 'ReportSchemas', 'ReportSchema', 'Training', 'Trainings'],
    endpoints: builder => ({
        getLocationNames: builder.query({
            query: () => '/locations/names',
            providesTags: (result, error, arg) => [{type: 'Locations'}]
        }),
        getLocations: builder.query({
            query: () => '/locations',
            providesTags: (result, error, arg) => [{type: 'Locations'}]
        }),
        getLocation: builder.query({
            query: (locationId) => `/locations/${locationId}`,
            providesTags: (result, error, arg) => [{type: 'Location', id: arg}, {type: 'Locations'}]
        }),
        createLocation: builder.mutation({
            query: (location) => ({
                url: `/locations`,
                method: 'POST',
                body: location
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Location', id: arg.id}, {type: 'Locations'}]
        }),
        updateLocation: builder.mutation({
            query: (location) => ({
                url: `/locations`,
                method: 'PATCH',
                body: location
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Location', id: arg.id}, {type: 'Locations'}]
        }),
        deleteLocation: builder.mutation({
            query: (location) => ({
                url: `/locations/${location.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Location', id: arg.id}, {type: 'Locations'}]
        }),

        getComplaint: builder.query({
            query: ({complaintId, language}) => `/complaints/${complaintId}/${language}`,
            providesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        getComplaintByCode: builder.query({
            query: (code) => `/complaint/bycode/${code}`,
            providesTags: (result, error, arg) => [{type: 'Complaint', id: result.id}]
        }),
        getComplaintCode: builder.query({
            query: () => `/complaint/code`,
        }),
        getComplaints: builder.query({
            query: () => `/complaints/all`,
            providesTags: (result, error, arg) => [{type: 'Complaints'}]
        }),
        getComplaintsWithoutBrand: builder.query({
            query: () => `/complaints/without-brand`,
        }),
        getComplaintStatistics: builder.query({
            query: () => '/complaints/statistics'
        }),
        createComplaint: builder.mutation({
            query: (data) => ({
                url: `/complaint`,
                method: 'POST',
                body: data
            }),
        }),
        updateComplaint: builder.mutation({
            query: (data) => ({
                url: `/complaint`,
                method: 'PUT',
                body: data
            }),
        }),
        updateComplaintLocation: builder.mutation({
            query: ({complaintId, locationId}) => ({
                url: `/complaints/${complaintId}/location/${locationId}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintSubBrand: builder.mutation({
            query: ({complaintId, brandId}) => ({
                url: `/complaints/${complaintId}/subBrand/${brandId}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintCategory: builder.mutation({
            query: ({complaintId, categories}) => ({
                url: `/complaints/${complaintId}/categories/${categories}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintCategoryNote: builder.mutation({
            query: ({complaintId, note}) => ({
                url: `/complaints/${complaintId}/category/note/${note}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintCategoryChemical: builder.mutation({
            query: ({complaintId, chemical, value}) => ({
                url: `/complaints/${complaintId}/categories/chemical/${chemical}/${value}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintMainPolicy: builder.mutation({
            query: ({complaintId, mainPolicy}) => ({
                url: `/complaints/${complaintId}/mainPolicy/${mainPolicy}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintAdditionalPolicy: builder.mutation({
            query: ({complaintId, additionalPolicy}) => ({
                url: `/complaints/${complaintId}/additionalPolicy/${additionalPolicy}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintDate: builder.mutation({
            query: ({complaintId, date}) => ({
                url: `/complaints/${complaintId}/date/${date}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintLegalCategory: builder.mutation({
            query: ({complaintId, legalCategory}) => ({
                url: `/complaints/${complaintId}/legalCategory/${legalCategory}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintRegion: builder.mutation({
            query: ({complaintId, region}) => ({
                url: `/complaints/${complaintId}/region/${region}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintSummary: builder.mutation({
            query: ({complaintId, summary}) => ({
                url: `/complaints/${complaintId}/summary/${summary}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintSeverity: builder.mutation({
            query: ({complaintId, severity}) => ({
                url: `/complaints/${complaintId}/severity/${severity}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        updateComplaintSeverityNote: builder.mutation({
            query: ({complaintId, note}) => ({
                url: `/complaints/${complaintId}/severity/note/${note}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        updateComplaintTitle: builder.mutation({
            query: ({complaintId, title}) => ({
                url: `/complaints/${complaintId}/title/${title}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => {
                return [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
            }
        }),
        assignComplaintBrand: builder.mutation({
            query: ({complaintId, brandId}) => ({
                url: `/complaints/assign-brand/${complaintId}/${brandId}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => {
                return [{type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
            }
        }),
        updateComplaintQuestionsAnswered: builder.mutation({
            query: ({complaintId, questionsAnswered}) => ({
                url: `/complaints/${complaintId}/questionsAnswered/${questionsAnswered}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        addMessageToComplaintByHandler: builder.mutation({
            query: ({complaintId, reply}) => ({
                url: `/complaints/${complaintId}/message`,
                method: 'POST',
                body: {message: reply, type: "USER"}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        addMessageToComplaintByComplainant: builder.mutation({
            query: ({complaintId, reply}) => ({
                url: `/complaint/${complaintId}/message`,
                method: 'POST',
                body: {message: reply, type: "COMPLAINANT"}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        updateComplaintStatus: builder.mutation({
            query: ({complaintId, status, comment}) => ({
                url: `/complaints/${complaintId}/status/${status}`,
                method: 'POST',
                body: {comment: comment}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}, {
                type: 'Investigation',
                id: arg.complaintId
            }, {type: 'CAP', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        deleteBrand: builder.mutation({
            query: (brand) => ({
                url: `/brands/${brand.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: "Brands"}, {type: 'Brand', id: arg.id}]
        }),
        updateBrand: builder.mutation({
            query: (brand) => ({
                url: `/brands`,
                method: 'PUT',
                body: brand
            }),
            invalidatesTags: (result, error, arg) => [{type: "Brands"}, {type: 'Brand', id: arg.id}]
        }),
        createBrand: builder.mutation({
            query: (brand) => ({
                url: `/brands`,
                method: 'POST',
                body: brand
            }),
            invalidatesTags: (result, error, arg) => [{type: "Brands"}, {type: 'Brand', id: arg.id}]
        }),
        getBrands: builder.query({
            query: () => '/brands',
            providesTags: [{type: 'Brands'}]
        }),
        getBrand: builder.query({
            query: brandId => `/brands/${brandId}`,
            providesTags: (result, error, arg) => [{type: 'Brands'}, {type: 'Brand', id: arg}]
        }),
        getCAP: builder.query({
            query: complaintId => `/caps/complaint/${complaintId}`,
            providesTags: (result, error, arg) => [{type: 'CAPs'}, {type: 'CAP', id: result ? result.id : null}]
        }),
        updateCAP: builder.mutation({
            query: (cap) => ({
                url: `/caps`,
                method: 'POST',
                body: cap
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.id}]
        }),
        saveCAPItem: builder.mutation({
            query: ({capId, capItem}) => ({
                url: `/caps/${capId}/items`,
                method: 'POST',
                body: capItem
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.capId}]
        }),
        deleteCAPItem: builder.mutation({
            query: ({capId, capItemId}) => ({
                url: `/caps/${capId}/items/${capItemId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.capId}]
        }),
        saveCAPItemStatus: builder.mutation({
            query: ({capId, capItemId, status}) => ({
                url: `/caps/${capId}/items/${capItemId}/status`,
                method: 'POST',
                body: status
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.capId}]
        }),
        updateCAPStatus: builder.mutation({
            query: ({capId, status, complaintId}) => ({
                url: `/caps/${capId}/status/${status}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.capId},
                {type: 'Complaint', id: arg.complaintId}]
        }),
        createCAPComment: builder.mutation({
            query: ({capId, capItemId, comment}) => ({
                url: `/caps/${capId}/${capItemId}/comments`,
                method: 'POST',
                body: {"content": comment}
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.capId}]
        }),
        inviteToCommentOnCAP: builder.mutation({
            query: ({capId, capItemId, userId, note, inviterInCC}) => ({
                url: `/caps/${capId}/${capItemId}/comments/invite`,
                method: 'POST',
                body: {
                    "userId": userId,
                    "note": note,
                    "inviterInCC": inviterInCC
                }
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.capId}]
        }),
        replyToInvitationToComment: builder.mutation({
            query: ({capId, capItemId, invitationId, reply,}) => ({
                url: `/caps/${capId}/${capItemId}/comments/invite/${invitationId}/reply`,
                method: 'POST',
                body: {"reply": reply,}
            }),
            invalidatesTags: (result, error, arg) => [{type: "CAPs"}, {type: 'CAP', id: arg.capId}]
        }),

        getInvestigation: builder.query({
            query: complaintId => `/investigations/complaint/${complaintId}`,
            providesTags: (result, error, arg) => [{type: 'Investigation', id: arg}]
        }),
        createInvestigationComment: builder.mutation({
            query: ({investigationId, complaintId, investigationItemId, comment}) => ({
                url: `/investigations/${investigationId}/${investigationItemId}/comments`,
                method: 'POST',
                body: {"content": comment}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        addTodoToInvestigation: builder.mutation({
            query: ({investigationId, complaintId, data}) => ({
                url: `/investigations/${investigationId}/todos`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        updateInvestigationTodo: builder.mutation({
            query: ({investigationId, complaintId, data}) => ({
                url: `/investigations/${investigationId}/todos/${data.id}`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        deleteInvestigationTodo: builder.mutation({
            query: ({investigationId, complaintId, data}) => ({
                url: `/investigations/${investigationId}/todos/${data.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        checkInvestigationTodo: builder.mutation({
            query: ({investigationId, complaintId, data}) => ({
                url: `/investigations/${investigationId}/todos/${data.id}/check`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        uncheckInvestigationTodo: builder.mutation({
            query: ({investigationId, complaintId, data}) => ({
                url: `/investigations/${investigationId}/todos/${data.id}/uncheck`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        saveInvestigationItem: builder.mutation({
            query: ({investigationId, complaintId, investigationItem}) => ({
                url: `/investigations/${investigationId}/items`,
                method: 'POST',
                body: investigationItem
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        deleteInvestigationItem: builder.mutation({
            query: ({investigationId, complaintId, investigationItemId}) => ({
                url: `/investigations/${investigationId}/items/${investigationItemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId}]
        }),
        closeInvestigation: builder.mutation({
            query: ({investigationId, complaintId}) => ({
                url: `/investigations/${investigationId}/status/CLOSED`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId},
                {type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),
        reopenInvestigation: builder.mutation({
            query: ({investigationId, complaintId}) => ({
                url: `/investigations/${investigationId}/status/IN_CREATION`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Investigation', id: arg.complaintId},
                {type: 'Complaint', id: arg.complaintId}, {type: 'Complaints'}]
        }),

        getMe: builder.query({
            query: () => '/users/me',
            providesTags: (result, error, arg) => [{type: 'User', id: result.id}]
        }),
        getUsers: builder.query({
            query: () => '/users',
            providesTags: (result, error, arg) => [{type: 'Users'}]
        }),
        createUser: builder.mutation({
            query: (user) => ({
                url: `/users`,
                method: 'POST',
                body: user
            }),
            invalidatesTags: (result, error, arg) => [{type: "Users"}]
        }),
        updateUser: builder.mutation({
            query: (user) => ({
                url: `/users`,
                method: 'PUT',
                body: user
            }),
            invalidatesTags: (result, error, arg) => [{type: "Users"}, {type: 'User', id: arg.id}]
        }),
        updateUserEmailSettings: builder.mutation({
            query: (user) => ({
                url: `/users/${user.id}`,
                method: 'PUT',
                body: user.emailSettings
            }),
            invalidatesTags: (result, error, arg) => [{type: "Users"}, {type: 'User', id: arg.id}, {type: "Team"}]
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `/users/${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: "Users"}, {type: 'User', id: arg.id}]
        }),
        updateUserPicture: builder.mutation({
            query: ({picture}) => ({
                url: `/users/picture`,
                method: 'POST',
                body: {"picture": picture}
            }),
        }),

        sendComplaintPDFEmail: builder.mutation({
            query: (bodyFormData) => ({
                url: `/emails/sendComplaintPDFEmail`,
                method: 'POST',
                body: bodyFormData
            }),
        }),
        sendSupportEmail: builder.mutation({
            query: (data) => ({
                url: `/emails/sendSupportEmail`,
                method: 'POST',
                body: data
            }),
        }),

        getComplaintForExternal: builder.query({
            query: ({complaintId, language}) => `/externals/complaints/${complaintId}/${language}`,
            providesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        getComplaintsForExternal: builder.query({
            query: () => `/externals/complaints`,
            providesTags: (result, error, arg) => [{type: 'Complaints'}]
        }),
        updateExternal: builder.mutation({
            query: ({complaintId, invitationId, data}) => ({
                url: `/externals/invite/${invitationId}`,
                method: 'POST',
                body: {...data, "complaintId": complaintId,}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        inviteExternalToCollaborateOnComplaint: builder.mutation({
            query: ({complaintId, data}) => ({
                url: `/externals/invite`,
                method: 'POST',
                body: {...data, "complaintId": complaintId,}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        revokeInvitation: builder.mutation({
            query: ({invitationId, complaintId}) => ({
                url: `/externals/invite/${invitationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId}]
        }),
        answerQuestion: builder.mutation({
            query: ({questionId, data, complaintId, investigationId}) => ({
                url: `/externals/question/${questionId}/answer`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{
                type: 'Complaint',
                id: arg.complaintId
            }, {type: 'Complaints'}, {type: 'Investigation', id: arg.complaintId}]
        }),
        askExternalQuestionAboutInvestigation: builder.mutation({
            query: ({investigationId, data, complaintId}) => ({
                url: `/externals/ask`,
                method: 'POST',
                body: {...data, "investigationId": investigationId, "mode": "INVESTIGATION"}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Complaint', id: arg.complaintId},
                {type: 'Investigation', id: arg.complaintId}]
        }),

        getRoles: builder.query({
            query: () => '/roles',
            providesTags: (result, error, arg) => [{type: 'Roles'}]
        }),
        getPermissions: builder.query({
            query: () => '/permissions',
            providesTags: (result, error, arg) => [{type: 'Permissions'}]
        }),
        addPermissionToRole: builder.mutation({
            query: ({role, permission}) => ({
                url: `/roles/${role}/${permission}`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Roles'}]
        }),
        deletePermissionFromRole: builder.mutation({
            query: ({role, permission}) => ({
                url: `/roles/${role}/${permission}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Roles'}]
        }),

        getLinks: builder.query({
            query: (name) => `/links/${name}`,
            providesTags: (result, error, arg) => [{type: 'Links'}]
        }),
        getAdminLinks: builder.query({
            query: () => '/admin/links',
            providesTags: (result, error, arg) => [{type: 'Links'}]
        }),
        openPage: builder.query({
            query: (name) => `/links/open/${name}`,
        }),
        linkClicked: builder.mutation({
            query: (link) => ({
                url: `/links/click`,
                method: 'POST',
                body: {"link": link}
            }),
        }),
        updateLinksDefinition: builder.mutation({
            query: ({id, data}) => ({
                url: `/admin/links/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Links'}]
        }),
        createLinksDefinition: builder.mutation({
            query: ({name, data}) => ({
                url: `/admin/links/${name}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Links'}]
        }),

        getFile: builder.query({
            query: (key) => ({
                url: `/files/${key}`,
                responseHandler: (response) => {
                    return response.text()
                }
            }),
            providesTags: (result, error, arg) => [{type: 'File', id: arg}, {type: 'Files'}]
        }),
        getFileForTrainer: builder.query({
            query: (key) => ({
                url: `/files/trainer/${key}`,
                responseHandler: (response) => {
                    return response.text()
                }
            }),
            providesTags: (result, error, arg) => [{type: 'File', id: arg}, {type: 'Files'}]
        }),
        deleteFileFromCapItem: builder.mutation({
            query: ({fileId, capId, capItemId}) => ({
                url: `/files/${fileId}/cap/${capId}/${capItemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'File', id: arg.fileId}, {type: 'Files'},
                {type: 'CAP', id: arg.capId}]
        }),
        deleteFileFromInvestigationItem: builder.mutation({
            query: ({fileId, investigationId, investigationItemId}) => ({
                url: `/files/${fileId}/investigation/${investigationId}/${investigationItemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: 'File', id: arg.fileId}, {type: 'Files'},
                {type: 'Investigation', id: arg.complaintId}]
        }),

        getTeam: builder.query({
            query: () => '/teams',
            providesTags: (result, error, arg) => [{type: 'Team'}]
        }),

        getKPIs: builder.query({
            query: () => '/kpis',
        }),

        getCheckins: builder.query({
            query: (name) => `/checkin/${name}`,
        }),
        checkin: builder.mutation({
            query: (checkin) => ({
                url: `/checkin`,
                method: 'PUT',
                body: checkin
            }),
        }),

        getTemplates: builder.query({
            query: () => '/templates',
            providesTags: (result, error, arg) => [{type: 'Templates'}]
        }),
        getTemplate: builder.query({
            query: (templateId) => `/templates/${templateId}`,
            providesTags: (result, error, arg) => [{type: 'Template', id: arg}, {type: 'Templates'}]
        }),

        getTranslations: builder.query({
            query: () => '/translations',
            providesTags: (result, error, arg) => [{type: 'Translations'}]
        }),

        getReportSchemas: builder.query({
            query: () => '/checkin/schema',
            providesTags: (result, error, arg) => [{type: 'ReportSchemas'}]
        }),
        getReportSchema: builder.query({
            query: (name) => `/checkin/schema/${name}`,
            providesTags: (result, error, arg) => [{type: 'ReportSchema', id: arg}, {type: 'ReportSchemas'}]
        }),
        getCheckInReport: builder.query({
            query: (name) => `/checkin/schema/${name}/report`,
        }),
        getCheckInReportRaw: builder.query({
            query: (name) => `/checkin/schema/${name}/raw`,
        }),
        saveReportSchema: builder.mutation({
            query: (schema) => ({
                url: `/checkin/schema`,
                method: 'POST',
                body: schema
            }),
            invalidatesTags: (result, error, arg) => [{type: 'ReportSchema', id: arg.name}, {type: 'ReportSchemas'}]
        }),
        saveAnswerMapping: builder.mutation({
            query: ({name, itemName, answer, reply}) => ({
                url: `/checkin/schema/mapping`,
                method: 'POST',
                body: {name, itemName, answer, reply}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'ReportSchema', id: arg.name}, {type: 'ReportSchemas'}]
        }),

        getTrainingForTrainer: builder.query({
            query: (trainingId) => `/training/trainer/${trainingId}`,
            providesTags: (result, error, arg) => [{type: 'Training', id: arg}]
        }),
        updateTrainingForTrainer: builder.mutation({
            query: (training) => ({
                url: `/training/trainer`,
                method: 'POST',
                body: training
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Training', id: arg.id}]
        }),
        getTrainings: builder.query({
            query: () => `/trainings`,
            providesTags: (result, error, arg) => [{type: 'Trainings'}]
        }),
        getTraining: builder.query({
            query: (trainingId) => `/trainings/${trainingId}`,
            providesTags: (result, error, arg) => [{type: 'Training', id: arg}]
        }),
        updateTraining: builder.mutation({
            query: (training) => ({
                url: `/trainings`,
                method: 'PUT',
                body: training
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Training', id: arg.id}, {type: 'Trainings'}]
        }),
        createTraining: builder.mutation({
            query: (training) => ({
                url: `/trainings`,
                method: 'POST',
                body: training
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Training', id: arg.id}, {type: 'Trainings'}]
        }),

    })
})

export const {
    useGetComplaintQuery,
    useGetComplaintByCodeQuery,
    useGetComplaintCodeQuery,
    useGetComplaintsQuery,
    useGetComplaintsWithoutBrandQuery,
    useGetComplaintStatisticsQuery,

    useGetLocationsQuery,
    useGetLocationQuery,
    useGetLocationNamesQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation,
    useDeleteLocationMutation,

    useUpdateComplaintLocationMutation,
    useCreateComplaintMutation,
    useUpdateComplaintMutation,
    useUpdateComplaintCategoryMutation,
    useUpdateComplaintCategoryNoteMutation,
    useUpdateComplaintCategoryChemicalMutation,
    useUpdateComplaintMainPolicyMutation,
    useUpdateComplaintAdditionalPolicyMutation,
    useUpdateComplaintDateMutation,
    useUpdateComplaintLegalCategoryMutation,
    useUpdateComplaintRegionMutation,
    useUpdateComplaintSummaryMutation,
    useUpdateComplaintSeverityMutation,
    useUpdateComplaintSeverityNoteMutation,
    useUpdateComplaintTitleMutation,
    useAssignComplaintBrandMutation,
    useUpdateComplaintQuestionsAnsweredMutation,
    useAddMessageToComplaintByHandlerMutation,
    useAddMessageToComplaintByComplainantMutation,
    useUpdateComplaintStatusMutation,
    useUpdateComplaintSubBrandMutation,

    useDeleteBrandMutation,
    useUpdateBrandMutation,
    useCreateBrandMutation,
    useGetBrandsQuery,
    useGetBrandQuery,
    useGetCAPQuery,
    useUpdateCAPMutation,
    useSaveCAPItemMutation,
    useDeleteCAPItemMutation,
    useSaveCAPItemStatusMutation,
    useUpdateCAPStatusMutation,
    useCreateCAPCommentMutation,
    useInviteToCommentOnCAPMutation,
    useReplyToInvitationToCommentMutation,

    useGetInvestigationQuery,
    useCreateInvestigationCommentMutation,
    useAddTodoToInvestigationMutation,
    useUpdateInvestigationTodoMutation,
    useDeleteInvestigationTodoMutation,
    useCheckInvestigationTodoMutation,
    useUncheckInvestigationTodoMutation,
    useSaveInvestigationItemMutation,
    useDeleteInvestigationItemMutation,
    useCloseInvestigationMutation,
    useReopenInvestigationMutation,

    useGetUsersQuery,
    useGetMeQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useUpdateUserEmailSettingsMutation,
    useDeleteUserMutation,
    useUpdateUserPictureMutation,

    useSendComplaintPDFEmailMutation,
    useSendSupportEmailMutation,

    useGetComplaintForExternalQuery,
    useGetComplaintsForExternalQuery,
    useUpdateExternalMutation,
    useInviteExternalToCollaborateOnComplaintMutation,
    useRevokeInvitationMutation,
    useAnswerQuestionMutation,
    useAskExternalQuestionAboutInvestigationMutation,

    useGetRolesQuery,
    useGetPermissionsQuery,
    useAddPermissionToRoleMutation,
    useDeletePermissionFromRoleMutation,

    useGetLinksQuery,
    useGetAdminLinksQuery,
    useLazyOpenPageQuery,
    useLinkClickedMutation,
    useUpdateLinksDefinitionMutation,
    useCreateLinksDefinitionMutation,

    useGetFileQuery,
    useGetFileForTrainerQuery,
    useDeleteFileFromCapItemMutation,
    useDeleteFileFromInvestigationItemMutation,

    useGetTeamQuery,

    useGetKPIsQuery,

    useGetCheckinsQuery,
    useCheckinMutation,

    useGetTemplatesQuery,
    useGetTemplateQuery,

    useGetTranslationsQuery,

    useGetReportSchemaQuery,
    useGetReportSchemasQuery,
    useGetCheckInReportQuery,
    useGetCheckInReportRawQuery,
    useSaveReportSchemaMutation,
    useSaveAnswerMappingMutation,


    useGetTrainingForTrainerQuery,
    useUpdateTrainingForTrainerMutation,
    useGetTrainingsQuery,
    useGetTrainingQuery,
    useUpdateTrainingMutation,
    useCreateTrainingMutation,


} = apiSlice;
