import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Stack, TextField} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {deadlineRed} from "../../../../common/colors";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {grey} from "@mui/material/colors";
import {useState} from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {parseISO} from "date-fns";


export default function CAPItemDueDate({disabled, save, item,}) {

    const [edit, setEdit] = useState(false);
    const [open, setOpen] = useState(false);

    if (!item) return null;

    if (edit || !item.dueDate)
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                inputFormat="MM/dd/yyyy"
                label="CAP Item Due Date"
                id="item_due_date_date"
                value={parseISO(item.dueDate)}
                onChange={(date) => {
                    save({
                        ...item, dueDate: date
                    });
                    setEdit(false);
                }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                disabled={disabled}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                renderInput={(props) => <TextField {...props} onMouseLeave={() => {
                    if (!open) setEdit(false);
                }}/>}

            />
        </LocalizationProvider>

    function getCalendarTodayOutlinedIcon() {
        if (new Date(item.dueDate) < new Date())
            return <WarningIcon style={{fill: deadlineRed}}/>
        return <CalendarTodayOutlinedIcon fontSize="small" sx={{color: grey[500]}}/>;
    }

    function getSx() {
        if (new Date(item.dueDate) < new Date())
            return {whiteSpace: "nowrap", color: deadlineRed,};
        return {whiteSpace: "nowrap",};
    }

    return <Stack direction={"row"} justifyContent="space-between" alignItems="center"
                  onMouseEnter={() => setEdit(true)}>
        {getCalendarTodayOutlinedIcon()}
        <Typography align={"left"} sx={getSx()}
                    variant="body"> {new Date(item.dueDate).toLocaleDateString()}</Typography>
    </Stack>
}
