import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../components/common/LoadingGIF";
import NotAuthorized from "../components/common/NotAuthorized";
import Error from "../components/common/Error";
import {PermissionsTable} from "../components/admin/PermissionsTable";
import Box from "@mui/material/Box";
import {useGetPermissionsQuery, useGetRolesQuery} from "../app/apiSlice";


const Permissions = () => {

    const {data: permissions, isLoading: permissionsLoading, error: permissionsError} = useGetPermissionsQuery();
    const {data: roles, isLoading: rolesLoading, error: rolesError} = useGetRolesQuery();

    if (permissionsLoading || rolesLoading) return <LoadingGIF/>;
    if (permissionsError && permissionsError.status === 403) return <NotAuthorized/>;
    if (rolesError && rolesError.status === 403) return <NotAuthorized/>;
    if (permissionsError || rolesError) return <Error/>;


    return (
        <Box sx={{mt: 2}}>
            <PermissionsTable permissions={permissions} roles={roles}/>
        </Box>
    );
};

export default withAuthenticationRequired(Permissions, {
    onRedirecting: () => <LoadingGIF/>,
});
