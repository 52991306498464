import React from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {useDispatch} from "react-redux";
import {useAddPermissionToRoleMutation, useDeletePermissionFromRoleMutation} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";

export const PermissionsTable = ({permissions, roles}) => {

    const dispatch = useDispatch();

    const [addPermissionToRole] = useAddPermissionToRoleMutation();
    const [deletePermissionFromRole] = useDeletePermissionFromRoleMutation();

    const pretty = (name) => {
        return name
            .replace("ROLE_", "")
            .replace("PERMISSION_", "")
            .replace(/_/g, " ")
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    async function handleClick(role, permission, permissionIsPartOfRole) {
        if (permissionIsPartOfRole) {
            try {
                await deletePermissionFromRole({role, permission}).unwrap();
                dispatch(openSnackbar({message: "Permission deleted successfully", severity: "success"}));
            } catch (err) {
                dispatch(openSnackbar({message: "Failed to delete permission", severity: "error"}));
            }
        } else {
            try {
                await addPermissionToRole({role, permission}).unwrap();
                dispatch(openSnackbar({message: "Permission added successfully", severity: "success"}));
            } catch (err) {
                dispatch(openSnackbar({message: "Failed to add permission", severity: "error"}));
            }
        }
    }

    return (<TableContainer component={Paper}>
        <Table sx={{minWidth: 650,}} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Permission</TableCell>
                    {roles.map(role => <TableCell key={role.name} align="right">
                        {pretty(role.name)}
                    </TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {permissions.map(permission => <TableRow key={permission}>
                    <TableCell component="th" scope="row">
                        {pretty(permission)}
                    </TableCell>
                    {roles.map(role => <TableCell align="right" key={role.name + permission}>
                        <Checkbox
                            checked={!!role.privileges.find(p => p === permission)}
                            // onChange={() => alert(role.name + " " + permission + !!role.privileges.find(p => p.name === permission))}
                            onChange={() => handleClick(role.name, permission, !!role.privileges.find(p => p === permission))}
                            color="primary"
                        />
                    </TableCell>)}
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>);
};
