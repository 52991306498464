import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../components/common/LoadingGIF";
import NotAuthorized from "../components/common/NotAuthorized";
import Error from "../components/common/Error";
import {BrandForm} from "../components/admin/brands/BrandForm";
import {Team} from "../components/team/Team";
import {useGetTeamQuery} from "../app/apiSlice";

const Company = () => {

    const {data: team, isLoading: loading, error: error} = useGetTeamQuery();

    if (loading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (error) return <Error/>;


    return (
        <>
            <Team team={team}/>
            <BrandForm brand={team.brand} sx={{mt: 2}}/>
        </>
    );
};

export default withAuthenticationRequired(Company, {
    onRedirecting: () => <LoadingGIF/>,
});
