import React from "react";

const Arrow = ({label, first, last, widthh}) => {
    const stroke = "#1b8591";
    const fill = "#1b8591";
    const textColor = "white";

    const XXX = () => {
        return <svg width={widthh} height="45" viewBox={`0 0 ${widthh} 40`}>
            {first &&
            <polygon points={`1,10 ${widthh-15},10 ${widthh-5},25 ${widthh-15},40 1,40`} stroke={stroke} strokeWidth={2} fill={fill}/>}
            {last &&
            <polygon points={`10,25 1,10 ${widthh-1},10 ${widthh-1},25 ${widthh-1},40 1,40`} stroke={stroke} strokeWidth={2} fill={fill}/>}
            {!first && !last &&
            <polygon points={`10,25 0,10 ${widthh-15},10 ${widthh-5},25 ${widthh-15},40 0,40`} stroke={stroke} strokeWidth={2} fill={fill}/>}
        </svg>;
    }

    return <div style={{position: "relative", textAlign: "center", color: textColor, fontWeight: 600}}>
        <XXX />
        <div style={{
            position: "absolute",
            top: "53%",
            left: "45%",
            transform: "translate(-50%, -50%)",
            whiteSpace: "nowrap",
            fontSize: "12px"
        }}>
            {label}
        </div>
    </div>

};

export default Arrow;
