import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../components/common/LoadingGIF";
import Error from "../components/common/Error";
import {Dashboard} from "../components/dashboard/Dashboard";
import {useHistory} from "react-router-dom";
import {useGetComplaintsQuery, useGetComplaintStatisticsQuery, useGetMeQuery} from '../app/apiSlice';

export const ComplaintsComponent = () => {

    const {data: statistics, isLoading: loadingStatistics, error: errorStatistics} = useGetComplaintStatisticsQuery();
    const {data: me, isLoading: loadingMe, error: errorMe} = useGetMeQuery();
    const {data: complaints, isLoading: loading, isFetching: fetching, error} = useGetComplaintsQuery();

    const history = useHistory();


    if (loadingStatistics || loadingMe || loading) {
        return <LoadingGIF/>;
    }
    if (errorStatistics || errorMe || error) return <Error/>;
    if (!(me && me.internal)) history.push("");

    return (
        <Dashboard statistics={statistics} complaints={complaints} me={me}/>
    );
};

export default withAuthenticationRequired(ComplaintsComponent, {
    onRedirecting: () => <LoadingGIF/>,
});
