export const categoryLabel = (categories) => {
    let result = "";
    if (!categories) return result;
    categories.forEach((category) => {
        result += oneCategoryLabel(category) + ", ";
    });
    if (result && result.length > 2)
        return result.substring(0, result.length - 2);
    return result;
};

export const oneCategoryLabel = (category) => {
    switch (category) {
        case "CHILD_LABOUR" :
            return "Child Labor";
        case "SLAVE_LABOUR" :
            return "Slave Labor";
        case "HEALTH_AND_SAFETY" :
            return "Health and Safety";
        case "ASSOCIATION_AND_COLLECTIVE_BARGAINING" :
            return "Association and Collective Bargaining";
        case "DISCRIMINATION" :
            return "Discrimination";
        case "SEXUAL_HARASSMENT" :
            return "Sexual Harassment";
        case "PAYMENT_OF_LIVING_WAGE" :
            return "Payment of Living Wage";
        case "WORKING_HOURS" :
            return "Working Hours";
        case "ENVIRONMENT_AND_RESOURCES" :
            return "Environment and Ressources";
        case "GHG_EMISSIONS" :
            return "GHG Emissions";
        case "LAND_RIGHTS" :
            return "Violation of Land Rights";
        case "INAPPROPRIATE_SECURITY_FORCES" :
            return "Inappropriate Security Forces";
        case "CHEMICALS_AND_WATER" :
            return "Chemicals and Water";
        case "CORRUPTION" :
            return "Corruption";
        case "ANIMAL_WELFARE" :
            return "Animal Welfare";
        case "LABOR_LAW_VIOLATION":
            return "Labor Law Violation";
        default:
        case "UNKNOWN" :
            return "Unknown Category"

    }
};

export const existingCategories = [
    "UNKNOWN",
    "CHILD_LABOUR",
    "SLAVE_LABOUR",
    "HEALTH_AND_SAFETY",
    "ASSOCIATION_AND_COLLECTIVE_BARGAINING",
    "DISCRIMINATION",
    "SEXUAL_HARASSMENT",
    "PAYMENT_OF_LIVING_WAGE",
    "WORKING_HOURS",
    "ENVIRONMENT_AND_RESOURCES",
    "GHG_EMISSIONS",
    "LAND_RIGHTS",
    "INAPPROPRIATE_SECURITY_FORCES",
    "CHEMICALS_AND_WATER",
    "CORRUPTION",
    "LABOR_LAW_VIOLATION",
    "ANIMAL_WELFARE",
];
