import React, {useMemo} from 'react';
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {Box, Button, IconButton, Tooltip,} from '@mui/material';
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Chip from "@mui/material/Chip";
import {brandLabel, severityLabel, statusIcon, statusLabel} from "./labels";
import {categoryLabel} from "./categories";
import {download, generateCsv, mkConfig} from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ComplaintsTable = ({complaints, brands, me}) => {

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => statusLabel(row.status),
                id: 'status', //id is still required when using accessorFn instead of accessorKey
                header: 'Status',
                Cell: ({renderedCellValue, row}) => (
                    <Chip icon={statusIcon(row.original.status)} label={renderedCellValue} variant="outlined"/>
                ),
            },
            {
                accessorFn: (row) => `C${row.id}`,
                accessorKey: 'id',
                header: 'Number',
            },
            {
                accessorKey: 'title',
                header: 'Title',
            },
            {
                accessorFn: (row) => `${row.location ? row.location.name : ""}`,
                accessorKey: 'factory',
                header: 'Factory',
            },
            {
                accessorFn: (row) => `${new Date(row.cdate).toLocaleDateString()}`,
                accessorKey: 'date',
                header: 'Date',
            },
            {
                accessorFn: (row) => `${categoryLabel(row.categories)}`,
                accessorKey: 'categories',
                header: 'Categories',
            },
            {
                accessorFn: (row) => `${severityLabel(row.severity)}`,
                accessorKey: 'severity',
                header: 'Severity',
            },
            {
                accessorFn: (row) => `${brandLabel(row.brand, brands)}`,
                accessorKey: 'brand',
                header: 'Brand',
                enableEditing: false,
            },
            {
                accessorFn: (row) => `${brandLabel(row.subBrands, brands)}`,
                accessorKey: 'subBrands',
                header: 'Sub Brand',
                enableEditing: false,
                visibleInShowHideMenu: me?.settings?.complaintsTableShowSubBrands,
                Cell: ({renderedCellValue, row}) => {
                    return row.original.subBrands && row.original.subBrands.map((value) => (
                        <Chip size="small" key={value} label={brandLabel(value, brands)} sx={{margin: 0.5,}}
                              variant="outlined"/>
                    ))
                },
            }
        ],
        [],
    );

    let date = new Date();
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
        filename: `Complaints_${date.toISOString().slice(0, 10)}_${date.toLocaleTimeString("de-DE").slice(0, 5)}`,
    });

    const handleExportRows = (rows) => {
        const rowData = rows.map(row => row.original)
            .map(row => ({
                number: `C${row.id}`,
                status: statusLabel(row.status),
                title: row.title,
                factory: `${row.location ? row.location.name : row.locationNameGivenByComplainant}`,
                created: row.cdate,
                categories: categoryLabel(row.categories),
                categoryNote: row.categoryNote,
                severity: severityLabel(row.severity),
                severityNote: row.severityNote,
                anonymous: row.anonymous,
                brand: brandLabel(row.brand, brands),
                date: row.incidentDate,
                summary: row.summary
            }));
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const table = useMaterialReactTable({
        columns,
        data: complaints,
        enableEditing: true,
        getRowId: (row) => row.id,

        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        renderRowActions: ({row, table}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Open Complaint">
                    <IconButton onClick={() => window.open(`/complaints/${row.id}`)}>
                        <OpenInNewRoundedIcon color="grey"/>
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                        handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon/>}
                >
                    Export All Rows
                </Button>
                <Button
                    disabled={table.getRowModel().rows.length === 0}
                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                    onClick={() => handleExportRows(table.getRowModel().rows)}
                    startIcon={<FileDownloadIcon/>}
                >
                    Export Page Rows
                </Button>
            </Box>
        ),
        initialState: {
            columnVisibility: {title: false, severity: false, categories: false, brand: false, subBrand: false},
            sorting: [
                {id: 'id', desc: true}],
        },
        state: {},
    });

    return <MaterialReactTable table={table}/>;
};

export default ComplaintsTable;
