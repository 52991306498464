import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from "@mui/material/Button";
import {InputLabel, Select, Stack, TextField} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {darkSalmon, lightTurquoise, mainSalmon} from "../../../common/colors";

export default function CreateCAPItem({save, handleClose, permissions}) {


    const [description, setDescription] = useState("");
    const [dueDate, setDueDate] = useState(null);
    const [title, setTitle] = useState("");
    const [priority, setPriority] = useState("");

    if (!permissions.has("WRITE_CAP")) return null;

    const headerTitle = <TextField
        id="item_title" required
        label="CAP Item Title"
        fullWidth={true}
        value={title}
        variant="outlined"
        onInput={e => setTitle(e.target.value)}
    />;

    return (
        <Card sx={{mt: 1, p: 1}}>
            <CardHeader
                title={headerTitle}
            />
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            inputFormat="MM/dd/yyyy"
                            label="CAP Item Due Date"
                            id="item_due_date_date"
                            value={dueDate}
                            onChange={setDueDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            renderInput={(props) => <TextField {...props} />}
                        />
                    </LocalizationProvider>

                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Item Priority</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={priority}
                            label="Item Priority"
                            onChange={e => setPriority(e.target.value)}
                            sx={{width: 200, borderRadius: 7}}
                        >
                            <MenuItem value="high"> <FiberManualRecordIcon
                                style={{fill: darkSalmon}}/> High Priority</MenuItem>
                            <MenuItem value="medium"> <FiberManualRecordIcon
                                style={{fill: mainSalmon}}/> Medium Priority</MenuItem>
                            <MenuItem value="low"> <FiberManualRecordIcon
                                style={{fill: lightTurquoise}}/> Low Priority</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                <TextField
                    id="item_description" required
                    label="CAP Item Description"
                    style={{marginTop: 20}}
                    multiline
                    minRows={4}
                    fullWidth={true}
                    value={description}
                    variant="outlined"
                    onInput={e => setDescription(e.target.value)}
                />
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end", p: 0, m: 0, lineHeight: 1,}}>
                <Button variant="contained"
                        color="secondary"
                        size={"large"}
                        style={{marginLeft: 5}}
                        onClick={() => handleClose(false)}>
                    cancel
                </Button>
                <Button variant="contained"
                        color="primary"
                        size={"large"}
                        style={{marginLeft: 5}}
                        onClick={() => save({title, description, dueDate, priority})}>
                    save
                </Button>
            </CardActions>
        </Card>
    );

}
