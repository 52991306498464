import React, {useState} from 'react';
import {Checkbox, Collapse, FormControlLabel, FormGroup, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {useAuth0} from "@auth0/auth0-react";
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from "@mui/material/IconButton";
import EditPermissions from "./EditPermissions";
import {useDispatch} from "react-redux";
import {useInviteExternalToCollaborateOnComplaintMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import LoadingButton from "@mui/lab/LoadingButton";

export default function InviteExternalForm({complaint, setOpen}) {
    const {user,} = useAuth0();
    const [sending, setSending] = useState(false);
    const [noteCollapsed, setNoteCollapsed] = useState(false);
    const [permissionNoteCollapsed, setPermissionNoteCollapsed] = useState(false);
    const [permissions, setPermissions] = useState({
        READ_COMPLAINANT_CONVERSATION: false,
        WRITE_COMPLAINANT_CONVERSATION: false,
        READ_ORIGINAL_COMPLAINT_MESSAGE: false,
        READ_LOCATION: false,
        READ_DATE_AND_TIME: false,
        READ_SUMMARY: false,
        READ_SEVERITY: false,
        READ_CATEGORY: false,
        READ_STATUS: false,
        READ_TITLE: false,
        READ_ALL_INVESTIGATION_ITEMS: false,
        READ_OWN_INVESTIGATION_ITEMS: false,
        READ_CAP: false,
        WRITE_CAP_COMMENTS: false,
        WRITE_CAP: false,
        READ_CAP_VERIFICATION: false,
        WRITE_CAP_VERIFICATION_COMMENTS: false,
        WRITE_CAP_VERIFICATION: false
    })
    const dispatch = useDispatch();

    const [inviteExternalToCollaborateOnComplaint] = useInviteExternalToCollaborateOnComplaintMutation();

    const handlePermissionNoteCollapsedChange = () => {
        setPermissionNoteCollapsed((prev) => !prev);
    };

    const handleNoteCollapsedChange = () => {
        setNoteCollapsed((prev) => !prev);
    };

    function contactAlreadyInvited(email) {
        if (!complaint || !complaint.externals || !email) return false;
        for (let i = 0; i < complaint.externals.length; i++) {
            let invitation = complaint.externals[i];
            if (invitation.invitedUser.email === email && !invitation.invitationRevoked) return true;
        }
        return false;
    }

    const validationSchema = Yup.object().shape({
        inviteeFirstName: Yup.string().required('First name is required'),
        inviteeLastName: Yup.string().required('Last name is required'),
        inviteeEmail: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
            .test('len', 'Email is already invited', val => val && !contactAlreadyInvited(val)),
        permissionNote: Yup.string().required('Explanation why the permissions were chosen is required'),
    });

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({resolver: yupResolver(validationSchema)});

    const onSubmit = async (data) => {
        setSending(true);

        try {
            await inviteExternalToCollaborateOnComplaint({
                complaintId: complaint.id,
                data: {...data, permissions}
            }).unwrap();
            dispatch(openSnackbar({message: "External successfully invited to complaint", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to invite external to complaint", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }

    };

    return (
        <>
            <Box component="form">
                <Typography variant="h6">
                    External Contact Data
                </Typography>
                <Stack direction="row" spacing={0}>
                    <Stack direction="column" spacing={0}>
                        <TextField
                            required sx={{mt: 1}}
                            id="inviteeFirstName"
                            name="inviteeFirstName"
                            label="First Name"
                            placeholder="First Name of External"
                            {...register('inviteeFirstName')}
                            error={!!errors.inviteeFirstName}
                        />
                        <Typography variant="inherit" color="error">
                            {errors.inviteeFirstName?.message}
                        </Typography>
                    </Stack>
                    <Stack direction="column" spacing={0} sx={{mt: 1, ml: 1}}>
                        <TextField
                            required
                            id="inviteeLastName"
                            name="inviteeLastName"
                            label="Last Name"
                            placeholder="Last Name of External"
                            {...register('inviteeLastName')}
                            error={!!errors.inviteeLastName}
                        />
                        <Typography variant="inherit" color="error">
                            {errors.inviteeLastName?.message}
                        </Typography>
                    </Stack>
                </Stack>
                <TextField
                    required sx={{mt: 1}} fullWidth
                    id="inviteeEmail"
                    name="inviteeEmail"
                    label="Email"
                    placeholder="Email of External"
                    {...register('inviteeEmail')}
                    error={!!errors.inviteeEmail}
                />
                <Typography variant="inherit" color="error">
                    {errors.inviteeEmail?.message}
                </Typography>

                <EditPermissions permissions={permissions} setPermissions={setPermissions}/>

                <Typography variant="inherit" color="error">
                    {errors.permissionNote?.message}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mt: 1,
                    cursor: 'pointer'
                }}>
                    <Typography variant="h6" component="span" onClick={handlePermissionNoteCollapsedChange}
                                color={!!errors.permissionNote ? "error" : "primary"}>
                        Permission setting explanation
                    </Typography>
                    <IconButton color="primary" aria-label="expand question" component="span"
                                onClick={handlePermissionNoteCollapsedChange}>
                        {permissionNoteCollapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </Box>
                <Collapse in={permissionNoteCollapsed}>
                    <TextField
                        sx={{mt: 1}} required
                        fullWidth
                        id="permissionNote"
                        name="permissionNote"
                        multiline
                        rows={4}
                        label="Permission Note"
                        {...register('permissionNote')}
                        error={!!errors.permissionNote}
                    />

                </Collapse>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mt: 1,
                    cursor: 'pointer'
                }}>
                    <Typography variant="h6" component="span" onClick={handleNoteCollapsedChange}>
                        Invitation note
                    </Typography>
                    <IconButton color="primary" aria-label="expand question" component="span"
                                onClick={handleNoteCollapsedChange}>
                        {noteCollapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </Box>
                <Collapse in={noteCollapsed}>
                    <TextField
                        sx={{mt: 1}}
                        fullWidth
                        id="note"
                        name="note"
                        multiline
                        rows={4}
                        label="Note"
                        placeholder="This note will be sent in the invitation mail to the external. You can write something like: this is the invitation to the tool I told you about on the phone."
                        {...register('note')}
                    />

                </Collapse>
                <FormGroup sx={{mt: 1}}>
                    <FormControlLabel
                        control={
                            <Controller
                                control={control}
                                name="inviterInCC"
                                id="inviterInCC"
                                defaultValue="false"
                                inputRef={register()}
                                render={({field: {onChange}}) => (
                                    <Checkbox
                                        color="primary"
                                        onChange={e => onChange(e.target.checked)}
                                    />
                                )}
                            />
                        }
                        label={`Send copy of invitation mail to ${user.email}`}/>
                </FormGroup>
            </Box>

            <LoadingButton
                fullWidth
                loading={sending}
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
            >
                Invite External to collaborate on Complaint
            </LoadingButton>
        </>

    );


}
