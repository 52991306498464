import * as React from 'react';
import Popover from '@mui/material/Popover';
import Chip from "@mui/material/Chip";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {darkSalmon, lightTurquoise, mainSalmon} from "../../../common/colors";
import theme from "../../../theme";
import {Stack} from "@mui/material";

export default function CAPItemPriority({priority}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (priority === null) return null;


    function getPriorityChip() {
        switch (priority) {
            case "high":
                return <Chip avatar={getPriorityDot()}
                             label="High" style={{borderColor: getPriorityColor()}} variant="outlined"
                             sx={{backgroundColor: theme.palette.background.paper,}}/>;
            case "medium":
                return <Chip avatar={getPriorityDot()}
                             label="Medium" style={{borderColor: getPriorityColor()}} variant="outlined"
                             sx={{backgroundColor: theme.palette.background.paper,}}/>;
            case "low":
                return <Chip avatar={getPriorityDot()}
                             label="Low" style={{borderColor: getPriorityColor()}} variant="outlined"
                             sx={{backgroundColor: theme.palette.background.paper,}}/>;
            default:
                return null;
        }
    }

    function getPriorityColor() {
        switch (priority) {
            case "high":
                return darkSalmon;
            case "medium":
                return mainSalmon;
            case "low":
                return lightTurquoise;
            default:
                return null;
        }
    }

    function getPriorityDot() {
        switch (priority) {
            case "high":
                return <FiberManualRecordIcon style={{fill: getPriorityColor()}}/>;
            case "medium":
                return <FiberManualRecordIcon style={{fill: getPriorityColor()}}/>;
            case "low":
                return <FiberManualRecordIcon style={{fill: getPriorityColor()}}/>;
            default:
                return null;
        }
    }

    return (
        <>
            <Stack
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {getPriorityDot()}
            </Stack>

            <Popover
                id="mouse-over-popover"
                name="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                    '& .MuiPopover-paper': {backgroundColor: 'transparent'}
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{elevation: 0}}
            >
                {getPriorityChip()}
            </Popover>
        </>
    );
}
