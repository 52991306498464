import React from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CAPItemCard from "./item/CAPItemCard";
import {styled} from "@mui/system";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: '#F6FAFB',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "100%"
}));

export default function CAPItemTiles({cap, complaint, isInternal, permissions, user}) {

    return (
        <>
            <Grid container direction={{xs: "column", md: "row"}} justifyContent="space-between" alignItems="stretch"
                  spacing={2} my={2}>
                <Grid item xs={4}>
                    <Item>
                        <Typography align="left" variant="h6">Todo</Typography>
                        {cap.items && cap.items.filter((item) => item.status === "IN_CREATION").map(item =>
                            <CAPItemCard key={item.id} item={item} cap={cap} complaint={complaint}
                                         permissions={permissions} user={user} isInternal={isInternal}/>)}
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <Typography align="left" variant="h6">In Progress</Typography>
                        {cap.items && cap.items.filter((item) => item.status === "IN_REMEDIATION").map(item =>
                            <CAPItemCard key={item.id} item={item} cap={cap} complaint={complaint}
                                         permissions={permissions} user={user} isInternal={isInternal}/>)}
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <Typography align="left" variant="h6">Done</Typography>
                        {cap.items && cap.items.filter((item) => item.status === "DONE" || item.status === "CLOSED").map(item =>
                            <CAPItemCard key={item.id} item={item} cap={cap} complaint={complaint}
                                         permissions={permissions} user={user} isInternal={isInternal}/>)}
                    </Item>
                </Grid>

            </Grid>

        </>
    );
}
