export const countries = {
    "AUSTRIA": 'Austria',
    "BANGLADESH": 'Bangladesh',
    "CHINA": 'China',
    'GERMANY': 'Germany',
    "ITALY": 'Italy',
    "JAPAN": 'Japan',
    "KOREA": 'Korea',
    "LITHUANIA": 'Lithuania',
    "PAKISTAN": 'Pakistan',
    "PORTUGAL": 'Portugal',
    "SWITZERLAND": 'Switzerland',
    "TAIWAN": 'Taiwan',
    "TURKEY": 'Turkey',
    "USA": 'USA',
    "VIETNAM": 'Vietnam',
    "MEXICO": "Mexico",
    "INDIA": "India",
    "PHILIPPINES": "Philippines",
    "INDONESIA": "Indonesia",
};
