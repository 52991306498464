import React from "react";
import {TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import {useDispatch} from "react-redux";
import {useAnswerQuestionMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export const Question = ({question, user, complaintId, investigationId}) => {

    const dispatch = useDispatch();
    const [answerQuestion] = useAnswerQuestionMutation();
    const validationSchema = Yup.object().shape({
        answer: Yup.string().required('Answer is required'),
    });
    const {
        register, handleSubmit, formState: {errors, isSubmitting}
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {
        try {
            await answerQuestion({questionId: question.id, data: data, complaintId, investigationId}).unwrap();
            dispatch(openSnackbar({message: "Successfully answered question", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to answer question", severity: "error"}));
        }
    };

    const ownQuestion = question.askedUser.email === user.email;
    return (<Box component="form" key={question.id}>
        <Typography variant="h6" color="black" sx={{mt: 1}}>Original Question</Typography>
        <Typography variant="span">{question.question}</Typography>
        <Typography variant="h6" color="black" sx={{mt: 1}}>Answer from External</Typography>
        {!ownQuestion &&
            <Typography variant="span">{question.answer ? question.answer : <i>waiting for answer</i>}</Typography>}
        {ownQuestion && <>
            <TextField
                sx={{m: 1}}
                id="answer"
                name="answer"
                multiline
                rows={4}
                defaultValue={question.answer}
                disabled={question.answer}
                label="Your answer"
                {...register('answer')}
            />
            <Typography variant="inherit" color="error">
                {errors.answer?.message}
            </Typography>
            {!question.answeredDate && <LoadingButton
                startIcon={<SendIcon/>}
                loading={isSubmitting}
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
            >
                Save answer
            </LoadingButton>}
        </>}
        {question.answeredDate && <><br/><Typography variant="div">
            answered
            by {question.askedUser.firstName} {question.askedUser.lastName} {new Date(question.answeredDate).toLocaleDateString()} {new Date(question.answeredDate).toLocaleTimeString()}
        </Typography></>}
    </Box>);

}

