import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import LanguageIcon from '@mui/icons-material/Language';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";

const LanguageSelector = () => {

    const {i18n} = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const languageNames = new Intl.DisplayNames([i18n.language], {style: 'narrow', type: 'language'});

    return (

        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<LanguageIcon/>}
            >
                {languageNames.of(i18n.language.substring(0, 2))}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => i18n.changeLanguage("en")}>English</MenuItem>
                <MenuItem onClick={() => i18n.changeLanguage("hi")}>हिन्दी</MenuItem>
                <MenuItem onClick={() => i18n.changeLanguage("ta")}>தமிழ்</MenuItem>
                <MenuItem onClick={() => i18n.changeLanguage("vi")}>Tiếng Việt</MenuItem>
                <MenuItem onClick={() => i18n.changeLanguage("my")}>မြန်မာ</MenuItem>
                <MenuItem onClick={() => i18n.changeLanguage("pt")}>Português</MenuItem>
                <MenuItem onClick={() => i18n.changeLanguage("de")}>Deutsch</MenuItem>
            </Menu>
        </>
    );

};


export default LanguageSelector;
