import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../common/LoadingGIF";
import NotAuthorized from "../common/NotAuthorized";
import Error from "../common/Error";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart, Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {darkSalmon, darkTurquoise, lightSalmon, lightTurquoise, mainSalmon, mainTurquoise} from "../common/colors";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import CollapsibleSection from "../common/CollapsibleSection";
import {useGetCheckinsQuery} from "../../app/apiSlice";
import {TagCloud} from "react-tagcloud";
import {useRouteMatch} from "react-router-dom";


const CheckIns = () => {

    const match = useRouteMatch();
    const {data, isLoading: loading, error} = useGetCheckinsQuery(match.params.id);

    if (loading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (error) return <Error/>;

    let COLORS = [darkSalmon, lightSalmon, darkTurquoise, mainTurquoise, lightTurquoise, mainSalmon];


    function getCollapsibleSection(title, data, numberOfParticipants) {
        return <CollapsibleSection title={title} mt={2} border={true}
                                   children={<PieChart width={750} height={300}>
                                       <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                            innerRadius={0}
                                            outerRadius={100} labelLine={false}>
                                           {data.map((entry, index) =>
                                               <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

                                       </Pie>
                                       <Tooltip/>
                                       <Legend
                                           layout="vertical" verticalAlign="top" align="right" height={200}
                                           formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                                           payload={
                                               data.map(
                                                   (item, index) => ({
                                                       id: item.name,
                                                       type: "square",
                                                       value: `${item.name} (${item.value} / ${(item.value / numberOfParticipants * 100).toFixed(1)}%)`,
                                                       color: COLORS[index % COLORS.length]
                                                   })
                                               )
                                           }
                                       />
                                   </PieChart>}/>;
    }

    function getCollapsibleSectionWithTagCloud(title, data) {
        if (!data) return null;
        return <CollapsibleSection title={title} mt={2} border={true} children={
            <TagCloud minSize={12} maxSize={35} tags={data}/>}
        />;
    }

    function getCollapsibleSectionWithBarChart(title, data) {
        return <CollapsibleSection title={title} mt={2} border={true} children={
            <BarChart width={750} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="value" fill="#8884d8"/>
            </BarChart>}/>;
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between" sx={{mt: 3}}>
                <Typography variant="h3" component="span" sx={{mb: 2, mt: 1}}>
                    Worker Check In: {match.params.id}
                </Typography>

            </Stack>

            <Chip label={`Number of participants: ${data.numberOfParticipants}`} color="primary" variant="outlined"/>

            <CollapsibleSection title="Age" mt={2} border={true} children={<PieChart width={750} height={300}>
                <Pie data={data.age} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={0}
                     outerRadius={100} labelLine={false}>
                    {data.age.map((entry, index) =>
                        <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

                </Pie>
                <Tooltip/>
                <Legend
                    layout="vertical" verticalAlign="top" align="right" height={200}
                    formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                    payload={
                        data.age.map(
                            (item, index) => ({
                                id: item.name,
                                type: "square",
                                value: `${item.name} (${item.value} / ${(item.value / data.numberOfParticipants * 100).toFixed(1)}%)`,
                                color: COLORS[index % COLORS.length]
                            })
                        )
                    }
                />
            </PieChart>}/>

            {getCollapsibleSectionWithBarChart("Time with the factory", data.timeInFactory)}

            {getCollapsibleSectionWithTagCloud("Complaint Attitude", data.solvings)}


            {getCollapsibleSection("Poster", data.atlatPoster, data.numberOfParticipants)}
            {getCollapsibleSection("Usage", data.atlatUsage, data.numberOfParticipants)}
            {getCollapsibleSection("Concern", data.concern, data.numberOfParticipants)}
            {getCollapsibleSectionWithTagCloud("Other Concern", data.otherConcern)}
            {getCollapsibleSectionWithTagCloud("Anything else", data.misc)}
            {getCollapsibleSectionWithTagCloud("Questions about atlat", data.atlatQuestion)}
            {getCollapsibleSection("Training", data.training, data.numberOfParticipants)}
            {getCollapsibleSection("What is atlat", data.whatIsAtlat, data.numberOfParticipants)}

            <CollapsibleSection title="Safety" mt={2} border={true}
                                children={<><BarChart width={800} height={100} data={[data.safety]} layout="vertical"
                                                      barCategoryGap={1}>
                                    <XAxis type="number"/>
                                    <YAxis type="category" width={150} dataKey="name"/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Bar dataKey="detractors" stackId="a" fill={darkSalmon}/>
                                    <Bar dataKey="indifferents" stackId="a" fill={lightSalmon}/>
                                    <Bar dataKey="promoters" stackId="a" fill={mainTurquoise}/>
                                </BarChart>

                                </>}/>
        </>);
};

export default withAuthenticationRequired(CheckIns, {
    onRedirecting: () => <LoadingGIF/>,
});
