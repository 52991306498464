import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import {getAllCountries} from 'countries-and-timezones';
import HelpPopUp from "../../../common/HelpPopUp";
import Box from "@mui/material/Box";
import {countries} from "../../../countries/countries";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useDispatch} from "react-redux";
import {useUpdateComplaintDateMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";


export default function VerifyDateAndTimeTab({complaint, permissions}) {

    const dispatch = useDispatch();

    const [updateComplaintDate] = useUpdateComplaintDateMutation();

    const [selectedDate, setSelectedDate] = useState(complaint.incidentDate ? new Date(complaint.incidentDate) : new Date(complaint.cdate));

    const handleDateChange = async (date) => {
        setSelectedDate(date)
        try {
            await updateComplaintDate({complaintId: complaint.id, date: date}).unwrap()
            dispatch(openSnackbar({message: "Complaint Date updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint Date update failed", severity: "error"}));
        }
    };

    const label = (country) => {
        return countries[country];
    };

    const country1 = Object.values(getAllCountries()).find(country => country.name === complaint.country || (complaint.location && complaint.location.country && country.name === label(complaint.location.country)));
    const timezone = country1 ? country1.timezones[0] : null;

    if (!permissions.has("READ_DATE_AND_TIME")) return null;

    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>Time and Date <HelpPopUp
                text="This is the timestamp that the original complaint was created.
                Please indicate the date and time of the incident concerning the complaint."/></Typography>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box>
                    <Typography variant="h6" color="black" sx={{mb: 3, mr: 3}}>Written by
                        complainant {complaint.translatedDateAndTimeGivenByComplainant ? "(translated)" : ""}</Typography>
                    <Typography
                        component="span">{complaint.translatedDateAndTimeGivenByComplainant ? complaint.translatedDateAndTimeGivenByComplainant : complaint.dateAndTimeGivenByComplainant}</Typography>
                </Box>
                <Box>
                    <Typography variant="h6" color="black" sx={{mb: 3}}>Complaint Creation Date and Time</Typography>
                    <Typography component="span">{new Date(complaint.cdate).toLocaleString()}
                        {timezone && " (local date in " + country1.name + ": " + new Date(complaint.cdate).toLocaleString([], {timeZone: timezone}) + ")"}
                    </Typography>
                </Box>

                <Box sx={{ml: 5}}>
                    {permissions.has("UPDATE_COMPLAINT_DATE") &&
                        <Typography variant="h6" color="black" sx={{mb: 1}}>Please select.</Typography>}
                    {!permissions.has("UPDATE_COMPLAINT_DATE") &&
                        <Typography variant="h6" color="black" sx={{mb: 1}}>Date and Time</Typography>}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="complaint_date_time"
                            label="Complaint Date"
                            inputFormat="dd.MM.yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            disabled={!permissions.has("UPDATE_COMPLAINT_DATE")}
                            renderInput={(params) => <TextField    {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>


            <Grid container>

            </Grid>

        </>
    );
}