import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {existingCategories, oneCategoryLabel} from "../../categories";
import {Checkbox, FormControlLabel, FormGroup, ListItemText, OutlinedInput} from "@mui/material";
import {
    useUpdateComplaintCategoryChemicalMutation,
    useUpdateComplaintCategoryMutation,
    useUpdateComplaintCategoryNoteMutation
} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import {useDispatch} from "react-redux";
import theme from "../../../theme";

export const ComplaintFactsCategory = ({complaint, permissions}) => {
    const dispatch = useDispatch();

    const [categories, setCategories] = useState(complaint.categories || []);
    const [note, setNote] = useState(complaint.categoryNote || "");
    const [mercury, setMercury] = useState((complaint.chemicals && complaint.chemicals.mercury) ? complaint.chemicals.mercury : false);
    const [pop, setPop] = useState((complaint.chemicals && complaint.chemicals.pop) ? complaint.chemicals.pop : false);
    const [basel, setBasel] = useState((complaint.chemicals && complaint.chemicals.basel) ? complaint.chemicals.basel : false);

    const [updateComplaintCategory] = useUpdateComplaintCategoryMutation();
    const [updateComplaintCategoryNote] = useUpdateComplaintCategoryNoteMutation();
    const [updateComplaintCategoryChemical] = useUpdateComplaintCategoryChemicalMutation();

    const handleCategoryChange = async (event) => {
        let newCategories = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
        setCategories(newCategories);
        try {
            await updateComplaintCategory({complaintId: complaint.id, categories: newCategories}).unwrap()
            dispatch(openSnackbar({message: "Categories updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Categories update failed", severity: "error"}));
        }
    };

    const handleCategoryNoteChange = async (event) => {
        setNote(event.target.value);
        try {
            await updateComplaintCategoryNote({complaintId: complaint.id, note: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Category Note updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Category Note update failed", severity: "error"}));
        }
    };

    const handleCategoryChemicalChange = async (chemical, value) => {
        try {
            await updateComplaintCategoryChemical({
                complaintId: complaint.id,
                chemical: chemical,
                value: value
            }).unwrap()
            dispatch(openSnackbar({message: "Category Chemical updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Category Chemical update failed", severity: "error"}));
        }
    };

    if (!permissions.has("READ_CATEGORY")) return null;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>
                Select categories
            </Typography>
            <FormControl variant="outlined" sx={{
                mt: 1, margin: theme.spacing(1),
                width: "100%",
            }}>
                <InputLabel id="demo-simple-select-outlined-label"
                            htmlFor="demo-simple-select-outlined">Category</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={categories} multiple
                    onChange={handleCategoryChange}
                    label="Categories"
                    input={<OutlinedInput notched={true} label="Category"/>}
                    renderValue={(selected) => selected.map(x => oneCategoryLabel(x)).join(', ')}
                    MenuProps={MenuProps}
                    disabled={!permissions.has("UPDATE_COMPLAINT_CATEGORY")}
                    aria-describedby="base-name-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                >
                    {existingCategories.map((cat) => (
                        <MenuItem key={cat} value={cat}>
                            <Checkbox color="secondary" checked={categories.indexOf(cat) > -1}/>
                            <ListItemText primary={oneCategoryLabel(cat)}/>
                        </MenuItem>
                    ))}
                </Select>

            </FormControl>

            {((categories && categories.includes("CHEMICALS_AND_WATER")) || (complaint.chemicals && (complaint.chemicals.pop || complaint.chemicals.basel || complaint.chemicals.mercury))) &&
                <FormGroup sx={{ml: 3}}>
                    <FormControlLabel control={<Checkbox checked={mercury} onChange={(event) => {
                        setMercury(event.target.checked);
                        handleCategoryChemicalChange("MERCURY", event.target.checked);
                    }}/>}
                                      label="Mercury (Minamata)"/>
                    <FormControlLabel control={<Checkbox checked={pop} onChange={(event) => {
                        setPop(event.target.checked);
                        handleCategoryChemicalChange("POP", event.target.checked);
                    }}/>}
                                      label="POP"/>
                    <FormControlLabel control={<Checkbox checked={basel} onChange={(event) => {
                        setBasel(event.target.checked);
                        handleCategoryChemicalChange("BASEL", event.target.checked);
                    }}/>}
                                      label="Basel Convention"/>
                </FormGroup>
            }
            <TextField id="categoryAddNote"
                       label="Category Note"
                       multiline
                       rows={3}
                       defaultValue={note}
                       onBlur={handleCategoryNoteChange}
                       placeholder="Here you can write a note with an additional explanation about the chosen category."
                       variant="outlined"
                       sx={{mt: 1, margin: theme.spacing(1), width: "100%",}}
                       disabled={!permissions.has("UPDATE_COMPLAINT_CATEGORY")}
            />
        </>
    );
};
