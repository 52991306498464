import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {useUpdateComplaintSummaryMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import theme from "../../../theme";

export const ComplaintFactsSummary = ({complaint, permissions}) => {
    const dispatch = useDispatch();

    const [updateComplaintSummary] = useUpdateComplaintSummaryMutation();

    const [summary, setSummary] = useState(complaint.summary || "");

    const handleComplaintSummaryChange = async (event) => {
        setSummary(event.target.value);
        try {
            await updateComplaintSummary({complaintId: complaint.id, summary: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Complaint summary updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint summary update failed", severity: "error"}));
        }
    };

    if (!permissions.has("READ_SUMMARY")) return null;

    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>
                Initial Complaint Summary
            </Typography>
            <TextField id="categoryAddNote"
                       label="Initial Summary"
                       multiline
                       rows={5}
                       defaultValue={summary}
                       onBlur={handleComplaintSummaryChange}
                       variant="outlined"
                       placeholder="Please write an initial summary of the complaint and of its implications."
                       sx={{margin: theme.spacing(1), width: "100%",}}
                       disabled={!permissions.has("UPDATE_COMPLAINT_SUMMARY")}
            />
        </>
    );
};
