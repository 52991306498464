export const deadlineRed = "#91271b";
export const darkSalmon = "#C37460";
export const mainSalmon = "#F8A18E";
export const lightSalmon = "#FDD2BC";

export const darkTurquoise = "#005863";
export const mainTurquoise = "#1B8591";
export const lightTurquoise = "#58B5C1";

export const black = "#000000";
export const grey = "#E1E2E1";
export const white = "#FFFFFF";

export const lightSalmonShade = "#FEF5F3"; // (INCOMING + WARNING ALERT):
export const lightTurquoiseShade = "#E5EEEF"; // (UPDATED)
export const darkSalmonShade = "#F0DCD7"; // (OPEN)
export const greyShade = "#F4F3F3"; // CLOSED
export const mainTurquoiseShade = "#EEF7F8"; // (INFO ALERT):
export const darkTurquoiseShade = "#E6EEEF"; // (SUCCESS ALERT):

export const COLORS = [darkSalmon, lightSalmon, darkTurquoise, mainTurquoise, lightTurquoise, mainSalmon];

