import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {hasBrand} from "../../../../utils/users";
import LoadingGIF from "../../../common/LoadingGIF";
import NotAuthorized from "../../../common/NotAuthorized";
import Error from "../../../common/Error";
import {
    useGetUsersQuery,
    useUpdateComplaintLegalCategoryMutation,
    useUpdateComplaintRegionMutation
} from "../../../../app/apiSlice";
import {useDispatch} from "react-redux";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function ChoseResponsiblePerson({complaint, permissions, isInternal, user}) {
    const dispatch = useDispatch();

    const [updateComplaintLegalCategory] = useUpdateComplaintLegalCategoryMutation();
    const [updateComplaintRegion] = useUpdateComplaintRegionMutation();

    const {data: users, isLoading: loading, error: error} = useGetUsersQuery();

    const [legal, setLegal] = useState(complaint.legalCategory ? complaint.legalCategory : "");
    const [region, setRegion] = useState(complaint.region ? complaint.region : "");

    if (!isInternal || !hasBrand(user, {id: "bsh"})) return null;
    if (loading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (error) return <Error/>;

    const handleChangeLegalCategory = async (event) => {
        setLegal(event.target.value);
        try {
            await updateComplaintLegalCategory({
                complaintId: complaint.id,
                legalCategory: event.target.value.id
            }).unwrap()
            dispatch(openSnackbar({message: "Complaint Legal Category updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint Legal Category update failed", severity: "error"}));
        }
    };

    const handleChangeRegion = async (event) => {
        setRegion(event.target.value);
        try {
            await updateComplaintRegion({complaintId: complaint.id, region: event.target.value.id}).unwrap()
            dispatch(openSnackbar({message: "Complaint Region updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint Region update failed", severity: "error"}));
        }
    };

    if (!isInternal || !hasBrand(user, {id: "bsh"})) return null;

    function legalCategory(user) {
        user = users.find(u => u.id === user.id);
        if (!user) return null;
        return user.firstName + " " + user.lastName + " (" + user.legalCategory + ")";
    }

    function regionLabel(user) {
        user = users.find(u => u.id === user.id);
        if (!user) return null;
        return user.firstName + " " + user.lastName + " (" + user.region + ")";
    }

    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>Employees in Charge</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box sx={{mt: 2}}>
                    <FormControl variant="outlined" component="span">
                        <InputLabel id="legal-label">Legal</InputLabel>
                        <Select
                            labelId="legal-label"
                            id="legal"
                            value={legal}
                            onChange={handleChangeLegalCategory}
                            renderValue={(legal) => legalCategory(legal)}
                            label="Legal"
                            disabled={!permissions.has("UPDATE_COMPLAINT_CATEGORY")}
                            sx={{minWidth: 120, marginRight: 2}}
                        >
                            {
                                users.filter(user => !!user.legalCategory)
                                    .sort()
                                    .map((user) => (
                                        <MenuItem key={user.id} value={user}>
                                            {legalCategory(user)}
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel id="regional-label">Region</InputLabel>
                        <Select
                            labelId="regional-label"
                            id="regional"
                            value={region}
                            onChange={handleChangeRegion}
                            renderValue={(region) => regionLabel(region)}
                            label="Region"
                            disabled={!permissions.has("UPDATE_COMPLAINT_CATEGORY")}
                            sx={{minWidth: 120}}
                        >
                            {
                                users
                                    .filter(user => !!user.region)
                                    .map(user =>
                                        <MenuItem key={user.id} value={user}>
                                            {regionLabel(user)}
                                        </MenuItem>
                                    )
                            }
                        </Select>
                    </FormControl>
                </Box>

            </Box>
        </>
    );
}
