import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from "@mui/material/Button";
import {Stack, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import UploadFile from "../../../common/file/UploadFile";
import Typography from "@mui/material/Typography";
import File from "../../../common/file/File";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import {Question} from "./Question";
import Chip from "@mui/material/Chip";
import PendingIcon from '@mui/icons-material/Pending';
import ForumIcon from '@mui/icons-material/Forum';
import RestoreIcon from "@mui/icons-material/Restore";
import PersonIcon from '@mui/icons-material/Person';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import InvestigationComments from "./InvestigationComments";
import {useDispatch} from "react-redux";
import {
    useDeleteInvestigationItemMutation,
    useGetFileQuery,
    useSaveInvestigationItemMutation
} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function InvestigationItem({item, investigation, complaint, permissions, user, isInternal}) {
    // todo check permissions

    const dispatch = useDispatch();
    const [saveInvestigationItem] = useSaveInvestigationItemMutation();
    const [deleteInvestigationItem] = useDeleteInvestigationItemMutation();
    const [edit, setEdit] = useState(false);
    const [description, setDescription] = useState(item.description);
    const [dueDate] = useState(item.dueDate);
    const [title, setTitle] = useState(item.title);
    const questionMode = !!item.question;

    const headerTitle = edit ? <TextField
        id="item_title" required
        label="Investigation Item Title"
        fullWidth={true}
        value={title}
        variant="outlined"
        onInput={e => setTitle(e.target.value)}
    /> : <Typography variant="h6" color="black">{title}</Typography>;

    function getAction() {

        function getWaitingForAnswerChip() {
            if (!item || !item.question || item.question.answer) return null;
            return <Chip avatar={<PendingIcon/>} label={"waiting for answer"} variant="outlined"/>;
        }

        function getAskExternalChip() {
            if (!item || !item.question) return null;
            return <Chip avatar={<ForumIcon/>}
                         label={`by ${item.question.askingUser.firstName} ${item.question.askingUser.lastName} to ${item.question.askedUser.firstName} ${item.question.askedUser.lastName}`}
                         variant="outlined"/>;
        }

        function getAddedExternalChip() {
            if (!item || item.question || !item.fromName) return null;
            return <Chip avatar={<LocalLibraryIcon/>}
                         label={`Opinion by ${item.fromRole} ${item.fromName}`}
                         variant="outlined"/>;
        }

        function getOwnOpinionChip() {
            if (!item || item.question || item.fromName) return null;

            const lastUpdate = investigation.history.filter(entry => entry.investigationItemId === item.id)
                .find(entry => entry.type === "CREATE_INVESTIGATION_ITEM");
            if (lastUpdate && lastUpdate.user)
                return <Chip avatar={<PersonIcon/>}
                             label={`Own Opinion by ${lastUpdate.user.firstName} ${lastUpdate.user.lastName}`}
                             variant="outlined"/>;
            return <Chip avatar={<PersonIcon/>}
                         label={`Opinion by brand employee`}
                         variant="outlined"/>;
        }

        function getLastUpdateChip() {
            if (!investigation || !investigation.history || investigation.history.length === 0) return null;
            const lastUpdate = investigation.history.filter(entry => entry.investigationItemId === item.id).sort((a, b) => (a.date > b.date && 1) || -1).pop();
            if (lastUpdate && lastUpdate.date)
                return <Chip avatar={<RestoreIcon/>}
                             label={"Last update: " + new Date(lastUpdate.date).toLocaleDateString()}
                             variant="outlined"/>;
        }

        return <Stack direction="row" spacing={1} alignItems="flex-end">
            <Stack spacing={1} alignItems="flex-end">
                <Stack direction="row" spacing={1}>
                    {getWaitingForAnswerChip()}
                    {getAskExternalChip()}
                    {getAddedExternalChip()}
                    {getOwnOpinionChip()}
                    {getLastUpdateChip()}
                </Stack>
            </Stack>
            {permissions.has("UPDATE_INVESTIGATION") && investigation.status === "IN_CREATION" &&
                <IconButton aria-label="settings" onClick={() => setEdit(!edit)}>
                    <EditIcon color={edit ? 'primary' : 'secondary'}/>
                </IconButton>}
        </Stack>;
    }

    async function handleSaveInvestigationItem() {
        try {
            await saveInvestigationItem({
                investigationId: investigation.id,
                complaintId: investigation.complaintId,
                investigationItem: {...item, description, dueDate, title}
            }).unwrap();
            dispatch(openSnackbar({message: "Successfully saved investigation item", severity: "success"}));
            setEdit(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to save investigation item", severity: "error"}));
        }
    }

    async function handleDeleteInvestigationItem() {
        try {
            await deleteInvestigationItem({investigationId: investigation.id, investigationItemId: item.id}).unwrap();
            dispatch(openSnackbar({message: "Successfully deleted investigation item", severity: "success"}));
            setEdit(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to delete investigation item", severity: "error"}));
        }
    }

    return (
        <Card sx={{mt: 3, p: 1}}>
            <CardHeader
                title={headerTitle}
                action={getAction()}
            />
            <CardContent>
                {questionMode && <Typography
                    variant="span">by {item.question.askingUser.firstName} {item.question.askingUser.lastName}&nbsp;to&nbsp;
                    <b>{item.question.askedUser.firstName} {item.question.askedUser.lastName}</b> from&nbsp;
                    {new Date(item.question.cdate).toLocaleDateString()} {new Date(item.question.cdate).toLocaleTimeString()}
                </Typography>}
                {item.fromRole && <Typography
                    variant="span">{`Opinion of ${item.fromRole} ${item.fromName} added ${new Date(item.cdate).toLocaleDateString()} ${new Date(item.cdate).toLocaleTimeString()}`}
                </Typography>}
                {!questionMode && !item.fromRole && <Typography
                    variant="span">{`Own Opinion added ${new Date(item.cdate).toLocaleDateString()} ${new Date(item.cdate).toLocaleTimeString()}`}
                </Typography>}

                <Typography variant="h6" color="black" sx={{mt: 1}}>Description</Typography>
                {edit && <TextField
                    id="item_description" required
                    label="Investigation Item Description"
                    style={{marginTop: 20}}
                    multiline
                    minRows={4}
                    fullWidth={true}
                    value={description}
                    variant="outlined"
                    onInput={e => setDescription(e.target.value)}
                />}
                {!edit && <Typography variant="span">{description}</Typography>}

                {questionMode && <Question question={item.question} user={user} complaintId={complaint.id}
                                           investigationId={investigation.id}/>}
                {item.files && item.files.length > 0 && <>
                    <Typography variant="h6">Existing files</Typography>
                    <Grid container spacing={1}>{item.files.map((file, index) => (
                        <Grid item key={index}> <File file={file} complaintId={investigation.complaintId}
                                                      investigationId={investigation.id} itemId={item.id}
                                                      useGetFileQuery={useGetFileQuery}
                                                      mayUpdate={permissions.has("UPDATE_INVESTIGATION") && investigation.status === "IN_CREATION"}
                        /> </Grid>))}
                    </Grid></>}
                {edit && <UploadFile
                    meta={{
                        "investigationId": investigation.id,
                        "key": item.id,
                        "complaintId": complaint.id,
                        "investigationItemId": item.id,
                        "fileType": "INVESTIGATION",
                    }} label="Upload File"/>}
                <InvestigationComments item={item} investigation={investigation} user={user}
                                       permissions={permissions} complaint={complaint} isInternal={isInternal}/>
            </CardContent>
            <CardActions hidden={!edit}>
                <Button variant="contained"
                        color="secondary"
                        size={"large"}
                        style={{marginLeft: 5}}
                        onClick={handleDeleteInvestigationItem}>
                    delete
                </Button>
                <Button variant="contained"
                        color="primary"
                        size={"large"}
                        style={{marginLeft: 5}}
                        onClick={handleSaveInvestigationItem}>
                    save
                </Button>

            </CardActions>
        </Card>
    );

}
