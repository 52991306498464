import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Link} from "react-router-dom";
import logo from "../assets/atlat_symbol.svg"
import {useAuth0} from "@auth0/auth0-react";
import LanguageSelector from "./LanguageSelector";
import {useGetBrandsQuery, useGetMeQuery} from "../app/apiSlice";

const AtlatAppBar = () => {

    const {user, isAuthenticated, loginWithRedirect, logout,} = useAuth0();
    const {data: me} = useGetMeQuery();
    const isAdmin = user && user['https://truecolorssustainability.com//role'] && user['https://truecolorssustainability.com//role'].includes("ADMIN");
    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        })

    const isExternal = me && me.external;
    const isUser = me && me.user;
    const isBrandManager = me && me.brandManager;

    const pages = [
        {name: 'Complaints', route: "/complaints", visible: (isAuthenticated && (isUser || isBrandManager || isAdmin))},
        {name: 'Complaints', route: "/external/complaints", visible: (isAuthenticated && isExternal)},
        {name: 'Company', route: "/company", visible: (isAuthenticated && isAdmin)},
        {name: 'Reports', route: "/kpis", visible: (isAuthenticated && isBrandManager)},
        {name: 'Users', route: "/users", visible: (isAuthenticated && isAdmin)},
        {name: 'Roles', route: "/permissions", visible: (isAuthenticated && isAdmin)},
        {name: 'Brands', route: "/brands", visible: (isAuthenticated && isAdmin)},
        {name: 'Links', route: "/linksmgmt", visible: (isAuthenticated && isAdmin)},
        {name: 'ReportPrep', route: "/reportPrep", visible: (isAuthenticated && isAdmin)},
        {name: 'Trainings', route: "/trainings", visible: (isAuthenticated && (isAdmin || isBrandManager))},
        {name: 'Surveys', route: "/surveys", visible: (isAuthenticated && (isAdmin || isBrandManager))},
        {name: 'Locations', route: "/locations", visible: (isAuthenticated && isAdmin)},   // todo location version for brand managers
        {name: 'Templates', route: "/templates", visible: (isAuthenticated && isAdmin)},
        {name: 'Help', route: "/help", visible: (!me)}
    ];
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" color="transparent" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to={"/"}>
                        <Box component="img" nowrap="true" sx={{mr: 2, display: {xs: 'none', md: 'flex'}, maxWidth: 50}}
                             alt="atlat logo" src={logo}/>
                    </Link>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="primary"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page, number) => (
                                page.visible && <MenuItem key={number} onClick={handleCloseNavMenu}>
                                    <Link to={page.route}>{page.name}</Link>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box nowrap="true" sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}} component={Link} to={"/"}>
                        <Box component="img" nowrap="true" sx={{maxHeight: 20, mr: 1}} alt="atlat logo" src={logo}/>
                        <Typography variant="h6" nowrap="true" component="div"> atlat </Typography>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page, number) => (
                            page.visible && <Button
                                key={number}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, display: 'block'}}
                                component={Link} to={page.route}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    <LanguageSelector/>

                    {!isAuthenticated && <Button onClick={() => loginWithRedirect()}>Log in</Button>}

                    {isAuthenticated && <Box sx={{flexGrow: 0}}>
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            <Avatar alt="user picture" src={user.picture}/>
                        </IconButton>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {me && <MenuItem key={"username"} onClick={handleCloseUserMenu}>
                                <Typography>{`${me.firstName} ${me.lastName}`}</Typography>
                            </MenuItem>}
                            {me && <MenuItem key={"email"} onClick={handleCloseUserMenu}>
                                <Typography>{`${me.email}`}</Typography><br/>
                            </MenuItem>}
                            {me && me.brands && brands && <MenuItem key={"brands"} onClick={handleCloseUserMenu}>
                                <Typography>{Array.from(new Set(brands
                                    .filter(b => me.brands.map(mb => mb.id).includes(b.id))
                                    .map(b => b.shortName)))
                                    .join(", ")}</Typography>
                            </MenuItem>}
                            {me && me.subBrands && brands && <MenuItem key={"subBrands"} onClick={handleCloseUserMenu}>
                                <Typography>{Array.from(new Set(brands
                                    .filter(b => me.subBrands.includes(b.id))
                                    .map(b => b.shortName)))
                                    .join(", ")}</Typography>
                            </MenuItem>}
                            <MenuItem key={"logout"} onClick={handleCloseUserMenu}>
                                <Button variant={"outlined"} onClick={() => logoutWithRedirect()}>Log out</Button>
                            </MenuItem>
                        </Menu>
                    </Box>}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default AtlatAppBar;
