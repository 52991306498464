import React from "react";
import Box from "@mui/material/Box";
import deuterLogo from "./deuter_logo.png";
import atlat_full_logo from "../../assets/atlat_full_logo.png";
import Grid from "@mui/material/Grid";
import {Link} from "@mui/material";
import {useTranslation} from "react-i18next";

const DeuterFooter  = () => {

    const {t} = useTranslation("imprint");

    return (  <Box sx={{
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        background: "#f0eeec",
        minHeight: "100px",
        padding: "10px"
    }} component="footer">
        <Grid container spacing={2} sx={{
            maxWidth: "600px"
        }}>
            <Grid item xs={2}> </Grid>
            <Grid item xs={2}><img src={deuterLogo} alt="illu_entercode" width="120"/> </Grid>
            <Grid item xs={3}><img src={atlat_full_logo} alt="illu_entercode" width="120"/></Grid>
            <Grid item xs={2}>
                <Link href={"/imprint"}>
                    {t("imprint.title")}
                </Link>
            </Grid>
            <Grid item xs={12}>
                <div style={{textAlign: "center"}}>
                    {t("imprint.madeByAtlatAndDeuter")}
                </div>
            </Grid>
        </Grid>
    </Box>
    )
}

export default DeuterFooter;
