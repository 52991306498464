import React from "react";
import {TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {useDispatch} from "react-redux";
import {useAnswerQuestionMutation} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";

export const Question = ({question, complaintId, investigationId}) => {

    const dispatch = useDispatch();
    const [answerQuestion] = useAnswerQuestionMutation();
    const validationSchema = Yup.object().shape({
        answer: Yup.string().required('Answer is required'),
    });

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm({resolver: yupResolver(validationSchema)});

    const onSubmit = async (data) => {
        try {
            await answerQuestion({questionId: question.id, data: data, complaintId, investigationId}).unwrap();
            dispatch(openSnackbar({message: "Successfully answered question", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to answer question", severity: "error"}));
        }
    };

    return (<Box component="form" style={{margin: 5}} key={question.id}>
        <Typography variant="h6" color="black">
            Question
            from {question.askingUser.firstName} {question.askingUser.lastName} at {new Date(question.cdate).toLocaleDateString()} {new Date(question.cdate).toLocaleTimeString()}:
        </Typography>{`"${question.question}"`}
        <Typography variant="h6" color="black">Your answer</Typography>
        {question.answer && question.answer}
        {!question.answer && <>
            <TextField
                sx={{m: 1}}
                id="answer"
                name="answer"
                multiline
                rows={4}
                label="Your answer"
                {...register('answer')}
            />
            <Typography variant="inherit" color="error">
                {errors.answer?.message}
            </Typography>
            <LoadingButton
                fullWidth
                loading={isSubmitting}
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
            >
                Save answer
            </LoadingButton>
        </>}
    </Box>);

}

