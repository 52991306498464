import React, {useEffect} from "react";
import {Route, Router, Switch} from "react-router-dom";
import {Alert, Container, Snackbar} from '@mui/material';
import LoadingGIF from "./components/common/LoadingGIF";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Complaints from "./views/Complaints";
import {useAuth0} from "@auth0/auth0-react";
import history from "./utils/history";
import axios from "axios";
import "./App.css";
import ViewComplaint from "./views/ViewComplaint";
import HandleComplaint from "./views/HandleComplaint";
import theme from "./components/theme";
import Users from "./views/Users";
import Permissions from "./views/Permissions";
import Brands from "./views/Brands";
import Locations from "./views/Locations";
import Location from "./views/Location";
import {ThemeProvider} from '@mui/material/styles';
import ExternalComplaints from "./views/externals/ExternalComplaints";
import HandleComplaintAsExternal from "./views/externals/HandleComplaintAsExternal";
import Company from "./views/Company";
import AtlatAppBar from "./components/AtlatAppBar";
import Help from "./views/Help";
import Links from "./views/Links";
import LinksMgmt from "./views/LinksMgmt";
import DeuterHome from "./custom/deuter/DeuterHome";
import DeuterFooter from "./custom/deuter/DeuterFooter";
import Imprint from "./components/Imprint";
import Brand from "./components/admin/brands/Brand";
import {useDispatch, useSelector} from "react-redux";
import {closeSnackbar} from "./app/snackbarSlice";
import {useUpdateUserPictureMutation} from "./app/apiSlice";
import {sec} from "./app/security";
import CheckIn from "./views/CheckIn";
import CheckIns from "./components/kpis/CheckIns";
import Voices from "./views/Voices";
import Templates from "./components/templates/Templates";
import Template from "./components/templates/Template";
import ReportPrep from "./components/kpis/ReportPrep";
import CheckinReport from "./components/kpis/CheckinReport";
import AssignBrands from "./components/brands/AssignBrands";
import KPISPage from "./components/kpis/KPISPage";
import CheckinReportData from "./components/kpis/CheckinReportData";
import Trainings from "./components/training/Trainings";
import Training from "./components/training/Training";
import Surveys from "./components/kpis/reports/Surveys";

const App = () => {
    const dispatch = useDispatch();
    const {isLoading, error, getAccessTokenSilently, user} = useAuth0();
    const [updateUserPicture] = useUpdateUserPictureMutation();

    // set getAccessTokenSilently in global module to reuse it outside a React component
    sec.setAccessTokenSilently(getAccessTokenSilently);

    useEffect(() => {
        async function fetchData() {
            async function fetchData() {
                // You can await here
                if (user && user.picture) {

                    let lastPictureUpdate;
                    if (localStorage.getItem(`picture_update_${user.sub}`)) {
                        lastPictureUpdate = new Date(localStorage.getItem(`picture_update_${user.sub}`));
                    }
                    const moreThanOneHourAgo = (date) => {
                        const HOUR = 1000 * 60 * 60;
                        const anHourAgo = Date.now() - HOUR;

                        return date < anHourAgo;
                    }

                    if (!lastPictureUpdate || moreThanOneHourAgo(lastPictureUpdate))
                        try {
                            await updateUserPicture({picture: user.picture}).unwrap();
                            localStorage.setItem(`picture_update_${user.sub}`, new Date().toISOString());
                        } catch (error) {
                            console.log(error);
                        }
                }
                // ...
            }
        }

        fetchData();
    }, [user]);

    const message = useSelector(state => state.snackbar.message)
    const severity = useSelector(state => state.snackbar.severity)
    const open = useSelector(state => state.snackbar.open)

    axios.interceptors.request.use(
        async (config) => {

            if ((new RegExp(/\/api\/complaint\/code\b/)).test(config.url)) return config;
            if ((new RegExp(/\/api\/complaint\b/)).test(config.url)) return config;
            if ((new RegExp(/\/api\/files\/complaint\b/)).test(config.url)) return config;
            if ((new RegExp(/\/api\/files\/trainer\b/)).test(config.url)) return config;

            const token = await getAccessTokenSilently({audience: `https://complaints/api`,});

            if (token) {
                config.headers = {
                    authorization: `Bearer ${token}`
                };
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    if (error) {
        return <div>An error occurred:... {error.message}</div>;
    }

    if (isLoading) {
        return <LoadingGIF/>;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(closeSnackbar());
    };

    return (
        <Router history={history}>
            <ThemeProvider theme={theme}>
                <div id="app" style={{display: "flex", minHeight: "100vh", flexDirection: "column"}}>
                    <AtlatAppBar/>
                    <Container maxWidth={'xl'} disableGutters={true} sx={{flex: 1}}>
                        <Switch>
                            <Route path="/" exact component={Home}/>
                            <Route path="/profile" component={Profile}/>
                            <Route path="/complaint/:code" component={ViewComplaint}/>
                            <Route path="/complaints/:id" component={HandleComplaint}/>
                            <Route path="/complaints" component={Complaints}/>
                            <Route path="/users" component={Users}/>
                            <Route path="/company" component={Company}/>
                            <Route path="/permissions" component={Permissions}/>
                            <Route path="/brands/:brand" component={Brand}/>
                            <Route path="/brands" component={Brands}/>
                            <Route path="/locations/:location" component={Location}/>
                            <Route path="/locations" component={Locations}/>
                            <Route path="/external/complaints/:id" component={HandleComplaintAsExternal}/>
                            <Route path="/external/complaints" component={ExternalComplaints}/>
                            <Route path="/help" component={Help}/>
                            <Route path="/links/:name" component={Links}/>
                            <Route path="/linksmgmt" component={LinksMgmt}/>
                            <Route path="/deuter" exact component={DeuterHome}/>
                            <Route path="/imprint" exact component={Imprint}/>
                            <Route path="/kpis" exact component={KPISPage}/>
                            <Route path="/surveys" exact component={Surveys}/>
                            <Route path="/checkins/:id" exact component={CheckIns}/>
                            <Route path="/checkin/:id" exact component={CheckIn}/>
                            <Route path="/voices" exact component={Voices}/>
                            <Route path="/templates/:id" component={Template}/>
                            <Route path="/templates" component={Templates}/>
                            <Route path="/assign-brands" component={AssignBrands}/>
                            <Route path="/reportPrep" component={ReportPrep}/>
                            <Route path="/report/:id/raw" exact component={CheckinReportData}/>
                            <Route path="/report/:id" exact component={CheckinReport}/>
                            <Route path="/training/:id" exact component={Training}/>
                            <Route path="/trainings" exact component={Trainings}/>
                            {/*// todo default page*/}
                        </Switch>
                    </Container>

                    <Switch>
                        <Route path="/deuter" exact component={DeuterFooter}/>
                        <Footer/>
                    </Switch>

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                            {message}
                        </Alert>
                    </Snackbar>


                </div>
            </ThemeProvider>
        </Router>
    );
};

export default App;

// todo add skeletons
// todo auth
// todo move secrets to env
// todo use theme everywhere?
