import React, {useState} from "react";
import TextField from '@mui/material/TextField';
import atlat_full_logo from "../assets/atlat_full_logo.png";
import illu_entercode from "../assets/home/illu_entercode.png";
import illu_faq_customerservice from "../assets/home/illu_faq_customerservice.png";
import {useHistory} from "react-router-dom";
import {Button, Link, Stack} from "@mui/material";
import ComplaintWizard from "./wizard/ComplaintWizard";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useTranslation} from 'react-i18next';
import {darkSalmon, lightSalmon, lightTurquoise} from "./common/colors";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Speech from "./wizard/Speech";
import {v4 as uuidv4} from "uuid";
import {complaintSteps} from './wizard/steps/complaintSteps';
import {useGetComplaintCodeQuery, useUpdateComplaintMutation, useCreateComplaintMutation} from "../app/apiSlice";
import {Helmet} from "react-helmet";
import themex from "./theme";

const Hero = ({
                  color1 = darkSalmon, logo = atlat_full_logo, color2 = lightTurquoise,
                  workerChat, bgcolorCode = lightSalmon,
                  illuEntercode = illu_entercode, brand = "default", theme = themex
              }) => {

    const history = useHistory();
    const {t, i18n} = useTranslation("home");

    const [code, setCode] = useState('');

    function handleSubmit(event) {
        event.preventDefault();
        history.push(`/complaint/${code}`);
    }

    function speech(text) {
        return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
    }

    return (

        <>
            <Helmet
                onChangeClientState={() => {
                    const metaDescription = document.querySelector('meta[name="description"]');
                    if (metaDescription) {
                        metaDescription.setAttribute('content', t('home.description'));
                    }
                }}>

                >
            </Helmet>

            <Grid container padding={1} mt={1} sx={{maxWidth: "650px"}}>
                <Grid item container xs={12}>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h3" component="span" sx={{mb: 2}}>
                            {t('home.hello')}
                        </Typography>
                        <Typography variant="h3" component="span" sx={{mb: 2, color: color1}}>
                            .
                        </Typography>
                        {speech(t('home.hello') + ".")}
                        <br/>
                        <Typography variant="h3" component="span" sx={{mb: 2}}>
                            {t('home.howCanWeHelpYou')}
                        </Typography>
                        <Typography variant="h3" component="span" sx={{mb: 2, color: color1}}>
                            ?
                        </Typography>
                        {speech(t('home.howCanWeHelpYou') + "?")}
                    </Grid>
                    <Grid item container xs={4}>
                        <Box
                            mt={4}
                            justifyContent="center"
                            alignItems="center" display={{xs: "none", sm: "block"}}
                        >
                            <img src={logo} alt="logo" width="120"/>
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch" padding={1} spacing={1} mb={5}>
                <Grid item xs={12} sm={6} md={4} id="complaintWizard" mt={3} padding={1}>

                    <ComplaintWizard workerChat={workerChat} brand={brand} steps={complaintSteps}
                                     name={`${brand}_complaint`} theme={theme} speech={true}
                                     translation={brand === "deuter" ? "complaint_deuter" : "complaint2"}
                                     useUpdateMutation={useUpdateComplaintMutation}
                                     useGetCodeQuery={useGetComplaintCodeQuery}
                                     useCreateMutation={useCreateComplaintMutation}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} id="checkCode" mt={3} padding={1}>
                    <Card variant="outlined" sx={{
                        height: "100%", p: 1,

                        boxShadow: "0px 4px 4px 0px #00000040",
                        borderWidth: "4px", borderColor: theme.palette.primary.light
                    }}>
                        <CardHeader
                            avatar={
                                <img src={illuEntercode} alt="illu_entercode" width="120"/>
                            }
                            title={<Stack justifyContent="start" direction="row" alignItems="center">
                                <Typography
                                    variant="h6">{t("home.enterYourCode")}</Typography> {speech(t('home.enterYourCode'))}
                            </Stack>}
                        />
                        <CardContent>
                            <Typography gutterBottom align={"center"}>
                                {t("home.pleaseEnterCode")}{speech(t('home.pleaseEnterCode'))}
                            </Typography>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField id="complaintCodeTextField"
                                               label={t("home.complaintCodeLabel")}
                                               variant={"outlined"}
                                               onInput={e => setCode(e.target.value)}
                                               InputProps={{
                                                   endAdornment: <Button variant={"outlined"}
                                                                         type="submit">{t("home.check")}</Button>
                                               }}/>
                                </form>
                                {speech(t('home.check') + " " + t("home.complaintCodeLabel"))}
                            </Box>
                            <Typography gutterBottom align={"center"} sx={{mt: 2}}>
                                {t("home.codeReceived")}{speech(t('home.codeReceived'))}
                            </Typography>
                        </CardContent>
                    </Card>


                </Grid>
                <Grid item xs={12} md={4} id="help" mt={3} padding={1}>
                    <Card variant="outlined" sx={{
                        height: "100%", p: 1,
                        boxShadow: "0px 4px 4px 0px #00000040",
                        borderWidth: "4px", borderColor: theme.palette.primary.light
                    }}>
                        <CardHeader
                            avatar={
                                <img src={illu_faq_customerservice} alt="illu_faq_customerservice" width="120"/>
                            }
                            title={<Stack justifyContent="start" direction="row" alignItems="center">
                                <Typography
                                    variant="h6">{t("home.needHelp")}</Typography> {speech(t('home.needHelp'))}
                            </Stack>}
                        />
                        <CardContent>
                            <Typography gutterBottom align={"center"}>
                                {t("home.helpText1")}{speech(t('home.helpText1'))}
                            </Typography>
                            <Typography gutterBottom align={"center"}>
                                {t("home.helpText2")}{speech(t('home.helpText2'))}
                            </Typography>

                            <Box display="flex" justifyContent="center">
                                <Link href={"/help"}>
                                    <Button variant="contained">
                                        {t("home.goToFAQ")}
                                    </Button>
                                </Link>
                                {speech(t('home.goToFAQ'))}
                            </Box>
                            <Typography gutterBottom align={"center"} sx={{mt: 2}}>
                                {t("home.contact")} {speech(t('home.contact'))}
                            </Typography>
                            <Typography gutterBottom align={"center"}>
                                {t("home.getHelp")} {speech(t('home.getHelp'))}
                            </Typography>
                            <Typography align={"center"}>
                                <a href="mailto:support@atlat.de"
                                   target="_top">support@atlat.de</a>{speech("support@atlat.de")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
};

export default Hero;
