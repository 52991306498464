import React from "react";
import Grid from "@mui/material/Grid";
import {useTranslation} from 'react-i18next';

const Imprint = () => {

    const {t} = useTranslation("imprint");

    return (
        <Grid container padding={2} spacing={2} p={5} sx={{maxWidth: "650px"}}>
            <Grid item xs={12}>
                {t("imprint.companyName")}
            </Grid>
            <Grid item xs={12}>
                {t("imprint.addressLine1")}
            </Grid>
            <Grid item xs={12}>
                {t("imprint.addressLine2")}
            </Grid>
            <Grid item xs={12}>
                {t("imprint.addressLine3")}
            </Grid>
            <Grid item xs={12}>
                <a href={"mailto:" + t("imprint.email")}>{t("imprint.email")}</a>
            </Grid>
            <Grid item xs={12}>
                <a href={"tel:" + t("imprint.phone")}>{t("imprint.phone")}</a>
            </Grid>
            <Grid item xs={12}>
                {t("imprint.representedBy")}
            </Grid>
            <Grid item xs={12}>
                {t("imprint.registrationCourt")}
            </Grid>
            <Grid item xs={12}>
                {t("imprint.companyID")}
            </Grid>
            <Grid item xs={12}>
                {t("imprint.taxID")}
            </Grid>
            <Grid item xs={12}>
                {t("imprint.dataSecurity")}: <a
                href={"mailto:" + t("imprint.dataSecurityEmail")}>{t("imprint.dataSecurityEmail")}</a>
            </Grid>
        </Grid>

    )
};

export default Imprint;
