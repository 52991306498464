import React from "react";
import LoadingGIF from "../common/LoadingGIF";
import NotAuthorized from "../common/NotAuthorized";
import Error from "../common/Error";
import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {useGetCheckInReportRawQuery} from "../../app/apiSlice";
import {useRouteMatch} from "react-router-dom";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import CheckinReportDataTable from "./CheckinReportDataTable";

const CheckinReportData = () => {

    const match = useRouteMatch();
    const {data: report, isLoading: reportLoading, error: reportError} = useGetCheckInReportRawQuery(match.params.id);

    if (reportLoading) return <LoadingGIF/>;
    if (reportError && reportError.status === 403) return <NotAuthorized/>;
    if (reportError) return <Error/>;

    return (
        <Box p={3}>
            <Stack direction="row" justifyContent="space-between" sx={{pt: 2, pb: 3}}>
                <Typography variant="h3" component="span">
                    Report {report.name ? report.name : report.id}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" sx={{pb: 3}}>
                <Chip label={`Number of participants: ${report.numberOfParticipants}`} color="primary"
                      variant="outlined"/>

                <Button variant="contained" color="primary" href={`/report/${match.params.id}`}>
                    Back to Report Page
                </Button>
            </Stack>

            <CheckinReportDataTable data={report}/>

        </Box>);
};
export default withAuthenticationRequired(CheckinReportData, {
    onRedirecting: () => <LoadingGIF/>,
});
