import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Stack, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useDispatch} from "react-redux";
import {useUpdateComplaintStatusMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function RejectComplaintForm({complaint, setOpen}) {

    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();
    const [updateComplaintStatus] = useUpdateComplaintStatusMutation();

    const validationSchema = Yup.object().shape({
        status: Yup.string().required('Status is required'),
        comment: Yup.string(),
    });

    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(validationSchema)
    });


    const onSubmit = async (data) => {
        if (complaint.status !== "OPEN") return;
        setSending(true);

        if (data.status)
            try {
                await updateComplaintStatus({
                    complaintId: complaint.id,
                    status: data.status,
                    comment: data.comment
                }).unwrap()
                dispatch(openSnackbar({message: "Complaint status updated successfully", severity: "success"}));
                setSending(false);
                reset();
                setOpen(false);
            } catch (err) {
                dispatch(openSnackbar({message: "Complaint status update failed", severity: "error"}));
                setSending(false);
                reset();
                setOpen(false);
            }
    };

    const {ref, ...rest} = register('status')

    return (
        <>
            <Typography variant="h5" component="h1">
                Close Complaint
            </Typography>
            <FormControl sx={{minWidth: 250, mt: 2}}>
                <InputLabel id="status-label">Rejection Complaint Status</InputLabel>
                <Select
                    labelId="status-label"
                    id="status"
                    name="status"
                    label="Rejection Complaint Status"
                    inputRef={ref}  {...rest}
                    error={!!errors.status}
                >
                    <MenuItem value={"CLOSED_NOT_PLAUSIBLE"}>Complaint is not plausible</MenuItem>
                    <MenuItem value={"CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION"}>Complainant can't be reached
                        for clarification</MenuItem>
                    <MenuItem value={"CLOSED_REASON_NOT_ELIGIBLE"}>Complaint reason is not eligible</MenuItem>
                </Select>
            </FormControl>
            <Typography variant="inherit" color="error">
                {errors.status?.message}
            </Typography>

            <TextField
                sx={{minWidth: 250, mt: 2}}
                id="comment"
                name="comment"
                label="Comment"
                multiline rows={3}
                {...register('comment')}
                error={!!errors.comment}
            />
            <Typography variant="inherit" color="error">
                {errors.comment?.message}
            </Typography>

            <Stack direction="row" spacing={2} sx={{mt: 2}}>
                <Button variant='outlined' onClick={() => setOpen(false)}
                        color='primary'>
                    Cancel
                </Button>
                <LoadingButton
                    loading={sending}
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit(onSubmit)}
                >
                    Close Complaint
                </LoadingButton>
            </Stack>
        </>
    );
}
