import React, {useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../common/LoadingGIF";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Cell, Legend, Pie, PieChart, Tooltip} from "recharts";
import {darkSalmon, darkTurquoise, lightSalmon, lightTurquoise, mainSalmon, mainTurquoise} from "../common/colors";
import {categoryLabel} from "../dashboard/categories";
import {countries} from "../countries/countries";
import Chip from "@mui/material/Chip";
import {capItemStatuslabel, statusLabel} from "../dashboard/labels";
import {parse} from "tinyduration";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Alert, Stack} from "@mui/material";
import CollapsibleSection from "../common/CollapsibleSection";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import File from "../common/file/File";
import {useGetFileQuery} from "../../app/apiSlice";

const KPIS = ({data, locations, me, brands}) => {


    const [brand, setBrand] = useState(brands[0]);
    const [interval, setInterval] = useState(Object.keys(data[brand.id])[0]);

    const handleChange = (event) => {
        setInterval(event.target.value);
    };

    const handleBrandChange = (event) => {
        if (data[event.target.value.id]) {
            setInterval(Object.keys(data[event.target.value.id])[0]);
        }
        setBrand(event.target.value);
    };


    const kpis = (data && brand && data[brand.id] && interval) ? data[brand.id][interval] : {};

    const complaintsByCategory = [];
    if (kpis.complaintsByCategory)
        Object.keys(kpis.complaintsByCategory).forEach(key => {
            if (kpis.complaintsByCategory[key] > 0)
                complaintsByCategory.push({
                    "name": categoryLabel([key]),
                    "value": kpis.complaintsByCategory[key]
                },)
        });

    const complaintsByCountry = [];
    if (kpis.complaintsByCountry)
        Object.keys(kpis.complaintsByCountry).forEach(key => {
            if (kpis.complaintsByCountry[key] > 0)
                complaintsByCountry.push({
                    "name": countries[key],
                    "value": kpis.complaintsByCountry[key]
                },)
        });

    const countByStatus = [];
    if (kpis.countByStatus)
        Object.keys(kpis.countByStatus).forEach(key => {
            if (kpis.countByStatus[key] > 0)
                countByStatus.push({
                    "name": statusLabel(key),
                    "value": kpis.countByStatus[key]
                },)
        });

    const countByCapItemStatus = [];
    if (kpis.countByCapItemStatus)
        Object.keys(kpis.countByCapItemStatus).forEach(key => {
            if (kpis.countByCapItemStatus[key] > 0)
                countByCapItemStatus.push({
                    "name": capItemStatuslabel(key),
                    "value": kpis.countByCapItemStatus[key]
                },)
        });

    let COLORS = [darkSalmon, lightSalmon, darkTurquoise, mainTurquoise, lightTurquoise, mainSalmon];

    function durationString(durationStr) {
        if (!durationStr) return null;
        let duration = parse(durationStr);
        let result = "";
        if (duration.years) result += duration.years + " Jahre ";
        if (duration.months) result += duration.months + " Monate ";
        if (duration.weeks) result += duration.weeks + " Wochen ";
        if (duration.days) result += duration.days + " Tage ";
        if (duration.hours) result += duration.hours + " Stunden ";
        if (duration.minutes) result += duration.minutes + " Minuten ";
        if (!result && duration.seconds) result += duration.seconds + " Sekunden ";
        return result;
    }

    function complaintButton(complaintId) {
        if (!complaintId) return null;
        return <Button sx={{m: 1}}
                       size="small"
                       variant="outlined"
                       target="_blank"
                       component="a"
                       href={`/complaints/${complaintId}`}
        >
            Gehe zu Complaint C{complaintId}
        </Button>
    }

    function getCategories() {
        if (complaintsByCategory.length === 0)
            return <Alert severity="info">No data</Alert>

        return <PieChart width={750} height={300}>
            <Pie data={complaintsByCategory} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={0}
                 outerRadius={100} labelLine={false}>
                {complaintsByCategory.map((entry, index) =>
                    <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

            </Pie>
            <Tooltip/>
            <Legend
                layout="vertical" verticalAlign="top" align="right" height={200}
                formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                payload={
                    complaintsByCategory.map(
                        (item, index) => ({
                            id: item.name,
                            type: "square",
                            value: `${item.name} (${item.value} / ${(item.value / kpis.numberOfComplaints * 100).toFixed(1)}%)`,
                            color: COLORS[index % COLORS.length]
                        })
                    )
                }
            />
        </PieChart>
    }

    function getCountries() {
        if (complaintsByCountry.length === 0)
            return <Alert severity="info">No data</Alert>
        return <PieChart width={750} height={300}>
            <Pie data={complaintsByCountry} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={0}
                 outerRadius={100} labelLine={false}>
                {complaintsByCountry.map((entry, index) =>
                    <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

            </Pie>
            <Tooltip/>
            <Legend
                layout="vertical" verticalAlign="top" align="right" height={200}
                formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                payload={
                    complaintsByCountry.map(
                        (item, index) => ({
                            id: item.name,
                            type: "square",
                            value: `${item.name} (${item.value} / ${(item.value / kpis.numberOfComplaints * 100).toFixed(1)}%)`,
                            color: COLORS[index % COLORS.length]
                        })
                    )
                }
            />
        </PieChart>;
    }

    function getProcessingStatus() {
        if (countByStatus.length === 0)
            return <Alert severity="info">No data</Alert>
        return <PieChart width={750} height={300}>
            <Pie data={countByStatus} dataKey="value" nameKey="name" cx="50%" cy="50%"
                 innerRadius={0}
                 outerRadius={100} labelLine={false}>
                {countByStatus.map((entry, index) =>
                    <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

            </Pie>
            <Tooltip/>
            <Legend
                layout="vertical" verticalAlign="top" align="right" height={200}
                formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                payload={
                    countByStatus.map(
                        (item, index) => ({
                            id: item.name,
                            type: "square",
                            value: `${item.name} (${item.value} / ${(item.value / kpis.numberOfComplaints * 100).toFixed(1)}%)`,
                            color: COLORS[index % COLORS.length]
                        })
                    )
                }
            />
        </PieChart>;
    }

    function getComplainantCompanyRelation() {
        if (!kpis.relationshipsToCompany)
            return <Alert severity="info">No data</Alert>
        if ((kpis.relationshipsToCompany && kpis.relationshipsToCompany.length === 0)
            && (kpis.workAtLocation && kpis.workAtLocation === 0))
            return <Alert severity="info">No data</Alert>
        return <Box sx={{m: 2}}>
            <Chip label={`Number of complainants working at locations: ${kpis.workAtLocation}`} color="primary"
                  variant="outlined"/>
            <br/><br/>
            Complainants explanation of their relationship to the company:
            <ul>
                {kpis.relationshipsToCompany.map((r, index) =>
                    <li key={index}>
                        {r}
                    </li>)}
            </ul>
        </Box>;
    }

    function getLKSGQuestionnaireData() {
        if (interval === "PLZ_TEXTILBUENDNIS") return null;
        if (interval === "THIS_MONTH") return null;
        if (interval === "LAST_MONTH") return null;
        if (interval === "THIS_QUARTER") return null;
        if (interval === "LAST_QUARTER") return null;

        return <Stack direction="column" sx={{mt: 3, mb: 5}}>
            <Typography variant="h3" component="span" sx={{mb: 2, mt: 1}}>
                Fragebogen zur Berichtersttung gemäß §10 Abs. 2 LkSG
            </Typography>
            <Typography variant="h5" component="span" sx={{mb: 2, mt: 1}}>
                [D3] Wirksamkeit des Beschwerdeverfahrens
            </Typography>

            <CollapsibleSection
                title={<Stack direction="row" justifyContent="space-between">
                    D3.1 Sind im Berichtszeitraum über das Beschwerdeverfahren Hinweise eingegangen?
                    <Chip sx={{ml: 5}} label="398 - 399" color="primary" variant="outlined"/>
                </Stack>}
                titleVariant="h6" mt={0} border={true} children={
                <Stack direction="column" sx={{ml: 2}}>
                    <div>398. <Checkbox checked={kpis.numberOfComplaints > 0}/> Ja</div>
                    <div>399. <Checkbox checked={kpis.numberOfComplaints === 0}/> Nein</div>
                </Stack>
            }/>

            {kpis.numberOfComplaints > 0 && <>
                <CollapsibleSection
                    title={<Stack direction="row" justifyContent="space-between">
                        Verfahrensdauer – Zieldauer und reale Dauer der Verfahren
                        <Chip sx={{ml: 5}} label="400" color="primary" variant="outlined"/>
                    </Stack>}
                    titleVariant="strong" mt={0} border={true} children={
                    <Box sx={{ml: 2}}>
                        400. Machen Sie nähere Angaben zur Verfahrensdauer – Zieldauer und reale Dauer der Verfahren
                        (Angabe der kürzesten und
                        der längsten Verfahrensdauer mit kurzer Erläuterung des Sachverhalts)
                        <br/>
                        <br/>
                        <strong>Erstes Öffnen des Complaints</strong><br/>
                        Durchschnittliche Zeit bis zum Öffnen des
                        Complaints: {durationString(kpis.averageTimeToOpenComplaint)}<br/>
                        Kürzeste Zeit bis zum Öffnen des
                        Complaints: {durationString(kpis.shortestTimeToOpenComplaint ? kpis.shortestTimeToOpenComplaint.duration : null)}
                        {complaintButton(kpis.shortestTimeToOpenComplaint ? kpis.shortestTimeToOpenComplaint.complaintId : null)}<br/>
                        Längste Zeit bis zum Öffnen des
                        Complaints: {durationString(kpis.longestTimeToOpenComplaint ? kpis.longestTimeToOpenComplaint.duration : null)}
                        {complaintButton(kpis.shortestTimeToOpenComplaint ? kpis.longestTimeToOpenComplaint.complaintId : null)}<br/>
                        <strong>Beginn der Investigation</strong><br/>
                        Durchschnittliche Zeit bis zum Beginn der
                        Investigation: {durationString(kpis.averageTimeToStartInvestigation)}<br/>
                        Kürzeste Zeit bis zum Beginn der
                        Investigation: {durationString(kpis.shortestTimeToStartInvestigation ? kpis.shortestTimeToStartInvestigation.duration : null)}
                        {complaintButton(kpis.shortestTimeToStartInvestigation ? kpis.shortestTimeToStartInvestigation.complaintId : null)}<br/>
                        Längste Zeit bis zum Beginn der
                        Investigation: {durationString(kpis.shortestTimeToStartInvestigation ? kpis.longestTimeToStartInvestigation.duration : null)}
                        {complaintButton(kpis.shortestTimeToStartInvestigation ? kpis.longestTimeToStartInvestigation.complaintId : null)}<br/>
                        <strong>Abweisen des Complaints</strong><br/>
                        Durchschnittliche Zeit bis zum Abweisen des
                        Complaints: {durationString(kpis.averageTimeToRejectComplaint)}<br/>
                        Kürzeste Zeit bis zum Abweisen des
                        Complaints: {durationString(kpis.shortestTimeToRejectComplaint ? kpis.shortestTimeToRejectComplaint.duration : null)}
                        {complaintButton(kpis.shortestTimeToRejectComplaint ? kpis.shortestTimeToRejectComplaint.complaintId : null)}<br/>
                        Längste Zeit bis zum Abweisen des
                        Complaints: {durationString(kpis.shortestTimeToRejectComplaint ? kpis.longestTimeToRejectComplaint.duration : null)}
                        {complaintButton(kpis.shortestTimeToRejectComplaint ? kpis.longestTimeToRejectComplaint.complaintId : null)}<br/>
                        <strong>Schließen des Complaints</strong><br/>
                        Durchschnittliche Zeit bis zum Schließen des
                        Complaints: {durationString(kpis.averageTimeToCloseComplaint)}<br/>
                        Kürzeste Zeit bis zum Schließen des
                        Complaints: {durationString(kpis.shortestTimeToCloseComplaint ? kpis.shortestTimeToCloseComplaint.duration : null)}
                        {complaintButton(kpis.shortestTimeToCloseComplaint ? kpis.shortestTimeToCloseComplaint.complaintId : null)}<br/>
                        Längste Zeit bis zum Schließen des
                        Complaints: {durationString(kpis.longestTimeToCloseComplaint ? kpis.longestTimeToCloseComplaint.duration : null)}
                        {complaintButton(kpis.longestTimeToCloseComplaint ? kpis.longestTimeToCloseComplaint.complaintId : null)}<br/>
                    </Box>
                }/>
                <CollapsibleSection
                    title={<Stack direction="row" justifyContent="space-between">
                        Gesamtzahl der eingegangenen Beschwerden
                        <Chip sx={{ml: 5}} label="401" color="primary" variant="outlined"/>
                    </Stack>}
                    titleVariant="strong" mt={0} border={true} children={
                    <Box sx={{ml: 2}}>
                        401. Machen Sie nähere Angaben zur Gesamtzahl der eingegangenen Beschwerden und, sofern
                        zutreffend, Veränderungen im Vergleich zum vorherigen Berichtszeitraum
                        <br/>
                        <br/>
                        Gesamtzahl der eingegangen Beschwerden: {kpis.numberOfComplaints}<br/>
                        {interval === "THIS_YEAR" && data[brand.id]["LAST_YEAR"] && data[brand.id]["LAST_YEAR"].numberOfComplaints &&
                            <>Gesamtzahl der eingegangen Beschwerden im vorherigen
                                Berichtszeitraum: {data[brand.id]["LAST_YEAR"].numberOfComplaints}<br/>
                                Veränderung: {((kpis.numberOfComplaints - data[brand.id]["LAST_YEAR"].numberOfComplaints) * 100 / data[brand.id]["LAST_YEAR"].numberOfComplaints).toFixed(1)}%
                            </>
                        }
                    </Box>
                }/>
                <CollapsibleSection
                    title={<Stack direction="row" justifyContent="space-between">
                        Anteil der abgeholfenen Beschwerden an der Gesamtzahl der Beschwerden
                        <Chip sx={{ml: 5}} label="402" color="primary" variant="outlined"/>
                    </Stack>}
                    titleVariant="strong" mt={0} border={true} children={
                    <Box sx={{ml: 2}}>
                        402. Machen Sie nähere Angaben zum Anteil der abgeholfenen Beschwerden an der Gesamtzahl der
                        Beschwerden (mit optionaler Erläuterung)
                        <br/>
                        <br/>
                        <ul>
                            {countByStatus.map(
                                (item, index) => (
                                    <li key={index}>{item.name} ({item.value} / {(item.value / kpis.numberOfComplaints * 100).toFixed(1)}%)</li>)
                            )}
                        </ul>
                    </Box>
                }/>
                <CollapsibleSection
                    title={<Stack direction="row" justifyContent="space-between">
                        Themen der Beschwerden
                        <Chip sx={{ml: 5}} label="403 - 415" color="primary" variant="outlined"/>
                    </Stack>}
                    titleVariant="strong" mt={0} border={true} children={
                    <Box sx={{ml: 2}}>
                        Zu welchen Themen sind Beschwerden eingegangen?
                        <br/>
                        <br/>
                        403. <Checkbox checked={kpis.complaintsByLKSGCategory["M1"] > 0}/> M1 Verbot von
                        Kinderarbeit <br/>
                        404. <Checkbox checked={kpis.complaintsByLKSGCategory["M2"] > 0}/> M2 Verbot von
                        Zwangsarbeit und aller Formen der Sklaverei<br/>
                        405. <Checkbox checked={kpis.complaintsByLKSGCategory["M3"] > 0}/> M3 Missachtung
                        von Arbeitsschutz und arbeitsbedingten Gesundheitsgefahren<br/>
                        406. <Checkbox checked={kpis.complaintsByLKSGCategory["M4"] > 0}/> M4 Missachtung
                        der Koalitionsfreiheit – Vereinigungsfreiheit und Recht auf
                        Kollektivverhandlungen<br/>
                        407. <Checkbox checked={kpis.complaintsByLKSGCategory["M5"] > 0}/> M5 Verbot der
                        Ungleichbehandlung in Beschäftigung<br/>
                        408. <Checkbox checked={kpis.complaintsByLKSGCategory["M6"] > 0}/> M6 Verbot des
                        Vorenthaltens eines angemessenen Lohns<br/>
                        409. <Checkbox checked={kpis.complaintsByLKSGCategory["M7"] > 0}/> M7 Zerstörung
                        der natürlichen Lebensgrundlage durch
                        Umweltverunreinigungen<br/>
                        410. <Checkbox checked={kpis.complaintsByLKSGCategory["M8"] > 0}/> M8
                        Widerrechtliche Verletzung von Landrechten<br/>
                        411. <Checkbox checked={kpis.complaintsByLKSGCategory["M9"] > 0}/> M9 Verbot der
                        Beauftragung oder Nutzung privater/öffentlicher
                        Sicherheitskräfte, die aufgrund mangelnder Unterweisung oder Kontrolle zu Beeinträchtigungen
                        führen
                        können<br/>
                        412. <Checkbox checked={kpis.complaintsByLKSGCategory["M10"] > 0}/> M10
                        Das Verbot eines […] Tuns oder pflichtwidrigen Unterlassens,
                        das unmittelbar geeignet ist, in besonders schwerwiegender Weise eine geschützte
                        Rechtsposition (die sich aus den Menschenrechtsabkommen i.S. § 2 Abs. 1 ergeben) zu
                        beeinträchtigen und dessen Rechtswidrigkeit bei verständiger Würdigung aller in Betracht
                        kommenden Umstände offensichtlich ist<br/>
                        413. <Checkbox checked={kpis.complaintsByLKSGCategory["U1"] > 0}/> U1 Verbotene Herstellung,
                        Einsatz und/oder Entsorgung von Quecksilber (Minamata-Übereinkommen)<br/>
                        414. <Checkbox checked={kpis.complaintsByLKSGCategory["U2"] > 0}/> U2 Verbotene Produktion
                        und/oder Verwendung von Stoffen im Anwendungsbereich des Stockholmer Übereinkommen (POP)
                        sowie nicht umweltgerechter Umgang mit POP-haltigen Abfällen<br/>
                        415. <Checkbox checked={kpis.complaintsByLKSGCategory["U3"] > 0}/> U3 Verbotene Ein-/Ausfuhr
                        gefährlicher Abfälle im Sinne des Basler Übereinkommens<br/>
                    </Box>
                }/>
            </>}

        </Stack>;
    }

    function getCapItemStatus() {
        if (countByCapItemStatus.length === 0)
            return <Alert severity="info">No data</Alert>;

        return <PieChart width={750} height={300}>
            <Pie data={countByCapItemStatus} dataKey="value" nameKey="name" cx="50%" cy="50%"
                 innerRadius={0}
                 outerRadius={100} labelLine={false}>
                {countByCapItemStatus.map((entry, index) =>
                    <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

            </Pie>
            <Tooltip/>
            <Legend
                layout="vertical" verticalAlign="top" align="right" height={200}
                formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                payload={
                    countByCapItemStatus.map(
                        (item, index) => ({
                            id: item.name,
                            type: "square",
                            value: `${item.name} (${item.value} / ${(item.value / Object.values(kpis.countByCapItemStatus).reduce((a, b) => a + b, 0) * 100).toFixed(1)}%)`,
                            color: COLORS[index % COLORS.length]
                        })
                    )
                }
            />
        </PieChart>;
    }

    function getTextilbuendnisData() {
        if (interval !== "PLZ_TEXTILBUENDNIS") return null;

        return <Stack direction="column" sx={{mt: 3, mb: 5}}>
            <Typography variant="h3" component="span" sx={{mb: 2, mt: 1}}>
                Projekt BI Textilbündnis
            </Typography>


            {kpis.numberOfComplaints > 0 && <>
                <CollapsibleSection
                    title={<Stack direction="row" justifyContent="space-between">
                        Bearbeitungszeit angenommener Beschwerden
                    </Stack>}
                    titleVariant="h6" mt={0} border={true} children={
                    <Box sx={{ml: 2}}>
                        Durchschnittliche Zeit bis zum Schließen des
                        Complaints: {durationString(kpis.averageTimeToCloseComplaint)}<br/>
                        Kürzeste Zeit bis zum Schließen des
                        Complaints: {durationString(kpis.shortestTimeToCloseComplaint ? kpis.shortestTimeToCloseComplaint.duration : null)}
                        {complaintButton(kpis.shortestTimeToCloseComplaint ? kpis.shortestTimeToCloseComplaint.complaintId : null)}<br/>
                        Längste Zeit bis zum Schließen des
                        Complaints: {durationString(kpis.longestTimeToCloseComplaint ? kpis.longestTimeToCloseComplaint.duration : null)}
                        {complaintButton(kpis.longestTimeToCloseComplaint ? kpis.longestTimeToCloseComplaint.complaintId : null)}<br/>
                    </Box>
                }/>
            </>}

            <CollapsibleSection
                title={<Stack direction="row" justifyContent="space-between">
                    Anzahl QR Code Scans
                </Stack>}
                titleVariant="h6" mt={0} border={true} children={
                <Stack direction="column" sx={{ml: 2}}>
                    {kpis?.qrCodeStatistics?.requestcount} Aufrufe<br/>
                    {kpis?.qrCodeStatistics?.uniqevisitors} Eindeutige Besucher
                </Stack>
            }/>
            <CollapsibleSection
                title={<Stack direction="row" justifyContent="space-between">
                    Anzahl Klick auf FAQs
                </Stack>}
                titleVariant="h6" mt={0} border={true} children={
                <Stack direction="column" sx={{ml: 2}}>
                    {kpis?.faqLinkClicks?.length} Aufrufe der FAQ-Seite<br/>
                </Stack>
            }/>

            <CollapsibleSection
                title={<Stack direction="row" justifyContent="space-between">
                    Verhältnis von umgesetzen zu nicht umgesetzten Maßnahmen
                </Stack>}
                titleVariant="h6" mt={0} border={true} children={getCapItemStatus()}/>

            <CollapsibleSection
                title={<Stack direction="row" justifyContent="space-between">
                    Verhältnis von angenommenen zu abgelehnten Beschwerden
                </Stack>}
                titleVariant="h6" mt={0} border={true} children={getProcessingStatus()}/>

            <CollapsibleSection
                title={<Stack direction="row" justifyContent="space-between">
                    Anzahl der trainierten Arbeiter*innen pro Produktionsstätte </Stack>}
                titleVariant="h6" mt={0} border={true} children={<Stack
                sx={{overflow: "auto"}}
                direction="row" ml={2} mb={2} mr={2} spacing={1}>
                {
                    kpis?.trainings.map((training) => (
                            <Card key={training.id} sx={{minWidth: 300}}>
                                <Grid container padding={1} spacing={0.5}>
                                    <Grid item xs={6}>Training-ID:</Grid>
                                    <Grid item xs={6}>{training.id}</Grid>
                                    <Grid item xs={6}>Total:</Grid>
                                    <Grid item xs={6}>{training.numberOfParticipants}</Grid>
                                    <Grid item xs={6}>Weiblich:</Grid>
                                    <Grid item xs={6}>{training.numberOfFemaleParticipants}</Grid>
                                    <Grid item xs={6}>Männlich:</Grid>
                                    <Grid item xs={6}>{training.numberOfMaleParticipants}</Grid>
                                    <Grid item xs={6}>Divers:</Grid>
                                    <Grid item xs={6}>{training.numberOfDiverseParticipants}</Grid>
                                </Grid>
                            </Card>
                        )
                    )
                }


            </Stack>}/>

            <CollapsibleSection
                title={<Stack direction="row" justifyContent="space-between">
                    Trainingsüberblick</Stack>}
                titleVariant="h6" mt={0} border={true} children={
                <>
                    {kpis?.trainings.map((training) => (
                        <CollapsibleSection
                            key={training.id}
                            title={<Stack direction="row" justifyContent="space-between">
                                Training-ID: {training.id}</Stack>}
                            titleVariant="h5" mt={0} border={true} children={
                            <Grid container padding={1} spacing={0.5} ml={4}>
                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid item xs={12}><Typography variant="h6">Teilnehmende</Typography></Grid>
                                        <Grid item xs={6}>Total:</Grid>
                                        <Grid item xs={6}>{training.numberOfParticipants}</Grid>
                                        <Grid item xs={6}>Weiblich:</Grid>
                                        <Grid item xs={6}>{training.numberOfFemaleParticipants}</Grid>
                                        <Grid item xs={6}>Männlich:</Grid>
                                        <Grid item xs={6}>{training.numberOfMaleParticipants}</Grid>
                                        <Grid item xs={6}>Divers:</Grid>
                                        <Grid item xs={6}>{training.numberOfDiverseParticipants}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid xs={12}> <Typography variant="h6">Datum</Typography></Grid>
                                        <Grid xs={12}>{new Date(training.trainingDate).toLocaleDateString()}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid xs={12}> <Typography variant="h6">Fabrik</Typography></Grid>
                                        <Grid xs={12}>{training.factoryName}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid xs={12}> <Typography variant="h6">Bilder</Typography></Grid>
                                        <Grid xs={12}>
                                            <Grid container spacing={1}>
                                                {training.images && training.images.map((file, index) => (
                                                    <Grid item key={index}>
                                                        <File file={file} mayUpdate={false}  useGetFileQuery={useGetFileQuery}/>
                                                    </Grid>))}
                                                {(!training.images || training.images.length === 0) &&
                                                    <Box mt={2}>no document uploaded</Box>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid xs={12}> <Typography variant="h6">Participant List and Consent
                                            Declaration</Typography></Grid>
                                        <Grid xs={12}>
                                            <Grid container spacing={1}>
                                                {training.consentForms && training.consentForms.map((file, index) => (
                                                    <Grid item key={index}>
                                                        <File file={file} mayUpdate={false}  useGetFileQuery={useGetFileQuery}/>
                                                    </Grid>))}
                                                {(!training.consentForms || training.consentForms.length === 0) &&
                                                    <Box mt={2}>no document uploaded</Box>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid xs={12}> <Typography variant="h6">Notizen</Typography></Grid>
                                        <Grid xs={12}>{training.notes}</Grid>
                                    </Grid>
                                </Grid>

                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid xs={12}> <Typography variant="h6">Kritische Themen</Typography></Grid>
                                        <Grid xs={12}>
                                            <ul>{training.criticalIssues?.map(issue => <li>{issue}</li>)}</ul>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid xs={4}>
                                    <Grid container padding={1} spacing={0.5}>
                                        <Grid xs={12}> <Typography variant="h6">Notizen</Typography></Grid>
                                        <Grid xs={12}>{training.criticalIssuesExplanation}</Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        }/>
                    ))}

                </>
            }/>

        </Stack>;
    }

    const order = ["THIS_MONTH", "LAST_MONTH", "THIS_QUARTER", "LAST_QUARTER", "THIS_YEAR", "LAST_YEAR", "ALL_TIME", "PLZ_TEXTILBUENDNIS"];

    function intervalLabel(interval) {
        switch (interval) {
            case "ALL_TIME" :
                return "All Time";
            case "THIS_MONTH":
                return "This Month";
            case "LAST_MONTH":
                return "Last Month";
            case "THIS_QUARTER":
                return "This Quarter";
            case "LAST_QUARTER":
                return "Last Quarter";
            case "THIS_YEAR":
                return "This Year";
            case "LAST_YEAR":
                return "Last Year";
            case "PLZ_TEXTILBUENDNIS":
                return "PLZ Textilbündnis";
            default:
                return interval;
        }
    }

    return (
        <Box p={2}>
            <Stack direction="row" justifyContent="space-between" sx={{mt: 3}}>
                <Typography variant="h3" component="span" sx={{mb: 2, mt: 1}}>
                    Overview {brand.shortName}
                </Typography>
                <Stack direction="row" justifyContent="end">
                    <Box sx={{minWidth: 120}} mr={2}>
                        <FormControl fullWidth>
                            <InputLabel id="brand-label">Brand</InputLabel>
                            <Select
                                labelId="brand-label"
                                id="brand"
                                value={brand}
                                label="Brand"
                                onChange={handleBrandChange}
                            >
                                {brands.filter(brand => data[brand.id])
                                    .map(brand =>
                                        <MenuItem key={brand.id} value={brand}>{brand.shortName}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="interval-label">Interval</InputLabel>
                            <Select
                                labelId="interval-label"
                                id="interval"
                                value={interval}
                                label="Interval"
                                onChange={handleChange}
                            >
                                {((data && brand && data[brand.id]) ? Object.keys(data[brand.id]) : [])
                                    .sort(function (a, b) {
                                        return order.indexOf(a) - order.indexOf(b);
                                    })
                                    .map(interval => <MenuItem key={interval}
                                                               value={interval}>{intervalLabel(interval)}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
            </Stack>

            <Chip label={`Total number of complaints: ${kpis.numberOfComplaints}`} color="primary" variant="outlined"/>
            <Chip label={`Number of production sites: ${locations.length}`} color="primary" variant="outlined"
                  sx={{ml: 2}}/>

            <CollapsibleSection title="Categories" mt={2} border={true} children={getCategories()}/>
            <CollapsibleSection title="Countries" mt={2} border={true} children={getCountries()}/>
            <CollapsibleSection title="Processing Status" mt={2} border={true} children={getProcessingStatus()}/>
            <CollapsibleSection title="Complainant-Company-Relation" mt={2} border={true}
                                children={getComplainantCompanyRelation()}/>

            {getLKSGQuestionnaireData()}
            {getTextilbuendnisData()}
        </Box>);
};

export default withAuthenticationRequired(KPIS, {
    onRedirecting: () => <LoadingGIF/>,
});
