import {Card, CardActions, Typography} from "@mui/material";
import React from "react";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import HelpPopUp from "./HelpPopUp";

// todo import colors

const darkSalmon = "#C37460";
const mainSalmon = "#F8A18E";
const lightSalmon = "#FDD2BC";

const darkTurquoise = "#005863";
// const mainTurquoise = "#1B8591";
const lightTurquoise = "#58B5C1";

export default function InfoBox({title, number, info, variant, minWidth}) {
    let backgroundColor;
    let textColor;
    if (variant === "red") {
        backgroundColor = lightSalmon;
        textColor = darkSalmon;
    } else if (variant === "blue"){
        backgroundColor = lightTurquoise;
        textColor = darkTurquoise;
    } else if (variant === "orange"){
        backgroundColor = mainSalmon;
        textColor = darkSalmon;
    }

    return (
        <Card sx={{minWidth: minWidth}}>
            <CardHeader title={title}
                        sx={{
                            backgroundColor: backgroundColor,
                            textAlign: "center",
                            fontWeight: 700,
                            color: textColor,
                            fontSize: 25
                        }}>
            </CardHeader>
            <CardContent sx={{p: 0, m: 0}}>
                <Typography
                    sx={{fontSize: 55, lineHeight: 1, fontWeight: 900, textAlign: "center", mt: 1, color: textColor}}>
                    {number}
                </Typography>
            </CardContent>
            <CardActions style={{display: "flex", justifyContent: "flex-end", p: 0, m: 0, lineHeight: 1,}}>
                <HelpPopUp text={info}/>
            </CardActions>
        </Card>
    )
}
