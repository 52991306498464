import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import EditExternal from "./EditExternal";


export default function ComplaintExternal({invitation, revokeMethod, complaint}) {
    // todo check permissions

    return (
        <Card sx={{m: 2, p: 1}}>
            <CardHeader
                title={invitation.invitedUser.firstName + " " + invitation.invitedUser.lastName + " - " + invitation.invitedUser.email}/>
            <CardContent>
                <Box>
                    Invited by {invitation.invitingUser.firstName + " " + invitation.invitingUser.lastName}<br/>
                    <Chip sx={{mr: 2}} label={"Created: " + new Date(invitation.cdate).toLocaleDateString()}
                          color="primary"/>
                    {!invitation.invitationRevoked && <Chip sx={{mr: 2}} label={"active"} color="secondary"/>}
                    {invitation.invitationRevoked &&
                    <Chip sx={{mr: 2}} label={"Revoked: " + new Date(invitation.invitationRevoked).toLocaleDateString()}
                          color="primary"/>}
                    {invitation.invitationRevoked && invitation.revokingUser &&
                    <Chip sx={{mr: 2}}
                          label={"Revoked by: " + invitation.revokingUser.firstName + " " + invitation.revokingUser.lastName}
                          color="primary"/>}
                    {invitation.note && <Typography variant="div" component="div" sx={{mt: 2}}>
                        Invitation note by inviter: {invitation.note}
                    </Typography>}
                </Box>
            </CardContent>
            <CardActions>
                {!invitation.invitationRevoked && <>
                    <Button variant="contained"
                            color="secondary"
                            size={"large"}
                            sx={{mr:2}}
                            onClick={revokeMethod}>
                        revoke invitation
                    </Button>
                    <EditExternal invitation={invitation} complaint={complaint}/>
                </>}
            </CardActions>
        </Card>
    );
}