import React, {useMemo} from "react";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {countries} from "../countries/countries";
import {useAuth0} from "@auth0/auth0-react";
import {useCreateLocationMutation, useDeleteLocationMutation, useUpdateLocationMutation} from "../../app/apiSlice";
import {Box} from "@mui/system";
import {brandLabel, countryLabel} from "../dashboard/labels";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {IconButton, Tooltip} from "@mui/material";
import {EditCellMultipleSelect} from "./EditCellMultipleSelect";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
export const LocationsTable = ({locations, brands}) => {

    const history = useHistory();
    const {user} = useAuth0();
    const isAdmin = user && user['https://truecolorssustainability.com//role'] && user['https://truecolorssustainability.com//role'].includes("ADMIN");
    const [createLocation] = useCreateLocationMutation();
    const [updateLocation] = useUpdateLocationMutation();
    const [deleteLocation] = useDeleteLocationMutation();

    function eligibleSubBrands(complaintBrands) {
        let currentBrands = complaintBrands || [];
        let currentSubBrandIds = brands.filter(brand => currentBrands.includes(brand.id))
            .flatMap(brand => brand.subBrands);
        return brands.filter(brand => currentSubBrandIds.includes(brand.id));
    }

    const columns = useMemo(
        () => [
            {accessorKey: 'id', header: 'Location ID', enableEditing: false, Edit: () => null,},
            {accessorKey: 'name', header: 'Name', enableEditing: isAdmin,},
            {
                accessorFn: (row) => `${countryLabel(row.country)}`,
                accessorKey: 'country', header: 'Country', enableEditing: isAdmin,
                editVariant: 'select',
                editSelectOptions: Object.values(countries),
                muiEditTextFieldProps: {
                    select: true,
                },
            },
            {accessorKey: 'city', header: 'City', enableEditing: isAdmin,},
            {
                Cell: ({renderedCellValue, row}) => {
                    return row.original.brands && row.original.brands.map((value) => (
                        <Chip size="small" key={value} label={brandLabel(value, brands)} sx={{margin: 0.5,}}
                              variant="outlined"/>
                    ))
                },
                Edit: ({column, row, table}) => <EditCellMultipleSelect row={row} column={column} table={table}
                                                                              label="Brands"
                                                                              items={brands}/>,
                accessorKey: 'brands',
                header: 'Brands',
            },
            {
                Cell: ({renderedCellValue, row}) => {
                    return row.original.subBrands && row.original.subBrands.map((value) => (
                        <Chip size="small" key={value} label={brandLabel(value, brands)} sx={{margin: 0.5,}}
                              variant="outlined"/>
                    ))
                },
                Edit: ({column, row, table}) => <EditCellMultipleSelect row={row} column={column} table={table}
                                                                              label="Sub-Brands"
                                                                              items={eligibleSubBrands(row._valuesCache["brands"])}/>,
                accessorKey: 'subBrands',
                header: 'Sub Brands',
            },
        ],
        [],
    );

    const handleCreateLocation = async ({values, table,}) => {
        let newVar = {
            "name": values.name,
            "city": values.city,
            "country": getKeyByValue(countries, values.country),
            "brands": values.brands,
            "subBrands": values.subBrands
        };
        await createLocation(newVar);
        table.setCreatingRow(null); //exit creating mode
    };

    const handleUpdateLocation = async ({values, table, row,}) => {
        await updateLocation({...row.original, ...values, "country": getKeyByValue(countries, values.country)});
        table.setEditingRow(null); //exit creating mode
    };

    const openDeleteConfirmModal = (row) => {
        if (window.confirm('Are you sure you want to delete this location?')) {
            deleteLocation(row.original.id);
        }
    };

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key =>
            object[key] === value);
    }

    const table = useMaterialReactTable({
        columns,
        data: locations,
        createDisplayMode: 'row',
        editDisplayMode: 'row',
        enableEditing: true,
        enableRowActions: true,
        getRowId: (row) => row.id,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        renderRowActions: ({row}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit Location">
                <Button variant="contained"
                        onClick={() => history.push(`/locations/${row.id}`)}>
                    Edit Details
                </Button>
            </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        initialState: {
            columnVisibility: {id: false, brands: true},
            sorting: [
                {id: 'name', desc: false}],
        },
        renderTopToolbarCustomActions: ({table}) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                }}
            >
                Create New Location
            </Button>
        ),
        state: {},
        onCreatingRowSave: handleCreateLocation,
        onEditingRowSave: handleUpdateLocation,

    });

    return <MaterialReactTable table={table}/>;
};
