import React, {useEffect} from "react";
import Hero from "../components/Hero";
import {useHistory} from "react-router-dom";
import {useGetMeQuery} from "../app/apiSlice";


const Home = () => {

    const history = useHistory();

    const {data: me, isLoading: meLoading} = useGetMeQuery();

    useEffect(() => {
        if (me && me.internal) history.push(`/complaints`);
        else if (me && me.external) history.push(`/external/complaints`);
    }, [meLoading]);

    return (<>
        <Hero/>
    </>);
};

export default Home;
