import React, {useState} from "react";
import {IconButton, Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

const CopyToClipboardButton = ({text, toast}) => {
    const [open, setOpen] = useState(false);

    const handleClick = async () => {
        setOpen(true);
        await navigator.clipboard.writeText(text);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} color="primary" variant="outlined">
                <b>{text}</b>
            </Button>
            <Snackbar
                message={toast}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                open={open}
                action={<>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                </>}
            />
        </>
    );
};

export default CopyToClipboardButton;