import React, {useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import NotAuthorized from "../components/common/NotAuthorized";
import LoadingGIF from "../components/common/LoadingGIF";
import Error from "../components/common/Error";
import {LinksMgmtForm} from "../components/admin/LinksMgmtForm";
import Links from "./Links";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useGetAdminLinksQuery} from "../app/apiSlice";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";

const LinksMgmt = () => {

    const {data: links, isLoading: loading, isSuccess, error: error} = useGetAdminLinksQuery();
    const [selected, setSelected] = useState({});
    const [newLinksDef, setNewLinksDef] = useState({name: ""});
    const [newLinks, setNewLinks] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setSelected(links.find(l => l.name === (selected.name ? selected.name : "default")));
        }
    }, [links]);

    if (loading) return <LoadingGIF/>;
    if (error && error.message.includes('403')) return <NotAuthorized/>;
    if (error) return <Error/>;

    const handleChange = (event) => {
        setSelected(links.find(l => l.name === event.target.value));
    };

    const handleNewLinks = () => {
        setNewLinks(true);
    };


    function getNewLinks() {
        return (
            <Grid container spacing={2} pt={4}>
                <Grid item xs={8}>
                    <Box sx={{minWidth: 120}}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Links Definition"
                            value={newLinksDef.name}
                            onChange={(e) => setNewLinksDef({...newLinksDef, name: e.target.value})}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="outlined" onClick={() => setNewLinks(false)}>Cancel</Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    {!newLinks && selected && <LinksMgmtForm links={selected.current} id={selected.id}/>}
                    {newLinks && newLinksDef && <LinksMgmtForm links={newLinksDef} name={newLinksDef.name} create={true} setNewLinks={setNewLinks}/>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{mt: 2, ml: 4}} variant="h6">Preview</Typography>
                    {!newLinks && selected && <Links preview={true} name={selected.name}/>}
                    {newLinks && newLinksDef && <Links preview={true} name={newLinksDef.name}/>}
                </Grid>
            </Grid>
        );
    }

    function getSelected() {
        if (!selected || !selected.name) return null;
        return (
            <Grid container spacing={2} pt={4}>
                <Grid item xs={8}>
                    <Box sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="links-select-label">Links Definition</InputLabel>
                            <Select
                                labelId="links-select-label"
                                id="links-select"
                                value={selected.name}
                                label="Links Definition"
                                onChange={handleChange}
                            >
                                {links.map(l => <MenuItem key={l.id} value={l.name}>{l.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="outlined" onClick={handleNewLinks}>New Links Definition</Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    {selected && <LinksMgmtForm links={selected.current} id={selected.id}/>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{mt: 2, ml: 4}} variant="h6">Preview</Typography>
                    <Links preview={true} name={selected.name}/>
                </Grid>
            </Grid>
        );
    }

    if (newLinks) return getNewLinks();

    return getSelected();
};

export default withAuthenticationRequired(LinksMgmt, {
    onRedirecting: () => <LoadingGIF/>,
});
