import React, {useEffect, useState} from "react";

const Voices = () => {

    const [voices, setVoices] = useState([]);

    function populateVoiceList() {
        if (typeof speechSynthesis === "undefined") {
            return;
        }
        setVoices(speechSynthesis.getVoices());
    }

    useEffect(() => {
        populateVoiceList();
    }, [speechSynthesis]);

    return (<>
        <h1>Voices</h1>

        {voices && voices.map((voice) => {
            return <div>{voice.lang} {voice.name} {voice.default && "default"} {voice.localService && "localService"}</div>
        })}

    </>);
};

export default Voices;
