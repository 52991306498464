import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useDispatch} from "react-redux";
import {useUpdateComplaintSeverityMutation, useUpdateComplaintSeverityNoteMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import theme from "../../../theme";

export const ComplaintFactsSeverity = ({complaint, permissions}) => {
    const dispatch = useDispatch();

    const [updateComplaintSeverity] = useUpdateComplaintSeverityMutation();
    const [updateComplaintSeverityNote] = useUpdateComplaintSeverityNoteMutation();

    const [severity, setSeverity] = useState(complaint.severity || "");
    const [note, setNote] = useState(complaint.severityNote || "");

    const handleSeverityChange = async (event) => {
        setSeverity(event.target.value);
        try {
            await updateComplaintSeverity({complaintId: complaint.id, severity: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Complaint severity updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint severity update failed", severity: "error"}));
        }
    };

    const handleSeverityNoteChange = async (event) => {
        setNote(event.target.value);
        try {
            await updateComplaintSeverityNote({complaintId: complaint.id, note: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Complaint severity note updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Complaint severity note update failed", severity: "error"}));
        }
    };

    if (!permissions.has("READ_SEVERITY")) return null;

    return (<>
            <Typography variant="h6" sx={{mt: 2}}>
                Complaint severity
            </Typography>
            <FormControl variant="outlined" sx={{
                margin: theme.spacing(1), width: "100%",
            }}>
                <InputLabel id="severity">Severity</InputLabel>
                <Select
                    labelId="severity-label"
                    id="severity-select"
                    value={severity}
                    onChange={handleSeverityChange}
                    label="Severity"
                    disabled={!permissions.has("UPDATE_COMPLAINT_SEVERITY")}
                >
                    <MenuItem value={"LOW"}>Low</MenuItem>
                    <MenuItem value={"MEDIUM"}>Medium</MenuItem>
                    <MenuItem value={"HIGH"}>High</MenuItem>
                </Select>

            </FormControl>
            <TextField
                id="severity-note"
                label="Severity Note"
                multiline
                rows={3}
                defaultValue={note}
                onBlur={handleSeverityNoteChange}
                placeholder="Here you can write a note with an additional explanation about the chosen severity."
                variant="outlined"
                sx={{
                    mt: 1, margin: theme.spacing(1), width: "100%",
                }}
                disabled={!permissions.has("UPDATE_COMPLAINT_SEVERITY")}
            />
        </>);
};
