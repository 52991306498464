import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {useTranslation} from "react-i18next";

const Nav = (props) => {

    const progress = (props.currentStep / props.totalSteps) * 100;
    const showBackButton = props.steps[props.currentStep - 1] && props.steps[props.currentStep - 1].previousStep;
    const {t, i18n} = useTranslation(props.translation);

    if (props.currentStep === 1) return null;
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={4} sm={3}>
                {showBackButton &&
                    <Button startIcon={<ArrowBackIosNewIcon/>}
                            onClick={() => {
                                props.goToNamedStep(props.steps[props.currentStep - 1].previousStep)
                            }}>
                        {t('back')}
                    </Button>}
            </Grid>
            <Grid item xs={8} sm={9} sx={{pr: 2}}>
                <LinearProgress color="primary" variant="determinate" value={progress}/>
            </Grid>
        </Grid>
    );
};

export default Nav;
