import React, {useEffect, useState} from 'react';
import {LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import axios from "axios";
import {url} from "../../urls";

export default function UploadBotFile({meta, label, update, callback}) {

    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

    const selectFile = (event) => {
        setCurrentFile(event.target.files[0]);
        setProgress(0);
        setMessage("");
    };

    useEffect(() => {
        if (currentFile) upload();
    }, [currentFile]);

    const upload = () => {
        setProgress(0);

        uploadFile(meta, currentFile, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((response) => {
                setMessage(response.data.message);
                setIsError(false);
                return response.data.key;
            })
            .then((key) => {
                if (update) update(key);
            })
            .catch((err) => {
                setProgress(0);
                setMessage("Could not upload the file!" + err);
                setCurrentFile(undefined);
                setIsError(true);
            })
            .then(() => {
                setCurrentFile(null);
                setProgress(0);
                setMessage("");
                if (callback) callback();
            });
    }


    return (
        <Box sx={{pt: 3}}>
            {!currentFile && <Box display="flex" justifyContent="flex-end">
                <label htmlFor={`btn-upload${meta.key}`}>
                    <input
                        id={`btn-upload${meta.key}`}
                        style={{display: 'none'}}
                        type="file"
                        onChange={selectFile}/>
                    <Button color="secondary" id={`visible-btn-upload${meta.key}`}
                            variant="contained"
                            startIcon={<DriveFolderUploadIcon/>}
                            component="span">
                        {label}
                    </Button>
                </label>
            </Box>}
            <div>
                {currentFile ? currentFile.name : null}
            </div>
            {currentFile &&
                <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                        <LinearProgress variant="determinate" value={progress}/>
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                    </Box>
                </Box>}

            {message &&
                <Typography variant="subtitle2" className={`${isError ? "error" : ""}`}>
                    {message}
                </Typography>}

        </Box>
    );
}

function uploadFile(meta, file, onUploadProgress) {

    const blob = new Blob([JSON.stringify(meta)], {
        type: 'application/json'
    });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploadFileRequest', blob);

    return axios.post(`${url}/api/checkin/schema/upload_bot` ,
        formData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }, onUploadProgress
        });

}
