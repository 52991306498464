import React from 'react';
import CAPItem from "./../cap/CAPItem";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {useDeleteCAPItemMutation, useSaveCAPItemMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function CAPItemList({cap, complaint, isInternal, permissions, user}) {

    const closedOrDone = cap.status === "CLOSED" || cap.status === "DONE";

    return (
        <>

            {((cap.items && cap.items.length > 0)) && <Typography sx={{mt: 2}} variant="h6">CAP Items</Typography>}

            {cap.items && cap.items.map((item, index) => {
                return (<CAPItem id={index} item={item} key={index} user={user} permissions={permissions}
                                 disabled={!permissions.has("WRITE_CAP") || closedOrDone} isInternal={isInternal}
                                 cap={cap} complaint={complaint}/>)
            })}

        </>
    );
}
