import React from "react";
import Grid from "@mui/material/Grid";
import CollapsibleSection from "../../../common/CollapsibleSection";
import File from "../../../common/file/File";
import UploadFile from "../../../common/file/UploadFile";
import {useGetFileQuery} from "../../../../app/apiSlice";

export default function CAPFiles({item, cap, disabled, complaint}) {

    let title = "Files";
    if (item.files && item.files.length === 1) {
        title = "1 File";
    } else if (item.files && item.files.length > 1) {
        title = item.files.length + " Files";
    }

    return (
        <CollapsibleSection title={title} sx={{mt: 2}}
                            children={<>
                                <Grid container spacing={1}> {item.files && item.files.map((file, index) => (
                                    <Grid item key={index}> <File file={file} capId={cap.id}
                                                                  complaintId={cap.complaintId} itemId={item.id}
                                                                  useGetFileQuery={useGetFileQuery}
                                                                  mayUpdate={!disabled}/> </Grid>))}
                                </Grid>
                                {!disabled && cap && cap.id && complaint && complaint.id && item && item.id &&
                                    <UploadFile
                                        meta={{
                                            "key": item.id,
                                            "capId": cap.id,
                                            "complaintId": complaint.id,
                                            "capItemId": item.id,
                                            "fileType": "CAP_PROOF",
                                        }} label={"Upload File"}/>}
                            </>}/>)
}
