import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import {useDispatch} from 'react-redux'
import {useAssignComplaintBrandMutation} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";


export default function AssignBrand({complaint, brands}) {

    const dispatch = useDispatch();
    const [brand, setBrand] = useState(brands.find(b => b.id === complaint.brand));

    const [assignBrand] = useAssignComplaintBrandMutation();

    const handleChangeBrand = async () => {
        try {
            await assignBrand({complaintId: complaint.id, brandId: brand.id}).unwrap()
            dispatch(openSnackbar({message: "Brand changed successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Brand update failed", severity: "error"}));
        }
    }

    return (
        <>
            <Typography variant="h6">Location</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                    <Typography variant="h6" color="black" sx={{mb: 1}}>Written by complainant</Typography>
                    <Typography component="span">{complaint.locationNameGivenByComplainant}</Typography>
                    <br/>
                    {complaint.translatedLocationNameGivenByComplainant &&
                        <Typography component="body2">{
                            complaint.translatedLocationNameGivenByComplainant} (translated)
                        </Typography>}

                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h6" color="black" sx={{mb: 1}}>Please select.</Typography>
                    <FormControl variant="outlined">
                        <InputLabel id="brand-label">Brand</InputLabel>
                        <Select
                            labelId="brand-label"
                            id="brand"
                            value={brand}
                            onChange={(event) => setBrand(event.target.value)}
                            label="Brand"
                            sx={{minWidth: 120}}
                        >
                            {
                                brands.map(brand =>
                                    <MenuItem key={brand.id} value={brand}>
                                        {brand.shortName}
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={handleChangeBrand} variant="contained">Save and notify Brand MEmbers by
                        mail</Button>
                </Grid>
            </Grid>

        </>
    );
}
