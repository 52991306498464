import React from 'react';
import {setTabInfo} from "../../../../utils/tabs";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {dateAndTime} from "../../../common/dates";
import Chip from "@mui/material/Chip";

function typeLabel(entry) {
    switch (entry.type) {
        case "CREATE":
            if (entry.from === "COMPLAINT") return "Complaint Created";
            if (entry.from === "CAP") return "CAP Created";
            if (entry.from === "INVESTIGATION") return "Investigation Created";
            return entry.type;
        case "ADDED_NEW_CAP_ITEM":
            return "New CAP Item added";
        case "LOCATION_CHANGE" :
            return "Changed Location";
        case "CATEGORY_CHANGE" :
            return "Changed Category";
        case "SEVERITY_CHANGE" :
            return "Changed Severity";
        case "UPDATE_STATUS" :
            return "Update Status";
        case "OPEN_COMPLAINT" :
            return "Complaint Opened";
        case "ADD_MESSAGE_BY_COMPLAINANT" :
            return "Complainant added Message";
        case "ADD_FILE_TO_COMPLAINT" :
            return "File added to Complaint";
        case "ADD_MESSAGE" :
            return "Message added to Complaint";
        case "START_INVESTIGATION" :
            return "Started investigation";
        case "CLOSED_INVESTIGATION" :
            return "Closed investigation";
        case "UPDATED_CAP_ITEM_STATUS" :
            return "Updated CAP Item Status";
        case "UPDATED_CAP_STATUS" :
            return "Updated CAP Status";
        case "CREATE_INVESTIGATION_TODO" :
            return "Created investigation Todo";
        case "CLOSED" :
            return "Closed Complaint";
    }
    return entry.type;
}

const ActivityTab = ({complaint, investigation, cap}) => {
    // todo check permissions CS-410
    setTabInfo("activity", complaint);

    if (!complaint || !complaint.history) return null;


    function avatar(user) {
        return <>
            {user && user.picture && <Avatar src={user.picture}/>}
            {user && !user.picture && <Avatar>{`${user.firstName.substr(0, 1)}${user.lastName.substr(0, 1)}`}</Avatar>}
            {!user && <Avatar>C</Avatar>}
        </>;
    }

    let entries = [];

    complaint.history.map(entry => entries.push({...entry, from: "COMPLAINT"}))

    investigation && investigation.history.map(entry => entries.push({
        ...entry,
        from: "INVESTIGATION"
    }))

    cap && cap.history.map(entry => entries.push({
        ...entry,
        from: "CAP"
    }))


    return (
        <div>

            {entries.sort((a, b) => (a.date > b.date && 1) || -1).map((entry, number) => {
                const external = entry.user ? entry.user.external : false;
                return <Card key={number}>
                    <Grid container sx={{p: 2}}>
                        <Grid item xs={4} sm={1}>
                            {avatar(entry.user)}
                        </Grid>
                        <Grid item xs={8} sm={11} sx={{pb: 1}}>
                            <Typography variant="h6" sx={{display: "inline"}}>
                                {typeLabel(entry)}
                            </Typography> &nbsp;
                            {entry.user && entry.user.firstName} {entry.user && entry.user.lastName}
                            &nbsp;
                            {dateAndTime(entry.date)}&nbsp;
                            {external && <Chip label="External" color="primary" variant="outlined"/>}
                            <Chip label={entry.from} color="primary"/>
                            <br/>
                            {entry.note}
                            {entry.comment}
                        </Grid>
                    </Grid>
                </Card>
            })}


        </div>
    );
}

export default ActivityTab;
