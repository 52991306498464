import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteFile from "./DeleteFile";
import LoadingGIF from "../LoadingGIF";

export default function File({file, itemId, complaintId, capId, investigationId, mayUpdate, useGetFileQuery}) {

    const {data: sourceLoaded, isLoading} = useGetFileQuery(file.key);

    const isImage = file.key.toLowerCase().endsWith("png") || file.key.toLowerCase().endsWith("jpg")
        || file.key.toLowerCase().endsWith("jpeg") || file.key.toLowerCase().endsWith("webp");
    const isPdf = file.key.toLowerCase().endsWith("pdf");
    const isTxt = file.key.toLowerCase().endsWith("txt");
    const isVideo = file.key.toLowerCase().endsWith("mp4");
    const isSVG = file.key.toLowerCase().endsWith("svg");

    if (isLoading || !sourceLoaded) return <LoadingGIF/>;

    function download() {
        const a = document.createElement("a");
        a.href = `data:image/png;base64,${sourceLoaded}`;
        a.setAttribute("download", file.name);
        a.click();
    }

    function base64PDFToBlobUrl(base64) {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        const blob = new Blob([arr], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
    }

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: "space-between",
            width: 275, height: 305
        }}>
            <CardContent sx={{height: 225}}>
                <Typography variant="subtitle2"
                            sx={{height: 45, wordWrap: "break-word"}}>{file.name}</Typography>
                {isImage && sourceLoaded &&
                    <img style={{maxWidth: 250, height: "auto", maxHeight: 150}}
                         src={`data:image/png;base64,${sourceLoaded}`}
                         alt={file.name}/>}
                {isSVG && sourceLoaded &&
                    <embed style={{maxWidth: 250, height: "auto", maxHeight: 150}}
                           src={`data:image/svg+xml;base64,${sourceLoaded}`}
                           type="image/svg+xml"/>}
                {isPdf && sourceLoaded &&
                    <iframe style={{maxWidth: 250, height: "auto", maxHeight: 150}}
                            src={base64PDFToBlobUrl(sourceLoaded)}/>}
                {isTxt && sourceLoaded &&
                    <embed style={{maxWidth: 250, height: "auto", maxHeight: 150}}
                           src={`data:text/plain;base64,${sourceLoaded}`}
                           type="text/plain"/>}
                {isVideo &&
                    <video style={{maxWidth: 250, height: "auto", maxHeight: 150}} controls>
                        <source src={`data:video/mp4;base64,${sourceLoaded}`} type="video/mp4"/>
                    </video>}
                {file.uploader && <Typography sx={{height: "45", pt: 1}} variant="subtitle2">
                    Uploaded by {file.uploader.firstName} {file.uploader.lastName}
                    &nbsp;at {new Date(file.cdate).toLocaleDateString()} {new Date(file.cdate).toLocaleTimeString()}
                </Typography>}
                {!file.uploader && <Typography sx={{height: "45", pt: 1}} variant="subtitle2">
                    Uploaded at {new Date(file.cdate).toLocaleDateString()} {new Date(file.cdate).toLocaleTimeString()}
                </Typography>}
            </CardContent>
            <CardActions sx={{height: 45, justifyContent: "end",}}>
                {mayUpdate && <DeleteFile file={file} capId={capId} complaintId={complaintId} itemId={itemId}
                                          investigationId={investigationId}/>}
                <IconButton color="primary" aria-label="download file" component="span" onClick={download}>
                    <DownloadIcon/>
                </IconButton>
            </CardActions>
        </Card>);
}
