import React, {useMemo, useState} from "react";
import {tableIcons} from "../table/icon";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {usStates} from "../admin/makeData";
import {useGetUsersQuery} from "../../app/apiSlice";
import {Box, Button, CircularProgress, IconButton, Tooltip, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export const ContactsTable = ({contacts, setContacts}) => {

    const [validationErrors, setValidationErrors] = useState({});
    //keep track of rows that have been edited
    const [editedUsers, setEditedUsers] = useState({});

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'firstName',
                header: 'First Name',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateRequired(event.currentTarget.value)
                            ? 'Required'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
            {
                accessorKey: 'lastName',
                header: 'Last Name',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateRequired(event.currentTarget.value)
                            ? 'Required'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
            {
                accessorKey: 'title',
                header: 'Title',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'title',
                    required: false,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
            {
                accessorKey: 'phone',
                header: 'Phone',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'phone',
                    required: false,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
            {
                accessorKey: 'email',
                header: 'Email',
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'email',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateEmail(event.currentTarget.value)
                            ? 'Incorrect Email Format'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedUsers({...editedUsers, [row.id]: row.original});
                    },
                }),
            },
        ],
        [editedUsers, validationErrors],
    );

    const handleSaveUsers = async () => {
        if (Object.values(validationErrors).some((error) => !!error)) return;
        const xxx = Object.values(editedUsers);
        console.log(xxx)
        setEditedUsers({});
    };


    const handleCreateContact = async ({values, table}) => {
        const newValidationErrors = validateContact(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        console.log(contacts)
        console.log([values, ...contacts,])
        setContacts([values, ...contacts,]);

        table.setCreatingRow(null); //exit creating mode
    };

    const handleSaveContact = async ({values, table}) => {
        const newValidationErrors = validateContact(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        setContacts([values, ...contacts.filter(i => i.id !== values.id)]);
        table.setEditingRow(null); //exit editing mode
    };

    const openDeleteConfirmModal = (row) => {
        if (window.confirm('Are you sure you want to delete this contact?')) {
            console.log(row)
            console.log(row.original)
            console.log(row.original.id)
            setContacts(contacts.filter(contact => contact.id === row.original.id)
                .sort((a, b) => a.lastName < b.lastName)
            );
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: contacts,
        createDisplayMode: 'row', // ('modal', and 'custom' are also available)
        editDisplayMode: 'row', // ('modal', 'row', 'cell', and 'custom' are also
        enableEditing: true,
        enableRowActions: true,
        positionActionsColumn: 'last',
        getRowId: (row) => row.id,
        muiTableContainerProps: {
            sx: {
                minHeight: '200px',
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateContact,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveContact,
        renderRowActions: ({row}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        renderBottomToolbarCustomActions: () => (
            <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                <Button
                    color="success"
                    variant="contained"
                    onClick={handleSaveUsers}
                    disabled={
                        Object.keys(editedUsers).length === 0 ||
                        Object.values(validationErrors).some((error) => !!error)
                    }
                >
                    Geänderte Kontakte speichern
                </Button>
                {Object.values(validationErrors).some((error) => !!error) && (
                    <Typography color="error">Fix errors before submitting</Typography>
                )}
            </Box>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <Button variant="contained" onClick={() => {
                table.setCreatingRow(true);
            }}>
                Create New Contact
            </Button>
        ),
        state: {},
        initialState: {
            sorting: [
                {
                    id: 'lastName',
                    desc: true,
                },
            ],
            columnVisibility: { id: false }
        },

    });


    return <MaterialReactTable table={table}/>;
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );

function validateContact(contact) {
    return {
        firstName: !validateRequired(contact.firstName)
            ? 'First Name is Required'
            : '',
        lastName: !validateRequired(contact.lastName) ? 'Last Name is Required' : '',
        email: !validateEmail(contact.email) ? 'Incorrect Email Format' : '',
    };
}

