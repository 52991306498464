import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import {dateAndTime} from "../../../common/dates";
import Chip from "@mui/material/Chip";

export default function InvestigationItemComment({comment}) {

    const external = comment.user.roles.find(r => r.name === "ROLE_EXTERNAL");
    return (
        <Paper key={"comment" + comment.id} sx={{mt: 2, mb: 1}}>
            <Grid container sx={{p: 2}}>
                <Grid item xs={4} sm={1}>
                    {avatar(comment.user)}
                </Grid>
                <Grid item xs={8} sm={11}>
                    <Typography variant="h6" sx={{display: "inline"}}>
                        {comment.user.firstName} {comment.user.lastName}
                    </Typography>&nbsp;
                    {dateAndTime(comment.cdate)}&nbsp;
                    {external && <Chip label="External" color="primary" variant="outlined"/>}
                    <Typography variant="body2" gutterBottom>
                        {comment.content}
                    </Typography>
                </Grid>

            </Grid>
        </Paper>);


    function avatar(user) {
        return <>
            {user.picture && <Avatar src={user.picture}/>}
            {!user.picture && <Avatar>{`${user.firstName.substr(0, 1)}${user.lastName.substr(0, 1)}`}</Avatar>}
        </>;
    }

}
