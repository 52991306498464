import React, {useState} from "react";
import Typography from '@mui/material/Typography';
import {Alert, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {
    useUpdateTrainingForTrainerMutation,
    useGetFileForTrainerQuery
} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";
import {useDispatch} from "react-redux";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import UploadFile from "../common/file/UploadFile";
import File from "../common/file/File";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

export const names = [
    'None',
    'No reaction from factory after initial contact',
    'Insufficient communication by factory management',
    'Issue with schedule arrangement',
    'No access to factory',
    'Last minute cancellation of factory training by factory management',
    'Insufficient attendance during training session',
    'The participant structure does not correspond to the gender ratio of the factory (regardless of the ratio at least 50% of the participants should be female workers)',
    'No allowance to take pictures',
    'No compensation for workers during training sessions',
    'Worker participation is not voluntary',
    'No worker representatives',
    'There are workers who do not have a mobile phone with internet access',
    'Non-compliance with legal or ILO standards',
    'Training module ineffective according to CDI’s evaluation criteria',
    'The factory rejects the program and does not collaborate with CDI',
    'Technical unavailability.'
];

const TrainingData = ({training, locations, disabled}) => {

    const [updateTraining] = useUpdateTrainingForTrainerMutation();
    const dispatch = useDispatch();

    const [location, setLocation] = useState((training.locationId && locations) ?
        locations.find(l => l.id === training.locationId) : ({id: training?.locationId}));


    const handleDateChange = async (date) => {
        try {
            await updateTraining({...training, trainingDate: date,}).unwrap()
            dispatch(openSnackbar({message: "Training updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({
                message: "Failed to update training", severity: "error"
            }));
        }
    };

    const handleDataChange = async (event) => {
        try {
            await updateTraining({...training, [event.target.name]: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Training updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({
                message: "Failed to update training", severity: "error"
            }));
        }
    };

    const handleChangeLocation = async (event) => {
        setLocation(event.target.value);
        try {
            await updateTraining({...training, locationId: event.target.value.id}).unwrap()
            dispatch(openSnackbar({message: "Training updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to update training", severity: "error"}));
        }
    }

    const handleChange = async (event) => {
        const {
            target: {value},
        } = event;
        let newVar = typeof value === 'string' ? value.split(',') : value;
        try {
            await updateTraining({...training, criticalIssues: newVar}).unwrap()
            dispatch(openSnackbar({message: "Training updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({
                message: "Failed to update training", severity: "error"
            }));
        }
    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    return (<Box p={5}>
        <Typography variant="h3" component="h1" gutterBottom>
            Training Data Entry for Trainers
        </Typography>

        {training.closed && disabled && <Alert severity="warning">This training has been closed and can't be updated anymore.</Alert>}

        <Grid container rowSpacing={1} columnSpacing={2} mt={3}>
            <Grid item container xs={4}>
                <Stack spacing={2} width="100%">
                    <TextField
                        fullWidth
                        id="trainingName"
                        name="trainingName"
                        label="Training Name"
                        defaultValue={training?.trainingName}
                        onBlur={handleDataChange}
                        disabled={disabled}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="training_date"
                            label="Training Date"
                            inputFormat="dd/MM/yyyy"
                            value={training.trainingDate ? new Date(training.trainingDate) : new Date()}
                            onChange={handleDateChange}
                            textField={(params) => <TextField    {...params} />}
                            disabled={disabled}
                        />
                    </LocalizationProvider>
                    <TextField
                        fullWidth
                        id="trainerName"
                        name="trainerName"
                        label="Trainer Name"
                        defaultValue={training?.trainerName}
                        onBlur={handleDataChange}
                        disabled={disabled}
                    />
                    <FormControl variant="outlined">
                        <InputLabel id="location-label">Factory</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location"
                            value={location}
                            onChange={handleChangeLocation}
                            label="Location"
                            sx={{minWidth: 120}}
                            disabled={disabled}
                        >
                            {
                                locations
                                    .map(location =>
                                        <MenuItem key={location.id} value={location}>
                                            {location.name}
                                        </MenuItem>
                                    )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        id="numberOfParticipants"
                        name="numberOfParticipants"
                        label="Number of Participants"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={training?.numberOfParticipants}
                        onBlur={handleDataChange}
                        disabled={disabled}
                    />

                    <TextField
                        fullWidth
                        id="numberOfMaleParticipants"
                        name="numberOfMaleParticipants"
                        label="Number of Male Participants"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={training?.numberOfMaleParticipants}
                        onBlur={handleDataChange}
                        disabled={disabled}
                    />

                    <TextField
                        fullWidth
                        id="numberOfFemaleParticipants"
                        name="numberOfFemaleParticipants"
                        label="Number of Female Participants"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={training?.numberOfFemaleParticipants}
                        onBlur={handleDataChange}
                        disabled={disabled}
                    />

                    <TextField
                        fullWidth
                        id="numberOfDiverseParticipants"
                        name="numberOfDiverseParticipants"
                        label="Number of Diverse Participants"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={training?.numberOfDiverseParticipants}
                        onBlur={handleDataChange}
                        disabled={disabled}
                    />

                </Stack>
            </Grid>


            <Grid item container xs={8}>
                <TextField
                    fullWidth
                    id="notes"
                    name="notes"
                    label="Notes"
                    defaultValue={training.notes}
                    multiline rows={7}
                    onBlur={handleDataChange}
                    disabled={disabled}
                />

                <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">Critical Issue</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={training.criticalIssues ? training.criticalIssues : []}
                        onChange={handleChange}
                        input={<OutlinedInput label="Critical Issue"/>}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        disabled={disabled}
                    >
                        {names.map((name) => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={training?.criticalIssues?.indexOf(name) > -1}/>
                                <ListItemText primary={name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    id="criticalIssuesExplanation"
                    name="criticalIssuesExplanation"
                    label="Explanation Critical Issue"
                    defaultValue={training.criticalIssuesExplanation}
                    multiline rows={4}
                    onBlur={handleDataChange}
                    disabled={disabled}
                />


            </Grid>
            <Grid item container xs={12}>
                <Stack spacing={2}>
                    <Typography variant="h6">Images</Typography>
                    {training.images && training.images.length > 0 && <>
                        <Grid container spacing={1}>{training.images.map((file, index) => (
                            <Grid item key={index}> <File file={file} mayUpdate={false}
                                                          useGetFileQuery={useGetFileForTrainerQuery}/>
                            </Grid>))}
                        </Grid></>}
                    {!disabled && <UploadFile id="uploadImages"
                                              key="uploadImages"
                                              meta={{
                                                  "key": `files_uploadImages`,
                                                  "fileType": "IMAGE_BY_TRAINER",
                                                  "trainingId": training.id
                                              }}
                                              label="Upload Images"
                    />}


                    <Typography variant="h6">Participant List and Consent Declaration</Typography>
                    {training.consentForms && training.consentForms.length > 0 && <>
                        <Grid container spacing={1}>{training.consentForms.map((file, index) => (
                            <Grid item key={index}> <File file={file} mayUpdate={false}
                                                          useGetFileQuery={useGetFileForTrainerQuery}/>
                            </Grid>))}
                        </Grid></>}
                    {!disabled && <UploadFile id="uploadConsentForm"
                                              key="uploadConsentForm"
                                              meta={{
                                                  "key": `files_uploadConsentForm`,
                                                  "fileType": "CONSENT_FORM_BY_TRAINER",
                                                  "trainingId": training.id
                                              }}
                                              label="Upload File" buttonColor="success"
                    />}
                </Stack>

            </Grid>
        </Grid>


    </Box>);
};

export default TrainingData;
