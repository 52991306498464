import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../common/LoadingGIF";
import NotAuthorized from "../common/NotAuthorized";
import Error from "../common/Error";
import {useGetBrandsQuery, useGetKPIsQuery, useGetLocationsQuery, useGetMeQuery} from "../../app/apiSlice";
import KPIS from "./KPIS";

const KPISPage = () => {

    const {data, isLoading: loading, error} = useGetKPIsQuery();
    const {data: locations, isLoading: locationsLoading, error: locationsError, isSuccess} = useGetLocationsQuery();
    const {data: me, isLoading: loadingMe, error: errorMe} = useGetMeQuery();
    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();

    if (locationsLoading || loading || loadingMe || brandsLoading) return <LoadingGIF/>;
    if (locationsError && locationsError.status === 403) return <NotAuthorized/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (errorMe && errorMe.status === 403) return <NotAuthorized/>;
    if (brandsError && brandsError.status === 403) return <NotAuthorized/>;
    if (locationsError || error || errorMe || brandsError) return <Error/>;

    return <KPIS data={data} locations={locations} me={me} brands={brands}/>

};

export default withAuthenticationRequired(KPISPage, {
    onRedirecting: () => <LoadingGIF/>,
});
