import React, {useState} from 'react';
import {PDFDownloadLink} from "@react-pdf/renderer";
import {PdfDocument} from "../../../common/pdf/PdfDocument";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import {timestamp} from "../../../common/timestamp";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function DownloadComplaintPDFButton({complaint, user}) {

    const [open, setOpen] = useState(false);
    const [includeOriginalComplaintMessage, setIncludeOriginalComplaintMessage] = useState(false);
    const [note, setNote] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button variant="contained" color="primary" size={"large"} onClick={handleOpen}>
                Download Complaint PDF
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={includeOriginalComplaintMessage}
                                                                 onClick={() => setIncludeOriginalComplaintMessage(!includeOriginalComplaintMessage)}/>}
                                              label="Include Original Message by Complainant"/>
                        </FormGroup>

                        <TextField
                            id="note"
                            label="Note included in PDF"
                            style={{marginTop: 10, marginBottom: 10}}
                            minRows={4} multiline
                            fullWidth={true}
                            value={note}
                            variant="outlined"
                            onInput={e => setNote(e.target.value)}
                        />

                        <PDFDownloadLink
                            document={<PdfDocument complaint={complaint}  user={user}
                                                   includeOriginalComplaintMessage={includeOriginalComplaintMessage}
                                                   note={note}/>}
                            fileName={`Complaint_${complaint.id}_${(timestamp())}.pdf`}
                        >
                            <Button variant="contained" color="primary">
                                Download
                            </Button>
                        </PDFDownloadLink>
                    </Box>
                </Fade>
            </Modal>


        </>
    );

}
