export const deuter_0323 = [
    {
        stepName: "welcome",
        title: "welcome.title",
        text: "welcome.text",
        field: {
            name: "age",
            type: "number",
            label: "welcome.field.label",
            submit: {
                label: "welcome.field.submit.label", icon: "SendIcon", goto: "timeInFactory", create: true
            }
        },
        buttons: [{label: "welcome.buttons.skip", icon: "SkipNextIcon", goto: "timeInFactory",}],
    }, {
        stepName: "timeInFactory",
        previousStep: "welcome",
        text: "timeInFactory.text",
        multipleChoice: true,
        buttons: [
            {
                label: "timeInFactory.buttons.lessThanSixMonths",
                goto: "training",
                update: {field: "timeInFactory", value: "LESS_THAN_SIX_MONTHS"}
            },
            {
                label: "timeInFactory.buttons.sevenToTwelveMonths",
                goto: "training",
                update: {field: "timeInFactory", value: "SEVEN_TO_TWELVE_MONTHS"}
            },
            {
                label: "timeInFactory.buttons.oneToThreeYears",
                goto: "training",
                update: {field: "timeInFactory", value: "ONE_TO_THREE_YEARS"}
            },
            {
                label: "timeInFactory.buttons.moreThanThreeYears",
                goto: "training",
                update: {field: "timeInFactory", value: "MORE_THAN_THREE_YEARS"}
            },]
    }, {
        stepName: "training",
        previousStep: "timeInFactory",
        text: "training.text",
        buttons: [
            {label: "training.buttons.yes", goto: "concern", update: {field: "training", value: "true"}},
            {label: "training.buttons.no", goto: "concern", update: {field: "training", value: "false"}},]
    }, {
        stepName: "concern",
        previousStep: "training",
        text: "concern.text",
        multipleChoice: true,
        buttons: [
            {
                label: "concern.buttons.supervisor",
                goto: "atlatKnowledge",
                update: {field: "concern", value: "SUPERVISOR"}
            },
            {
                label: "concern.buttons.internal",
                goto: "atlatKnowledge",
                update: {field: "concern", value: "INTERNAL_COMPLAINT_BOX"}
            },
            {label: "concern.buttons.dontKnow", goto: "atlatKnowledge", update: {field: "concern", value: "DONT_KNOW"}},
            {label: "concern.buttons.somethingElse", goto: "otherConcern", update: {field: "concern", value: "OTHER"}},]
    }, {
        stepName: "otherConcern",
        previousStep: "concern",
        text: "otherConcern.text",
        field: {
            name: "otherConcern",
            type: "text",
            label: "otherConcern.field.label",
            submit: {
                label: "otherConcern.field.submit.label", icon: "SendIcon", goto: "atlatKnowledge"
            }
        },
        buttons: [{label: "otherConcern.buttons.skip", icon: "SkipNextIcon", goto: "atlatKnowledge",}],
    }, {
        stepName: "atlatKnowledge",
        previousStep: "concern",
        text: "atlatKnowledge.text",
        multipleChoice: true,
        buttons: [
            {
                label: "atlatKnowledge.buttons.foodDelivery",
                goto: "poster",
                update: {field: "whatIsAtlat", value: "FOOD_DELIVERY"}
            },
            {
                label: "atlatKnowledge.buttons.fashionBrand",
                goto: "poster",
                update: {field: "whatIsAtlat", value: "FASHION_BRAND"}
            },
            {
                label: "atlatKnowledge.buttons.complaintChat",
                goto: "atlatUsage",
                update: {field: "whatIsAtlat", value: "COMPLAINT_CHAT"}
            },
            {
                label: "atlatKnowledge.buttons.workersUnion",
                goto: "poster",
                update: {field: "whatIsAtlat", value: "WORKERS_UNION"}
            },]
    }, {
        stepName: "atlatUsage",
        previousStep: "atlatKnowledge",
        text: "atlatUsage.text",
        multipleChoice: true,
        buttons: [
            {label: "atlatUsage.buttons.yes", goto: "safety", update: {field: "atlatUsage", value: "YES"}},
            {
                label: "atlatUsage.buttons.yesBut",
                goto: "safety",
                update: {field: "atlatUsage", value: "YES_BUT_IT_DOESNT_WORK"}
            },
            {label: "atlatUsage.buttons.no", goto: "atlatQuestion", update: {field: "atlatUsage", value: "NO"}},
            {label: "atlatUsage.buttons.skip", icon: "SkipNextIcon", goto: "safety",}]
    }, {
        stepName: "atlatQuestion",
        previousStep: "atlatUsage",
        text: "atlatQuestion.text",
        field: {
            name: "atlatQuestion",
            type: "text",
            label: "atlatQuestion.field.label",
            submit: {
                label: "atlatQuestion.field.submit.label", icon: "SendIcon", goto: "safety"
            }
        },
        buttons: [{label: "atlatQuestion.buttons.skip", icon: "SkipNextIcon", goto: "safety",}],
    }, {
        stepName: "safety",
        previousStep: "atlatUsage",
        text: "safety.text",
        scale: {
            name: "safety",
            steps: ["safety.scale.step_1", "safety.scale.step_2", "safety.scale.step_3", "safety.scale.step_4", "safety.scale.step_5"],
            submit: {label: "safety.field.submit.label", icon: "SendIcon", goto: "poster"}
        },
    }, {
        stepName: "poster",
        previousStep: "atlatKnowledge",
        text: "poster.text",
        buttons: [
            {label: "poster.buttons.yes", goto: "misc", update: {field: "atlatPoster", value: "true"}},
            {label: "poster.buttons.no", goto: "misc", update: {field: "atlatPoster", value: "false"}},]
    }, {
        stepName: "misc",
        previousStep: "poster",
        text: "misc.text",
        field: {
            name: "misc",
            type: "text",
            label: "misc.field.label",
            submit: {
                label: "misc.field.submit.label", icon: "SendIcon", goto: "thanks"
            }
        },
        buttons: [{label: "misc.buttons.no", goto: "thanks",},]
    }, {
        stepName: "thanks",
        previousStep: "misc",
        text: "thanks.text"
    },
];
