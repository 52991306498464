import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React from "react";
import theme from "../theme";
import {useTranslation} from "react-i18next";
import File from "../common/file/File";
import Speech from "../wizard/Speech";
import {v4 as uuidv4} from "uuid";
import {useGetFileQuery} from "../../app/apiSlice";

export const MessageByComplainant = ({message, file, text2Speech}) => {
        const {t, i18n} = useTranslation("complaintForComplainant");

        function speech(text) {
            if (text2Speech)
                return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
        }

        function getInitials() {
            if (message.anonymous) return "C";
            if (message.sender) return message.sender.firstName.substr(0, 1) + message.sender.lastName.substr(0, 1);
            return "C";
        }

        function getAvatar() {
            if (message.sender && message.sender.picture) return <Avatar src={message.sender.picture}/>;
            return <Avatar component={'span'}>{getInitials()}</Avatar>;
        }

        return (<>
            <Paper sx={{ marginTop: theme.spacing(2),
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',}}>
                <Grid container spacing={3} direction="row" justifyContent={file ? "start" : "space-between"}
                      alignItems="stretch">
                    <Grid item xs={2} sm={1}>
                        {getAvatar()}
                    </Grid>
                    {!file && <Grid item xs={10} sm={11}>
                        <Typography variant="body2" component={'span'} gutterBottom>
                            {message.translatedDescription}{speech(message.translatedDescription)}
                        </Typography>
                        <Typography variant="caption" component={'span'} display="block" gutterBottom>
                            {t("originalMessage")}: {message.text}{speech(message.text)}
                        </Typography>
                    </Grid>}
                    {file && <File file={file} mayUpdate={false}  useGetFileQuery={useGetFileQuery}/>}
                </Grid>
            </Paper>
            <Typography variant="caption" component={'span'} display="block" gutterBottom>
                {!isNaN(new Date(message.cdate).getTime()) ? new Date(message.cdate).toLocaleString() : message.cdate}
            </Typography>
        </>)
    }
;

export default MessageByComplainant;
