import React from "react";
import LoadingGIF from "../common/LoadingGIF";
import NotAuthorized from "../common/NotAuthorized";
import Error from "../common/Error";
import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {
    useGetCheckInReportQuery
} from "../../app/apiSlice";
import {useRouteMatch} from "react-router-dom";
import CollapsibleSection from "../common/CollapsibleSection";
import {Cell, Legend, Pie, PieChart, Tooltip} from "recharts";
import {COLORS,} from "../common/colors";
import {timestamp} from "../common/timestamp";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {CheckinPdfDocument} from "./CheckinPdfDocument";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Paper from "@mui/material/Paper";
import theme from "../theme";
import Grid from "@mui/material/Grid";
import pdf from "./Report_tai_hong_010324.pdf"
import {hiddenColumns} from "./CheckinReportDataTable";

const CheckinReport = () => {

    const match = useRouteMatch();
    const {data: report, isLoading: reportLoading, error: reportError} = useGetCheckInReportQuery(match.params.id);

    if (reportLoading) return <LoadingGIF/>;
    if (reportError && reportError.status === 403) return <NotAuthorized/>;
    if (reportError) return <Error/>;

    function getTitle(title, number) {
        return <Stack direction="row" width={"100%"} justifyContent="space-between">
            {title}
            <Chip sx={{ml: 5}} label={`${number} Answers`} color="primary" variant="outlined"/>
        </Stack>;
    }

    function getCollapsibleSection(item, numberOfParticipants) {
        const title = transform(item.name) + " - " + item.question;
        const data = item.data;
        const number = data
            .map(item => item.value)
            .reduce((prev, curr) => prev + curr, 0);

        function getChildren(number) {
            return item.data.slice().sort((b, a) => {
                return ((a.value || 0) - (b.value || 0))})
                .map(xxx => <Paper sx={{
                    m: 1,
                    padding: theme.spacing(2),
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'column',
                }}>
                    <Grid container spacing={3} direction="row"
                          justifyContent={"space-between"}
                          alignItems="stretch">
                        <Grid item xs={10} sm={11}>

                            <Typography variant="body2" component={'span'} gutterBottom>
                                {xxx.name}
                            </Typography>
                            <Typography variant="caption" component={'span'} display="block"
                                        gutterBottom>
                                Translation: {xxx.translation}, Count: {xxx.value}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>);
        }

        if (item.type === "openQuestion") {
            return <CollapsibleSection key={title}
                                       title={getTitle(title, number)}
                                       mt={2} border={true}
                                       children={getChildren()}/>;
        }

        function getPie() {
            return <PieChart width={950} height={300}>
                <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%"
                     innerRadius={0}
                     outerRadius={100} labelLine={false}>
                    {data.map((entry, index) =>
                        <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

                </Pie>
                <Tooltip/>
                <Legend
                    layout="vertical" verticalAlign="top" align="right" height={200} width={400}
                    formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                    payload={
                        data.map(
                            (item, index) => ({
                                id: item.name,
                                type: "square",
                                value: `${item.name} (${item.value} / ${(item.value / number * 100).toFixed(1)}%)`,
                                color: COLORS[index % COLORS.length]
                            })
                        )
                    }
                />
            </PieChart>;
        }

        return <CollapsibleSection key={title} title={getTitle(title, number)} mt={2} border={true}
                                   children={getPie()}/>;
    }

    function transform(str) {
        str = str.charAt(0).toUpperCase() + str.slice(1); // Capitalize the first letter
        str = str.replace(/([0-9A-Z])/g, ' $&'); // Add space between camel casing
        return str
    }


    return (
        <Box p={3}>
            <Stack direction="row" justifyContent="space-between" sx={{pt: 2, pb: 3}}>
                <Typography variant="h3" component="span">
                    Report {report.name ? report.name : report.id}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">

                <Chip label={`Number of participants: ${report.numberOfParticipants}`} color="primary"
                      variant="outlined"/>
                <Stack direction="row" justifyContent="end" spacing={2}>
                    {match.params.id === "tai_hong_010324" &&
                        <Button variant="contained" color="primary" href={pdf}
                                download={`Report_CheckIn_${match.params.id}_${(timestamp())}.pdf`}>
                            Download
                        </Button>}

                    {match.params.id !== "tai_hong_010324" &&
                        <PDFDownloadLink
                            document={<CheckinPdfDocument report={report} name={match.params.id}/>}
                            fileName={`Report_CheckIn_${match.params.id}_${(timestamp())}.pdf`}
                        >
                            <Button variant="contained" color="primary">
                                Download
                            </Button>
                        </PDFDownloadLink>}

                    <Button variant="contained" color="primary" href={`/report/${match.params.id}/raw`}>
                        Raw Data
                    </Button>

                </Stack>
            </Stack>

            {report.items
                .filter(item => !hiddenColumns.includes(item.name))
                .map(item => getCollapsibleSection(item, report.numberOfParticipants))}

        </Box>);
};
export default withAuthenticationRequired(CheckinReport, {
    onRedirecting: () => <LoadingGIF/>,
});
