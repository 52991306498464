import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React from "react";
import {useTranslation} from "react-i18next";
import logo from "../../assets/atlat_symbol.svg";
import Speech from "../wizard/Speech";
import {v4 as uuidv4} from "uuid";
import theme from "../theme";

export const MessageByAtlat = ({message, text2Speech}) => {
    const {t, i18n} = useTranslation("complaintForComplainant");

    function speech(text) {
        if (text2Speech)
            return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
    }

    return (<>
        <Paper sx={{marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',}}>
            <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="stretch">
                <Grid item xs={9}>
                    <Typography variant="body2" gutterBottom>
                        {message.translatedDescription}{speech(message.translatedDescription)}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        {t("originalMessage")}: {message.text}{!message.translatedDescription && speech(message.text)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar src={logo}/>

                </Grid>
            </Grid>
        </Paper>
        <Typography variant="caption" component={'span'} display="block" gutterBottom>
            {!isNaN(new Date(message.cdate).getTime()) ? new Date(message.cdate).toLocaleString() : message.cdate}
        </Typography>
    </>)
};

export default MessageByAtlat;
