import React, {useState} from 'react';
import {InputLabel, Select, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {useAskExternalQuestionAboutInvestigationMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function AddInvestigationItemAskExternal({setOpen, complaint, investigation, setTab}) {

    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();
    const [askExternalQuestionAboutInvestigation] = useAskExternalQuestionAboutInvestigationMutation();

    const validationSchema = Yup.object().shape({
        external: Yup.string().required('External is required'),
        question: Yup.string().required('Question is required'),
    });

    const {register, handleSubmit, formState: {errors}, reset,} = useForm({resolver: yupResolver(validationSchema)});

    const onSubmit = async (data) => {
        setSending(true);

        try {
            await askExternalQuestionAboutInvestigation({investigationId: investigation.id, data: data, complaintId: complaint.id}).unwrap();
            dispatch(openSnackbar({message: "Successfully asked question", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to ask question", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }

    };

    const {ref, ...rest} = register('external')

    return (
        <>
            <Typography variant="h5" sx={{mt: 2}}>Ask For External Opinion</Typography>

            {complaint.externals.length === 0 && <Alert variant="outlined" severity="warning">
                Please go to the external tab and add externals.
                <Button onClick={() => {
                    setOpen(false);
                    setTab(4);
                }}>Go To External Tab</Button>
            </Alert>}

            {complaint.externals.length > 0 && <> <Box component="form">
                <FormControl sx={{minWidth: 250, mt: 1}}>
                    <InputLabel id="external-label">External To Ask</InputLabel>
                    <Select
                        labelId="external-label"
                        id="external"
                        name="external"
                        label="External To Ask"
                        inputRef={ref}  {...rest}
                        error={!!errors.external}
                    >
                        {complaint.externals.map((external) => (
                            <MenuItem
                                value={external.id}>{external.invitedUser.firstName} {external.invitedUser.lastName}</MenuItem>))}

                    </Select>
                </FormControl>
                <Typography variant="inherit" color="error">
                    {errors.external?.message}
                </Typography>

                <TextField
                    sx={{mt: 1}}
                    fullWidth
                    id="question"
                    name="question"
                    multiline
                    rows={4}
                    label="Question"
                    {...register('question')}
                    error={!!errors.question}
                />
                <Typography variant="inherit" color="error">
                    {errors.question?.message}
                </Typography>
            </Box>

                <LoadingButton
                    fullWidth sx={{mt: 1}}
                    loading={sending}
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit(onSubmit)}
                >
                    Ask Question about Complaint Investigation
                </LoadingButton></>}
        </>

    );


}
