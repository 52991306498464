import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {Auth0Provider} from "@auth0/auth0-react";
import history from "./utils/history";
import {auth0, auth0ClientId} from "./urls";
import './i18n';
import {Suspense} from 'react';
import LoadingGIF from "./components/common/LoadingGIF";
import {Provider} from "react-redux";
import store from "./app/store";

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
// for a full list of the available properties on the provider

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(  <React.StrictMode>
    <Auth0Provider
        domain={`${auth0}.eu.auth0.com`}
        audience={"https://complaints/api"}
        // scope="read:current_user update:current_user_metadata"
        onRedirectCallback={onRedirectCallback}

        clientId={auth0ClientId}
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://complaints/api",
            scope: "openid profile email"
        }}


    >
        <Suspense fallback={<LoadingGIF/>}>
            <Provider store={store}>
                <App/>
            </Provider>
        </Suspense>
    </Auth0Provider>
</React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
