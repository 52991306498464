import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    message: "",
    severity: "info",
    open: false,
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar: (state, action) => {
            state.message = action.payload.message;
            state.severity = action.payload.severity;
            state.open = true;
        },
        closeSnackbar: (state) => {
            state.message = '';
            state.severity = 'info';
            state.open = false;
        },
    },
});

export const {openSnackbar, closeSnackbar} = snackbarSlice.actions;

export default snackbarSlice.reducer;
