import React from 'react';
import Container from '@mui/material/Container';
import {Stack} from "@mui/material";
import InfoBox from "../common/InfoBox";
import Card from "@mui/material/Card";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {darkSalmon, darkTurquoise, lightSalmon, lightTurquoise, mainSalmon, mainTurquoise} from "../common/colors";
import ComplaintsTable from "./ComplaintsTable";
import {useGetBrandsQuery} from "../../app/apiSlice";
import LoadingGIF from "../common/LoadingGIF";
import NotAuthorized from "../common/NotAuthorized";
import Error from "../common/Error";

export const Dashboard = ({statistics, complaints, me}) => {

    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();
    if (brandsLoading) return <LoadingGIF/>;
    if (brandsError && brandsError.message.includes('403')) return <NotAuthorized/>;
    if (brandsError) return <Error/>;

    const data02 = [
        {
            "name": "Incoming",
            "value": statistics ? statistics.incomingComplaints : 0
        },
        {
            "name": "Open",
            "value": statistics ? statistics.openComplaints : 0
        },
        {
            "name": "Investigation",
            "value": statistics ? statistics.investigationComplaints : 0
        },
        {
            "name": "CAP",
            "value": statistics ? statistics.capComplaints : 0
        },
        {
            "name": "Closed",
            "value": statistics ? statistics.closedComplaints : 0
        },
    ];

    let COLORS = [darkSalmon, lightSalmon, darkTurquoise, mainTurquoise, lightTurquoise, mainSalmon];
    return (

        <div style={{display: 'flex',}}>
            <main style={{flexGrow: 1, overflow: 'auto',}}>
                <Container maxWidth="lg" sx={{pt: 2, pb: 1}}>
                    {statistics &&
                        <Stack direction="row" justifyContent="space-between" sx={{mb: 2}}>
                            <InfoBox title="Incoming" number={statistics.incomingComplaints} minWidth={200}
                                     info="Incoming complaints are complaints that have not been worked on yet"
                                     variant="orange"/>
                            <InfoBox title="In Progress" number={statistics.complaintsInProgress} minWidth={200}
                                     info="Complaints with active admission, investigation or cap"
                                     variant="blue"/>
                            <InfoBox title="Closed" number={statistics.closedComplaints} minWidth={200}
                                     info="Closed successfully and unsuccessfully"
                                     variant="red"/>

                            <Card sx={{minWidth: 300, maxWidth: 350, height: 200}}>
                                <ResponsiveContainer debounce={1}>
                                    <PieChart width={350} height={200}>
                                        <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                             innerRadius={50}
                                             outerRadius={80}>
                                            {data02.map((entry, index) =>
                                                <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}
                                        </Pie>
                                        <Legend layout="vertical" verticalAlign="top" align="right" height={200}/>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Card>
                        </Stack>}


                    <ComplaintsTable complaints={complaints} brands={brands} me={me}/>


                </Container>
            </main>
        </div>
    );
};
