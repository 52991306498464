import React from 'react';
import InviteExternal from "./InviteExternal";
import ComplaintExternal from "./ComplaintExternal";
import {setTabInfo} from "../../../../utils/tabs";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {useRevokeInvitationMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

const ExternalsTab = ({complaint}) => {

    const dispatch = useDispatch();

    const [revokeInvitation] = useRevokeInvitationMutation();

    setTabInfo("external", complaint);

    // todo check permissions CS-410

    async function handleRevokeInvitation(invitation) {
        try {
            await revokeInvitation({invitationId: invitation.id, complaintId: complaint.id}).unwrap()
            dispatch(openSnackbar({message: "Invitation revoked successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Revoking invitation failed", severity: "error"}));
        }
    }

    return (
        <div>
            <InviteExternal complaint={complaint}/>
            <br/>
            {complaint.externals && complaint.externals.length > 0 && <>
                <Typography variant="h5">Externals</Typography>
                {complaint.externals.map((invitation, index) => {
                    return (<ComplaintExternal id={index} invitation={invitation} key={index} complaint={complaint}
                                               revokeMethod={() => handleRevokeInvitation(invitation)}/>)
                })}

            </>}
        </div>
    );
}

export default ExternalsTab;
