import React from "react";
import {useRouteMatch} from "react-router-dom";
import LoadingGIF from "../common/LoadingGIF";
import Error from "../common/Error";
import {
    useGetLocationNamesQuery,
    useGetTrainingForTrainerQuery
} from "../../app/apiSlice";
import NotAuthorized from "../common/NotAuthorized";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import TrainingData from "./TrainingData";


const Training = () => {

    const match = useRouteMatch();

    const {data: training, isLoading: loading, error} = useGetTrainingForTrainerQuery(match.params.id);
    const {data: locations, isFetching: loadingLocations, error: locationsError} = useGetLocationNamesQuery();

    if (loading || loadingLocations) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (locationsError && locationsError.status === 403) return <NotAuthorized/>;
    if (error || locationsError) return <Error/>;


    return (<TrainingData training={training} locations={locations} disabled={training.closed}/>);
};

export default withAuthenticationRequired(Training, {
    onRedirecting: () => <LoadingGIF/>,
});

