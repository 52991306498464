import React, {useState} from 'react';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {grey} from "@mui/material/colors";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CardActions from "@mui/material/CardActions";
import Chip from "@mui/material/Chip";
import {capItemStatuslabel} from "../../../labels";
import Button from "@mui/material/Button";
import CloseCapItem from "./CloseCapItem";
import WarningIcon from "@mui/icons-material/Warning";
import {darkSalmon, deadlineRed, lightTurquoise, mainSalmon} from "../../../../common/colors";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {openSnackbar} from "../../../../../app/snackbarSlice";
import {useDispatch} from "react-redux";
import {useSaveCAPItemStatusMutation} from "../../../../../app/apiSlice";
import CAPItemDialog from "./CAPItemDialog";
import {createdBy} from "./itemFacts";

export default function CAPItemCard({item, cap, permissions, complaint, user, isInternal}) {

    const [open, setOpen] = useState(localStorage.getItem(`${cap.id}_openCard`) ? localStorage.getItem(`${cap.id}_openCard`) === item.id : false);

    const dispatch = useDispatch();

    const [saveCAPItemStatus] = useSaveCAPItemStatusMutation();

    const closedOrDone = cap.status === "CLOSED" || cap.status === "DONE";

    const handleClickOpen = () => {
        localStorage.setItem(`${cap.id}_openCard`, item.id);
        setOpen(true);
    };

    const handleClose = () => {
        localStorage.setItem(`${cap.id}_openCard`, null);
        setOpen(false);
    };

    async function handleSaveCAPItemStatus(item) {
        try {
            await saveCAPItemStatus({capId: cap.id, capItemId: item.id, status: {status: "IN_REMEDIATION"}}).unwrap()
            dispatch(openSnackbar({message: "CAP Item status updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP Item status update failed", severity: "error"}));
        }
    }

    function getItemDueDate(item) {
        if (!item || !item.dueDate) return null;

        if (new Date(item.dueDate) < new Date())
            return <Stack direction={"row"} justifyContent="space-between" alignItems="center">
                <WarningIcon style={{fill: deadlineRed}}/>
                <Typography align={"left"} sx={{whiteSpace: "nowrap"}}
                            variant="body"> {new Date(item.dueDate).toLocaleDateString()}</Typography>
            </Stack>

        return <Stack direction={"row"} justifyContent="space-between" alignItems="center">
            <CalendarTodayOutlinedIcon fontSize="small" sx={{color: grey[500]}}/>
            <Typography align={"left"} sx={{whiteSpace: "nowrap"}}
                        variant="body"> {new Date(item.dueDate).toLocaleDateString()}</Typography>
        </Stack>
    }



    function priorityColor(item) {
        if (!item || !item.priority) return null;

        switch (item.priority) {
            case "high":
                return darkSalmon;
            case "medium":
                return mainSalmon;
            case "low":
                return lightTurquoise;
            default:
                return null;
        }
    }

    return <Card key={item.id} sx={{
        padding: 2,
        marginTop: 2,
        borderColor: "primary.main",
        borderStyle: `solid`,
        borderWidth: 1,
        borderRadius: 2,
        boxShadow: "none"
    }}>
        <CardHeader
            avatar={<Avatar
                sx={{width: 24, height: 24, bgcolor: priorityColor(item)}}> </Avatar>}
            action={
                <>
                    <IconButton aria-label="open" color={"primary"} onClick={handleClickOpen}>
                        <OpenInNewIcon/>
                    </IconButton>
                    <CAPItemDialog open={open} onClose={handleClose} permissions={permissions} closedOrDone={closedOrDone} item={item} cap={cap} complaint={complaint} user={user} isInternal={isInternal}/>
                </>
            }
            title={<Stack direction={"row"} justifyContent="space-between"
                          alignItems="baseline">
                <Typography align={"left"} variant="h4">{item.title}</Typography>
                {getItemDueDate(item)}
            </Stack>}/>
        <CardContent>
            <Typography variant="caption" display="block" align="left" gutterBottom>
                {createdBy(item,cap)}
            </Typography>
            <Typography variant="body1" display="block" align="left" gutterBottom>
                Description:<br/>
                {item.description}
            </Typography>
            {item.files && item.files.map(file =>
                <Stack direction="row" alignItems="center" key={file.id} sx={{color: grey[500]}}>
                    <AttachFileIcon sx={{color: grey[500]}}/>
                    {file.name}
                </Stack>)}
        </CardContent>
        <CardActions>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} width="100%">
                <Chip variant="outlined" label={capItemStatuslabel(item.status)}></Chip>

                {item.status === "IN_CREATION" &&
                    <Button variant="outlined"
                            size={"large"}
                            style={{marginLeft: 5}}
                            disabled={!permissions.has("WRITE_CAP") || closedOrDone}
                            onClick={() => handleSaveCAPItemStatus(item)}>
                        Start
                    </Button>}
                {item.status === "IN_REMEDIATION" && <CloseCapItem cap={cap} item={item} permissions={permissions}
                                                                   disabled={!permissions.has("WRITE_CAP") || closedOrDone}/>}
            </Stack>
        </CardActions>
    </Card>
}
