import React, {useEffect, useState} from 'react';
import {Collapse, Stack, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from "@mui/material/IconButton";
import EditPermissions from "./EditPermissions";
import {useDispatch} from "react-redux";
import {useUpdateExternalMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function EditExternalForm({complaint, setOpen, invitation}) {
    const [sending, setSending] = useState(false);

    const [noteCollapsed, setNoteCollapsed] = useState(false);
    const [permissionNoteCollapsed, setPermissionNoteCollapsed] = useState(false);
    const dispatch = useDispatch();
    const [updateExternal] = useUpdateExternalMutation();
    const [permissions, setPermissions] = useState(invitation.permissions.reduce((obj, item) => {
        return {
            ...obj,
            [item["permission"]]: item["granted"],
        };
    }, {}));

    const handlePermissionNoteCollapsedChange = () => {
        setPermissionNoteCollapsed((prev) => !prev);
    };

    const handleNoteCollapsedChange = () => {
        setNoteCollapsed((prev) => !prev);
    };

    function contactAlreadyInvited(email) {
        if (email === invitation.invitedUser.email) return false;
        if (!complaint || !complaint.externals || !email) return false;
        for (let i = 0; i < complaint.externals.length; i++) {
            let invitation = complaint.externals[i];
            if (invitation.invitedUser.email === email && !invitation.invitationRevoked) return true;
        }
        return false;
    }

    const validationSchema = Yup.object().shape({
        inviteeFirstName: Yup.string().required('First name is required'),
        inviteeLastName: Yup.string().required('Last name is required'),
        inviteeEmail: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
            .test('len', 'Email is already invited', val => val && !contactAlreadyInvited(val)),
        permissionNote: Yup.string().required('Explanation why the permissions were chosen is required'),
    });

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({resolver: yupResolver(validationSchema)});

    useEffect(() => {
        reset({
            inviteeFirstName: invitation.invitedUser.firstName,
            inviteeLastName: invitation.invitedUser.lastName,
            inviteeEmail: invitation.invitedUser.email,
            note: invitation.note
        });
    }, [invitation, reset]);

    const onSubmit = async (data) => {
        setSending(true);

        try {
            await updateExternal({
                complaintId: complaint.id,
                invitationId: invitation.id,
                data: {...data, permissions}
            }).unwrap();
            dispatch(openSnackbar({message: "External updated successfully", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to update external", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }

    };

    return (
        <>
            <Box component="form">
                <Typography variant="h5" sx={{mt: 1}}>
                    Edit External
                </Typography>
                <Typography variant="h6" sx={{mt: 1}}>
                    External Contact Data
                </Typography>
                <Stack direction="row" spacing={0}>
                    <TextField
                        required sx={{mt: 1}} disabled
                        id="inviteeFirstName"
                        name="inviteeFirstName"
                        label="First Name"
                        placeholder="First Name of External"
                        {...register('inviteeFirstName')}
                        error={!!errors.inviteeFirstName}
                    />
                    <Typography variant="inherit" color="error">
                        {errors.inviteeFirstName?.message}
                    </Typography>
                    <TextField
                        required sx={{mt: 1, ml: 1}} disabled
                        id="inviteeLastName"
                        name="inviteeLastName"
                        label="Last Name"
                        placeholder="Last Name of External"
                        {...register('inviteeLastName')}
                        error={!!errors.inviteeLastName}
                    />
                    <Typography variant="inherit" color="error">
                        {errors.inviteeLastName?.message}
                    </Typography>
                </Stack>
                <TextField
                    required sx={{mt: 1}} fullWidth disabled
                    id="inviteeEmail"
                    name="inviteeEmail"
                    label="Email"
                    placeholder="Email of External"
                    {...register('inviteeEmail')}
                    error={!!errors.inviteeEmail}
                />
                <Typography variant="inherit" color="error">
                    {errors.inviteeEmail?.message}
                </Typography>

                <EditPermissions permissions={permissions} setPermissions={setPermissions}/>


                <Typography variant="inherit" color="error">
                    {errors.permissionNote?.message}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mt: 1,
                    cursor: 'pointer'
                }}>
                    <Typography variant="h6" component="span" onClick={handlePermissionNoteCollapsedChange}
                                color={!!errors.permissionNote ? "error" : "primary"}>
                        Permission setting explanation
                    </Typography>
                    <IconButton color="primary" aria-label="expand question" component="span"
                                onClick={handlePermissionNoteCollapsedChange}>
                        {permissionNoteCollapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </Box>
                <Collapse in={permissionNoteCollapsed}>
                    <TextField
                        sx={{mt: 1}} required
                        fullWidth
                        id="permissionNote"
                        name="permissionNote"
                        multiline
                        rows={4}
                        label="Permission Note"
                        {...register('permissionNote')}
                        error={!!errors.permissionNote}
                    />

                </Collapse>


                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mt: 1,
                    cursor: 'pointer'
                }}>
                    <Typography variant="h6" component="span" onClick={handleNoteCollapsedChange}>
                        Invitation note
                    </Typography>
                    <IconButton color="primary" aria-label="expand question" component="span"
                                onClick={handleNoteCollapsedChange}>
                        {noteCollapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </Box>
                <Collapse in={noteCollapsed}>
                    <TextField
                        sx={{mt: 1}}
                        fullWidth disabled
                        id="note"
                        name="note"
                        multiline
                        rows={4}
                        label="Note"
                        {...register('note')}
                    />

                </Collapse>

            </Box>

            <LoadingButton
                fullWidth
                loading={sending}
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
            >
                Save Changes
            </LoadingButton>
        </>

    );


}
