import CircularProgress from "@mui/material/CircularProgress";
import theme from "../../theme";
import Chip from "@mui/material/Chip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Arrow from "./Arrow";

function StatusHelp({onClose, open, label, progress}) {

    const widthh = (600 - 48) / 5;

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle sx={{px: 3}}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}
                >
                    <Stack>
                        <Typography align="left" variant="h5" sx={{color: 'black'}}>Status Explanation</Typography>
                        <Typography align="left" variant="body2" sx={{color: 'black'}}>This is an explanation of the
                            grievance handling
                            process and the status you can see on the top left.</Typography>
                    </Stack>
                    <Chip color="primary" variant="outlined" label={label} deleteIcon={<InfoOutlinedIcon/>}
                          icon={<CircularProgress variant="determinate" value={progress} size={18} thickness={22}
                                                  style={{
                                                      borderRadius: '50%',
                                                      boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`
                                                  }}/>}/>
                </Stack>
                <Stack direction="row" spacing={0} width={"100%"}>
                    <Arrow label="Received" first widthh={widthh}/>
                    <Arrow label="Admission" widthh={widthh}/>
                    <Arrow label="Investigating" widthh={widthh}/>
                    <Arrow label="CAP" widthh={widthh}/>
                    <Arrow label="Closed" last widthh={widthh}/>
                </Stack>
            </DialogTitle>

            <Typography align="left" variant="h5" sx={{color: 'black', px: 3}}>
                The Complaint Handling Process
            </Typography>

            <Typography align="left" variant="body2" sx={{color: 'black', px: 3, pt: 2}}>
                This is an explanation of the grievance handling process and the status you can see on the top.
            </Typography>

            <Typography align="left" variant="h6" sx={{px: 3, pt: 2}}>
                Received
            </Typography>

            <Typography align="left" variant="body2" sx={{color: 'black', px: 3,}}>
                Upon receiving the complaint, no immediate action is required at this stage. The complainant receives an
                automatic acknowledgment of receipt.
            </Typography>

            <Typography align="left" variant="h6" sx={{px: 3, pt: 2}}>
                Admission
            </Typography>

            <Typography align="left" variant="body2" sx={{color: 'black', px: 3,}}>
                In the admission step, the second stage involves collecting information. In the "Conversation" tab, ask
                additional questions to the complainant if necessary. In the "Facts" tab, categorize the details,
                provide a summary, and assess the severity. It is crucial to either accept or decline the complaint
                based on eligibility and project scope. If declining, send a personalized message to the complainant
                explaining the decision; otherwise, an automatic message is sent.
            </Typography>

            <Typography align="left" variant="h6" sx={{px: 3, pt: 2}}>
                Investigation
            </Typography>

            <Typography align="left" variant="body2" sx={{color: 'black', px: 3,}}>
                In the "Investigation" tab, gather opinions and thoughts about the issue. Record your own assessments,
                especially when working with a team, or include assessments and investigations by others. You can invite
                them in the "Externals" tab or manually input their thoughts from emails or phone calls. Pose questions
                such as: Is this a one-time or repeated problem? Which stakeholders need to be involved in the
                remediation? Is it a legal or action-oriented issue? Prepare for the next step: remediating the
                grievance with corrective actions. The complainant won't receive an automatic update here, but you can
                ask them more questions if needed in the "Conversation" tab.
            </Typography>

            <Typography align="left" variant="h6" sx={{px: 3, pt: 2}}>
                CAP
            </Typography>

            <Typography align="left" variant="body2" sx={{color: 'black', px: 3,}}>
                In the CAP tab, you can plan and carry out corrective remedial actions, also known as a CAP (Corrective
                Action Plan). Tasks can be assigned different priorities, timelines, and due dates. You can view them as
                a list or in tile view/KANBAN to work on the CAP. Delegate and collaborate with your team and
                stakeholders. Don't forget to seek the complainant's approval and feedback for the corrective actions by
                documenting them in the "Conversation" tab. Upload evidence or request proof to confirm that remediation
                has occurred (documents, delivery notes, invoices, images, etc.).
            </Typography>

            <Typography align="left" variant="h6" sx={{px: 3, pt: 2}}>
                Closed
            </Typography>

            <Typography align="left" variant="body2" sx={{color: 'black', px: 3,}}>
                You can close the CAP even if there are tasks left unfinished, and you always have the option to reopen
                it. When the CAP is closed, the case is considered closed, but you can reopen it at any time.
            </Typography>


        </Dialog>
    );

}

StatusHelp.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
};
export default function ComplaintTimeline({complaint}) {

    function isComplaintClosed() {
        if (!complaint.status) return false;
        switch (complaint.status) {
            case "DONE" :
            case "CLOSED" :
            case "CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION" :
            case "CLOSED_NOT_PLAUSIBLE" :
            case "CLOSED_REASON_NOT_ELIGIBLE" :
                return true;
            default :
                return false;

        }
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function getChip(label, progress) {
        return <><Chip color="primary" variant="outlined" label={label} deleteIcon={<InfoOutlinedIcon/>}
                       onDelete={handleClickOpen}
                       icon={<CircularProgress variant="determinate" value={progress} size={18} thickness={22}
                                               style={{
                                                   borderRadius: '50%',
                                                   boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`
                                               }}/>}
        ></Chip>
            <StatusHelp
                open={open}
                onClose={handleClose}
                label={label}
                progress={progress}
            /></>;
    }

    if (complaint.status === "NEW")
        return getChip("1/5 | Received", 20);
    if (complaint.status === "OPEN")
        return getChip("2/5 | Admission", 40);
    if (complaint.status === "UNDER_INVESTIGATION")
        return getChip("3/5 | Investigating", 60);
    if (complaint.status === "CAP")
        return getChip("4/5 | CAP", 80);
    if (isComplaintClosed())
        return getChip("5/5 | Closed", 100);

}




