import EditRoundedIcon from "@mui/icons-material/EditRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import React from "react";
import {countries} from "../countries/countries";

export const statusLabel = (status) => {
    switch (status) {
        case "NEW" :
            return "Incoming";
        case "OPEN" :
            return "Open";
        case "UNDER_INVESTIGATION" :
            return "Under Investigation";
        case "DONE":
            return "Closed Unsuccessfully";
        case "CLOSED":
            return "Closed Successfully";
        case "CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION":
            return "Closed - Complainant not reachable for clarification";
        case "CLOSED_NOT_PLAUSIBLE":
            return "Closed - Complaint is not plausible";
        case "CLOSED_REASON_NOT_ELIGIBLE":
            return "Closed - Complaint reason not eligible";
        case "CAP":
            return "CAP";
        default:
            return status

    }
};

export const statusIcon = (status) => {
    switch (status) {
        case "NEW" :
            return <EditRoundedIcon/>;
        case "OPEN" :
            return <InfoRoundedIcon/>;
        case "UNDER_INVESTIGATION" :
            return <CachedRoundedIcon/>;
        case "CAP" :
            return <CachedRoundedIcon/>;
        case "DONE":
        case "CLOSED":
        case "CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION":
        case "CLOSED_NOT_PLAUSIBLE":
        case "CLOSED_REASON_NOT_ELIGIBLE":
            return <CheckRoundedIcon/>;
        default:
            return <InfoRoundedIcon/>;

    }
}

export const permissionLabel = (permission) => {
    switch (permission) {
        case "READ_ORIGINAL_COMPLAINT_MESSAGE" :
            return "Read Original Complaint Message";
        case "READ_LOCATION" :
            return "Read Location";
        case "READ_DATE_AND_TIME" :
            return "Read Date and Time";
        case "READ_SUMMARY" :
            return "Read Summary";
        case "READ_SEVERITY" :
            return "Read Severity";
        case "READ_CATEGORY" :
            return "Read Category";
        case "READ_STATUS" :
            return "Read Status";
        case "READ_TITLE" :
            return "Read Title";
        case "READ_ALL_INVESTIGATION_ITEMS":
            return "Read All Investigation Items";
        case "READ_OWN_INVESTIGATION_ITEMS":
            return "Read Own Investigation Items";
        case "WRITE_INVESTIGATION_COMMENTS":
            return "Write Investigation Comments";
        case "READ_COMPLAINANT_CONVERSATION":
            return "Read Complainant Conversation";
        case "WRITE_COMPLAINANT_CONVERSATION":
            return "Write Complainant Conversation";
        case "READ_CAP":
            return "Read CAP";
        case "WRITE_CAP":
            return "Write CAP";
        case "WRITE_CAP_COMMENTS":
            return "Write CAP Comments";
        default:
            return permission;

    }
};

export const severityLabel = (label) => {
    switch (label) {
        case "LOW" :
            return "Low";
        case "MEDIUM" :
            return "Medium";
        case "HIGH" :
            return "High";
        default :
            return "";
    }
};

export function capItemStatuslabel(status) {
    if (status === "IN_CREATION") return "In Creation";
    if (status === "IN_REMEDIATION") return "In Remediation";
    if (status === "DONE") return "Unsuccessfully closed";
    if (status === "CLOSED") return "Successfully closed";
    return status;
}

export function brandLabel(brand, brands) {
    const brandLabel = brands.find((b) => b.id === brand);
    if (brandLabel) return brandLabel.shortName;
    return "";
}

export function roleLabel(role) {
    return role
        .replace("ROLE_", "")
        .replace("PERMISSION_", "")
        .replace(/_/g, " ")
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function countryLabel(country) {
    return countries[country];
}
