export const checkInSteps = [
    {
        stepName: "welcome",
        title: "Worker Check In",
        text: "How old are you?",
        field: {
            name: "age",
            type: "number",
            label: "Age",
            submit: {
                label: "Send", icon: "SendIcon", goto: "timeInFactory"
            }
        },
    }, {
        stepName: "timeInFactory",
        previousStep: "welcome",
        text: "In which year did you start to work at this factory?",
        field: {
            name: "yearWorkStartInFactory",
            type: "number",
            label: "Year you started to work in Factory",
            submit: {
                label: "Send", icon: "SendIcon", goto: "solving"
            }
        },
    }, {
        stepName: "solving",
        previousStep: "timeInFactory",
        text: "How are you handling concerns relating to your workplace?",
        field: {
            name: "solving",
            type: "text",
            label: "Describe how you handle concerns",
            submit: {
                label: "Send", icon: "SendIcon", goto: "knowledge"
            }
        },
    }, {
        stepName: "knowledge",
        previousStep: "solving",
        text: "Do you know the atlat chat?",
        buttons: [
            {label: "Yes", goto: "training", update: {field: "knowledgeOfAtlatChat", value: "true"}},
            {label: "No", goto: "atlatChatExplanation", update: {field: "knowledgeOfAtlatChat", value: "false"}},]
    }, {
        stepName: "atlatChatExplanation",
        previousStep: "solving",
        text: "The atlat chat helps you to resolve concerns relating to your work and workplace.",
        buttons: [{label: "OK", goto: "training"}]
    }, {
        stepName: "training",
        previousStep: "knowledge",
        text: "Did you have training about the atlat chat?",
        buttons: [
            {label: "Yes", goto: "access", update: {field: "atlatChatTrainingReceived", value: "true"}},
            {label: "No", goto: "access", update: {field: "atlatChatTrainingReceived", value: "false"}},]
    }, {
        stepName: "access",
        previousStep: "training",
        text: "Do you know how to access the atlat chat?",
        buttons: [
            {label: "Yes", goto: "poster", update: {field: "atlatChatAccessKnown", value: "true"}},
            {label: "No", goto: "poster", update: {field: "atlatChatAccessKnown", value: "false"}},]
    }, {
        stepName: "poster",
        previousStep: "access",
        text: "Was the atlat poster always available in the factory?",
        buttons: [
            {label: "Yes", goto: "safety", update: {field: "atlatPosterAvailable", value: "true"}},
            {label: "No", goto: "safety", update: {field: "atlatPosterAvailable", value: "false"}},]
    }, {
        stepName: "safety",
        previousStep: "poster",
        text: "Would you feel safe using the atlat chat?",
        buttons: [
            {label: "Yes", goto: "info", update: {field: "safety", value: "true"}},
            {label: "No", goto: "info", update: {field: "safety", value: "false"}},]
    }, {
        stepName: "safetyExplanation",
        previousStep: "safety",
        text: "Why wouldn't you feel safe using the atlat chat?",
        field: {
            name: "safetyExplanation",
            type: "text",
            label: "Why wouldn't you feel safe using the atlat chat?",
            submit: {
                label: "Send", icon: "SendIcon", goto: "info"
            }
        },
    }, {
        stepName: "info",
        previousStep: "safety",
        text: "Would you need more information on the atlat chat?",
        buttons: [
            {label: "Yes", goto: "infoExplanation", update: {field: "moreInformationNeeded", value: "true"}},
            {label: "No", goto: "case1", update: {field: "moreInformationNeeded", value: "false"}},]
    }, {
        stepName: "infoExplanation",
        previousStep: "info",
        text: "What information do you need?",
        field: {
            name: "infoExplanation",
            type: "text",
            label: "What information do you need?",
            submit: {
                label: "Send", icon: "SendIcon", goto: "case1"
            }
        },
    }, {
        stepName: "case1",
        previousStep: "info",
        title: "Would you use the atlat chat in this case?",
        text: "Your are not being paid.",
        buttons: [
            {label: "Yes", goto: "case2", update: {field: "case1", value: "true"}},
            {label: "No", goto: "case1Explanation", update: {field: "case1", value: "false"}},]
    }, {
        stepName: "case1Explanation",
        previousStep: "case1",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case2"},]
    }, {
        stepName: "case2",
        previousStep: "case1",
        title: "Would you use the atlat chat in this case?",
        text: "Your are being discriminated against.",
        buttons: [
            {label: "Yes", goto: "case3", update: {field: "case2", value: "true"}},
            {label: "No", goto: "case2Explanation", update: {field: "case2", value: "false"}},]
    }, {
        stepName: "case2Explanation",
        previousStep: "case2",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case3"},]
    }, {
        stepName: "case3",
        previousStep: "case2",
        title: "Would you use the atlat chat in this case?",
        text: "Your boss is hitting you.",
        buttons: [
            {label: "Yes", goto: "case4", update: {field: "case3", value: "true"}},
            {label: "No", goto: "case3Explanation", update: {field: "case3", value: "false"}},]
    }, {
        stepName: "case3Explanation",
        previousStep: "case3",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case4"},]
    }, {
        stepName: "case4",
        previousStep: "case3",
        title: "Would you use the atlat chat in this case?",
        text: "The food in the factory is not to your taste.",
        buttons: [
            {label: "Yes", goto: "case4Explanation", update: {field: "case4", value: "true"}},
            {label: "No", goto: "case5", update: {field: "case4", value: "false"}},]
    }, {
        stepName: "case4Explanation",
        previousStep: "case4",
        title: "That was not correct!",
        text: "You could NOT have used the atlat chat because your rights are not infringed in this case.",
        buttons: [{label: "OK", goto: "case5"},]
    }, {
        stepName: "case5",
        previousStep: "case4",
        title: "Would you use the atlat chat in this case?",
        text: "Your boss is touching you inappropriately.",
        buttons: [
            {label: "Yes", goto: "case6", update: {field: "case5", value: "true"}},
            {label: "No", goto: "case5Explanation", update: {field: "case5", value: "false"}},]
    }, {
        stepName: "case5Explanation",
        previousStep: "case5",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case6"},]
    }, {
        stepName: "case6",
        previousStep: "case5",
        title: "Would you use the atlat chat in this case?",
        text: "Your factory puts chemicals in the river.",
        buttons: [
            {label: "Yes", goto: "case7", update: {field: "case6", value: "true"}},
            {label: "No", goto: "case6Explanation", update: {field: "case6", value: "false"}},]
    }, {
        stepName: "case6Explanation",
        previousStep: "case6",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case7"},]
    }, {
        stepName: "case7",
        previousStep: "case6",
        title: "Would you use the atlat chat in this case?",
        text: "You witness children working.",
        buttons: [
            {label: "Yes", goto: "case8", update: {field: "case7", value: "true"}},
            {label: "No", goto: "case7Explanation", update: {field: "case7", value: "false"}},]
    }, {
        stepName: "case7Explanation",
        previousStep: "case7",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case8"},]
    }, {
        stepName: "case8",
        previousStep: "case7",
        title: "Would you use the atlat chat in this case?",
        text: "Your bus was late once.",
        buttons: [
            {label: "Yes", goto: "case8Explanation", update: {field: "case8", value: "true"}},
            {label: "No", goto: "case9", update: {field: "case8", value: "false"}},]
    }, {
        stepName: "case8Explanation",
        previousStep: "case8",
        title: "That was not correct!",
        text: "You could NOT have used the atlat chat because it is not directly work related and no law was broken.",
        buttons: [{label: "OK", goto: "case9"},]
    }, {
        stepName: "case9",
        previousStep: "case8",
        title: "Would you use the atlat chat in this case?",
        text: "You had an argument with your spouse.",
        buttons: [
            {label: "Yes", goto: "case9Explanation", update: {field: "case9", value: "true"}},
            {label: "No", goto: "case10", update: {field: "case9", value: "false"}},]
    }, {
        stepName: "case9Explanation",
        previousStep: "case9",
        title: "That was not correct!",
        text: "You could NOT have used the atlat chat because that problem is not related to your work at the factory.",
        buttons: [{label: "OK", goto: "case10"},]
    }, {
        stepName: "case10",
        previousStep: "case9",
        title: "Would you use the atlat chat in this case?",
        text: "You once weren't allowed to join a protest.",
        buttons: [
            {label: "Yes", goto: "case11", update: {field: "case10", value: "true"}},
            {label: "No", goto: "case10Explanation", update: {field: "case10", value: "false"}},]
    }, {
        stepName: "case10Explanation",
        previousStep: "case10",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case11"},]
    }, {
        stepName: "case11",
        previousStep: "case10",
        title: "Would you use the atlat chat in this case?",
        text: "You have to work a lot of overtime.",
        buttons: [
            {label: "Yes", goto: "case12", update: {field: "case11", value: "true"}},
            {label: "No", goto: "case11Explanation", update: {field: "case11", value: "false"}},]
    }, {
        stepName: "case11Explanation",
        previousStep: "case11",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "case12"},]
    }, {
        stepName: "case12",
        previousStep: "case11",
        title: "Would you use the atlat chat in this case?",
        text: "You witness somebody is forced to work against their will.",
        buttons: [
            {label: "Yes", goto: "internal", update: {field: "case12", value: "true"}},
            {label: "No", goto: "case12Explanation", update: {field: "case12", value: "false"}},]
    }, {
        stepName: "case12Explanation",
        previousStep: "case12",
        title: "That was not correct!",
        text: "You could have used the atlat chat and you would have gotten help with this problem.",
        buttons: [{label: "OK", goto: "internal"},]
    }, {
        stepName: "internal",
        previousStep: "info",
        text: "Did you try to report a problem at work in the past via a different channel?",
        buttons: [
            {label: "Yes", goto: "internalDescription", update: {field: "reportedBefore", value: "true"}},
            {label: "No", goto: "usage", update: {field: "reportedBefore", value: "false"}},]
    }, {
        stepName: "internalDescription",
        previousStep: "internal",
        text: "What happened when you reported a problem at work?",
        field: {
            name: "internalDescription",
            type: "text",
            label: "What happened when you reported a problem at work?",
            submit: {
                label: "Send", icon: "SendIcon", goto: "usage"
            }
        },
    }, {
        stepName: "usage",
        previousStep: "internal",
        text: "Did you try to report a problem at work in the past to atlat?",
        buttons: [
            {label: "Yes", goto: "usageDescription", update: {field: "usage", value: "true"}},
            {label: "No", goto: "promoter", update: {field: "usage", value: "false"}},]
    }, {
        stepName: "usageDescription",
        previousStep: "usage",
        text: "What happened when you reported a problem to atlat?",
        field: {
            name: "usageDescription",
            type: "text",
            label: "What happened when you reported a problem to atlat?",
            submit: {
                label: "Send", icon: "SendIcon", goto: "promoter"
            }
        },
    }, {
        stepName: "promoter",
        previousStep: "usage",
        text: "Would you recommend the atlat chat to your colleagues?",
        buttons: [
            {label: "Yes!", goto: "promoterExplanation", update: {field: "promoter", value: "5"}},
            {label: "Ok", goto: "promoterExplanation", update: {field: "promoter", value: "4"}},
            {label: "Maybe", goto: "promoterExplanation", update: {field: "promoter", value: "3"}},
            {label: "Rather not", goto: "promoterExplanation", update: {field: "promoter", value: "2"}},
            {label: "No", goto: "promoterExplanation", update: {field: "promoter", value: "1"}},]
    }, {
        stepName: "promoterExplanation",
        previousStep: "promoter",
        text: "Can you explain?",
        field: {
            name: "promoterExplanation",
            type: "text",
            label: "Can you explain?",
            submit: {
                label: "Send", icon: "SendIcon", goto: "misc"
            }
        },
    }, {
        stepName: "misc",
        previousStep: "promoter",
        text: "Is there anything else you would like to let us know?",
        field: {
            name: "misc",
            type: "text",
            label: "Anything else you would like to let us know?",
            submit: {
                label: "Send", icon: "SendIcon", goto: "thanks"
            }
        },
        buttons: [{label: "No", goto: "thanks",},]
    }, {
        stepName: "thanks",
        previousStep: "misc",
        text: "Thank you for helping us!",
    },
];