import React from "react";
import {lightTurquoiseShade} from "./common/colors";
import atlat_full_logo from "../assets/atlat_full_logo.png";

import {Link, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

const Footer = () => {

    const {t} = useTranslation("imprint");

    return (<Stack sx={{
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            background: lightTurquoiseShade,
            minHeight: "100px",
            padding: "10px"
        }} component="footer" direction="column">

            <img src={atlat_full_logo} alt="logo" width="160"/>
            <Link href={"/imprint"}>
                {t("imprint.title")}
            </Link>
            <div>
                {t("imprint.complaintManagementMadeWith")} <span role="img"
                                                                 aria-label="green heart">💚</span> {t("imprint.by")}&nbsp;
                <a
                    href="https://atlat.de/">atlat</a>.
            </div>
        </Stack>
    )
}

export default Footer;
