import React, {useEffect} from "react";
import {Document, Font, Page, Text, View} from "@react-pdf/renderer";

import lufga from "../common/fonts/lufga-medium.ttf"
import {LogoFooter} from "../common/pdf/LogoFooter";
import ReactPDFChart from "react-pdf-charts";
import {Cell, Legend, Pie, PieChart} from "recharts";
import {COLORS,} from "../common/colors";
import {ComplaintHeader} from "../common/pdf/ComplaintHeader";

export function CheckinPdfDocument({report, name}) {

    useEffect(() => {
        Font.register({family: 'Lufga', src: lufga});
    }, [])

    const XXXX = ({title, data, numberOfParticipants}) => (
        <>
            <View>
                <Text style={{
                    width: "14cm",
                    textAlign: 'left',
                    fontSize: 20,
                    fontWeight: 700,
                    color: '#1b8591',
                    paddingTop: "0.2cm",
                    paddingLeft: "0.4cm",
                    paddingRight: "0.4cm",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>
                    {title}
                </Text>
            </View>
            <ReactPDFChart debug={false} style={{paddingBottom: "1cm"}}>
                <PieChart displayName={title} width={500} height={300}>
                    <Pie isAnimationActive={false} data={data} dataKey="value" nameKey="name" cx="50%" cy="50%"
                         innerRadius={0}
                         outerRadius={100} labelLine={false}>
                        {data.map((entry, index) =>
                            <Cell key={index} fill={COLORS[index % COLORS.length]}/>)}

                    </Pie>
                    <Legend
                        layout="vertical" verticalAlign="top" align="right" height={200}
                        formatter={(value) => <span style={{color: "black"}}>{value}</span>}
                        payload={
                            data.map(
                                (item, index) => ({
                                    id: item.name,
                                    type: "square",
                                    value: `${item.name} (${item.value} / ${(item.value / numberOfParticipants * 100).toFixed(1)}%)`,
                                    color: COLORS[index % COLORS.length]
                                })
                            )
                        }
                    />
                </PieChart>
            </ReactPDFChart></>
    );

    const OpenQuestion = ({title, data, numberOfParticipants}) => (
        <>
            <View>
                <Text style={{
                    width: "14cm",
                    textAlign: 'left',
                    fontSize: 20,
                    fontWeight: 700,
                    color: '#1b8591',
                    paddingTop: "0.2cm",
                    paddingLeft: "0.4cm",
                    paddingRight: "0.4cm",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>
                    {title}
                </Text>
            </View>
            <View>
                {data.map(xxx => <>
                    <Text style={{
                        width: "14cm",
                        textAlign: 'left',
                        fontFamily: "Helvetica",
                        fontSize: 16,
                        fontWeight: 500,
                        color: '#000',
                        paddingTop: "0.2cm",
                        paddingLeft: "0.4cm",
                        paddingRight: "0.4cm",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {xxx.translation ? xxx.translation : xxx.name}
                    </Text>
                </>)}

            </View>
        </>
    );

    function getXxxx(item, transform, report, XXXX) {
        if (item.type === "openQuestion") return <OpenQuestion key={item.name}
                                                               title={transform(item.name) + " - " + item.question}
                                                               data={item.data}
                                                               numberOfParticipants={report.numberOfParticipants}/>;
        return <XXXX key={item.name}
                     title={transform(item.name) + " - " + item.question}
                     data={item.data}
                     numberOfParticipants={report.numberOfParticipants}/>;
    }

    return (
        <Document>
            <Page size='A4'
                  style={{fontFamily: "Lufga", paddingTop: 16, paddingHorizontal: "0.8cm", paddingBottom: 56}}>
                <ComplaintHeader title={name}
                                 subtitle={`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], {
                                     hour: '2-digit',
                                     minute: '2-digit'
                                 })}`}/>

                <View>
                    <View>
                        <View style={{width: "auto"}}>
                            <View style={{flexDirection: "row", marginTop: "0.6cm"}}>
                                <View style={{width: "100%", border: "0.15cm outset #f5f5f5",}}>
                                    {report.items.map(item =>
                                        getXxxx(item, transform, report, XXXX))}

                                </View>

                            </View>
                        </View>


                    </View>
                </View>
                <LogoFooter/>
            </Page>
        </Document>
    )
        ;

    function transform(str) {
        str = str.charAt(0).toUpperCase() + str.slice(1); // Capitalize the first letter
        str = str.replace(/([0-9A-Z])/g, ' $&'); // Add space between camel casing
        return str
    }

}
