import React, {useMemo} from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {Checkbox} from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from "@mui/material/IconButton";

const SurveyTable = ({schemas}) => {

        const columns = useMemo(
            () => [
                {
                    accessorKey: 'link',
                    header: 'Report',
                    enableEditing: false,
                    enableSorting: false,
                    enableColumnFilter: false,
                    Cell: ({row}) => {
                        let link = window.location.href;
                        link = link.replace("surveys", "report/" + row.original.id)
                        return <IconButton aria-label="open_in_new" target="_blank" href={link}>
                            <OpenInNewIcon/>
                        </IconButton>
                    },
                },
                {
                    accessorKey: 'id',
                    header: 'Id',
                },
                {
                    accessorKey: 'name',
                    header: 'Name',
                },
                {
                    accessorKey: 'brand',
                    header: 'Brand',
                },
                {
                    accessorKey: 'finished',
                    header: 'Closed',
                    Cell: ({row}) => {
                        return <Checkbox checked={row.original.finished}/>
                    },
                },
            ],
            [schemas],
        );

        const table = useMaterialReactTable({
            columns,
            data: schemas,
        });

        return <MaterialReactTable table={table}/>;
    }
;

export default SurveyTable;
