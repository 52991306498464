import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Stack, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useDispatch} from "react-redux";
import {useDeleteInvestigationTodoMutation, useUpdateInvestigationTodoMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function EditInvestigationToDoForm({investigation, setOpen, todo, history}) {

    const dispatch = useDispatch();
    const [updateInvestigationTodo] = useUpdateInvestigationTodoMutation();
    const [deleteInvestigationTodo] = useDeleteInvestigationTodoMutation();
    const [sending, setSending] = useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string(),
    });

    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        reset({id: todo.id, title: todo.title, description: todo.description});
    }, [todo, reset]);

    const onSubmit = async (data) => {
        setSending(true);

        try {
            await updateInvestigationTodo({
                investigationId: investigation.id,
                complaintId: investigation.complaintId,
                data: data
            }).unwrap();
            dispatch(openSnackbar({message: "Successfully updated action item", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to update action item", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }
    };

    async function deleteTodo() {
        setSending(true);

        try {
            await deleteInvestigationTodo({
                investigationId: investigation.id,
                complaintId: investigation.complaintId,
                data: todo
            }).unwrap();
            dispatch(openSnackbar({message: "Successfully deleted action item", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to delete action item", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }
    }

    function buildHistoryItem(item) {
        if (item.type === "CREATE_INVESTIGATION_TODO") {
            return <div key={item.id}>Created
                by {item.user.firstName} {item.user.lastName} {new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}
            </div>;
        }
        if (item.type === "CHECK_INVESTIGATION_TODO") {
            return <div key={item.id}>Checked
                by {item.user.firstName} {item.user.lastName} {new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}
            </div>;
        }
        if (item.type === "UNCHECK_INVESTIGATION_TODO") {
            return <div key={item.id}>Unchecked
                by {item.user.firstName} {item.user.lastName} {new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}
            </div>;
        }
        return null;
    }

    return (
        <>
            <h2>Edit Action Item</h2>
            <TextField
                required sx={{m: 1, width: "93%"}}
                id="title"
                name="title"
                label="Title"
                {...register('title')}
                error={!!errors.title}
            />
            <Typography variant="inherit" color="error">
                {errors.title?.message}
            </Typography>
            <TextField
                sx={{m: 1, width: "93%"}}
                id="description"
                name="description"
                label="Description"
                multiline rows={3}
                {...register('description')}
                error={!!errors.description}
            />
            <Typography variant="inherit" color="error">
                {errors.description?.message}
            </Typography>

            <Typography variant="caption" display="block" gutterBottom>
                {history.map(item => buildHistoryItem(item))}
            </Typography>

            <Stack direction="row" spacing={2}>

                <LoadingButton
                    loading={sending}
                    variant='contained'
                    color='error'
                    onClick={deleteTodo}
                >
                    Delete Action Item
                </LoadingButton>

                <LoadingButton
                    loading={sending}
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </LoadingButton>
            </Stack>
        </>
    );
}
