import * as React from 'react';
import InvestigationControls from "./InvestigationControls";
import InvestigationItem from "./InvestigationItem";
import Typography from "@mui/material/Typography";
import HelpPopUp from "../../../common/HelpPopUp";

export default function InvestigationItems({complaint, investigation, permissions, user, isInternal, setTab}) {

    return (
        <>
            <Typography variant="h6" sx={{mt: 3}}>
                Investigation Items
                <HelpPopUp text={"Add items or let externals add items to document what needs to be investigated."}/>
            </Typography>
            {investigation.items && investigation.items.map((item, index) => {
                return (<InvestigationItem id={index} item={item} key={item.id} permissions={permissions} user={user}
                                           investigation={investigation} complaint={complaint}
                                           isInternal={isInternal}/>)
            })}
            {isInternal &&
                <InvestigationControls complaint={complaint} investigation={investigation} setTab={setTab}/>}
        </>
    );
}
