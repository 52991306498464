import React from "react";
import FAQs from "../components/help/FAQs";
import Support from "../components/help/Support";

const Help = () => {

    return (<>
        <FAQs/>
        <Support/>
    </>);
};

export default Help;
