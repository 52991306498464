import React from "react";

export const isDescriptionEdited = (cap, item) => {
    return cap.history.filter(x => x.capItemId === item.id).filter(x => x.type === "UPDATED_CAP_ITEM")
        .filter(x => x.changes.filter(q => q.affectedField === "DESCRIPTION").length > 0).length > 0;
};

export function createdBy(item, cap, oneline = false) {
    const createdHistoryEntry = cap.history.filter(x => x.capItemId === item.id).find(x => x.type === "ADDED_NEW_CAP_ITEM");
    if (createdHistoryEntry && createdHistoryEntry.user)
        return <>
            created by {createdHistoryEntry.user.firstName} {createdHistoryEntry.user.lastName}
            {createdHistoryEntry.user.department ? " (" + createdHistoryEntry.user.department + ") " : ""}
            {!oneline && <br/>}{oneline && " "}on {new Date(item.cdate).toLocaleDateString()}
        </>;
    return <>
        created on {new Date(item.cdate).toLocaleDateString()}
    </>;
}
