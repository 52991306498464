import React, {useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../components/common/LoadingGIF";
import {LocationsTable} from "../components/admin/LocationsTable";
import Error from "../components/common/Error";
import Box from "@mui/material/Box";
import NotAuthorized from "../components/common/NotAuthorized";
import {useGetBrandsQuery, useGetLocationsQuery} from "../app/apiSlice";


export const Locations = () => {

    const {data: locations, isLoading: locationsLoading, error: locationsError, isSuccess} = useGetLocationsQuery();
    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();

    if (locationsLoading || brandsLoading) return <LoadingGIF/>;
    if (locationsError && locationsError.message.includes('403')) return <NotAuthorized/>;
    if (brandsError && brandsError.message.includes('403')) return <NotAuthorized/>;
    if (locationsError || brandsError) return <Error/>;

    return (
        <Box sx={{mt: 2}}>
            <LocationsTable locations={locations} brands={brands}/>
        </Box>
    );
};

export default withAuthenticationRequired(Locations, {
    onRedirecting: () => <LoadingGIF/>,
});
