import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from "@mui/material/Avatar";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useDispatch} from "react-redux";
import {useUpdateUserEmailSettingsMutation} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";

export const Team = ({team}) => {

    const dispatch = useDispatch();
    const [updateUserEmailSettings] = useUpdateUserEmailSettingsMutation();

    async function getOnClick(e, user, field) {
        try {
            await updateUserEmailSettings({
                ...user,
                emailSettings: {...user.emailSettings, [field]: e.target.checked}
            }).unwrap();
            dispatch(openSnackbar({message: "Successfully updated email settings", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to update email settings", severity: "error"}));
        }
    }

    function Row({user}) {

        const [open, setOpen] = useState(localStorage.getItem(`teams_user_${user.id}_collapsed`) === "true");

        return <>
            <TableRow
                key={user.id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen((prev) => {
                                localStorage.setItem(`teams_user_${user.id}_collapsed`, "" + !prev);
                                return !prev
                            });
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                        <Avatar alt="user picture" src={user.picture}
                                sx={{mr: 5}}/> {user.firstName} {user.lastName}
                    </Stack>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.brandManager ? "Brand Manager" : "Team Member"}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                E-Mail Settings
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={user.emailSettings.receiveEmailWhenCapItemDueDateIsPast}
                                                       sx={{p: 1}}
                                                       onClick={e => getOnClick(e, user, "receiveEmailWhenCapItemDueDateIsPast")}/>}
                                    sx={{mb: 0}}
                                    label="Receive Email when CAP item due date is past"/>

                                <FormControlLabel
                                    control={<Checkbox checked={user.emailSettings.receiveEmailWhenNewComplaintIsReceived}
                                                       sx={{p: 1}}
                                                       onClick={e => getOnClick(e, user, "receiveEmailWhenNewComplaintIsReceived")}/>}
                                    sx={{mb: 0}}
                                    label="Receive Email when new complaint is received"/>

                                <FormControlLabel
                                    control={<Checkbox checked={user.emailSettings.receiveEmailWhenNewMessageFromComplainantIsReceived}
                                                       sx={{p: 1}}
                                                       onClick={e => getOnClick(e, user, "receiveEmailWhenNewMessageFromComplainantIsReceived")}/>}
                                    sx={{mb: 0}}
                                    label="Receive Email when new message from complainant is received"/>

                            </FormGroup>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>;
    }

    return (
        <Box sx={{mt: 2}}>
            <Typography variant="h3" component="h1" gutterBottom>
                {team.brand.shortName} Team Members
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Team Member</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{team.users.map((user) => (<Row key={user.id} user={user}/>))}</TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
