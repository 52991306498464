import React from "react";
import {useRouteMatch} from "react-router-dom";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../../../components/common/LoadingGIF";
import Error from "../../../components/common/Error";
import {BrandForm} from "./BrandForm";
import NotAuthorized from "../../common/NotAuthorized";
import {useGetBrandQuery} from "../../../app/apiSlice";


const Brand = () => {

    const match = useRouteMatch();

    const {data: brand, isLoading: brandLoading, error: brandError} = useGetBrandQuery(match.params.brand);

    if (brandLoading) return <LoadingGIF/>;
    if (brandError && brandError.message && brandError.message.includes('403')) return <NotAuthorized/>;
    if (brandError) return <Error/>;

    return (
        <>
            <BrandForm brand={brand} sx={{mt: 2}}/>
        </>
    );
};

export default withAuthenticationRequired(Brand, {
    onRedirecting: () => <LoadingGIF/>,
});
