import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../../components/common/LoadingGIF";
import {Card, CardActions, Stack} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Badge from '@mui/material/Badge';
import CollapsibleSection from "../../components/common/CollapsibleSection";
import {Question} from "./Question";
import InfoBox from "../../components/common/InfoBox";
import MailIcon from '@mui/icons-material/Mail';
import {useGetComplaintsForExternalQuery} from "../../app/apiSlice";
import Error from "../../components/common/Error";

export const ExternalComplaints = () => {

    const {data: complaints, isLoading: complaintsLoading, error: complaintsError} = useGetComplaintsForExternalQuery();
    const history = useHistory();

    if (complaintsLoading) return <LoadingGIF/>;
    if (complaintsError) return <Error/>;

    if (!complaints || complaints.length === 0) {
        return (
            <Alert variant="outlined" severity="info" sx={{mt: 2}}>
                You are not invited to work on any complaints at the moment.
            </Alert>
        );
    }

    function openQuestions(complaint) {
        if (!complaint.questions) return 0;
        const openQuestions = complaint.questions.filter(function (question) {
            return !question.answer;
        });
        return openQuestions ? openQuestions.length : 0;
    }

    function numberOfOpenQuestions() {
        let result = 0;
        for (let complaint in complaints) {
            result += openQuestions(complaint);
        }
        return result;
    }

    return (
        <>

            <Stack direction="row" justifyContent="space-between" sx={{p: 3}}>
                <div>
                    <Typography variant="h4">
                        Your Complaint Invitations
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="div" sx={{pt: 3}}>
                        Have a look at the complaints you have been invited to work on.<br/>
                        Answer open questions, upload files, or view complaint details, if needed.
                    </Typography>
                </div>
                <InfoBox title="All invitations" number={complaints.length} minWidth={100}
                         info={`You have ${numberOfOpenQuestions()} open questions.`} variant="red"/>
            </Stack>


            {complaints.map((complaint) => {
                const invitingUser = complaint.invitation.invitingUser;
                const title = "Complaint Invitation to C" + complaint.id + " \"" + (complaint.title || `Complaint C${complaint.id}`) + `" by ${invitingUser.firstName} ${invitingUser.lastName} from ${new Date(complaint.invitation.cdate).toLocaleDateString()}`;
                const badgeContent = openQuestions(complaint);
                return (
                    <Card key={complaint.id} style={{margin: 15, padding: 10}}>
                        <CardHeader title={title}/>
                        <CardContent>
                            <Typography variant="h6">
                                {`Note from ${new Date(complaint.invitation.cdate).toLocaleDateString()} at ${new Date(complaint.invitation.cdate).toLocaleTimeString()}`}
                            </Typography>
                            {complaint.invitation.note}

                            <CollapsibleSection
                                title={<>Questions for you <Badge badgeContent={badgeContent} color="primary"> <MailIcon
                                    color={"action"}/> </Badge></>}>
                                {complaint.questions && complaint.questions.map((question) => {
                                    return <Question question={question} complaintId={complaint.id} key={question.id}/>
                                })}
                            </CollapsibleSection>


                        </CardContent>
                        <CardActions style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button onClick={() => history.push(`complaints/${complaint.id}`)}>Go to
                                complaint</Button>
                        </CardActions>
                    </Card>
                );
            })}


        </>
    );
};

export default withAuthenticationRequired(ExternalComplaints, {
    onRedirecting: () => <LoadingGIF/>,
});
