import * as React from 'react';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import RejectComplaintForm from "./RejectComplaintForm";
import Button from "@mui/material/Button";

export default function RejectComplaint({complaint, permissions}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button variant="outlined" color="warning" edge="end" aria-label="Close complaint"
                    onClick={handleOpen}
                    disabled={!permissions.has("UPDATE_COMPLAINT_STATUS")}>
                Close complaint
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 450,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <RejectComplaintForm complaint={complaint} setOpen={setOpen}/>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}