import React, {useEffect} from "react";
import {Document, Font, Page, Text, View} from "@react-pdf/renderer";
import {severityLabel, statusLabel} from "../../dashboard/labels";
import {categoryLabel} from "../../dashboard/categories";
import lufga from "../fonts/lufga-medium.ttf"
import {LogoFooter} from "./LogoFooter";
import {ComplaintHeader} from "./ComplaintHeader";
import {dateAndTime} from "../dates";


export function PdfDocument({complaint, includeOriginalComplaintMessage, note, user}) {

    useEffect(() => {
        Font.register({family: 'Lufga', src: lufga});
    }, [])


    if (!complaint) return;

    function bigBoxRow(left, right) {
        return <View style={{flexDirection: "row", marginTop: "0.3cm"}}>
            <View style={{width: "35%",}}>
                <Text style={{fontSize: 14, color: '#1b8591'}}>
                    {left + ":"}
                </Text>
            </View>
            <View style={{width: "65%",}}>
                <Text style={{fontSize: 14}}>
                    {right}
                </Text>
            </View>
        </View>;
    }

    function smallBoxRow(left, right) {
        return <View style={{flexDirection: "row", marginTop: "0.2cm"}}>
            <View style={{width: "35%",}}>
                <Text style={{fontSize: 12, color: '#1b8591'}}>
                    {left + ":"}
                </Text>
            </View>
            <View style={{width: "65%",}}>
                <Text style={{fontSize: 12}}>
                    {right}
                </Text>
            </View>
        </View>;
    }

    function locationLabel(complaint) {
        if (complaint.location && complaint.location.name) return complaint.location.name;
        return complaint.locationNameGivenByComplainant;
    }

    return (
        <Document>
            <Page style={{fontFamily: "Lufga", paddingTop: 16, paddingHorizontal: "0.8cm", paddingBottom: 56}}>
                <ComplaintHeader title={`C${complaint.id}: ${complaint.title ? complaint.title : "New Complaint"}`}
                                 subtitle={`Complaint Overview\ncreated ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], {
                                     hour: '2-digit',
                                     minute: '2-digit'
                                 })}`}/>
                <View>
                    <View>
                        <View style={{display: "table", width: "auto"}}>
                            <View style={{flexDirection: "row", marginTop: "0.6cm"}}>
                                <View style={{width: "50%", border: "0.15cm outset #f5f5f5",}}>
                                    <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: '#1b8591'}}>
                                        {`Note created by ${user.firstName} ${user.lastName} on ${new Date().toLocaleDateString()}`}
                                    </Text>
                                    <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: 'black'}}>
                                        {note}
                                    </Text>
                                </View>
                                <View style={{flexDirection: "column", width: "50%", marginLeft: "0.4cm"}}>
                                    <View style={{border: "0.15cm outset #f5f5f5", padding: "0.3cm 0.5cm 0.5cm 0.5cm"}}>
                                        {bigBoxRow("Severity", severityLabel(complaint.severity))}
                                        {bigBoxRow("Categories", categoryLabel(complaint.categories))}
                                        {bigBoxRow("Factory", locationLabel(complaint))}
                                    </View>
                                    <View style={{
                                        border: "0.15cm outset #f5f5f5",
                                        padding: "0.3cm 0.5cm 0.5cm 0.5cm",
                                        marginTop: "0.2cm"
                                    }}>
                                        {smallBoxRow("Status", statusLabel(complaint.status))}
                                        {smallBoxRow("Complaint Date", dateAndTime(complaint.cdate))}
                                        {smallBoxRow("Incident Date", dateAndTime(complaint.incidentDate))}
                                    </View>
                                </View>
                            </View>
                        </View>
                        {includeOriginalComplaintMessage && <>
                            <View style={{display: "table", width: "auto"}}>
                                <View style={{flexDirection: "row", marginTop: "0.4cm"}}>
                                    <View style={{border: "0.15cm outset #f5f5f5", width: "100%"}}>
                                        <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: '#1b8591'}}>
                                            Initial claim
                                        </Text>
                                        <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: 'black'}}>
                                            {complaint.text}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{display: "table", width: "auto"}}>
                                <View style={{flexDirection: "row", marginTop: "0.4cm"}}>
                                    <View style={{border: "0.15cm outset #f5f5f5", width: "100%"}}>
                                        <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: '#1b8591'}}>
                                            Translated message
                                        </Text>
                                        <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: 'black'}}>
                                            {complaint.translatedDescription}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </>}
                        {complaint.summary &&
                            <View style={{display: "table", width: "auto"}}>
                                <View style={{flexDirection: "row", marginTop: "0.4cm"}}>
                                    <View style={{border: "0.15cm outset #f5f5f5", width: "100%"}}>
                                        <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: '#1b8591'}}>
                                            Complaint Summary
                                        </Text>
                                        <Text style={{textAlign: "left", margin: 10, fontSize: 12, color: 'black'}}>
                                            {complaint.summary}
                                        </Text>
                                    </View>
                                </View>
                            </View>}

                        {/*{row("Investigator:", "Karl Heinz TODO")}*/}
                        {/*// Todo insert original image from complaint*/}

                    </View>
                </View>
                <LogoFooter/>
                <LogoFooter/>
            </Page>
        </Document>
    );

}
