import * as React from 'react';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {useDeleteFileFromCapItemMutation, useDeleteFileFromInvestigationItemMutation} from "../../../app/apiSlice";
import {openSnackbar} from "../../../app/snackbarSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function DeleteFile({file, capId, complaintId, itemId, investigationId}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();

    const [deleteFileFromInvestigationItem] = useDeleteFileFromInvestigationItemMutation();
    const [deleteFileFromCapItem] = useDeleteFileFromCapItemMutation();

    async function handleDelete() {
        if (capId) {
            try {
                await deleteFileFromCapItem({fileId: file.id, complaintId, capId, capItemId: itemId}).unwrap();
                dispatch(openSnackbar({message: "File deleted successfully", severity: "success"}));
                setOpen(false);
            } catch (err) {
                dispatch(openSnackbar({message: "Failed to delete file", severity: "error"}));
                setOpen(false);
            }
        }
        if (investigationId) {
            try {
                await deleteFileFromInvestigationItem({
                    fileId: file.id,
                    complaintId,
                    investigationId,
                    investigationItemId: itemId
                }).unwrap();
                dispatch(openSnackbar({message: "File deleted successfully", severity: "success"}));
                setOpen(false);
            } catch (err) {
                dispatch(openSnackbar({message: "Failed to delete file", severity: "error"}));
                setOpen(false);
            }
        }
    }

    return (
        <div>

            <IconButton color="primary" aria-label="delete file" onClick={handleOpen}>
                <DeleteIcon/>
            </IconButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {`Are you sure you want to delete ${file.name}?`}
                        <Stack direction="row" justifyContent="end" sx={{mt: 2}}>
                            <Button variant="contained" color="primary" size={"large"} onClick={handleClose}
                                    startIcon={<CancelIcon/>}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="warning" size={"large"} onClick={handleDelete}
                                    sx={{ml: 2}}
                                    startIcon={<DeleteIcon/>}>
                                Delete
                            </Button>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}