import React, {useEffect, useState} from 'react';
import {Font, pdf} from "@react-pdf/renderer";
import {PdfDocument} from "../../../common/pdf/PdfDocument";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import lufga from "../../../common/fonts/lufga-medium.ttf";
import {useDispatch} from "react-redux";
import {useSendComplaintPDFEmailMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function SendComplaintPDFWithEmailButton({complaint, user}) {

    useEffect(() => {
        Font.register({family: 'Lufga', src: lufga});
    }, [])

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [sendComplaintPDFEmail] = useSendComplaintPDFEmailMutation();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSubmit = async (data) => {
        setLoading(true);
        const element = <PdfDocument complaint={complaint} user={user}
                                     includeOriginalComplaintMessage={data.includeOriginalComplaintMessage}
                                     note={data.note}/>;
        const myPdf = pdf();
        myPdf.updateContainer(element);
        const blob = await myPdf.toBlob();
        const file = new File([blob], `complaint_${complaint.id}.pdf`, {lastModified: (new Date()).getTime()});

        function callback() {
            setLoading(false)
            reset()
            setOpen(false)
        }

        const bodyFormData = new FormData();
        bodyFormData.append('sendEmailRequest', new Blob([JSON.stringify(data)],
            {type: 'application/json'}), "sendEmailRequest.json");
        bodyFormData.append('file', file);

        try {
            await sendComplaintPDFEmail(bodyFormData).unwrap();
            dispatch(openSnackbar({message: "Email sent successfully", severity: "success"}));
            callback();
        } catch (err) {
            dispatch(openSnackbar({message: "Sending email failed", severity: "error"}));
            callback();
        }

    }

    const validationSchema = Yup.object().shape({
        includeOriginalComplaintMessage: Yup.boolean(),
        note: Yup.string(),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        subject: Yup.string().required('Subject is required'),
        text: Yup.string().required('Text is required'),
        senderInCC: Yup.boolean(),
    });

    const {register, control, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(validationSchema)
    });

    return (
        <>
            <LoadingButton variant="contained" color="primary" size={"large"} onClick={handleOpen} loading={loading}>
                Send Complaint PDF with Email
            </LoadingButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component="form">
                        <FormGroup sx={{m: 1, width: "97%"}}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        control={control}
                                        name="includeOriginalComplaintMessage"
                                        id="includeOriginalComplaintMessage"
                                        defaultValue="false"
                                        inputRef={register()}
                                        render={({field: {onChange}}) => (
                                            <Checkbox
                                                color="primary"
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        )}
                                    />
                                }
                                label={`Include Original Message by Complainant`}/>
                        </FormGroup>


                        {/*<FormGroup>*/}
                        {/*    <FormControlLabel control={<Checkbox checked={includeOriginalComplaintMessage}*/}
                        {/*                                         onClick={() => setIncludeOriginalComplaintMessage(!includeOriginalComplaintMessage)}/>}*/}
                        {/*                      label="Include Original Message by Complainant"/>*/}
                        {/*</FormGroup>*/}

                        <TextField
                            id="note"
                            name="note"
                            label="Note included in PDF"
                            style={{marginTop: 10, marginBottom: 10}}
                            minRows={4} multiline
                            fullWidth={true}
                            variant="outlined"
                            {...register('note')}
                            error={!!errors.note}
                        />

                        <TextField
                            required id="email"
                            name="email"
                            label="Email Address"
                            style={{marginTop: 10, marginBottom: 10}}
                            fullWidth={true}
                            variant="outlined"
                            {...register('email')}
                            error={!!errors.email}
                        />
                        <Typography variant="inherit" color="error">
                            {errors.email?.message}
                        </Typography>

                        <FormGroup sx={{m: 1, width: "97%"}}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        control={control}
                                        name="senderInCC"
                                        id="senderInCC"
                                        defaultValue="false"
                                        inputRef={register()}
                                        render={({field: {onChange}}) => (
                                            <Checkbox
                                                color="primary"
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        )}
                                    />
                                }
                                label={`Send copy of invitation mail to ${user.email}`}/>
                        </FormGroup>

                        <TextField
                            required id="subject"
                            name="subject"
                            label="Email Subject"
                            style={{marginTop: 10, marginBottom: 10}}
                            fullWidth={true}
                            variant="outlined"
                            {...register('subject')}
                            error={!!errors.subject}
                        />
                        <Typography variant="inherit" color="error">
                            {errors.subject?.message}
                        </Typography>

                        <TextField
                            required id="text"
                            name="text"
                            label="Email Message"
                            style={{marginTop: 10, marginBottom: 10}}
                            minRows={4} multiline
                            fullWidth={true}
                            variant="outlined"
                            {...register('text')}
                            error={!!errors.text}
                        />
                        <Typography variant="inherit" color="error">
                            {errors.text?.message}
                        </Typography>

                        <LoadingButton
                            fullWidth
                            loading={loading}
                            variant='contained'
                            color='primary'
                            onClick={handleSubmit(onSubmit)}
                        >
                            Send Email with Complaint PDF attached
                        </LoadingButton>

                    </Box>
                </Fade>
            </Modal>


        </>
    );

}
