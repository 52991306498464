import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useDispatch} from "react-redux";
import {useAddTodoToInvestigationMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

export default function AddInvestigationToDoForm({investigation, setOpen}) {

    const dispatch = useDispatch();
    const [addTodoToInvestigation] = useAddTodoToInvestigationMutation();
    const [sending, setSending] = useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string(),
    });

    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {
        setSending(true);

        try {
            await addTodoToInvestigation({
                investigationId: investigation.id,
                complaintId: investigation.complaintId,
                data: data
            }).unwrap();
            dispatch(openSnackbar({message: "Successfully added action item", severity: "success"}));
            setSending(false);
            reset();
            setOpen(false);
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to add action item", severity: "error"}));
            setSending(false);
            reset();
            setOpen(false);
        }
    };

    return (
        <>
            <Typography variant="h6">Add Action Item to Investigation</Typography>
            <TextField
                required sx={{m: 1, width: "93%"}}
                id="title"
                name="title"
                label="Title"
                {...register('title')}
                error={!!errors.title}
            />
            <Typography variant="inherit" color="error">
                {errors.title?.message}
            </Typography>
            <TextField
                sx={{m: 1, width: "93%"}}
                id="description"
                name="description"
                label="Description"
                {...register('description')}
                error={!!errors.description}
            />
            <Typography variant="inherit" color="error">
                {errors.description?.message}
            </Typography>

            <LoadingButton
                fullWidth
                loading={sending}
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
            >
                Add Action Item
            </LoadingButton>
        </>
    );
}
