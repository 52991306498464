import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import AllMessages from "../../../../views/AllMessages";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {languageName} from "../../../../utils/languageNames";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {useAuth0} from "@auth0/auth0-react";
import Alert from '@mui/material/Alert';
import {setTabInfo} from "../../../../utils/tabs";
import {useDispatch} from "react-redux";
import {useAddMessageToComplaintByHandlerMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import theme from "../../../theme";

export default function ComplainantConversation({complaint, permissions}) {
    setTabInfo("conversation", complaint);
    const {user} = useAuth0();
    const dispatch = useDispatch();

    const [reply, setReply] = useState("");
    const [addMessageToComplaintByHandler] = useAddMessageToComplaintByHandlerMutation();

    async function handleClick() {
        try {
            await addMessageToComplaintByHandler({complaintId: complaint.id, reply: reply}).unwrap()
            dispatch(openSnackbar({message: "Message sent successfully", severity: "success"}));
            setReply("");
        } catch (err) {
            dispatch(openSnackbar({message: "Message sending failed", severity: "error"}));
        }
    }

    if (!complaint.messages) {
        return <Alert severity="info">
            You are not allowed to see messages.
        </Alert>
    }

    return (
        <>
            {permissions.has("READ_COMPLAINANT_CONVERSATION") && <AllMessages data={complaint}/>}

            {permissions.has("ADD_MESSAGE_TO_COMPLAINT") && <Paper sx={{marginTop: theme.spacing(2),
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',}}>
                <Grid container spacing={3} direction="row" justifyContent="space-around" alignItems="stretch">
                    <Grid item xs={8} sm={10}>
                        <Typography variant="body2" component={'span'} gutterBottom>
                            <TextField id="standard-basic" label="Your reply" value={reply} multiline
                                       rows={4} fullWidth
                                       onInput={e => setReply(e.target.value)}/>
                        </Typography>
                        <Typography variant="caption" component={'span'} display="block" gutterBottom>
                            Will be automatically translated
                            to {languageName(complaint.originalTextLanguage)} for the
                            complainant.
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button
                            variant="contained"
                            style={{padding: 10}}
                            endIcon={<Avatar src={user.picture} style={{width: 30, height: 30}}/>}
                            onClick={handleClick}
                        >
                            Reply
                        </Button>
                    </Grid>
                </Grid>
            </Paper>}
        </>

    )
}
