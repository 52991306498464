export function languageName(language) {
    switch (language) {
        case 'en':
            return "English";
        case 'hr':
            return "Croatian";
        case 'sl':
            return "Slovenian";
        case 'hu':
            return "Hungarian";
        case 'pl':
            return "Polish";
        case 'it':
            return "Italian";
        case 'es':
            return "Spanish";
        case 'sw':
            return "Swahili";
        case 'fr':
            return "French";
        case 'vi':
            return "Vietnamese";
        case 'bn':
            return "Bengali";
        case "ur":
            return "Urdu";
        case "hi" :
            return "Hindi";
        case "my" :
            return "Burmese";
        case "ta" :
            return "tamil";
        default :
            return language;
    }
}
