import React, {useMemo, useState} from 'react';
import {MaterialReactTable, useMaterialReactTable,} from 'material-react-table';
import {Box, Button, IconButton, Tooltip,} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    useCreateBrandMutation,
    useDeleteBrandMutation,
    useGetBrandsQuery,
    useUpdateBrandMutation,
} from "../../app/apiSlice";
import {useHistory} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import {brandLabel} from "../dashboard/labels";
import {EditCellMultipleSelect} from "./EditCellMultipleSelect";


const BrandTable = () => {
    const history = useHistory();

    const {
        data: fetchedBrands = [],
        isLoading: isLoadingBrands,
        isFetching: isFetchingBrands,
        error: isLoadingBrandsError
    } = useGetBrandsQuery();

    const [validationErrors, setValidationErrors] = useState({});
    //keep track of rows that have been edited
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Brand ID',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.id,
                    helperText: validationErrors?.id,
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            id: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: 'shortName',
                header: 'Short Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.shortName,
                    helperText: validationErrors?.shortName,
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            shortName: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: 'longName',
                header: 'Long Name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.longName,
                    helperText: validationErrors?.longName,
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            longName: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                Cell: ({renderedCellValue, row}) => {
                    return row.original.subBrands && row.original.subBrands.map((value) => (
                        <Chip size="small" key={value} label={brandLabel(value, fetchedBrands)} sx={{margin: 0.5,}}
                              variant="outlined"/>
                    ))
                },
                Edit: ({cell, column, row, table}) => <EditCellMultipleSelect row={row} column={column} table={table}
                                                                              label="Sub-Brands"
                                                                              items={fetchedBrands}/>,
                accessorKey: 'subBrands',
                header: 'Sub Brands',
                muiEditTextFieldProps: {
                    required: false,
                    error: !!validationErrors?.subBrands,
                    helperText: validationErrors?.subBrands,
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            subBrands: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
        ],
        [validationErrors, fetchedBrands],
    );

    const [createBrand, {isPending: isCreatingBrand},] = useCreateBrandMutation()
    const [updateBrand, {isPending: isUpdatingBrand},] = useUpdateBrandMutation();
    const [deleteBrand, {isPending: isDeletingBrand},] = useDeleteBrandMutation();

    const handleCreateBrand = async ({values, table}) => {
        const newValidationErrors = validateBrand(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        await createBrand(values);
        table.setCreatingRow(null); //exit creating mode
    };

    const handleSaveBrand = async ({values, table, row}) => {
        const newValidationErrors = validateBrand(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        await updateBrand({...row.original, ...values});
        table.setEditingRow(null); //exit editing mode
    };

    const openDeleteConfirmModal = (row) => {
        if (window.confirm('Are you sure you want to delete this brand?')) {
            deleteBrand(row.original);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: fetchedBrands,
        createDisplayMode: 'row', // ('modal', and 'custom' are also available)
        editDisplayMode: 'row', // ('modal', 'row', 'cell', and 'custom' are also
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingBrandsError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateBrand,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveBrand,
        renderRowActions: ({row, table}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit Details">
                    <Button variant="contained"
                            onClick={() => history.push(`/brands/${row.id}`)}>
                        Edit Details
                    </Button>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                    //or you can pass in a row object to set default values with the `createRow` helper function
                    // table.setCreatingRow(
                    //   createRow(table, {
                    //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                    //   }),
                    // );
                }}
            >
                Create New Brand
            </Button>
        ),
        state: {
            isLoading: isLoadingBrands,
            isSaving: isCreatingBrand || isUpdatingBrand || isDeletingBrand,
            showAlertBanner: isLoadingBrandsError,
            showProgressBars: isFetchingBrands,
        },
    });

    return <MaterialReactTable table={table}/>;
};

export default BrandTable;

const validateRequired = (value) => !!value.length;

function validateBrand(brand) {
    if (brand.subBrands === "") brand.subBrands = [];
    return {
        longName: !validateRequired(brand.longName)
            ? 'Long Name is required'
            : '',
        shortName: !validateRequired(brand.shortName) ? 'Short Name is required' : '',
        id: !validateRequired(brand.id) ? 'Id is required' : '',
    };
}
