import React from "react";
import {useRouteMatch} from "react-router-dom";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import VerticalTabs from "../components/dashboard/VerticalTabs";
import LoadingGIF from "../components/common/LoadingGIF";
import Error from "../components/common/Error";
import {determineTab} from "../utils/tabs";
import {
    useGetComplaintQuery,
    useGetMeQuery
} from "../app/apiSlice";

export const HandleComplaint = () => {
    const match = useRouteMatch();
    const {user} = useAuth0();

    const {data: me, isLoading: meLoading, error: meError} = useGetMeQuery();

    const {data: complaint, isLoading: complaintLoading, error: complaintError}
        = useGetComplaintQuery({complaintId: match.params.id, language: user.locale ? user.locale : "en"});

    if (complaintLoading || meLoading) return <LoadingGIF/>;
    if (complaintError || meError) return <Error/>;

    let permissions = extractPermissions(me, complaint);

    return (<VerticalTabs complaint={complaint} tab={determineTab(complaint)} permissions={permissions}
                          statusInfo={complaint.statusInfo}/>);
};

function extractPermissions(me, complaint) {
    let permissions = new Set();
    for (const role of me.roles) {
        for (const permission of role.privileges) {
            permissions.add(permission)
        }
    }

    // TODO this should all come from the backend CS-411

    permissions.add("READ_COMPLAINANT_CONVERSATION");
    if (permissions.has("READ_COMPLAINT")) {
        permissions.add("READ_ORIGINAL_COMPLAINT_MESSAGE")
        permissions.add("READ_LOCATION")
        permissions.add("READ_DATE_AND_TIME")
        permissions.add("READ_CATEGORY")
        permissions.add("READ_SUMMARY")
        permissions.add("READ_SEVERITY")
        permissions.add("READ_STATUS")
        permissions.add("READ_TITLE")
    }
    if (permissions.has("READ_INVESTIGATION")) {
        permissions.add("READ_ALL_INVESTIGATION_ITEMS")
        permissions.add("READ_OWN_INVESTIGATION_ITEMS")
    }
    if (permissions.has("UPDATE_INVESTIGATION")) {
        permissions.add("WRITE_INVESTIGATION_COMMENTS")
    }
    if (permissions.has("UPDATE_CAP")) {
        permissions.add("WRITE_CAP")
        permissions.add("WRITE_CAP_COMMENTS")
    }

    function subBrandMatches(me, complaint) {
        if(!me.subBrands) return false;
        if(!complaint.subBrands) return false;

        return  me.subBrands.filter(value => complaint.subBrands.includes(value)).length > 0;
    }

    if(me.settings?.subBrandMustMatchToEditComplaint && !subBrandMatches(me, complaint)){
        permissions.delete("ADD_MESSAGE_TO_COMPLAINT");
        permissions.delete("UPDATE_COMPLAINT");
        permissions.delete("UPDATE_COMPLAINT_STATUS");
        permissions.delete("UPDATE_COMPLAINT_CATEGORY");
        permissions.delete("UPDATE_COMPLAINT_LOCATION");
        permissions.delete("UPDATE_COMPLAINT_DATE");
        permissions.delete("CREATE_CAP");
        permissions.delete("UPDATE_CAP");
        permissions.delete("DELETE_CAP");
        permissions.delete("UPDATE_COMPLAINT_SUMMARY");
        permissions.delete("UPDATE_COMPLAINT_SEVERITY");
        permissions.delete("UPDATE_COMPLAINT_TITLE");
        permissions.delete("CREATE_INVESTIGATION");
        permissions.delete("UPDATE_INVESTIGATION");
        permissions.delete("WRITE_INVESTIGATION_COMMENTS");
        permissions.delete("WRITE_CAP");
        permissions.delete("WRITE_CAP_COMMENTS");
    }

    return permissions;
}

export default withAuthenticationRequired(HandleComplaint, {
    onRedirecting: () => <LoadingGIF/>,
});
