import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import {Collapse, Stack} from "@mui/material";
import CAPComments from "./CAPComments";
import LoadingGIF from "../../../common/LoadingGIF";
import CAPFiles from "./CAPFiles";
import CAPFacts from "./CAPFacts";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Chip from "@mui/material/Chip";
import CAPItemHistory from "./CAPItemHistory";
import RestoreIcon from '@mui/icons-material/Restore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from "@mui/material/Button";
import CloseCapItem from "./item/CloseCapItem";
import {darkSalmon} from "../../../common/colors";
import {capItemStatuslabel} from "../../labels";
import {useDispatch} from "react-redux";
import {
    useDeleteCAPItemMutation,
    useGetTeamQuery,
    useSaveCAPItemMutation,
    useSaveCAPItemStatusMutation
} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";
import CAPItemPriority from "./CAPItemPriority";
import {createdBy} from "./item/itemFacts";
import EditableText from "./item/EditableText";
import CAPItemDueDate from "./item/CAPItemDueDate";

export default function CAPItem({item, cap, complaint, disabled, permissions, isInternal, user}) {

    const {data: team, isLoading: loading} = useGetTeamQuery();

    const [collapsed, setCollapsed] = useState("true" === localStorage.getItem(`tab_cap_item_${item.id}`));
    const dispatch = useDispatch();

    const [saveCAPItem] = useSaveCAPItemMutation();
    const [deleteCAPItem] = useDeleteCAPItemMutation();
    const [saveCAPItemStatus] = useSaveCAPItemStatusMutation();

    async function handleSaveCAPItem(item) {
        try {
            await saveCAPItem({capId: cap.id, capItem: item}).unwrap()
            dispatch(openSnackbar({message: "CAP Item updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP Item update failed", severity: "error"}));
        }
    }

    async function handleDeleteCAPItem(item) {
        try {
            await deleteCAPItem({capId: cap.id, capItemId: item.id}).unwrap()
            dispatch(openSnackbar({message: "CAP Item deleted successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP Item deletion failed", severity: "error"}));
        }
    }


    if (!permissions.has("READ_CAP")) return null;
    if (loading) return <LoadingGIF/>;

    function getAction() {

        function getAnswerDueChip() {
            if (!item || !item.invitationsToComment) return null;
            const lastOpenInvitation = item.invitationsToComment.filter(entry => !entry.answered && !entry.invitationRevoked)
                .sort((a, b) => (a.date > b.date && 1) || -1).pop();

            if (!lastOpenInvitation) return null;

            return <Chip avatar={<ErrorOutlineIcon style={{fill: darkSalmon}}/>}
                         style={{borderColor: darkSalmon}}
                         label={"Waiting for comment by " + lastOpenInvitation.invitedUser.firstName + " " + lastOpenInvitation.invitedUser.lastName}
                         variant="outlined"/>;
        }

        function getLastUpdateChip() {
            if (!cap || !cap.history || cap.history.length === 0) return null;
            const lastUpdate = cap.history.filter(entry => entry.capItemId === item.id).sort((a, b) => (a.date > b.date && 1) || -1).pop();

            if (!lastUpdate || !lastUpdate.data) return null;

            return <Chip avatar={<RestoreIcon/>}
                         label={"Last update: " + new Date(lastUpdate.date).toLocaleDateString()}
                         variant="outlined"/>;
        }

        return <>
            <Stack direction="row" spacing={1} alignItems="flex-end">
                <Stack spacing={1} alignItems="flex-end">
                    <Stack direction="row" spacing={1}>
                        <CAPItemDueDate item={item} disabled={disabled} save={handleSaveCAPItem}/>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        {getAnswerDueChip()}
                        {getLastUpdateChip()}
                    </Stack>
                </Stack>

                <IconButton color="primary" aria-label="expand section" component="span"
                            onClick={() => {
                                const b = !collapsed;
                                setCollapsed(b);
                                localStorage.setItem(`tab_cap_item_${item.id}`, String(b))
                            }}>
                    {collapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </Stack>
        </>;
    }

    function getBackgroundColor() {
        if (item.status === "CLOSED") return "#e9f2e9";
    }

    async function handleSaveCAPItemStatus() {
        try {
            await saveCAPItemStatus({capId: cap.id, capItemId: item.id, status: {status: "IN_REMEDIATION"}}).unwrap()
            dispatch(openSnackbar({message: "CAP Item status updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP Item status update failed", severity: "error"}));
        }
    }

    return (
        <Card sx={{mt: 1, p: 1}} style={{backgroundColor: getBackgroundColor()}}>
            <CardHeader disableTypography={true}
                        title={<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                            <CAPItemPriority priority={item.priority}/>
                            <EditableText variant="h6" disabled={disabled} save={handleSaveCAPItem} item={item}
                                          text={item.title}/>
                            <Chip variant="outlined" label={capItemStatuslabel(item.status)}></Chip>
                            <Typography variant="caption" display="block"
                                        gutterBottom>{createdBy(item, cap, true)}</Typography>
                        </Stack>}
                        action={getAction()}
            />
            <Collapse in={collapsed}>
                <CardContent>
                    <CAPFacts item={item} disabled={disabled} deleteMethod={handleDeleteCAPItem}
                              save={handleSaveCAPItem}/>
                    <CAPFiles item={item} cap={cap} disabled={disabled} complaint={complaint}/>
                    <CAPComments item={item} cap={cap} user={user} permissions={permissions} complaint={complaint}
                                 disabled={disabled} team={team} isInternal={isInternal}/>
                    {/*<CAPItemHistory item={item} cap={cap} permissions={permissions}/>*/}
                </CardContent>
                <CardActions sx={{display: "flex", justifyContent: "flex-end", p: 0, m: 0, lineHeight: 1,}}>
                    {item.status === "IN_CREATION" &&
                        <Button variant="contained"
                                color="warning"
                                size={"large"}
                                style={{marginLeft: 5}}
                                disabled={disabled}
                                onClick={handleSaveCAPItemStatus}>
                            Start Implementation
                        </Button>}
                    {item.status === "IN_REMEDIATION" && <CloseCapItem cap={cap} item={item} permissions={permissions}
                                                                       disabled={disabled}/>}
                </CardActions>
            </Collapse>
        </Card>
    );

}
