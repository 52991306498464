import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import SendMessage from "../messages/SendMessage";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useSendSupportEmailMutation} from "../../app/apiSlice";
import {openSnackbar} from "../../app/snackbarSlice";
import Speech from "../wizard/Speech";
import {v4 as uuidv4} from "uuid";

const Support = () => {
    const [reply, setReply] = useState("");
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation(['support']);
    const dispatch = useDispatch();

    const [sendSupportEmail] = useSendSupportEmailMutation();

    const onSubmit = async () => {
        setLoading(true);

        try {
            await sendSupportEmail({
                "email": "support@atlat.de",
                "subject": "Support Request",
                "text": reply,
                "senderInCC": "false"
            }).unwrap()
            dispatch(openSnackbar({message: t("sendingSuccess"), severity: "success"}));
            setReply("")
            setLoading(false)
        } catch (err) {
            dispatch(openSnackbar({message: t("sendingFail"), severity: "error"}));
            setReply("")
            setLoading(false)
        }
    }

    function speech(text) {
        return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
    }


// todo also link complaint form
    return (<>
        <Typography component="h1" variant="h3" color="primary" fontWeight={600} mt={1} gutterBottom>
            {t("title")}{speech(t('title'))}
        </Typography>
        <Typography component="div">
            <Trans i18nKey="text" t={t} components={[<a href="mailto:support@atlat.de" target="_top"/>]}/>
            {speech(t('text'))}
        </Typography>
        <SendMessage onClick={onSubmit} reply={reply} setReply={setReply} loading={loading}/>
    </>);
};

export default Support;
