import * as React from 'react';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Stack, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useDispatch} from "react-redux";
import {useSaveCAPItemStatusMutation} from "../../../../../app/apiSlice";
import {openSnackbar} from "../../../../../app/snackbarSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function CloseCapItem({item, cap, disabled, permissions}) {
    const [open, setOpen] = useState(false);
    const [closingSuccessfully, setClosingSuccessfully] = useState(false);
    const [closingUnsuccessfully, setClosingUnsuccessfully] = useState(false);
    const dispatch = useDispatch();

    const [saveCAPItemStatus] = useSaveCAPItemStatusMutation();

    const validationSchema = Yup.object().shape({
        note: Yup.string().required('Note is required'),
    });

    const {register, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(validationSchema)
    });

    if (item.status !== "IN_REMEDIATION") return null;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        reset();
        setOpen(false);
    };

    if (!permissions.has("WRITE_CAP")) return null;

    const handleUnsuccessfulClose = () => {
        handleSubmit(async (data) => {
            setClosingUnsuccessfully(true);

            function getCallback() {
                return () => {
                    setClosingUnsuccessfully(false);
                    reset();
                    setOpen(false);
                };
            }

            try {
                await saveCAPItemStatus({
                    capId: cap.id,
                    capItemId: item.id,
                    status: {status: "DONE", note: data.note}
                }).unwrap()
                dispatch(openSnackbar({message: "CAP Item status updated successfully", severity: "success"}));
                getCallback();
            } catch (err) {
                dispatch(openSnackbar({message: "CAP Item status update failed", severity: "error"}));
                getCallback();
            }

        })();
    }

    const handleSuccessfulClose = () => {
        handleSubmit(async (data) => {
            setClosingSuccessfully(true);

            function getCallback() {
                return () => {
                    setClosingSuccessfully(false);
                    reset();
                    setOpen(false);
                };
            }

            try {
                await saveCAPItemStatus({
                    capId: cap.id,
                    capItemId: item.id,
                    status: {status: "CLOSED", note: data.note}
                }).unwrap()
                dispatch(openSnackbar({message: "CAP Item status updated successfully", severity: "success"}));
                getCallback();
            } catch (err) {
                dispatch(openSnackbar({message: "CAP Item status update failed", severity: "error"}));
                getCallback();
            }
        })();
    }

    return (
        <div>
            <Button variant="outlined" color="warning" size="large" disabled={disabled} onClick={handleOpen}
                    startIcon={<FlagRoundedIcon/>}>
                Close Item
            </Button>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>

                        <Typography variant="h6">Close CAP Item: {item.title}</Typography>
                        <Typography variant="body1" sx={{mt: 2}}>
                            Please explain your decision
                        </Typography>
                        <TextField
                            sx={{mt: 1}}
                            fullWidth
                            id="note"
                            name="note"
                            multiline
                            rows={3}
                            label="Reason"
                            placeholder="Explanation"
                            {...register('note')}
                            error={!!errors.note}
                        />
                        <Typography variant="inherit" color="error">
                            {errors.note?.message}
                        </Typography>


                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{mt: 3}}>
                            <LoadingButton
                                loading={closingUnsuccessfully}
                                variant='outlined'
                                color='warning'
                                onClick={handleUnsuccessfulClose}
                                startIcon={<DoNotDisturbOnIcon/>}>
                                Unsuccessful close
                            </LoadingButton>
                            <LoadingButton
                                loading={closingSuccessfully}
                                variant='contained'
                                color='warning'
                                onClick={handleSuccessfulClose}
                                startIcon={<CheckCircleIcon/>}>
                                Successful close
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
