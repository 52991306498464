import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import Typography from '@mui/material/Typography';
import AllMessages from "./AllMessages";
import LoadingGIF from "../components/common/LoadingGIF";
import Error from "../components/common/Error";
import {Alert, Stack} from "@mui/material";
import {SendMessage} from "../components/messages/SendMessage";
import Box from "@mui/material/Box";
import {useTranslation} from 'react-i18next';
import {useAddMessageToComplaintByComplainantMutation, useGetComplaintByCodeQuery,} from "../app/apiSlice";
import {openSnackbar} from "../app/snackbarSlice";
import {useDispatch} from "react-redux";
import Speech from "../components/wizard/Speech";
import {v4 as uuidv4} from "uuid";


const ViewComplaint = () => {

        const match = useRouteMatch();
        const {t, i18n} = useTranslation("complaintForComplainant"); // TODO CS-227

        const {data: complaint, isLoading: loading, error} = useGetComplaintByCodeQuery(match.params.code);
        const [addMessageToComplaintByComplainant] = useAddMessageToComplaintByComplainantMutation();
        const dispatch = useDispatch();

        const [reply, setReply] = useState("");

        if (loading || !complaint) return <LoadingGIF/>;
        if (error) return <Error/>;

        function speech(text) {
            return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
        }

        async function handleClick() {
            if (!reply) return;
            try {
                await addMessageToComplaintByComplainant({complaintId: complaint.id, reply: reply}).unwrap()
                dispatch(openSnackbar({message: t("messageSentSuccessfully"), severity: "success"}));
                setReply("");
            } catch (err) {
                dispatch(openSnackbar({message: t("messageSendingFailed"), severity: "error"}));
            }
        }

        function statusInfo() {
            if (complaint.statusInfo && complaint.statusInfo.type === "REJECTED") {
                return (
                    <Alert variant="outlined" severity="error">
                        <Stack justifyContent="flex-start" direction="row" alignItems="flex-start">
                            {t("closed_s1", {"date": new Date(complaint.statusInfo.date).toLocaleDateString()})}
                            {speech(t("closed_s1", {"date": new Date(complaint.statusInfo.date).toLocaleDateString()}))}<br/>
                            {complaint.status === "CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION" && t("closed_s2")}
                            {complaint.status === "CLOSED_NOT_PLAUSIBLE" && t("closed_s3")}
                            {complaint.status === "CLOSED_REASON_NOT_ELIGIBLE" && t("closed_s4")}
                        </Stack>
                    </Alert>
                );
            }

            if (complaint.statusInfo && (complaint.statusInfo.type === "START_INVESTIGATION" || complaint.statusInfo.type === "REOPEN_INVESTIGATION")) {
                return (
                    <Alert variant="outlined" severity="success">
                        <Stack justifyContent="flex-start" direction="row" alignItems="flex-start">
                            {t("investigation", {"date": new Date(complaint.statusInfo.date).toLocaleDateString()})}
                            {speech(t("investigation", {"date": new Date(complaint.statusInfo.date).toLocaleDateString()}))}
                        </Stack>
                    </Alert>
                );
            }
            if (complaint.statusInfo && complaint.statusInfo.type === "CLOSED_INVESTIGATION") {
                return (
                    <Alert variant="outlined" severity="success">
                        <Stack justifyContent="flex-start" direction="row" alignItems="flex-start">
                            {t("cap")}{speech(t("cap"))}
                        </Stack>
                    </Alert>
                );
            }
        }

        return (
            <Box sx={{width: "100%", maxWidth: "750px",}} p={3}>
                <Typography variant="h3" component="h1" gutterBottom>
                    {t("complaintTitle", {complaintId: complaint.id})}{speech(t("complaintTitle", {complaintId: complaint.id}))}
                </Typography>

                {statusInfo()}

                <AllMessages data={complaint} text2Speech={true}/>

                <SendMessage reply={reply}
                             setReply={setReply}
                             note={t("translationNote")}
                             text2Speech={true}
                             onClick={handleClick}
                />
            </Box>
        );
    }
;

export default ViewComplaint;
