import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import theme from "../theme";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, value, theme) {
    if (!value) return {fontWeight: theme.typography.fontWeightRegular};
    return {
        fontWeight:
            value.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const EditCellMultipleSelect = ({row, table, column, label, items}) => {

    const [value, setValue] = useState(row._valuesCache[column.id] || []);
    const handleChange = (event) => {
        row._valuesCache[column.id] = event.target.value;
        setValue(row._valuesCache[column.id])
        table.setEditingRow(row);
    };

    return (<div>
        <FormControl sx={{m: 1, width: 300}}>
            <InputLabel id="multiple-select-label">{label}</InputLabel>
            <Select
                labelId="multiple-select-label"
                id="multiple-select"
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput label={label}/>}
                MenuProps={MenuProps}
            >
                {items.map((brand) => (
                    <MenuItem
                        key={brand.id}
                        value={brand.id}
                        style={getStyles(brand.id, value, theme)}
                    >
                        {brand.shortName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </div>);
};
