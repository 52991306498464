import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {Select, Stack, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {darkSalmon, deadlineRed, lightTurquoise, mainSalmon} from "../../../../common/colors";
import FormControl from "@mui/material/FormControl";
import {capItemStatuslabel} from "../../../labels";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import {openSnackbar} from "../../../../../app/snackbarSlice";
import {useDispatch} from "react-redux";
import {useGetTeamQuery, useSaveCAPItemMutation} from "../../../../../app/apiSlice";
import CAPFiles from "../CAPFiles";
import CAPComments from "../CAPComments";
import LoadingGIF from "../../../../common/LoadingGIF";
import {createdBy} from "./itemFacts";
import EditableText from "./EditableText";
import CAPItemDueDate from "./CAPItemDueDate";

export default function CAPItemDialog({
                                          onClose,
                                          open,
                                          permissions,
                                          closedOrDone,
                                          item,
                                          cap,
                                          complaint,
                                          user,
                                          isInternal
                                      }) {

    const dispatch = useDispatch();
    const {data: team, isLoading: loading} = useGetTeamQuery();
    const [saveCAPItem] = useSaveCAPItemMutation();

    const disabled = !permissions.has("WRITE_CAP") || closedOrDone;

    if (loading) return <LoadingGIF/>;

    async function handleSaveCAPItem(item) {
        try {
            await saveCAPItem({capId: cap.id, capItem: item}).unwrap()
            dispatch(openSnackbar({message: "CAP Item updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP Item update failed", severity: "error"}));
        }
    }

    return (
        <Dialog onClose={onClose} open={open} sx={{minWidth:500}} >
            <Box px={2} pt={3} pb={1} sx={{minWidth:500}}>
                <Stack direction={"row"} justifyContent="start" spacing={2} alignItems="center">
                    <FormControl>
                        <Select
                            value={item.priority}
                            onChange={e => {
                                handleSaveCAPItem({
                                    ...item, priority: e.target.value
                                })
                            }}
                            disabled={disabled}
                            sx={{width: 130, height: 32, borderRadius: 7}}
                        >
                            <MenuItem value="high">
                                <div style={{display: 'flex', alignItems: 'end'}}>
                                    <FiberManualRecordIcon sx={{fill: darkSalmon}}/> High
                                </div>
                            </MenuItem>
                            <MenuItem value="medium">
                                <div style={{display: 'flex', alignItems: 'end'}}>
                                    <FiberManualRecordIcon sx={{fill: mainSalmon}}/> Medium
                                </div>
                            </MenuItem>
                            <MenuItem value="low">
                                <div style={{display: 'flex', alignItems: 'end'}}>
                                    <FiberManualRecordIcon sx={{fill: lightTurquoise}}/> Low
                                </div>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Chip variant="outlined" label={capItemStatuslabel(item.status)}></Chip>
                    <CAPItemDueDate item={item} save={handleSaveCAPItem} disabled={disabled}/>
                </Stack>
                <EditableText align="left" variant="h5" pt={2} pl={1} disabled={disabled} item={item}
                              save={handleSaveCAPItem} text={item.title}/>
                <Typography variant="caption" display="block" align="left" gutterBottom pl={1}>
                    {createdBy(item, cap, true)}
                </Typography>
                <Typography variant="h6" align="left" gutterBottom pl={1} pt={2}>
                    Description
                </Typography>
                <TextField
                    id="item_description" required
                    label="CAP Item Description"
                    style={{marginTop: 20}}
                    multiline
                    minRows={4}
                    fullWidth={true}
                    defaultValue={item.description}
                    variant="outlined"
                    disabled={disabled}
                    onBlur={(e) => handleSaveCAPItem({...item, description: e.target.value})}
                />
                <CAPFiles item={item} cap={cap} disabled={disabled} complaint={complaint}/>
                <CAPComments item={item} cap={cap} user={user} permissions={permissions} complaint={complaint}
                             disabled={disabled} team={team} isInternal={isInternal}/>
            </Box>
        </Dialog>
    );
}

CAPItemDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

