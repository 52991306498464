import React, {useState} from "react";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {Button, InputLabel} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useDispatch} from "react-redux";
import {useUpdateBrandMutation} from "../../../app/apiSlice";
import {openSnackbar} from "../../../app/snackbarSlice";
import {parseISO} from "date-fns";

export const BrandForm = ({brand: data}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [updateBrand] = useUpdateBrandMutation();

    function defaultBusinessYearStartDate() {
        let date = new Date();
        date.setMonth(0);
        date.setDate(1);
        return date;
    }

    function defaultBusinessYearEndDate() {
        let date = new Date();
        date.setMonth(11);
        date.setDate(31);
        return date;
    }

    const [brand, setBrand] = useState({
        ...data,
        address: data.address ? data.address : {},
        businessYearStart: data.businessYearStart ? data.businessYearStart : defaultBusinessYearStartDate(),
        businessYearEnd: data.businessYearEnd ? data.businessYearEnd : defaultBusinessYearEndDate()
    });


    function handleChange(event) {
        setBrand({
            ...brand,
            [event.target.name]: event.target.value
        });
    }

    async function handleClick(event) {
        event.preventDefault();
        try {
            await updateBrand(brand).unwrap()
            dispatch(openSnackbar({message: "Brand updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Brand update failed", severity: "error"}));
        }
    }

    return (
        <Box sx={{mt: 2}}>
            <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h1" gutterBottom>
                            {brand.longName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="shortName" name="shortName" value={brand.shortName} label="Short Name"
                                   variant="outlined" fullWidth
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="longName" name="longName" value={brand.longName} label="Full Legal Name"
                                   variant="outlined" fullWidth
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="legalFormLabel">LegalForm</InputLabel>
                            <Select
                                labelId="legalFormLabel"
                                id="legalForm"
                                value={brand.legalForm}
                                label="Legal Form"
                                onChange={(event) => setBrand({...brand, legalForm: event.target.value})}
                            >
                                <MenuItem value="EINZELUNTERNEHMEN">Einzelunternehmen</MenuItem>
                                <MenuItem value="GBR">Gesellschaft bürgerlichen Rechts (GbR)</MenuItem>
                                <MenuItem value="EK">Eingetragener Kaufmann (e.K.)</MenuItem>
                                <MenuItem value="OHG">Offene Handelsgesellschaft (OHG)</MenuItem>
                                <MenuItem value="KG">Kommanditgesellschaft (KG)</MenuItem>
                                <MenuItem value="GMBH">Gesellschaft mit beschränkter Haftung (GmbH)</MenuItem>
                                <MenuItem value="UG">Unternehmergesellschaft (haftungsbeschränkt)</MenuItem>
                                <MenuItem value="AG">Aktiengesellschaft (AG)</MenuItem>
                                <MenuItem value="GMBHCOKG">GmbH & Co KG</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="street" name="street" value={brand.address.street} fullWidth
                                   label="Street" variant="outlined" onChange={(event) =>
                            setBrand({...brand, address: {...brand.address, street: event.target.value}})}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="houseNumber" name="houseNumber" value={brand.address.houseNumber} fullWidth
                                   label="House Number" variant="outlined" onChange={(event) =>
                            setBrand({...brand, address: {...brand.address, houseNumber: event.target.value}})}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="postCode" name="postCode" value={brand.address.postCode} fullWidth
                                   label="Post Code" variant="outlined" onChange={(event) =>
                            setBrand({...brand, address: {...brand.address, postCode: event.target.value}})}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="city" name="city" value={brand.address.city} fullWidth
                                   label="City" variant="outlined" onChange={(event) =>
                            setBrand({...brand, address: {...brand.address, city: event.target.value}})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="handelsregisternummer" name="handelsregisternummer"
                                   value={brand.handelsregisternummer} fullWidth
                                   label="Handelsregisternummer" variant="outlined" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="registergericht" name="registergericht" value={brand.registergericht} fullWidth
                                   label="Registergericht" variant="outlined" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Geschäftsjahr Beginn"
                                name="businessYearStart"
                                inputFormat="dd.MM.yyyy"
                                value={parseISO(brand.businessYearStart)}
                                onChange={event => setBrand({...brand, businessYearStart: event})}
                                textField={(params) => <TextField name="businessYearStart" {...params} />}
                            />
                            <DesktopDatePicker
                                label="Geschäftsjahr Ende"
                                name="businessYearEnd"
                                inputFormat="dd.MM.yyyy"
                                value={parseISO(brand.businessYearEnd)}
                                onChange={event => setBrand({...brand, businessYearEnd: event})}
                                textField={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="noEmployees" name="noEmployees" value={brand.noEmployees} fullWidth
                                   label="Number of Employees" variant="outlined" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="vertretungsberechtigtePersonen" name="vertretungsberechtigtePersonen"
                                   value={brand.vertretungsberechtigtePersonen} fullWidth
                                   label="Vertretungsberechtigte Personen" variant="outlined" onChange={handleChange}/>
                    </Grid>


                    <Grid item xs={12} style={{marginBottom: 50}}>
                        <Button variant="contained"
                                onClick={() => history.push(`/brands/`)}
                                style={{marginRight: 50}}>
                            Cancel
                        </Button>
                        <Button variant="contained"
                                onClick={handleClick}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};
