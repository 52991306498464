import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import Speech from "../wizard/Speech";
import {v4 as uuidv4} from "uuid";
import theme from "./../theme";

export const SendMessage = ({reply, setReply, note, avatar, onClick, loading, text2Speech}) => {
    const {t, i18n} = useTranslation("complaintForComplainant");

    function speech(text) {
        if (text2Speech)
            return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
    }

    return (<>
        <Paper sx={{marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',}}>
            <Grid container spacing={1} direction="row" justifyContent="space-around" alignItems="stretch">
                <Grid item xs={8} sm={10}>
                    <TextField id="standard-basic" label={t("yourReply")} value={reply} multiline
                               disabled={loading}
                               rows={4} fullWidth onInput={e => setReply(e.target.value)}/>
                    {note && <Typography variant="caption" display="block" gutterBottom>
                        {note}{speech(note)}
                    </Typography>}
                </Grid>
                <Grid item xs={4} sm={2}>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        style={{padding: 10}}
                        endIcon={avatar && <Avatar style={{
                            width: 30,
                            height: 30
                        }}>
                            {avatar}
                        </Avatar>}
                        onClick={onClick}
                    >
                        {t("reply")}
                    </LoadingButton>{speech(t('reply'))}
                </Grid>
            </Grid>
        </Paper>
    </>)
};

export default SendMessage;
