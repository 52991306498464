import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import {dateAndTime} from "../../../common/dates";
import Chip from "@mui/material/Chip";

export default function InvitationToComment({invitationToComment, user}) {

    if (invitationToComment.user) {
        // TODO
        const external = invitationToComment.user.roles.find(r => r.name === "ROLE_EXTERNAL");
        return (
            <Paper key={"comment" + invitationToComment.id} sx={{mt: 2}}>
                <Grid container sx={{p: 2}}>
                    <Grid item xs={4} sm={1}>
                        {avatar(invitationToComment.user)}
                    </Grid>
                    <Grid item xs={8} sm={11}>
                        <Typography variant="h6" sx={{display: "inline"}}>
                            {invitationToComment.user.firstName} {invitationToComment.user.lastName}
                        </Typography>&nbsp;
                        {dateAndTime(invitationToComment.cdate)}&nbsp;
                        {external && <Chip label="External" color="primary" variant="outlined"/>}
                        <Typography variant="body2" gutterBottom>
                            {invitationToComment.content}
                        </Typography>
                    </Grid>

                </Grid>
            </Paper>);
    }

    function avatar(user) {
        return <>
            {user.picture && <Avatar src={user.picture}/>}
            {!user.picture && <Avatar>{`${user.firstName.substr(0, 1)}${user.lastName.substr(0, 1)}`}</Avatar>}
        </>;
    }

    if (invitationToComment.answered) {
        // TODO  // todo is it sent??
        const external = invitationToComment.invitedUser.roles.find(r => r.name === "ROLE_EXTERNAL");
        return (
            <Paper key={"invitationToCommentAnswered" + invitationToComment.id} sx={{mt: 2}}>
                <Grid container sx={{p: 2}}>
                    <Grid item xs={4} sm={1}>
                        {avatar(invitationToComment.invitedUser)}
                    </Grid>
                    <Grid item xs={8} sm={11} sx={{pb: 1}}>
                        <Typography variant="h6" sx={{display: "inline"}}>
                            {invitationToComment.invitedUser.firstName} {invitationToComment.invitedUser.lastName}
                        </Typography>&nbsp;
                        {dateAndTime(invitationToComment.answered)}&nbsp;
                        {external && <Chip label="External" color="primary" variant="outlined"/>}
                        <Typography variant="body2" gutterBottom>
                            {invitationToComment.answer}
                        </Typography>
                        <Typography variant="div">
                            <small>{`Reply to ${invitationToComment.invitingUser.firstName} ${invitationToComment.invitingUser.lastName} from
                             ${dateAndTime(invitationToComment.cdate)}: ${invitationToComment.note}`}</small>
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    if (invitationToComment.invitingUser.id === user.id)
        return (
            <Paper key={"invitationToComment" + invitationToComment.id} sx={{mt: 2}}>
                <Grid container sx={{p: 2}}>
                    <Grid item xs={4} sm={1}>
                        {invitationToComment.invitingUser.picture &&
                        <Avatar src={invitationToComment.invitingUser.picture}/>}
                        {!invitationToComment.invitingUser.picture &&
                        <Avatar>{`${invitationToComment.invitingUser.firstName.substr(0, 1)}${invitationToComment.invitingUser.lastName.substr(0, 1)}`}</Avatar>}
                    </Grid>
                    <Grid item xs={8} sm={11} sx={{pb: 1}}>
                        <Typography variant="div">
                            <i>{`You have invited ${invitationToComment.invitedUser.firstName} ${invitationToComment.invitedUser.lastName} to comment on this cap item
                        on ${dateAndTime(invitationToComment.cdate)}.`}</i><br/>
                            <i>{`Waiting for response by ${invitationToComment.invitedUser.firstName} ${invitationToComment.invitedUser.lastName}...`}</i>
                        </Typography>
                        <br/>
                        <Typography variant="div">
                            <small>{`Your note: ${invitationToComment.note}`}</small>
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        )

    return (
        <Paper key={"invitationToComment" + invitationToComment.id} sx={{mt: 2}}>
            <Grid container sx={{p: 2}}>
                <Grid item xs={4} sm={1}>
                    {invitationToComment.invitingUser.picture &&
                    <Avatar src={invitationToComment.invitingUser.picture}/>}
                    {!invitationToComment.invitingUser.picture &&
                    <Avatar>{`${invitationToComment.invitingUser.firstName.substr(0, 1)}${invitationToComment.invitingUser.lastName.substr(0, 1)}`}</Avatar>}
                </Grid>
                <Grid item xs={8} sm={11} sx={{pb: 1}}>
                    <Typography variant="div">
                        <i>{`${invitationToComment.invitingUser.firstName} ${invitationToComment.invitingUser.lastName} has invited ${invitationToComment.invitedUser.firstName} ${invitationToComment.invitedUser.lastName} to comment on this cap item
                        on ${dateAndTime(invitationToComment.cdate)}.`}</i><br/>
                        <i>{`Waiting for response by ${invitationToComment.invitedUser.firstName} ${invitationToComment.invitedUser.lastName}...`}</i>
                    </Typography>
                    <br/>
                    <Typography variant="div">
                        <small>{`Note by ${invitationToComment.invitingUser.firstName} ${invitationToComment.invitingUser.lastName}: ${invitationToComment.note}`}</small>
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
