import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#003a60',
        },
        secondary: {
            main: '#6c757d',
        },
        warning: {
            main: '#ffc107'
        },
        success: {
            main: '#65ba8c'
        },
        info: {
            main: '#17a2b8'
        }
    },
    typography: {
        fontFamily: "neusa-next-std, sans-serif",
        h3: {
            fontSize: "2rem",
            color: "#013A60",
        },
        h5: {
        },
        h6: {
            fontSize: "1rem",
            color: "#013A60",
        }
    }
});

export default theme;