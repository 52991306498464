import React, {useState} from 'react';
import {Popover, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import Alert from '@mui/material/Alert';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const HelpPopUp = ({text, tooltip, severity}) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (tooltip) {
        return <Tooltip title={text} arrow>
            <IconButton color="primary"> <HelpCenterIcon/></IconButton>
        </Tooltip>;
    }

    return (
        <>
            <IconButton color="primary" aria-describedby={id} variant="contained" onClick={handlePopoverOpen}
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
            >
                {!severity && <HelpCenterIcon/>}
                {severity === "warning" && <WarningAmberIcon color="warning"/>}
                {severity === "info" && <InfoOutlinedIcon color="info"/>}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    pointerEvents: 'none',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <Alert severity={severity || "info"}>{text}</Alert>
            </Popover>
        </>
    );
}

export default HelpPopUp;
