import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import ReplyToInvitationToComment from "./ReplyToInvitationToComment";
import InvitationToComment from "./InvitationToComment";
import CollapsibleSection from "../../../common/CollapsibleSection";
import Badge from "@mui/material/Badge";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import InviteToComment from "./InviteToComment";
import {useDispatch} from "react-redux";
import {openSnackbar} from "../../../../app/snackbarSlice";
import {useCreateCAPCommentMutation} from "../../../../app/apiSlice";

export default function CAPComments({item, cap, complaint, permissions, user, disabled, team, isInternal}) {

    const [comment, setComment] = useState("");
    const dispatch = useDispatch();

    const [createCAPComment] = useCreateCAPCommentMutation();

    if (!permissions.has("READ_CAP")) return null;
    if (!item.id) return null;

    async function handleReply() {
        try {
            await createCAPComment({capId: cap.id, capItemId: item.id, comment: comment}).unwrap()
            dispatch(openSnackbar({message: "CAP Comment created successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP Comment creation failed", severity: "error"}));
        }

    }

    const openInvitationsToCommentForMe = item.invitationsToComment
        .filter((invitationToComment) => !invitationToComment.invitationRevoked)
        .filter((invitationToComment) => !invitationToComment.answered)
        .filter((invitationToComment) => invitationToComment.invitedUser.id === user.id);

    let theComments = [];
    if (item.comments) {
        theComments.push(...item.comments);
        theComments.push(...item.invitationsToComment
            .filter((invitationToComment) => !openInvitationsToCommentForMe.includes(invitationToComment)));
    }
    theComments.sort(function (a, b) {
        if (a.answered && b.answered) return new Date(a.answered) - new Date(b.answered);
        if (a.answered) return new Date(a.answered) - new Date(b.cdate);
        if (b.answered) return new Date(a.cdate) - new Date(b.answered);
        return new Date(a.cdate) - new Date(b.cdate);
    });

    function comments() {

        return <>
            {theComments
                .map(invitationToComment => {
                    return <InvitationToComment key={invitationToComment.id} invitationToComment={invitationToComment}
                                                user={user}/>
                })}

            {permissions.has("WRITE_CAP_COMMENTS") && (openInvitationsToCommentForMe.length === 0) &&
                <Paper sx={{p: 2, display: "flex", alignItems: "end", mt: 2}}>
                    <Typography variant="span" gutterBottom sx={{display: "flex", flex: 1, mb: 0}}>
                        <TextField label="Your comment" value={comment} multiline
                                   rows={4} fullWidth
                                   onInput={e => setComment(e.target.value)}/>
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ml: 1,}}
                        onClick={handleReply}
                    >
                        Comment
                    </Button>
                </Paper>}

            {openInvitationsToCommentForMe.map(invitationToComment => {
                return <ReplyToInvitationToComment key={invitationToComment.id}
                                                   invitationToComment={invitationToComment} user={user} capId={cap.id}
                                                   capItemId={item.id}/>
            })}

            <Grid container justifyContent="flex-end" sx={{pt: 1}}>
                <InviteToComment item={item} cap={cap} user={user} disabled={disabled} team={team}
                                 permissions={permissions} isInternal={isInternal} complaint={complaint}/>
            </Grid>
        </>;
    }

    const numberOfComments = (item.comments && item.comments.length) + item.invitationsToComment
        .filter((invitationToComment) => !invitationToComment.invitationRevoked).length;

    return (<CollapsibleSection title={<>
        {`${numberOfComments} Comments`}
        {openInvitationsToCommentForMe.length > 0 &&
            <Badge badgeContent={openInvitationsToCommentForMe.length} sx={{ml: 2}}
                   color="primary"><QuestionAnswerRoundedIcon/>
            </Badge>}</>} id={`comments_${cap.id}_${item.id}`}
                                children={comments()}/>)
}
