import React from "react";
import {ThemeProvider} from '@mui/material/styles';
import theme from "./theme";
import Hero from "../../components/Hero";
import logo from "./deuter_logo.png";
import chat_hand_atlat_deuter from "./chat_hand_atlat_deuter.png";
import illu_enteryourcode_deuter from "./illu_enteryourcode_deuter.png";


const DeuterHome = () => {

    return (
        <ThemeProvider theme={theme}>
            <Hero color1={theme.palette.info.main} color2={theme.palette.primary.main}
                  logo={logo} workerChat={chat_hand_atlat_deuter}
                  bgcolorCode={"#f0eeec"}
                  illuEntercode={illu_enteryourcode_deuter} brand="deuter"
                  theme={theme}
            />
        </ThemeProvider>);
};

export default DeuterHome;
