import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../components/common/LoadingGIF";
import {UserTableDefault} from "../components/admin/UserTableDefault";
import Box from "@mui/material/Box";

const Users = () => {

    return (
        <Box sx={{mt: 2}}>
            <UserTableDefault />
        </Box>
    );
};

export default withAuthenticationRequired(Users, {
    onRedirecting: () => <LoadingGIF/>,
});
