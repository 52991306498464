import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1b8591',
            light: '#AED9DF'
        },
        secondary: {
            main: '#5b627c',
        },
        warning: {
            main: '#F8A18E'
        },
    },
    typography: {
        allVariants: {
            fontFamily: ['Lufga', 'sans-serif'].join(','),
        },
        fontWeightMedium: 600,
        h3: {
            fontSize: "2rem",
            fontWeight: 900,
            color: "#1b8591",
        },
        h4: {
            fontSize: "1rem",
        },
        h5: {
            fontSize: "1rem",
            fontWeight: 800,
        },
        h6: {
            fontSize: "1rem",
            fontWeight: 900,
            color: "#1b8591",
        },
        button: {
            fontFamily: ['Lufga', 'sans-serif'].join(','),
        },
    }
});

export default theme;
