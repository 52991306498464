export const baselineassessment_cdi_worker = [
    {
        stepName: "surveyName",
        title: "surveyName.title",
        text: "surveyName.text",
        buttons: [{label: "ok", goto: "surveyPurpose"},]
    },
    {
        stepName: "surveyPurpose",
        previousStep: "surveyName",
        title: "surveyPurpose.title",
        text: "surveyPurpose.text",
        buttons: [{label: "ok", goto: "surveyParticipants"},]
    },
    {
        stepName: "surveyParticipants",
        previousStep: "surveyPurpose",
        title: "surveyParticipants.title",
        text: "surveyParticipants.text",
        buttons: [{label: "ok", goto: "surveyPeriod"},]
    },
    {
        stepName: "surveyPeriod",
        previousStep: "surveyParticipants",
        title: "surveyPeriod.title",
        text: "surveyPeriod.text",
        buttons: [{label: "ok", goto: "surveyContact"},]
    },
    {
        stepName: "surveyContact",
        previousStep: "surveyPeriod",
        title: "surveyContact.title",
        text: "surveyContact.text",
        buttons: [{label: "ok", goto: "personalInformation"},]
    },
    {
        stepName: "personalInformation",
        previousStep: "surveyContact",
        title: "personalInformation.title",
        buttons: [{label: "ok", goto: "factoryName"},]
    },
    {
        stepName: "factoryName",
        previousStep: "personalInformation",
        title: "factoryName.title",
        text: "factoryName.text",
        field: {
            name: "factoryName",
            type: "text",
            label: "factoryName.field.label",
            submit: {
                label: "factoryName.field.submit.label",
                icon: "SendIcon",
                goto: "sex"
            }
        },
    },
    {
        stepName: "sex",
        previousStep: "factoryName",
        title: "sex.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "sex.buttons.male",
                goto: "age",
                update: {field: "sex", value: "male"}
            },
            {
                label: "sex.buttons.female",
                goto: "age",
                update: {field: "sex", value: "female"}
            },
            {
                label: "sex.buttons.other",
                goto: "age",
                update: {field: "sex", value: "other"}
            }]
    },
    {
        stepName: "age",
        previousStep: "sex",
        title: "age.title",
        text: "age.text",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "age.buttons.below15",
                goto: "position",
                update: {field: "age", value: "below15"}
            }, {
                label: "age.buttons.15to18",
                goto: "position",
                update: {field: "age", value: "15to18"}
            }, {
                label: "age.buttons.19to24",
                goto: "position",
                update: {field: "age", value: "19to24"}
            }, {
                label: "age.buttons.25to34",
                goto: "position",
                update: {field: "age", value: "25to34"}
            }, {
                label: "age.buttons.35to44",
                goto: "position",
                update: {field: "age", value: "35to44"}
            }, {
                label: "age.buttons.45to54",
                goto: "position",
                update: {field: "age", value: "45to54"}
            }, {
                label: "age.buttons.55andAbove",
                goto: "position",
                update: {field: "age", value: "55andAbove"}
            }, {
                label: "age.buttons.preferNotToSay",
                goto: "position",
                update: {field: "age", value: "preferNotToSay"}
            },
        ]
    },
    {
        stepName: "position",
        previousStep: "age",
        title: "position.title",
        field: {
            name: "position",
            type: "text",
            label: "position.field.label",
            submit: {
                label: "position.field.submit.label",
                icon: "SendIcon",
                goto: "department"
            }
        },
    },
    {
        stepName: "department",
        previousStep: "position",
        title: "department.title",
        field: {
            name: "department",
            type: "text",
            label: "department.field.label",
            submit: {
                label: "department.field.submit.label",
                icon: "SendIcon",
                goto: "seniority"
            }
        },
    },
    {
        stepName: "seniority",
        previousStep: "department",
        title: "seniority.title",
        text: "seniority.text",
        field: {
            name: "seniority",
            type: "text",
            label: "seniority.field.label",
            submit: {
                label: "seniority.field.submit.label",
                icon: "SendIcon",
                goto: "understandingOfStandardsAndRegulations"
            }
        },
    },
    {
        stepName: "understandingOfStandardsAndRegulations",
        previousStep: "seniority",
        title: "understandingOfStandardsAndRegulations.title",
        buttons: [{label: "ok", goto: "knowledgeOfWorkersRights"},]
    },
    {
        stepName: "knowledgeOfWorkersRights",
        previousStep: "understandingOfStandardsAndRegulations",
        title: "knowledgeOfWorkersRights.title",
        text: "knowledgeOfWorkersRights.text",
        scale: {
            name: "knowledgeOfWorkersRights",
            steps: ["knowledgeOfWorkersRights.scale.step_0", "knowledgeOfWorkersRights.scale.step_1", "knowledgeOfWorkersRights.scale.step_2", "knowledgeOfWorkersRights.scale.step_3", "knowledgeOfWorkersRights.scale.step_4", "knowledgeOfWorkersRights.scale.step_5"],
            submit: {
                label: "knowledgeOfWorkersRights.field.submit.label",
                icon: "SendIcon",
                goto: "sourceOfLaborStandardsInformation"
            }
        },
    },
    {
        stepName: "sourceOfLaborStandardsInformation",
        previousStep: "knowledgeOfWorkersRights",
        title: "sourceOfLaborStandardsInformation.title",
        text: "sourceOfLaborStandardsInformation.text",
        multipleChoice: true,
        multipleSelect: {
            name: "sourceOfLaborStandardsInformation",
            submit: {
                label: "send",
                goto: "understandingOfLaborLaws",
            }
        },
        buttons: [
            {
                label: "sourceOfLaborStandardsInformation.buttons.factory",
                update: {value: "factory"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.selfObserve",
                update: {value: "selfObserve"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.customersAndBrands",
                update: {value: "customersAndBrands"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.friendsAndColleagues",
                update: {value: "friendsAndColleagues"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.other",
                goto: "sourceOfLaborStandardsInformationSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "sourceOfLaborStandardsInformationSpecification",
        previousStep: "sourceOfLaborStandardsInformation",
        title: "sourceOfLaborStandardsInformationSpecification.title",
        text: "sourceOfLaborStandardsInformationSpecification.text",
        field: {
            name: "sourceOfLaborStandardsInformationSpecification",
            type: "text",
            rows: 3,
            label: "sourceOfLaborStandardsInformationSpecification.field.label",
            submit: {
                label: "sourceOfLaborStandardsInformationSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "understandingOfLaborLaws"
            }
        },
    },
    {
        stepName: "understandingOfLaborLaws",
        previousStep: "sourceOfLaborStandardsInformation",
        title: "understandingOfLaborLaws.title",
        text: "understandingOfLaborLaws.text",
        scale: {
            name: "understandingOfLaborLaws",
            steps: ["understandingOfLaborLaws.scale.step_0", "understandingOfLaborLaws.scale.step_1", "understandingOfLaborLaws.scale.step_2", "understandingOfLaborLaws.scale.step_3", "understandingOfLaborLaws.scale.step_4", "understandingOfLaborLaws.scale.step_5"],
            submit: {label: "understandingOfLaborLaws.field.submit.label", icon: "SendIcon", goto: "workingHours"}
        },
    },
    {
        stepName: "workingHours",
        previousStep: "understandingOfLaborLaws",
        title: "workingHours.title",
        text: "workingHours.text",
        scale: {
            name: "workingHours",
            steps: ["workingHours.scale.step_0", "workingHours.scale.step_1", "workingHours.scale.step_2", "workingHours.scale.step_3", "workingHours.scale.step_4", "workingHours.scale.step_5"],
            submit: {label: "workingHours.field.submit.label", icon: "SendIcon", goto: "sourceOfLaborLaws"}
        },
    },
    {
        stepName: "sourceOfLaborLaws",
        previousStep: "workingHours",
        title: "sourceOfLaborLaws.title",
        text: "sourceOfLaborLaws.text",
        multipleChoice: true,
        multipleSelect: {
            name: "sourceOfLaborLaws",
            submit: {
                label: "send",
                goto: "responsibleStakeholders",
            }
        },
        buttons: [
            {
                label: "sourceOfLaborLaws.buttons.factory",
                update: {value: "factory"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.selfObserve",
                update: {value: "selfObserve"}
            },
            {
                label: "sourceOfLaborLaws.buttons.customersAndBrands",
                update: {value: "customersAndBrands"}
            },
            {
                label: "sourceOfLaborLaws.buttons.friendsAndColleagues",
                update: {value: "friendsAndColleagues"}
            },
            {
                label: "sourceOfLaborLaws.buttons.other",
                goto: "sourceOfLaborLawsSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "sourceOfLaborLawsSpecification",
        previousStep: "sourceOfLaborLaws",
        title: "sourceOfLaborLawsSpecification.title",
        text: "sourceOfLaborLawsSpecification.text",
        field: {
            name: "sourceOfLaborLawsSpecification",
            type: "text",
            rows: 3,
            label: "sourceOfLaborLawsSpecification.field.label",
            submit: {
                label: "sourceOfLaborLawsSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "responsibleStakeholders"
            }
        },
    },
    {
        stepName: "responsibleStakeholders",
        previousStep: "sourceOfLaborLaws",
        title: "responsibleStakeholders.title",
        text: "responsibleStakeholders.text",
        multipleChoice: true,
        multipleSelect: {
            name: "responsibleStakeholders",
            submit: {
                label: "send",
                goto: "difficulties",
            }
        },
        buttons: [
            {
                label: "responsibleStakeholders.buttons.leadership",
                update: {value: "leadership"}
            },
            {
                label: "responsibleStakeholders.buttons.managers",
                update: {value: "managers"}
            },
            {
                label: "responsibleStakeholders.buttons.workers",
                update: {value: "workers"}
            },
        ]
    },
    {
        stepName: "difficulties",
        previousStep: "responsibleStakeholders",
        title: "difficulties.title",
        text: "difficulties.text",
        multipleChoice: true,
        multipleSelect: {
            name: "difficulties",
            submit: {
                label: "send",
                goto: "complaintExplanation",
            }
        },
        buttons: [
            {
                label: "difficulties.buttons.findingInformation",
                update: {value: "findingInformation"}
            },
            {
                label: "difficulties.buttons.verifyingInformation",
                update: {value: "verifyingInformation"}
            },
            {
                label: "difficulties.buttons.trustingInformation",
                update: {value: "trustingInformation"}
            },
            {
                label: "difficulties.buttons.other",
                goto: "difficultiesSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "difficultiesSpecification",
        previousStep: "difficulties",
        title: "difficultiesSpecification.title",
        text: "difficultiesSpecification.text",
        field: {
            name: "difficultiesSpecification",
            type: "text",
            rows: 3,
            label: "difficultiesSpecification.field.label",
            submit: {
                label: "difficultiesSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "complaintExplanation"
            }
        },
    },
    {
        stepName: "complaintExplanation",
        previousStep: "difficulties",
        text: "complaintExplanation.text",
        title: "complaintExplanation.title",
        buttons: [
            {
                label: "complaintExplanation.buttons.ok",
                goto: "channels"
            },]
    },
    {
        stepName: "channels",
        previousStep: "complaintExplanation",
        text: "channels.text",
        title: "channels.title",
        multipleChoice: true,
        multipleSelect: {
            name: "channels",
            submit: {
                label: "send",
                goto: "currentChannels",
            }
        },
        buttons: [
            {
                label: "channels.buttons.notReportingToAnyone",
                excludesOthers: true,
                update: {value: "notReportingToAnyone"}
            },
            {
                label: "channels.buttons.teamLeader",
                update: {value: "teamLeader"}
            },
            {
                label: "channels.buttons.foreman",
                update: {value: "foreman"}
            },
            {
                label: "channels.buttons.tradeUnion",
                update: {value: "tradeUnion"}
            },
            {
                label: "channels.buttons.humanResources",
                update: {value: "humanResources"}
            },
            {
                label: "channels.buttons.director",
                update: {value: "director"}
            },
            {
                label: "channels.buttons.other",
                goto: "channelsSpecification",
                update: {value: "other"}
            },

        ]
    },
    {
        stepName: "channelsSpecification",
        previousStep: "channels",
        title: "channelsSpecification.title",
        text: "channelsSpecification.text",
        field: {
            name: "channelsSpecification",
            type: "text",
            rows: 3,
            label: "channelsSpecification.field.label",
            submit: {
                label: "channelsSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "currentChannels"
            }
        },
    },
    {
        stepName: "currentChannels",
        previousStep: "channels",
        text: "currentChannels.text",
        title: "currentChannels.title",
        multipleChoice: true,
        multipleSelect: {
            name: "currentChannels",
            submit: {
                label: "send",
                goto: "grievanceMechanismChannels",
            }
        },
        buttons: [
            {
                label: "currentChannels.buttons.teamLeader",
                update: {value: "teamLeader"}
            },
            {
                label: "currentChannels.buttons.humanResources",
                update: {value: "humanResources"}
            },
            {
                label: "currentChannels.buttons.tradeUnion",
                update: {value: "tradeUnion"}
            },
            {
                label: "currentChannels.buttons.factoryDirector",
                update: {value: "factoryDirector"}
            },
            {
                label: "currentChannels.buttons.hotline",
                update: {value: "hotline"}
            },
            {
                label: "currentChannels.buttons.suggestionBox",
                update: {value: "suggestionBox"}
            },
            {
                label: "currentChannels.buttons.socialMedia",
                update: {value: "socialMedia"}
            },
            {
                label: "currentChannels.buttons.app",
                update: {value: "app"}
            },
            {
                label: "currentChannels.buttons.socialDialogue",
                update: {value: "socialDialogue"}
            },
            {
                label: "currentChannels.buttons.dontKnow",
                excludesOthers: true,
                update: {value: "dontKnow"}
            },
            {
                label: "currentChannels.buttons.other",
                goto: "currentChannelsSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "currentChannelsSpecification",
        previousStep: "currentChannels",
        title: "currentChannelsSpecification.title",
        text: "currentChannelsSpecification.text",
        field: {
            name: "currentChannelsSpecification",
            type: "text",
            rows: 3,
            label: "currentChannelsSpecification.field.label",
            submit: {
                label: "currentChannelsSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "grievanceMechanismChannels"
            }
        },
    },
    {
        stepName: "grievanceMechanismChannels",
        previousStep: "currentChannels",
        title: "grievanceMechanismChannels.title",
        text: "grievanceMechanismChannels.text",
        multipleChoice: true,
        multipleSelect: {
            name: "grievanceMechanismChannels",
            submit: {
                label: "send",
                goto: "complaintComfort",
            }
        },
        buttons: [
            {
                label: "grievanceMechanismChannels.buttons.teamLeader",
                update: {value: "teamLeader"}
            },
            {
                label: "grievanceMechanismChannels.buttons.humanResources",
                update: {value: "humanResources"}
            },
            {
                label: "grievanceMechanismChannels.buttons.tradeUnion",
                update: {value: "tradeUnion"}
            },
            {
                label: "grievanceMechanismChannels.buttons.factoryDirector",
                update: {value: "factoryDirector"}
            },
            {
                label: "grievanceMechanismChannels.buttons.hotline",
                update: {value: "hotline"}
            },
            {
                label: "grievanceMechanismChannels.buttons.suggestionBox",
                update: {value: "suggestionBox"}
            },
            {
                label: "grievanceMechanismChannels.buttons.phoneNumber",
                update: {value: "phoneNumber"}
            },
            {
                label: "grievanceMechanismChannels.buttons.socialMedia",
                update: {value: "socialMedia"}
            },
            {
                label: "grievanceMechanismChannels.buttons.app",
                update: {value: "app"}
            },
            {
                label: "grievanceMechanismChannels.buttons.socialDialogue",
                update: {value: "socialDialogue"}
            },
            {
                label: "grievanceMechanismChannels.buttons.other",
                goto: "grievanceMechanismChannelsSpecification",
                update: {value: "other"}
            },
            {
                label: "grievanceMechanismChannels.buttons.none",
                goto: "sexismChannel",
                excludesOthers: true,
                update: {value: "none"}
            },
        ]
    },
    {
        stepName: "grievanceMechanismChannelsSpecification",
        previousStep: "grievanceMechanismChannels",
        title: "grievanceMechanismChannelsSpecification.title",
        text: "grievanceMechanismChannelsSpecification.text",
        field: {
            name: "grievanceMechanismChannelsSpecification",
            type: "text",
            label: "grievanceMechanismChannelsSpecification.field.label",
            submit: {
                label: "grievanceMechanismChannelsSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "complaintComfort"
            }
        },
    },
    {
        stepName: "complaintComfort",
        previousStep: "grievanceMechanismChannels",
        title: "complaintComfort.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "complaintComfort.buttons.yes",
                goto: "complaintComfortYes",
                update: {field: "complaintComfort", value: "yes"}
            },
            {
                label: "complaintComfort.buttons.no",
                goto: "complaintComfortNo",
                update: {field: "complaintComfort", value: "no"}
            },
            {
                label: "complaintComfort.buttons.preferNotToSay",
                goto: "sexismChannel",
                update: {field: "complaintComfort", value: "preferNotToSay"}
            },]
    },
    {
        stepName: "complaintComfortYes",
        previousStep: "complaintComfort",
        title: "complaintComfortYes.title",
        text: "complaintComfortYes.text",
        field: {
            name: "complaintComfortYes",
            type: "text",
            rows: 3,
            label: "complaintComfortYes.field.label",
            submit: {
                label: "send",
                icon: "SendIcon",
                goto: "sexismChannel"
            }
        },
    },
    {
        stepName: "complaintComfortNo",
        previousStep: "complaintComfort",
        title: "complaintComfortNo.title",
        text: "complaintComfortNo.text",
        field: {
            name: "complaintComfortNo",
            type: "text",
            rows: 3,
            label: "complaintComfortNo.field.label",
            submit: {
                label: "send",
                icon: "SendIcon",
                goto: "sexismChannel"
            }
        },
    },
    {
        stepName: "sexismChannel",
        previousStep: "complaintComfort",
        text: "sexismChannel.text",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "sexismChannel.buttons.separate",
                goto: "focalPoint",
                update: {field: "sexismChannel", value: "separate"}
            },
            {
                label: "sexismChannel.buttons.main",
                goto: "focalPoint",
                update: {field: "sexismChannel", value: "main"}
            },
            {
                label: "sexismChannel.buttons.none",
                goto: "focalPoint",
                update: {field: "sexismChannel", value: "none"}
            },
            {
                label: "sexismChannel.buttons.dontKnow",
                goto: "focalPoint",
                update: {field: "sexismChannel", value: "dontKnow"}
            }]
    },
    {
        stepName: "focalPoint",
        previousStep: "sexismChannel",
        title: "focalPoint.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "focalPoint.buttons.yes",
                goto: "focalPointSpecification",
                update: {field: "focalPoint", value: "yes"}
            },
            {
                label: "focalPoint.buttons.noFocalPoint",
                goto: "suggestion",
                update: {field: "focalPoint", value: "noFocalPoint"}
            },
            {
                label: "focalPoint.buttons.dontKnow",
                goto: "suggestion",
                update: {field: "focalPoint", value: "dontKnow"}
            }
        ]
    },
    {
        stepName: "focalPointSpecification",
        previousStep: "focalPoint",
        title: "focalPointSpecification.title",
        text: "focalPointSpecification.text",
        field: {
            name: "focalPointSpecification",
            type: "text",
            rows: 3,
            label: "focalPointSpecification.field.label",
            submit: {
                label: "focalPointSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "suggestion"
            }
        },
    },
    {
        stepName: "suggestion",
        previousStep: "focalPoint",
        title: "suggestion.title",
        field: {
            name: "suggestion",
            type: "text",
            rows: 3,
            label: "suggestion.field.label",
            submit: {
                label: "suggestion.field.submit.label",
                icon: "SendIcon",
                goto: "understandingOfExternalGrievanceMechanisms",
                canBeSkipped: true
            }
        },
    },
    {
        stepName: "understandingOfExternalGrievanceMechanisms",
        previousStep: "suggestion",
        title: "understandingOfExternalGrievanceMechanisms.title",
        text: "understandingOfExternalGrievanceMechanisms.text",
        buttons: [{label: "ok", goto: "help",}],
    },
    {
        stepName: "help",
        previousStep: "understandingOfExternalGrievanceMechanisms",
        title: "help.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "help.buttons.yes",
                goto: "helpSpecification",
                update: {field: "help", value: "yes"}
            },
            {
                label: "help.buttons.no",
                goto: "externalChannel",
                update: {field: "help", value: "no"}
            }]
    },
    {
        stepName: "helpSpecification",
        previousStep: "help",
        title: "helpSpecification.title",
        text: "helpSpecification.text",
        field: {
            name: "helpSpecification",
            type: "text",
            rows: 3,
            label: "helpSpecification.field.label",
            submit: {
                label: "helpSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "outsideHelp"
            }
        },
    },
    {
        stepName: "outsideHelp",
        previousStep: "help",
        title: "outsideHelp.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "outsideHelp.buttons.yes",
                goto: "outsideHelpSpecification",
                update: {field: "outsideHelp", value: "yes"}
            },
            {
                label: "outsideHelp.buttons.no",
                goto: "sourceOfGrievanceMechanismInformation",
                update: {field: "outsideHelp", value: "no"}
            }]
    },
    {
        stepName: "outsideHelpSpecification",
        previousStep: "outsideHelp",
        title: "outsideHelpSpecification.title",
        text: "outsideHelpSpecification.text",
        field: {
            name: "outsideHelpSpecification",
            type: "text",
            rows: 3,
            label: "outsideHelpSpecification.field.label",
            submit: {
                label: "outsideHelpSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "sourceOfGrievanceMechanismInformation"
            }
        },
    },
    {
        stepName: "sourceOfGrievanceMechanismInformation",
        previousStep: "outsideHelp",
        title: "sourceOfGrievanceMechanismInformation.title",
        text: "sourceOfGrievanceMechanismInformation.text",
        multipleChoice: true,
        multipleSelect: {
            name: "sourceOfGrievanceMechanismInformation",
            submit: {
                label: "send",
                goto: "externalChannel",
            }
        },
        buttons: [
            {
                label: "sourceOfGrievanceMechanismInformation.buttons.training",
                update: {value: "training"}
            },
            {
                label: "sourceOfGrievanceMechanismInformation.buttons.selfObserve",
                update: {value: "selfObserve"}
            },
            {
                label: "sourceOfGrievanceMechanismInformation.buttons.customersBrands",
                update: {value: "customersBrands"}
            },
            {
                label: "sourceOfGrievanceMechanismInformation.buttons.friendsColleagues",
                update: {value: "friendsColleagues"}
            },
            {
                label: "sourceOfGrievanceMechanismInformation.buttons.dontKnow",
                excludesOthers: true,
                update: {value: "dontKnow"}
            },
            {
                label: "sourceOfGrievanceMechanismInformation.buttons.other",
                goto: "sourceOfGrievanceMechanismInformationSpecification",
                update: {value: "other"}
            },
        ]

    },
    {
        stepName: "sourceOfGrievanceMechanismInformationSpecification",
        previousStep: "sourceOfGrievanceMechanismInformation",
        title: "sourceOfGrievanceMechanismInformationSpecification.title",
        text: "sourceOfGrievanceMechanismInformationSpecification.text",
        field: {
            name: "sourceOfGrievanceMechanismInformationSpecification",
            type: "text",
            rows: 3,
            label: "sourceOfGrievanceMechanismInformationSpecification.field.label",
            submit: {
                label: "send",
                icon: "SendIcon",
                goto: "externalChannel"
            }
        },
    },
    {
        stepName: "externalChannel",
        previousStep: "sourceOfGrievanceMechanismInformation",
        title: "externalChannel.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "externalChannel.buttons.no",
                goto: "understandingOfGeneralWorkEnvironment",
                update: {field: "externalChannel", value: "no"}
            },
            {
                label: "externalChannel.buttons.maybe",
                goto: "understandingOfGeneralWorkEnvironment",
                update: {field: "externalChannel", value: "maybe"}
            },
            {
                label: "externalChannel.buttons.maybeAnonymously",
                goto: "understandingOfGeneralWorkEnvironment",
                update: {field: "externalChannel", value: "maybeAnonymously"}
            },
            {
                label: "externalChannel.buttons.yes",
                goto: "understandingOfGeneralWorkEnvironment",
                update: {field: "externalChannel", value: "yes"}
            }
        ]
    },
    {
        stepName: "understandingOfGeneralWorkEnvironment",
        previousStep: "externalChannel",
        title: "understandingOfGeneralWorkEnvironment.title",
        buttons: [{label: "ok", goto: "talkingComfort",}],
    },
    {
        stepName: "talkingComfort",
        previousStep: "understandingOfGeneralWorkEnvironment",
        title: "talkingComfort.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "talkingComfort.buttons.yesAlways",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "yesAlways"}
            },
            {
                label: "talkingComfort.buttons.yesSometimes",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "yesSometimes"}
            },
            {
                label: "talkingComfort.buttons.noRarely",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "noRarely"}
            },
            {
                label: "talkingComfort.buttons.noNever",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "noNever"}
            },
            {
                label: "talkingComfort.buttons.preferNotToSay",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "preferNotToSay"}
            },
        ],
    },
    {
        stepName: "atmosphere",
        previousStep: "talkingComfort",
        title: "atmosphere.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "atmosphere.buttons.positive",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "positive"}
            },
            {
                label: "atmosphere.buttons.neutral",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "neutral"}
            },
            {
                label: "atmosphere.buttons.negative",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "negative"}
            },
            {
                label: "atmosphere.buttons.preferNotToSay",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "preferNotToSay"}
            },
        ]
    },
    {
        stepName: "anythingElse",
        previousStep: "atmosphere",
        title: "anythingElse.title",
        field: {
            name: "anythingElse",
            type: "text",
            rows: 3,
            label: "anythingElse.field.label",
            submit: {
                label: "anythingElse.field.submit.label",
                icon: "SendIcon",
                goto: "thanks",
                canBeSkipped: true
            }
        },
    },
    {
        stepName: "thanks",
        previousStep: "anythingElse",
        text: "thanks.text"
    },
];

//console.log(JSON.stringify(baselineassessment_cdi_worker, null, 2))
