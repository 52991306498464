import React from 'react';
import Alert from '@mui/material/Alert';
import {Box} from "@mui/system";
import theme from "../theme";

export default function NotAuthorized() {
    return (
        <Box sx={{
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        }}>
            <Alert variant="outlined" severity="warning" sx={{margin: 3}}>
                You are not authorized to see this data. If you think you should be contact your atlat sales
                representative.
            </Alert>
        </Box>
    );
}
