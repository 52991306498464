import React from "react";
import {useRouteMatch} from "react-router-dom";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import VerticalTabs from "../../components/dashboard/VerticalTabs";
import LoadingGIF from "../../components/common/LoadingGIF";
import Error from "../../components/common/Error";
import {determineTab} from "../../utils/tabs";
import {useGetComplaintForExternalQuery, useGetMeQuery} from "../../app/apiSlice";

export const HandleComplaintAsExternal = () => {
    const match = useRouteMatch();
    const {user} = useAuth0();

    const {data: me, isLoading: meLoading, error: meError} = useGetMeQuery();
    const {data: complaintDTO, isLoading: complaintLoading, error: complaintError}
        = useGetComplaintForExternalQuery({complaintId: match.params.id, language: user.locale ? user.locale : "en"});

    if (complaintLoading || meLoading) return <LoadingGIF/>;
    if (complaintError || meError) return <Error/>;

    if (!complaintDTO) return null;

    let permissions = extractPermissions(me, complaintDTO.invitation.permissions);

    // const {complaint, statusInfo} = complaintDTO;
    const {statusInfo} = complaintDTO;

    const tab = determineTab(complaintDTO);

    return (<VerticalTabs complaint={complaintDTO} tab={tab} permissions={permissions} statusInfo={statusInfo}/>);
};

function extractPermissions(me, externalPermissions) {
    let permissions = new Set();
    for (const permission of externalPermissions) {
        if (permission.granted) {
            permissions.add(permission.permission)
        }
    }
    if (permissions.has("WRITE_COMPLAINANT_CONVERSATION"))
        permissions.add("ADD_MESSAGE_TO_COMPLAINT");
    else permissions.delete("ADD_MESSAGE_TO_COMPLAINT")

    return permissions;
}

export default withAuthenticationRequired(HandleComplaintAsExternal, {
    onRedirecting: () => <LoadingGIF/>,
});
