import * as React from 'react';
import Alert from '@mui/material/Alert';
import {tabs} from "../../../../utils/tabs";

export default function InvestigationStatusInfo({complaint, statusInfo, setTab}) {
    if (!statusInfo || !statusInfo.type) return null;

    if (statusInfo.type === "OPEN") {
        return (
            <Alert variant="outlined" severity="warning" onClick={() => setTab(tabs.facts)}>
                The investigation has not been started. Please start the investigation from the Complaint Facts tab.
            </Alert>
        );
    }

    if (statusInfo.type === "REJECTED") {
        return (
            // todo one place for tab numbers
            <Alert variant="outlined" severity="error" onClick={() => setTab(tabs.facts)}>
                The complaint has been closed
                by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()} {new Date(statusInfo.date).toLocaleTimeString()} and
                will not be investigated.
                {complaint.status === "CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION" && " The reason" +
                    " is that the complainant has not been reachable for clarification. "}
                {complaint.status === "CLOSED_NOT_PLAUSIBLE" && " The reason is that the complaint is not" +
                    " plausible. "}
                {complaint.status === "CLOSED_REASON_NOT_ELIGIBLE" && " The reason is that the reason" +
                    " for the complaint is not eligible. "}
                {statusInfo.comment && <>Additional comment by {statusInfo.statusInfoUser}:
                    <i>{statusInfo.comment}</i></>}
            </Alert>
        );
    }

    if (statusInfo.type === "START_INVESTIGATION") {
        return (
            <Alert variant="outlined" severity="info">
                The investigation has been started
                by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()} {new Date(statusInfo.date).toLocaleTimeString()}.
            </Alert>
        );
    }

    if (statusInfo.type === "CLOSED_INVESTIGATION") {
        return (
            <Alert variant="outlined" severity="info">
                The investigation has been closed
                by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()} {new Date(statusInfo.date).toLocaleTimeString()}.
            </Alert>
        );
    }

    if (statusInfo.type === "REOPEN_INVESTIGATION") {
        return (
            <Alert variant="outlined" severity="info">
                The investigation has been reopened
                by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()} {new Date(statusInfo.date).toLocaleTimeString()}.
            </Alert>
        );
    }

    if (statusInfo.type === "REOPENED_CAP") {
        return (
            <Alert variant="outlined" severity="info">
                The cap has been reopened
                by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()} {new Date(statusInfo.date).toLocaleTimeString()}.
            </Alert>
        );
    }

    // TODO are all types shown?

    return null;

    // return <p style={{marginTop: 20}}>{JSON.stringify(statusInfo, null, 2)}</p>;
}
