import * as React from 'react';
import {useState} from 'react';
import {InputLabel, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import AddInvestigationItemAskExternal from "./AddInvestigationItemAskExternal";
import AddInvestigationItemAddExternal from "./AddInvestigationItemAddExternal";
import AddInvestigationItemAddOwn from "./AddInvestigationItemAddOwn";

export default function AddInvestigationItemForm({setOpen, complaint, investigation, setTab}) {

    const [type, setType] = useState("")

    const handleChange = (event) => {
        setType(event.target.value);
    };

    return (
        <>
            <FormControl sx={{minWidth: 250}}>
                <InputLabel id="itemType-label">Investigation Item Source</InputLabel>
                <Select
                    labelId="itemType-label"
                    id="itemType"
                    name="itemType"
                    value={type}
                    label="Investigation Item Source"
                    onChange={handleChange}
                >
                    <MenuItem value={"ASK_EXTERNAL_OPINION"}>Ask for External Opinion</MenuItem>
                    <MenuItem value={"ADD_EXTERNAL_OPINION"}>Add External Opinion manually</MenuItem>
                    <MenuItem value={"OWN_OPINION"}>Add Own Opinion</MenuItem>
                </Select>
            </FormControl>

            {type === "ASK_EXTERNAL_OPINION" &&
                <AddInvestigationItemAskExternal investigation={investigation} setOpen={setOpen} complaint={complaint}
                                                 setTab={setTab}/>}
            {type === "ADD_EXTERNAL_OPINION" &&
                <AddInvestigationItemAddExternal investigation={investigation} setOpen={setOpen}
                                                 complaint={complaint}/>}
            {type === "OWN_OPINION" &&
                <AddInvestigationItemAddOwn investigation={investigation} setOpen={setOpen} complaint={complaint}/>}

        </>
    );
}
