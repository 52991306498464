import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import {Alert, Stack, TextField,} from "@mui/material";
import CAPControls from "./CAPControls";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import AddNewCapItemButton from "./AddNewCapItemButton";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CAPItemList from "./CAPItemList";
import CAPItemTiles from "./CAPItemTiles";

export default function CAPForm({cap, statusInfo, complaint, isInternal, permissions, user}) {

    const [addNewCapItem, setAddNewCapItem] = useState(false);
    const [description, setDescription] = useState(cap.description);
    const [view, setView] = useState(localStorage.getItem(`view_${cap.id}`) ? localStorage.getItem(`view_${cap.id}`) : 'list');

    const handleChange = (event, nextView) => {
        if (nextView !== null) {
            setView(nextView);
            localStorage.setItem(`view_${cap.id}`, nextView);
        }
    };

    if (!permissions.has("READ_CAP")) return null;

    if (statusInfo && statusInfo.type === "REJECTED") {
        return (
            <Alert variant="outlined" severity="error">
                The complaint has been closed
                by {statusInfo.statusInfoUser} at {new Date(statusInfo.date).toLocaleDateString()}&nbsp;
                {new Date(statusInfo.date).toLocaleTimeString()} and
                therefore there will be no investigation and no CAP.
                {complaint.status === "CLOSED_COMPLAINANT_NOT_REACHABLE_FOR_CLARIFICATION" && " The reason" +
                    " is that the complainant has not been reachable for clarification. "}
                {complaint.status === "CLOSED_NOT_PLAUSIBLE" && " The reason is that the complaint is not" +
                    " plausible. "}
                {complaint.status === "CLOSED_REASON_NOT_ELIGIBLE" && " The reason is that the reason" +
                    " for the complaint is not eligible. "}
                {statusInfo.comment && <>Additional comment by {statusInfo.statusInfoUser}:
                    <i>{statusInfo.comment}</i></>}
            </Alert>
        );
    }

    const latestChange = (cap && cap.history && cap.history.length > 1) ? cap.history.reduce(function (a, b) {
        return a.date > b.date ? a.date : b.date;
    }) : ((cap && cap.history && cap.history.length > 1) ? cap.history[0].date : null);

    function getStatusChip() {
        if (cap.status === "IN_CREATION")
            return <Chip label={"Status: In Creation"} color="primary" variant="outlined"/>;
        if (cap.status === "IN_REMEDIATION")
            return <Chip label={"Status: In Remediation"} color="primary" variant="outlined"/>;
        const statusChangeDate = cap.history.filter(e => e.type === "UPDATED_CAP_STATUS")
            .sort((a, b) => (a.date > b.date && 1) || -1)[0];
        if (statusChangeDate && statusChangeDate.date) {
            if (cap.status === "DONE")
                return <Chip
                    label={"Status: Unsuccessfully closed: " + new Date(statusChangeDate.date).toLocaleDateString()}
                    color="warning" variant="outlined" icon={<DoNotDisturbOnIcon/>}/>;
            if (cap.status === "CLOSED")
                return <Chip
                    label={"Status: Successfully closed: " + new Date(statusChangeDate.date).toLocaleDateString()}
                    color="warning" variant="outlined" icon={<CheckCircleIcon/>}/>;
        }

        if (cap.status === "DONE")
            return <Chip label={"Status: Unsuccessfully closed"}
                         color="warning" variant="outlined" icon={<DoNotDisturbOnIcon/>}/>;
        if (cap.status === "CLOSED")
            return <Chip label={"Status: Successfully closed"}
                         color="warning" variant="outlined" icon={<CheckCircleIcon/>}/>;
    }

    const closedOrDone = cap.status === "CLOSED" || cap.status === "DONE";

    return (
        <>
            <Stack sx={{width: "100%"}} direction="row" spacing={2} justifyContent="space-between">
                <span>
                    <Typography variant="h5" component="span" noWrap sx={{maxWidth: "40%px"}}>
                        CAP for C{complaint.id} {complaint.title ? " - " + complaint.title : ""}
                    </Typography>
                    <Stack direction="row" spacing={1} ml={1} sx={{display: "inline-block"}}>
                        <Chip label={"Created: " + new Date(cap.cdate).toLocaleDateString()} variant="outlined"/>
                        {latestChange &&
                            <Chip label={"Last Change: " + new Date(latestChange).toLocaleDateString()} color="primary"
                                  variant="outlined"/>}
                        {getStatusChip()}
                     </Stack>
                </span>
                {isInternal && <CAPControls cap={cap} permissions={permissions} description={description}/>}
            </Stack>

            <TextField
                id="cap_description" required
                label="CAP Description"
                style={{marginTop: 20}}
                minRows={4} multiline
                fullWidth={true}
                value={description}
                variant="outlined"
                disabled={!permissions.has("WRITE_CAP")}
                onInput={e => setDescription(e.target.value)}
            />

            <Stack sx={{width: "100%"}} mt={3} direction="row" spacing={2} justifyContent="space-between">
                <ToggleButtonGroup color="primary" size="small" value={view} exclusive onChange={handleChange}>
                    <ToggleButton value="list" aria-label="list">
                        <ListAltOutlinedIcon/> List View
                    </ToggleButton>
                    <ToggleButton value="tile" aria-label="tile">
                        <GridViewOutlinedIcon/> Tile View
                    </ToggleButton>
                </ToggleButtonGroup>

                {isInternal &&
                    <AddNewCapItemButton permissions={permissions} closedOrDone={closedOrDone}
                                         addNewCapItem={addNewCapItem} setAddNewCapItem={setAddNewCapItem} cap={cap}/>}
            </Stack>

            {view === "list" && <CAPItemList cap={cap} permissions={permissions} user={user} complaint={complaint}
                                             isInternal={isInternal}/>}

            {view === "tile" && <CAPItemTiles cap={cap} permissions={permissions} user={user} complaint={complaint}
                                              isInternal={isInternal}/>}


        </>
    );
}
