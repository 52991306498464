import React, {useMemo, useState} from 'react';
import {MaterialReactTable, useMaterialReactTable,} from 'material-react-table';
import {Alert, Box, Button, Checkbox, IconButton, Stack, Tooltip} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingGIF from "../common/LoadingGIF";
import {
    useCreateTrainingMutation,
    useGetBrandsQuery,
    useGetLocationNamesQuery,
    useGetMeQuery,
    useGetTrainingsQuery,
    useUpdateTrainingMutation
} from "../../app/apiSlice";
import Chip from "@mui/material/Chip";
import TrainingData from "./TrainingData";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const Trainings = () => {

        const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();
        const {user, isAuthenticated, loginWithRedirect, logout, isLoading: userLoading} = useAuth0();
        const {data: me, isLoading: meLoading, error: meError} = useGetMeQuery();
        const isAdmin = user && user['https://truecolorssustainability.com//role'] && user['https://truecolorssustainability.com//role'].includes("ADMIN");

        const columns = useMemo(
                () => [
                    {
                        accessorKey: 'link',
                        header: 'Link',
                        enableEditing: false,
                        Cell: ({row}) => {
                            if (row.original.closed) return <Alert severity="error">Training is closed</Alert>;
                            if (!row.original.brand) return <Alert severity="warning">Please configure brand</Alert>;
                            let link = window.location.href;
                            link = link.replace("trainings", "training/" + row.original.id)

                            return <Button onClick={() => (async () => {
                                await navigator.clipboard.writeText(link);
                            })(link)}>
                                Copy Link
                            </Button>
                        },
                    },
                    {
                        accessorKey: 'id',
                        header: 'Id',
                        enableEditing: isAdmin
                    },
                    {
                        accessorKey: 'brand',
                        header: 'Brand',
                        editVariant: 'select',
                        editSelectOptions: brands && brands.map(brand => brand.id),
                        muiEditTextFieldProps: {
                            select: true
                        },
                        enableEditing: isAdmin,
                        Cell: ({row}) => {
                            return <>{brands.find(b => b.id === row.original.brand)?.shortName}</>
                        },
                    },
                    {
                        accessorKey: 'trainerName',
                        header: 'Trainer Name',
                        enableEditing: isAdmin
                    },
                    {
                        accessorKey: 'trainingDate',
                        header: 'Training Date',
                        Cell: ({row}) => {
                            return <>{new Date(row.original.trainingDate).toLocaleDateString()}</>
                        },
                        Edit: ({cell, column, row, table}) => {
                            function handleDateChange(date) {
                                row._valuesCache[column.id] = date.toISOString();
                                table.setEditingRow(row);
                            }

                            return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    id="training_date"
                                    label="Training Date"
                                    inputFormat="dd-MM-yy"
                                    value={row.original.trainingDate ? new Date(row.original.trainingDate) : new Date()}
                                    onChange={handleDateChange}
                                    textField={(params) => <TextField    {...params} />}
                                />
                            </LocalizationProvider>
                        },
                    },
                    {
                        accessorKey: 'trainingName',
                        header: 'Training Name',
                    },
                    {
                        accessorKey: 'closed',
                        header: 'Closed',
                        Cell: ({row}) => {
                            return <Checkbox checked={row.original.closed}/>
                        },
                        Edit: ({cell, column, row, table}) => {
                            const handleChange = (event) => {
                                row._valuesCache[column.id] = event.target.value;
                                table.setEditingRow(row);
                            };

                            return <Checkbox onChange={handleChange}/>;
                        },
                    },
                    {
                        accessorKey: 'cdate',
                        accessorFn: (row) => new Date(row.cdate).toLocaleDateString(),
                        header: 'Creation Date',
                        enableEditing: false,
                    },
                    {
                        accessorKey: 'locationId',
                        header: 'Factory',
                        Cell: ({row}) => {
                            return <>{locations && locations.find(b => b.id === row.original.locationId)?.name}</>
                        },
                        Edit: ({cell, column, row, table}) => {

                            const [value, setValue] = useState(row._valuesCache[column.id] || null);
                            function handleDateChange(event) {
                                row._valuesCache[column.id] = event.target.value;
                                setValue(row._valuesCache[column.id])
                                table.setEditingRow(row);
                            }

                            return <FormControl variant="outlined">
                                <InputLabel id="location-label">Factory</InputLabel>
                                <Select
                                    labelId="location-label"
                                    id="location"
                                    value={value}
                                    onChange={handleDateChange}
                                    label="Location"
                                    sx={{minWidth: 120}}
                                >
                                    {
                                        locations && locations
                                            .map(location =>
                                                <MenuItem key={location.id} value={location.id}>
                                                    {location.name}
                                                </MenuItem>
                                            )
                                    }
                                </Select>
                            </FormControl>
                        },
                    },
                    {
                        accessorKey: 'numberOfParticipants',
                        header: '# Participants',
                        muiEditTextFieldProps: {
                            type: 'number',
                        },
                        enableEditing: false,
                    },
                    {
                        accessorKey: 'numberOfMaleParticipants',
                        header: '# Male Participants',
                        muiEditTextFieldProps: {
                            type: 'number',
                        },
                        enableEditing: false,
                    },
                    {
                        accessorKey: 'numberOfFemaleParticipants',
                        header: '# Female Participants',
                        muiEditTextFieldProps: {
                            type: 'number',
                        },
                        enableEditing: false,
                    },
                    {
                        accessorKey: 'numberOfDiverseParticipants',
                        header: '# Diverse Participants',
                        muiEditTextFieldProps: {
                            type: 'number',
                        },
                        enableEditing: false,
                    },
                    {
                        accessorKey: 'notes',
                        header: 'Notes',
                        enableEditing: false,
                    },
                    {
                        accessorKey: 'criticalIssues',
                        header: 'Critical Issues',
                        Cell: ({row}) => {
                            return row.original.criticalIssues && row.original.criticalIssues.map((value) => (
                                <Chip size="small" key={value} label={value} sx={{margin: 0.5,}} variant="outlined"/>
                            ))
                        },
                        enableEditing: false
                    },
                    {
                        accessorKey: 'criticalIssuesExplanation',
                        header: 'Critical Issues Explanation',
                        enableEditing: false,
                    },
                ],
                [brands],
            )
        ;

        const [createTraining, {isPending: isCreatingTraining},] = useCreateTrainingMutation();

        const {data: locations, isFetching: loadingLocations, error: locationsError} = useGetLocationNamesQuery();

        const {
            data: fetchedTrainings = [],
            error: isLoadingTrainingsError,
            isFetching: isFetchingTraining,
            isLoading: isLoadingTraining,
        } = useGetTrainingsQuery();

        const [updateTraining, {isPending: isUpdatingTraining},] = useUpdateTrainingMutation();

        const handleCreateTraining = async ({values, table}) => {
            await createTraining({
                id: values.id,
                brand: values.brand,
                trainerName: values.trainerName,
                trainingDate: values.trainingDate,
                trainingName: values.trainingName,
                closed: values.closed,
                locationId: values.locationId
            });
            table.setCreatingRow(null); //exit creating mode
        };

        const handleSaveTraining = async ({values, table}) => {
            await updateTraining({
                id: values.id,
                brand: values.brand,
                trainerName: values.trainerName,
                trainingDate: values.trainingDate,
                trainingName: values.trainingName,
                closed: values.closed,
                locationId: values.locationId
            });
            table.setEditingRow(null); //exit editing mode
        };

        const table = useMaterialReactTable({
                    columns,
                    data: fetchedTrainings,
                    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
                    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
                    enableEditing: isAdmin,
                    getRowId: (row) => row.id,
                    muiToolbarAlertBannerProps: isLoadingTrainingsError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined,
                    muiTableContainerProps: {
                        sx: {
                            minHeight: '500px',
                        },
                    },
                    onCreatingRowSave: handleCreateTraining,
                    onEditingRowSave: handleSaveTraining,
                    renderRowActions: ({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ),
                    renderTopToolbarCustomActions: ({table}) => (
                        <>{isAdmin && <Button
                            variant="contained"
                            onClick={() => {
                                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                                //or you can pass in a row object to set default values with the `createRow` helper function
                                // table.setCreatingRow(
                                //   createRow(table, {
                                //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                                //   }),
                                // );
                            }}
                        >
                            Create New Training
                        </Button>}
                            <div></div>
                        </>
                    ),
                    enableExpandAll: false,
                    muiExpandButtonProps: ({row, table}) => ({
                        onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}), //only 1 detail panel open at a time
                        sx: {
                            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                            transition: 'transform 0.2s',
                        },
                    }),
                    renderDetailPanel: ({row}) =>
                        <Stack width={1000}><TrainingData training={row.original} disabled={true} locations={locations}/></Stack>,
                    state: {
                        isLoading: isLoadingTraining || brandsLoading || loadingLocations || meLoading || userLoading,
                        isSaving: isCreatingTraining || isUpdatingTraining,
                        showAlertBanner: isLoadingTrainingsError || brandsError || locationsError || meError,
                        showProgressBars: isFetchingTraining || isLoadingTraining || brandsLoading || loadingLocations || meLoading,
                    },
                    initialState: { columnVisibility: { notes: false, brand:!isAdmin , cdate:false, criticalIssues:false, criticalIssuesExplanation:false} },

                }
            )
        ;

        return <MaterialReactTable table={table}/>;
    }
;

export default withAuthenticationRequired(Trainings, {
    onRedirecting: () => <LoadingGIF/>,
});
