import React, {useEffect, useState} from "react";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {countries} from "../countries/countries";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import {ContactsTable} from "../contacts/ContactsTable";
import {useHistory} from "react-router-dom";
import {openSnackbar} from "../../app/snackbarSlice";
import {useUpdateLocationMutation} from "../../app/apiSlice";
import {useDispatch} from "react-redux";
import theme from "../theme";
import {Box} from "@mui/system";



export const LocationForm = ({location: data, brands}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [location, setLocation] = useState(data);
    const [updateLocation] = useUpdateLocationMutation();

    useEffect(() => {
        console.log(location);
        let locationBrands = location.brands;
        console.log(locationBrands);
        console.log(brands);
        let xx = brands.filter(b => locationBrands.includes(b.id));
        console.log(xx);
        setLocation({...location, brands: xx});

    }, [data]);

    function setContacts(contacts) {
        const newLocation = {
            ...location, contacts
        };
        setLocation(newLocation);
    }

    function handleChange(event) {
        const newLocation = {
            ...location,
            [event.target.name]: event.target.value
        };
        setLocation(newLocation);
    }

    async function handleClick(event) {
        event.preventDefault();
        try {
            await updateLocation(location).unwrap();
            dispatch(openSnackbar({message: "Location updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Location update failed", severity: "error"}));
        }
    }

    const handleSelectChange = (value, name) => {
        const counts = {};
        for (let i = 0; i < value.length; i++) {
            const num = value[i];
            counts[num.id] = counts[num.id] ? counts[num.id] + 1 : 1;
        }
        const newLocation = {
            ...location,
            [name]: value.filter(x => counts[x.id] % 2 === 1)
        };
        setLocation(newLocation);
    };

    return (
        <Box padding={3}>
            <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h1" gutterBottom>
                            {location.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="name" name="name" value={location.name} label="Name" variant="outlined" fullWidth
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                            <Select
                                id="select-brands"
                                multiple
                                value={location.brands}
                                onChange={(e) => handleSelectChange(e.target.value, "brands")}
                                input={<Input id="select-brand"/>}
                                renderValue={(selected) => (
                                    <div >
                                        {selected.map((value) => (
                                            <Chip key={value.id} label={value.shortName} variant="outlined"/>
                                        ))}
                                    </div>
                                )}
                            >
                                {brands.map((brand) => (
                                    <MenuItem key={brand.id} value={brand}
                                              style={getStyles(brand, location.brands, theme)}>
                                        {brand.shortName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="website" name="website" value={location.website} fullWidth
                                   label="Website" variant="outlined" onChange={handleChange}/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="addressLine1" name="addressLine1" value={location.addressLine1} fullWidth
                                   label="Address Line 1" variant="outlined" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="addressLine2" name="addressLine2" value={location.addressLine2} fullWidth
                                   label="Address Line 2" variant="outlined" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="addressLine3" name="addressLine3" value={location.addressLine3} fullWidth
                                   label="Address Line 3" variant="outlined" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="addressLine4" name="addressLine4" value={location.addressLine4} fullWidth
                                   label="Address Line 4" variant="outlined" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField id="city" name="city" value={location.city} label="City" variant="outlined" fullWidth
                                   onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <TextField
                            id="country"
                            name="country"
                            select
                            label="Country"
                            value={location.country}
                            onChange={handleChange}
                            variant="outlined" fullWidth
                        >
                            {Object.keys(countries).sort().map((country) => (
                                <MenuItem key={country} value={country}>
                                    {countries[country]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <ContactsTable contacts={location.contacts} setContacts={setContacts}/>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 50}}>
                        <Button variant="contained"
                                onClick={() => history.push(`/locations/`)}
                                style={{marginRight: 50}}>
                            Cancel
                        </Button>
                        <Button variant="contained"
                                onClick={handleClick}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

function getStyles(brand, brands, theme) {
    if (!brands) return {fontWeight: theme.typography.fontWeightRegular};
    return {
        fontWeight:
            brands.map(x => x.id).indexOf(brand.id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

