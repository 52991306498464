import * as React from 'react';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import AddInvestigationItemForm from "./AddInvestigationItemForm";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import {Stack} from "@mui/material";
import FlagIcon from '@mui/icons-material/Flag';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {useDispatch} from "react-redux";
import {useCloseInvestigationMutation, useReopenInvestigationMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function InvestigationControls({complaint, investigation, setTab}) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [closeInvestigation] = useCloseInvestigationMutation();
    const [reopenInvestigation] = useReopenInvestigationMutation();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    async function handleCloseInvestigation() {
        try {
            await closeInvestigation({investigationId: investigation.id, complaintId: complaint.id}).unwrap();
            dispatch(openSnackbar({message: "Successfully closed investigation", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to close investigation", severity: "error"}));
        }
    }

    async function handleReopenInvestigation() {
        try {
            await reopenInvestigation({investigationId: investigation.id, complaintId: complaint.id}).unwrap();
            dispatch(openSnackbar({message: "Successfully reopened investigation", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Failed to reopen investigation", severity: "error"}));
        }
    }

    return (
        <>
            <Stack direction="row" justifyContent="end" sx={{mt: 2}}>
                {investigation.status === "IN_CREATION" &&
                    <Button variant="contained" color="warning" size={"large"}
                            onClick={handleCloseInvestigation}
                            startIcon={<FlagIcon/>}>
                        Close Investigation
                    </Button>}
                {investigation.status === "CLOSED" &&
                    <Button variant="contained" color="warning" size={"large"}
                            onClick={handleReopenInvestigation}
                            startIcon={<LockOpenIcon/>}>
                        Reopen Investigation
                    </Button>}
                <Button variant="contained" color="primary" size={"large"} onClick={handleOpen}
                        startIcon={<AddTaskOutlinedIcon/>} sx={{ml: 2}}>
                    Add Investigation Item
                </Button>
            </Stack>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <AddInvestigationItemForm setOpen={setOpen} complaint={complaint} investigation={investigation}
                                                  setTab={setTab}/>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
