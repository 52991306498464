export const baselineassessment_cdi_management = [
    {
        stepName: "surveyName",
        title: "surveyName.title",
        text: "surveyName.text",
        buttons: [{label: "ok", goto: "surveyPurpose"},]
    },
    {
        stepName: "surveyPurpose",
        previousStep: "surveyName",
        title: "surveyPurpose.title",
        text: "surveyPurpose.text",
        buttons: [{label: "ok", goto: "surveyParticipants"},]
    },
    {
        stepName: "surveyParticipants",
        previousStep: "surveyPurpose",
        title: "surveyParticipants.title",
        text: "surveyParticipants.text",
        buttons: [{label: "ok", goto: "surveyPeriod"},]
    },
    {
        stepName: "surveyPeriod",
        previousStep: "surveyParticipants",
        title: "surveyPeriod.title",
        text: "surveyPeriod.text",
        buttons: [{label: "ok", goto: "surveyContact"},]
    },
    {
        stepName: "surveyContact",
        previousStep: "surveyPeriod",
        title: "surveyContact.title",
        text: "surveyContact.text",
        buttons: [{label: "ok", goto: "personalInformation"},]
    },
    {
        stepName: "personalInformation",
        previousStep: "surveyContact",
        title: "personalInformation.title",
        buttons: [{label: "ok", goto: "factoryName"},]
    },
    {
        stepName: "factoryName",
        previousStep: "personalInformation",
        title: "factoryName.title",
        text: "factoryName.text",
        field: {
            name: "factoryName",
            type: "text",
            label: "factoryName.field.label",
            submit: {
                label: "factoryName.field.submit.label",
                icon: "SendIcon",
                goto: "sex"
            }
        },
    },
    {
        stepName: "sex",
        previousStep: "factoryName",
        title: "sex.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "sex.buttons.male",
                goto: "age",
                update: {field: "sex", value: "male"}
            },
            {
                label: "sex.buttons.female",
                goto: "age",
                update: {field: "sex", value: "female"}
            },
            {
                label: "sex.buttons.other",
                goto: "age",
                update: {field: "sex", value: "other"}
            }]
    },
    {
        stepName: "age",
        previousStep: "sex",
        title: "age.title",
        text: "age.text",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "age.buttons.below15",
                goto: "department",
                update: {field: "age", value: "below15"}
            }, {
                label: "age.buttons.15to18",
                goto: "department",
                update: {field: "age", value: "15to18"}
            }, {
                label: "age.buttons.19to24",
                goto: "department",
                update: {field: "age", value: "19to24"}
            }, {
                label: "age.buttons.25to34",
                goto: "department",
                update: {field: "age", value: "25to34"}
            }, {
                label: "age.buttons.35to44",
                goto: "department",
                update: {field: "age", value: "35to44"}
            }, {
                label: "age.buttons.45to54",
                goto: "department",
                update: {field: "age", value: "45to54"}
            }, {
                label: "age.buttons.55andAbove",
                goto: "department",
                update: {field: "age", value: "55andAbove"}
            }, {
                label: "age.buttons.preferNotToSay",
                goto: "department",
                update: {field: "age", value: "preferNotToSay"}
            },
        ]
    },
    {
        stepName: "department",
        previousStep: "age",
        title: "department.title",
        text: "department.text",
        field: {
            name: "department",
            type: "text",
            label: "department.field.label",
            submit: {
                label: "department.field.submit.label",
                icon: "SendIcon",
                goto: "seniority"
            }
        },
    },
    {
        stepName: "seniority",
        previousStep: "department",
        title: "seniority.title",
        text: "seniority.text",
        field: {
            name: "seniority",
            type: "text",
            label: "seniority.field.label",
            submit: {
                label: "seniority.field.submit.label",
                icon: "SendIcon",
                goto: "understandingOfStandardsAndRegulations"
            }
        },
    },
    {
        stepName: "understandingOfStandardsAndRegulations",
        previousStep: "seniority",
        title: "understandingOfStandardsAndRegulations.title",
        buttons: [{label: "ok", goto: "knowledgeOfWorkersRights"},]
    },
    {
        stepName: "knowledgeOfWorkersRights",
        previousStep: "understandingOfStandardsAndRegulations",
        title: "knowledgeOfWorkersRights.title",
        text: "knowledgeOfWorkersRights.text",
        scale: {
            name: "knowledgeOfWorkersRights",
            steps: ["knowledgeOfWorkersRights.scale.step_0",
                "knowledgeOfWorkersRights.scale.step_1",
                "knowledgeOfWorkersRights.scale.step_2",
                "knowledgeOfWorkersRights.scale.step_3",
                "knowledgeOfWorkersRights.scale.step_4",
                "knowledgeOfWorkersRights.scale.step_5"],
            submit: {
                label: "knowledgeOfWorkersRights.field.submit.label",
                icon: "SendIcon",
                goto: "sourceOfLaborStandardsInformation"
            }
        },
    },
    {
        stepName: "sourceOfLaborStandardsInformation",
        previousStep: "knowledgeOfWorkersRights",
        title: "sourceOfLaborStandardsInformation.title",
        text: "sourceOfLaborStandardsInformation.text",
        multipleChoice: true,
        multipleSelect: {
            name: "sourceOfLaborStandardsInformation",
            submit: {
                label: "send",
                goto: "understandingOfLaborLaws",
            }
        },
        buttons: [
            {
                label: "sourceOfLaborStandardsInformation.buttons.factory",
                update: {value: "factory"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.selfObserve",
                update: {value: "selfObserve"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.customersAndBrands",
                update: {value: "customersAndBrands"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.friendsAndColleagues",
                update: {value: "friendsAndColleagues"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.other",
                goto: "sourceOfLaborStandardsInformationSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "sourceOfLaborStandardsInformationSpecification",
        previousStep: "sourceOfLaborStandardsInformation",
        title: "sourceOfLaborStandardsInformationSpecification.title",
        text: "sourceOfLaborStandardsInformationSpecification.text",
        field: {
            name: "sourceOfLaborStandardsInformationSpecification",
            type: "text",
            label: "sourceOfLaborStandardsInformationSpecification.field.label",
            submit: {
                label: "sourceOfLaborStandardsInformationSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "understandingOfLaborLaws"
            }
        },
    },
    {
        stepName: "understandingOfLaborLaws",
        previousStep: "sourceOfLaborStandardsInformation",
        title: "understandingOfLaborLaws.title",
        text: "understandingOfLaborLaws.text",
        scale: {
            name: "understandingOfLaborLaws",
            steps: ["understandingOfLaborLaws.scale.step_0", "understandingOfLaborLaws.scale.step_1", "understandingOfLaborLaws.scale.step_2", "understandingOfLaborLaws.scale.step_3", "understandingOfLaborLaws.scale.step_4", "understandingOfLaborLaws.scale.step_5"],
            submit: {label: "understandingOfLaborLaws.field.submit.label", icon: "SendIcon", goto: "sourceOfLaborLaws"}
        },
    },
    {
        stepName: "sourceOfLaborLaws",
        previousStep: "understandingOfLaborLaws",
        title: "sourceOfLaborLaws.title",
        text: "sourceOfLaborLaws.text",
        multipleChoice: true,
        multipleSelect: {
            name: "sourceOfLaborLaws",
            submit: {
                label: "send",
                goto: "laborCode",
            }
        },
        buttons: [
            {
                label: "sourceOfLaborLaws.buttons.factory",
                update: {value: "factory"}
            },
            {
                label: "sourceOfLaborStandardsInformation.buttons.selfObserve",
                update: {value: "selfObserve"}
            },
            {
                label: "sourceOfLaborLaws.buttons.customersAndBrands",
                update: {value: "customersAndBrands"}
            },
            {
                label: "sourceOfLaborLaws.buttons.friendsAndColleagues",
                update: {value: "friendsAndColleagues"}
            },
            {
                label: "sourceOfLaborLaws.buttons.other",
                goto: "sourceOfLaborLawsSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "sourceOfLaborLawsSpecification",
        previousStep: "sourceOfLaborLaws",
        title: "sourceOfLaborLawsSpecification.title",
        text: "sourceOfLaborLawsSpecification.text",
        field: {
            name: "sourceOfLaborLawsSpecification",
            type: "text",
            label: "sourceOfLaborLawsSpecification.field.label",
            submit: {
                label: "sourceOfLaborLawsSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "laborCode"
            }
        },
    },
    {
        stepName: "laborCode",
        previousStep: "sourceOfLaborLaws",
        title: "laborCode.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "laborCode.buttons.encouraging",
                goto: "responsibleStakeholders",
                update: {field: "laborCode", value: "encouraging"}
            },
            {
                label: "laborCode.buttons.mandatory",
                goto: "responsibleStakeholders",
                update: {field: "laborCode", value: "mandatory"}
            },
            {
                label: "laborCode.buttons.encouragingAndMandatory",
                goto: "responsibleStakeholders",
                update: {field: "laborCode", value: "encouragingAndMandatory"}
            },
            {
                label: "laborCode.buttons.none",
                goto: "laborCodeSpecification",
                update: {field: "laborCode", value: "none"}
            }
        ]
    },
    {
        stepName: "laborCodeSpecification",
        previousStep: "laborCode",
        title: "laborCodeSpecification.title",
        text: "laborCodeSpecification.text",
        field: {
            name: "laborCodeSpecification",
            type: "text",
            label: "laborCodeSpecification.field.label",
            submit: {
                label: "laborCodeSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "responsibleStakeholders"
            }
        },
    },
    {
        stepName: "responsibleStakeholders",
        previousStep: "laborCode",
        title: "responsibleStakeholders.title",
        text: "responsibleStakeholders.text",
        multipleChoice: true,
        multipleSelect: {
            name: "responsibleStakeholders",
            submit: {
                label: "send",
                goto: "difficulties",
            }
        },
        buttons: [
            {
                label: "responsibleStakeholders.buttons.leadership",
                update: {value: "leadership"}
            },
            {
                label: "responsibleStakeholders.buttons.managers",
                update: {value: "managers"}
            },
            {
                label: "responsibleStakeholders.buttons.workers",
                goto: "difficulties",
                update: {value: "workers"}
            },
        ]
    },
    {
        stepName: "difficulties",
        previousStep: "responsibleStakeholders",
        title: "difficulties.title",
        text: "difficulties.text",
        multipleChoice: true,
        multipleSelect: {
            name: "difficulties",
            submit: {
                label: "send",
                goto: "complaintExplanation",
            }
        },
        buttons: [
            {
                label: "difficulties.buttons.findingInformation",
                update: {value: "findingInformation"}
            },
            {
                label: "difficulties.buttons.verifyingInformation",
                update: {value: "verifyingInformation"}
            },
            {
                label: "difficulties.buttons.trustingInformation",
                update: {value: "trustingInformation"}
            },
            {
                label: "difficulties.buttons.other",
                goto: "difficultiesSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "difficultiesSpecification",
        previousStep: "difficulties",
        title: "difficultiesSpecification.title",
        text: "difficultiesSpecification.text",
        field: {
            name: "difficultiesSpecification",
            type: "text",
            label: "difficultiesSpecification.field.label",
            submit: {
                label: "difficultiesSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "complaintExplanation"
            }
        },
    },
    {
        stepName: "complaintExplanation",
        previousStep: "difficulties",
        text: "complaintExplanation.text",
        title: "complaintExplanation.title",
        buttons: [
            {
                label: "complaintExplanation.buttons.ok",
                goto: "grievanceMechanism"
            },]
    },
    {
        stepName: "grievanceMechanism",
        previousStep: "complaintExplanation",
        title: "grievanceMechanism.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "grievanceMechanism.buttons.yes",
                goto: "factoryChannels",
                update: {field: "grievanceMechanism", value: "yes"}
            },
            {
                label: "grievanceMechanism.buttons.no",
                goto: "sexismChannel",
                update: {field: "grievanceMechanism", value: "no"}
            },
            {
                label: "grievanceMechanism.buttons.dontKnow",
                goto: "sexismChannel",
                update: {field: "grievanceMechanism", value: "dontKnow"}
            },
        ]
    },
    {
        stepName: "factoryChannels",
        previousStep: "grievanceMechanism",
        text: "factoryChannels.text",
        title: "factoryChannels.title",
        multipleChoice: true,
        multipleSelect: {
            name: "factoryChannels",
            submit: {
                label: "send",
                goto: "sexismChannel",
            }
        },
        buttons: [
            {
                label: "factoryChannels.buttons.teamLeader",
                update: {value: "teamLeader"}
            },
            {
                label: "factoryChannels.buttons.humanResources",
                update: {value: "humanResources"}
            },
            {
                label: "factoryChannels.buttons.factoryDirector",
                update: {value: "factoryDirector"}
            },
            {
                label: "factoryChannels.buttons.factoryTradeUnion",
                update: {value: "factoryTradeUnion"}
            },
            {
                label: "factoryChannels.buttons.hotline",
                update: {value: "hotline"}
            },
            {
                label: "factoryChannels.buttons.suggestionBox",
                update: {value: "suggestionBox"}
            },
            {
                label: "factoryChannels.buttons.socialMedia",
                update: {value: "socialMedia"}
            },
            {
                label: "factoryChannels.buttons.app",
                update: {value: "app"}
            },
            {
                label: "factoryChannels.buttons.socialDialogue",
                update: {value: "socialDialogue"}
            },
            {
                label: "factoryChannels.buttons.other",
                goto: "factoryChannelsSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "factoryChannelsSpecification",
        previousStep: "factoryChannels",
        title: "factoryChannelsSpecification.title",
        text: "factoryChannelsSpecification.text",
        field: {
            name: "factoryChannelsSpecification",
            type: "text",
            label: "factoryChannelsSpecification.field.label",
            submit: {
                label: "factoryChannelsSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "sexismChannel"
            }
        },
    },
    {
        stepName: "sexismChannel",
        previousStep: "factoryChannels",
        title: "sexismChannel.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "sexismChannel.buttons.separate",
                goto: "complaintMechanismRating",
                update: {field: "sexismChannel", value: "separate"}
            },
            {
                label: "sexismChannel.buttons.main",
                goto: "complaintMechanismRating",
                update: {field: "sexismChannel", value: "main"}
            },
            {
                label: "sexismChannel.buttons.none",
                goto: "complaintMechanismRating",
                update: {field: "sexismChannel", value: "none"}
            },
            {
                label: "sexismChannel.buttons.dontKnow",
                goto: "complaintMechanismRating",
                update: {field: "sexismChannel", value: "dontKnow"}
            }]
    },
    {
        stepName: "complaintMechanismRating",
        previousStep: "sexismChannel",
        title: "complaintMechanismRating.title",
        text: "complaintMechanismRating.text",
        scale: {
            name: "complaintMechanismRating",
            steps: ["complaintMechanismRating.scale.step_1", "complaintMechanismRating.scale.step_2", "complaintMechanismRating.scale.step_3", "complaintMechanismRating.scale.step_4", "complaintMechanismRating.scale.step_5"],
            startAtOne: true,
            submit: {
                label: "complaintMechanismRating.field.submit.label",
                icon: "SendIcon",
                goto: "regulationDifficulties"
            }
        },
    },
    {
        stepName: "regulationDifficulties",
        previousStep: "complaintMechanismRating",
        title: "regulationDifficulties.title",
        field: {
            name: "regulationDifficulties",
            type: "text",
            rows: 3,
            label: "regulationDifficulties.field.label",
            submit: {
                label: "regulationDifficulties.field.submit.label",
                icon: "SendIcon",
                goto: "improvement"
            }
        },
    },
    {
        stepName: "improvement",
        previousStep: "regulationDifficulties",
        title: "improvement.title",
        field: {
            name: "improvement",
            type: "text",
            rows: 3,
            label: "improvement.field.label",
            submit: {
                label: "improvement.field.submit.label",
                icon: "SendIcon",
                goto: "responsibilities"
            }
        },
    },
    {
        stepName: "responsibilities",
        previousStep: "improvement",
        title: "responsibilities.title",
        field: {
            name: "responsibilities",
            type: "text",
            rows: 3,
            label: "responsibilities.field.label",
            submit: {
                label: "send",
                icon: "SendIcon",
                goto: "complaintComfort"
            }
        },
    },
    {
        stepName: "complaintComfort",
        previousStep: "responsibilities",
        title: "complaintComfort.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "complaintComfort.buttons.yes",
                goto: "complaintComfortYes",
                update: {field: "complaintComfort", value: "yes"}
            },
            {
                label: "complaintComfort.buttons.no",
                goto: "complaintComfortNo",
                update: {field: "complaintComfort", value: "no"}
            },
            {
                label: "complaintComfort.buttons.preferNotToSay",
                goto: "understandingOfExternalGrievanceMechanisms",
                update: {field: "complaintComfort", value: "preferNotToSay"}
            },]
    },
    {
        stepName: "complaintComfortYes",
        previousStep: "complaintComfort",
        title: "complaintComfortYes.title",
        text: "complaintComfortYes.text",
        field: {
            name: "complaintComfortYes",
            type: "text",
            rows: 3,
            label: "complaintComfortYes.field.label",
            submit: {
                label: "send",
                icon: "SendIcon",
                goto: "understandingOfExternalGrievanceMechanisms"
            }
        },
    },
    {
        stepName: "complaintComfortNo",
        previousStep: "complaintComfort",
        title: "complaintComfortNo.title",
        text: "complaintComfortNo.text",
        field: {
            name: "complaintComfortNo",
            type: "text",
            rows: 3,
            label: "complaintComfortNo.field.label",
            submit: {
                label: "send",
                icon: "SendIcon",
                goto: "understandingOfExternalGrievanceMechanisms"
            }
        },
    },
    {
        stepName: "understandingOfExternalGrievanceMechanisms",
        previousStep: "complaintComfort",
        title: "understandingOfExternalGrievanceMechanisms.title",
        text: "understandingOfExternalGrievanceMechanisms.text",
        buttons: [{label: "ok", goto: "outsideHelpKnowledge",}],
    },
    {
        stepName: "outsideHelpKnowledge",
        previousStep: "understandingOfExternalGrievanceMechanisms",
        title: "outsideHelpKnowledge.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "outsideHelpKnowledge.buttons.yes",
                goto: "outsideHelpKnowledgeSpecification",
                update: {field: "outsideHelpKnowledge", value: "yes"}
            },
            {
                label: "outsideHelpKnowledge.buttons.no",
                goto: "outsideHelp",
                update: {field: "outsideHelpKnowledge", value: "no"}
            }]
    },
    {
        stepName: "outsideHelpKnowledgeSpecification",
        previousStep: "outsideHelpKnowledge",
        title: "outsideHelpKnowledgeSpecification.title",
        text: "outsideHelpKnowledgeSpecification.text",
        field: {
            name: "outsideHelpKnowledgeSpecification",
            type: "text",
            rows: 3,
            label: "outsideHelpKnowledgeSpecification.field.label",
            submit: {
                label: "outsideHelpKnowledgeSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "outsideHelp"
            }
        },
    },
    {
        stepName: "outsideHelp",
        previousStep: "outsideHelpKnowledge",
        title: "outsideHelp.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "outsideHelp.buttons.yes",
                goto: "outsideHelpSpecification",
                update: {field: "outsideHelp", value: "yes"}
            },
            {
                label: "outsideHelp.buttons.no",
                goto: "externalGrievanceMechanismInformation",
                update: {field: "outsideHelp", value: "no"}
            }]
    },
    {
        stepName: "outsideHelpSpecification",
        previousStep: "outsideHelp",
        title: "outsideHelpSpecification.title",
        text: "outsideHelpSpecification.text",
        field: {
            name: "outsideHelpSpecification",
            type: "text",
            rows: 3,
            label: "outsideHelpSpecification.field.label",
            submit: {
                label: "outsideHelpSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "externalGrievanceMechanismInformation"
            }
        },
    },
    {
        stepName: "externalGrievanceMechanismInformation",
        previousStep: "outsideHelp",
        title: "externalGrievanceMechanismInformation.title",
        text: "externalGrievanceMechanismInformation.text",
        multipleChoice: true,
        multipleSelect: {
            name: "externalGrievanceMechanismInformation",
            submit: {
                label: "send",
                goto: "externalGrievanceMechanismOpinion",
            }
        },
        buttons: [
            {
                label: "externalGrievanceMechanismInformation.buttons.factory",
                update: {value: "factory"}
            },
            {
                label: "externalGrievanceMechanismInformation.buttons.selfObserve",
                update: {value: "selfObserve"}
            },
            {
                label: "externalGrievanceMechanismInformation.buttons.customersAndBrands",
                update: {value: "customersAndBrands"}
            },
            {
                label: "externalGrievanceMechanismInformation.buttons.friendsAndColleagues",
                update: {value: "friendsAndColleagues"}
            },
            {
                label: "externalGrievanceMechanismInformation.buttons.dontKnow",
                excludesOthers: true,
                update: {value: "dontKnow"}
            },
            {
                label: "externalGrievanceMechanismInformation.buttons.other",
                goto: "externalGrievanceMechanismInformationSpecification",
                update: {value: "other"}
            },
        ]
    },
    {
        stepName: "externalGrievanceMechanismInformationSpecification",
        previousStep: "externalGrievanceMechanismInformation",
        title: "externalGrievanceMechanismInformationSpecification.title",
        text: "externalGrievanceMechanismInformationSpecification.text",
        field: {
            name: "externalGrievanceMechanismInformationSpecification",
            type: "text",
            label: "externalGrievanceMechanismInformationSpecification.field.label",
            submit: {
                label: "externalGrievanceMechanismInformationSpecification.field.submit.label",
                icon: "SendIcon",
                goto: "externalGrievanceMechanismOpinion"
            }
        },
    },
    {
        stepName: "externalGrievanceMechanismOpinion",
        previousStep: "externalGrievanceMechanismInformation",
        title: "externalGrievanceMechanismOpinion.title",
        field: {
            name: "externalGrievanceMechanismOpinion",
            type: "text",
            rows: 3,
            label: "externalGrievanceMechanismOpinion.field.label",
            submit: {
                label: "externalGrievanceMechanismOpinion.field.submit.label",
                icon: "SendIcon",
                goto: "understandingOfGeneralWorkEnvironment"
            }
        },
    },
    {
        stepName: "understandingOfGeneralWorkEnvironment",
        previousStep: "externalGrievanceMechanismOpinion",
        title: "understandingOfGeneralWorkEnvironment.title",
        buttons: [{label: "ok", goto: "talkingComfort",}],
    },
    {
        stepName: "talkingComfort",
        previousStep: "understandingOfGeneralWorkEnvironment",
        title: "talkingComfort.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "talkingComfort.buttons.yesAlways",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "yesAlways"}
            },
            {
                label: "talkingComfort.buttons.yesSometimes",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "yesSometimes"}
            },
            {
                label: "talkingComfort.buttons.noRarely",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "noRarely"}
            },
            {
                label: "talkingComfort.buttons.noNever",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "noNever"}
            },
            {
                label: "talkingComfort.buttons.preferNotToSay",
                goto: "atmosphere",
                update: {field: "talkingComfort", value: "preferNotToSay"}
            }
        ]
    },
    {
        stepName: "atmosphere",
        previousStep: "talkingComfort",
        title: "atmosphere.title",
        multipleChoice: true,
        singleSelect: true,
        buttons: [
            {
                label: "atmosphere.buttons.positive",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "positive"}
            },
            {
                label: "atmosphere.buttons.neutral",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "neutral"}
            },
            {
                label: "atmosphere.buttons.negative",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "negative"}
            },
            {
                label: "atmosphere.buttons.preferNotToSay",
                goto: "anythingElse",
                update: {field: "atmosphere", value: "preferNotToSay"}
            },
        ]
    },
    {
        stepName: "anythingElse",
        previousStep: "atmosphere",
        title: "anythingElse.title",
        field: {
            name: "anythingElse",
            type: "text",
            rows: 3,
            label: "anythingElse.field.label",
            submit: {
                label: "anythingElse.field.submit.label",
                icon: "SendIcon",
                goto: "other",
                canBeSkipped: true
            }
        },
    },
    {
        stepName: "other",
        previousStep: "anythingElse",
        title: "other.title",
        buttons: [{label: "ok", goto: "training",}],
    },
    {
        stepName: "training",
        previousStep: "other",
        title: "training.title",
        field: {
            name: "training",
            type: "text",
            rows: 3,
            label: "training.field.label",
            submit: {
                label: "training.field.submit.label",
                icon: "SendIcon",
                goto: "thanks",
                canBeSkipped: true
            }
        },
    },
    {
        stepName: "thanks",
        previousStep: "training",
        text: "thanks.text"
    },
];

//console.log(JSON.stringify(baselineassessment_cdi_management, null, 2))

