import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import {Popover, Stack} from "@mui/material";
import FlagIcon from '@mui/icons-material/Flag';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SaveIcon from '@mui/icons-material/Save';
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import img from "../../../../assets/cap/illu_close.webp";
import {useDispatch} from "react-redux";
import {useUpdateCAPMutation, useUpdateCAPStatusMutation} from "../../../../app/apiSlice";
import {openSnackbar} from "../../../../app/snackbarSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function CAPControls({cap, permissions, description}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const dispatch = useDispatch();

    const [updateCAP] = useUpdateCAPMutation();
    const [updateCAPStatus] = useUpdateCAPStatusMutation();

    if (!permissions.has("WRITE_CAP")) return null;

    function closeButtonNoItems() {
        return <>
            <span onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <Button variant="text" color="primary" size={"large"} disabled
                        sx={{whiteSpace: "nowrap", minWidth: "auto"}}
                        aria-describedby={id}
                        style={{marginTop: 5, marginLeft: 30}}
                        aria-owns={openModal ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        startIcon={<FlagIcon/>}>
                    Close CAP
                </Button></span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                sx={{pointerEvents: 'none',}}
                transformOrigin={{vertical: 'top', horizontal: 'left',}}
                disableRestoreFocus
            >
                <Alert severity={"info"}>{"You can't close the CAP without CAP tasks."}</Alert>
            </Popover></>;
    }

    async function handleCAPStatusChange(status) {
        try {
            await updateCAPStatus({capId: cap.id, status: status, complaintId: cap.complaintId}).unwrap()
            dispatch(openSnackbar({message: "CAP status updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP status update failed", severity: "error"}));
        }

    }

    function closeButtonWithItems() {
        if (!cap || !cap.items || cap.items.length < 1) return null;

        const capInProgress = cap.items.filter(x => x.status === "IN_CREATION" || x.status === "IN_REMEDIATION").length > 0;

        if (capInProgress) {
            return <>
                <Button variant="text" color="primary" size={"large"} onClick={handleOpen}
                        sx={{whiteSpace: "nowrap", minWidth: "auto"}}
                        style={{marginTop: 5, marginLeft: 30}}
                        startIcon={<FlagIcon/>}>
                    Close CAP
                </Button>

                <Modal
                    open={openModal}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <Box sx={style}>
                            <Typography variant="h4" sx={{mt: 2}}>Open CAP Tasks! Are you sure you want to
                                close?</Typography>
                            <Grid container>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Typography variant="h5" sx={{mt: 4}}>You have unfinished CAP tasks, if you
                                            close
                                            now your CAP will be marked as closed unsuccessfully.</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <img src={img} alt={"closing cap"} style={{
                                            marginTop: "-60px",
                                            marginLeft: "-60px",
                                            marginRight: "-30px",
                                            marginBottom: "-110px"
                                        }}/>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Button variant="outlined" color="warning"
                                                onClick={handleClose}
                                                startIcon={<ArrowBackOutlinedIcon/>}>Back</Button>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Button variant="contained" color="warning"
                                                onClick={() => handleCAPStatusChange('DONE')}
                                                sx={{float: "right"}}
                                                startIcon={<FlagIcon/>}>I'm sure. close cap</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </>
        }

        return <Button variant="text" color="primary" size={"large"}
                       onClick={() => handleCAPStatusChange("CLOSED")}
                       startIcon={<FlagIcon/>}>
            Close CAP
        </Button>;
    }

    async function handleUpdateCAP() {
        try {
            await updateCAP({...cap, description}).unwrap()
            dispatch(openSnackbar({message: "CAP updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "CAP update failed", severity: "error"}));
        }
    }

    return (
        <Stack direction="row" justifyContent="end" sx={{mt: 2}} alignItems="flex-start">
            {cap.status === "IN_CREATION" && cap.items.length === 0 &&
                closeButtonNoItems()}
            {cap.status === "IN_CREATION" && cap.items.length > 0 &&
                closeButtonWithItems()}
            {(cap.status === "CLOSED" || cap.status === "DONE") &&
                <Button variant="text" color="primary" size={"large"}
                        sx={{whiteSpace: "nowrap", minWidth: "auto"}}
                        onClick={() => handleCAPStatusChange('IN_CREATION')}
                        style={{marginTop: 5, marginLeft: 30}}
                        startIcon={<LockOpenIcon/>}>
                    Reopen CAP
                </Button>}
            <Button variant="contained"
                    color="primary"
                    size="large"
                    style={{marginTop: 5, marginLeft: 30}}
                    disabled={!permissions.has("WRITE_CAP")}
                    onClick={handleUpdateCAP} startIcon={<SaveIcon/>}>
                save
            </Button>
        </Stack>

    );
}
