import React, {useState} from 'react';
import {Checkbox, Collapse, FormControlLabel, FormGroup} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from "@mui/material/IconButton";
import HelpPopUp from "../../../common/HelpPopUp";

export default function EditPermissions({permissions, setPermissions}) {
    const [permissionsCollapsed, setPermissionsCollapsed] = useState(true);

    const handlePermissionCollapsedChange = () => {
        setPermissionsCollapsed((prev) => !prev);
    };

    function handleClickAllComplainantConversationPermissions(e) {
        const newPermissions = {
            ...permissions,
            READ_COMPLAINANT_CONVERSATION: e.target.checked,
            WRITE_COMPLAINANT_CONVERSATION: e.target.checked,
        }
        setPermissions(newPermissions)
    }

    function handleClickAllComplaintFactsPermissions(e) {
        const newPermissions = {
            ...permissions,
            READ_ORIGINAL_COMPLAINT_MESSAGE: e.target.checked,
            READ_LOCATION: e.target.checked,
            READ_DATE_AND_TIME: e.target.checked,
            READ_SUMMARY: e.target.checked,
            READ_SEVERITY: e.target.checked,
            READ_CATEGORY: e.target.checked,
            READ_STATUS: e.target.checked,
            READ_TITLE: e.target.checked,
        }
        setPermissions(newPermissions)
    }

    function handleClickAllInvestigationPermissions(e) {
        const newPermissions = {
            ...permissions,
            READ_ALL_INVESTIGATION_ITEMS: e.target.checked,
            READ_OWN_INVESTIGATION_ITEMS: e.target.checked,
        }
        setPermissions(newPermissions)
    }

    function handleClickAllCAPPermissions(e) {
        const newPermissions = {
            ...permissions,
            READ_CAP: e.target.checked,
            WRITE_CAP: e.target.checked,
            WRITE_CAP_COMMENTS: e.target.checked,
        }
        setPermissions(newPermissions)
    }


    function handleClickPermission(e, permission) {
        setPermissions({...permissions, [permission]: e.target.checked});
    }

    function checkbox(permission, label) {
        return <FormControlLabel control={<Checkbox checked={permissions[permission]}
                                                    sx={{p: 1}}
                                                    onClick={(e) => handleClickPermission(e, permission)}/>}
                                 sx={{mb: 0}}
                                 label={label}/>;
    }

    function warning() {
        return <HelpPopUp sx={{float: "right"}}
                          text="Please consider: Often vulnerable information." severity="warning"/>;
    }

    function onlyInvites() {
        return <HelpPopUp sx={{float: "right"}}
                          text="The external can reply to items they were invited to." severity="info"/>;
    }

    function files() {
        return <HelpPopUp sx={{float: "right"}}
                          text="The external can also upload files, e.g images." severity="info"/>;
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: 1,
                cursor: 'pointer'
            }}>
                <Typography variant="h6" component="span" onClick={handlePermissionCollapsedChange}>
                    Permissions
                </Typography>
                <IconButton color="primary" aria-label="expand permissions" component="span"
                            onClick={handlePermissionCollapsedChange}>
                    {permissionsCollapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </Box>
            <Collapse in={permissionsCollapsed}>
                <Box display="flex" justifyContent="space-between">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={permissions.READ_COMPLAINANT_CONVERSATION && permissions.WRITE_COMPLAINANT_CONVERSATION}
                            onClick={handleClickAllComplainantConversationPermissions}/>}
                                          label={<b>Complainant Conversation</b>}/>
                        {checkbox("READ_COMPLAINANT_CONVERSATION", <Box
                            sx={{whiteSpace: "nowrap"}}>See{warning()}</Box>)}

                        {checkbox("WRITE_COMPLAINANT_CONVERSATION", <Box sx={{whiteSpace: "nowrap"}}>Write
                            message{warning()}</Box>)}
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={permissions.READ_ORIGINAL_COMPLAINT_MESSAGE && permissions.READ_LOCATION &&
                            permissions.READ_DATE_AND_TIME && permissions.READ_SUMMARY && permissions.READ_SEVERITY &&
                            permissions.READ_CATEGORY && permissions.READ_STATUS && permissions.READ_TITLE}
                            onClick={handleClickAllComplaintFactsPermissions}/>} label={<b>Complaint Facts</b>}/>
                        {checkbox("READ_ORIGINAL_COMPLAINT_MESSAGE", <Box sx={{whiteSpace: "nowrap"}}>See Complainant
                            Message{warning()}</Box>)}
                        {checkbox("READ_LOCATION", <Box sx={{whiteSpace: "nowrap"}}>See Location{warning()}</Box>)}
                        {checkbox("READ_DATE_AND_TIME", "See Date and Time")}
                        {checkbox("READ_SUMMARY", "See Summary")}
                        {checkbox("READ_SEVERITY", "See Severity")}
                        {checkbox("READ_CATEGORY", "See Categories")}
                        {checkbox("READ_STATUS", "See Status")}
                        {checkbox("READ_TITLE", "See Title")}
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={permissions.READ_ALL_INVESTIGATION_ITEMS && permissions.READ_OWN_INVESTIGATION_ITEMS}
                            onClick={handleClickAllInvestigationPermissions}/>}
                                          label={<Box sx={{whiteSpace: "nowrap"}}><b>Investigation</b>{onlyInvites()}
                                          </Box>}/>
                        {checkbox("READ_ALL_INVESTIGATION_ITEMS", "See all items")}
                        {checkbox("READ_OWN_INVESTIGATION_ITEMS", "See only their own items")}
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={permissions.READ_CAP && permissions.WRITE_CAP && permissions.WRITE_CAP_COMMENTS}
                            onClick={handleClickAllCAPPermissions}/>} label={<b>CAP</b>}/>
                        {checkbox("READ_CAP", "See")}
                        {checkbox("WRITE_CAP_COMMENTS", <Box sx={{whiteSpace: "nowrap"}}>Comment{files()}</Box>)}
                        {checkbox("WRITE_CAP", "Edit")}
                    </FormGroup>
                </Box>

            </Collapse>
        </>

    );


}
