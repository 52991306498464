import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import HelpPopUp from "../../../common/HelpPopUp";
import Box from "@mui/material/Box";
import {useDispatch} from 'react-redux'
import {openSnackbar} from "../../../../app/snackbarSlice";
import {useGetBrandsQuery, useUpdateComplaintSubBrandMutation} from "../../../../app/apiSlice";
import {brandLabel} from "../../labels";
import LoadingGIF from "../../../common/LoadingGIF";
import NotAuthorized from "../../../common/NotAuthorized";
import Error from "../../../common/Error";

export default function SelectSubBrand({complaint, isInternal, me}) {

    const dispatch = useDispatch();

    const {data: brands, isLoading: brandsLoading, error: brandsError} = useGetBrandsQuery();

    const [updateComplaintSubBrand] = useUpdateComplaintSubBrandMutation();

    const [subBrand, setSubBrand] = useState(complaint.subBrand ? complaint.subBrand : "");

    const handleChangeSubBrand = async (event) => {
        setSubBrand(event.target.value);
        try {
            await updateComplaintSubBrand({complaintId: complaint.id, brandId: event.target.value}).unwrap()
            dispatch(openSnackbar({message: "Sub Brand updated successfully", severity: "success"}));
        } catch (err) {
            dispatch(openSnackbar({message: "Sub Brand update failed", severity: "error"}));
        }
    }

    if (brandsLoading) return <LoadingGIF/>;
    if (brandsError && brandsError.message.includes('403')) return <NotAuthorized/>;
    if (brandsError) return <Error/>;

    let brand = brands.find(brand => brand.id === complaint.brand);
    if (!brand.subBrands) return null;
    if (!isInternal) return null;
    if (!me?.settings?.selectSubBrandInComplaintFacts) return null;

    return (
        <>
            <Typography variant="h6" sx={{mt: 2}}>Sub Brand <HelpPopUp
                text="Please choose a sub brand."/></Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box >
                    <Typography variant="h6" color="black" sx={{mb: 1}}>Please select.</Typography>
                    <FormControl variant="outlined" component="span">
                        <InputLabel id="brand-label">Sub Brand</InputLabel>
                        <Select
                            labelId="brand-label"
                            id="subBrand"
                            value={subBrand}
                            onChange={handleChangeSubBrand}
                            label="Sub Brand"
                            sx={{minWidth: 120, marginRight: 2}}
                        >
                            {
                                brand.subBrands
                                    .map((brand) => (
                                        <MenuItem key={brand} value={brand}>
                                            {brandLabel(brand, brands)}
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </>
    );
}
