import React from 'react';
import {Select, Stack, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {darkSalmon, lightTurquoise, mainSalmon} from "../../../common/colors";
import Typography from "@mui/material/Typography";

export default function CAPFacts({item, disabled, save}) {

    return (
        <>
            <Stack direction="row" justifyContent="right">
                <FormControl>
                    <Select
                        value={item.priority}
                        onChange={e => {
                            save({
                                ...item, priority: e.target.value
                            })
                        }}
                        disabled={disabled}
                        sx={{width: 130, height: 32, borderRadius: 7}}
                    >
                        <MenuItem value="high">
                            <div style={{display: 'flex', alignItems: 'end'}}>
                                <FiberManualRecordIcon sx={{fill: darkSalmon}}/> High
                            </div>
                        </MenuItem>
                        <MenuItem value="medium">
                            <div style={{display: 'flex', alignItems: 'end'}}>
                                <FiberManualRecordIcon sx={{fill: mainSalmon}}/> Medium
                            </div>
                        </MenuItem>
                        <MenuItem value="low">
                            <div style={{display: 'flex', alignItems: 'end'}}>
                                <FiberManualRecordIcon sx={{fill: lightTurquoise}}/> Low
                            </div>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Stack>

            <Typography variant="h6" align="left" gutterBottom pl={1} pt={0}>
                Description
            </Typography>
            <TextField
                id="item_description" required
                label="CAP Item Description"
                pt={1}
                multiline
                minRows={4}
                fullWidth={true}
                defaultValue={item.description}
                variant="outlined"
                disabled={disabled}
                onBlur={(e) => save({...item, description: e.target.value})}
            />
        </>)
}
