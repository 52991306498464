import React, {useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import NotAuthorized from "../../components/common/NotAuthorized";
import LoadingGIF from "../../components/common/LoadingGIF";
import Box from "@mui/material/Box";
import Error from "../../components/common/Error";
import {useGetTemplatesQuery} from "../../app/apiSlice";
import Highlight from "../Highlight";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import {CardActionArea, Stack} from "@mui/material";
import {Link} from "react-router-dom";

const Templates = () => {

    const {data: templates, isLoading: loading, isSuccess, error: error} = useGetTemplatesQuery();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (isSuccess) {
            setRows(JSON.parse(JSON.stringify(templates)));
        }
    }, [templates]);

    if (loading) return <LoadingGIF/>;
    if (error && error.status === 403) return <NotAuthorized/>;
    if (error || !rows) return <Error/>;

    return (
        <Box sx={{mt: 2}}>

            Templates

            {templates && templates.map(template =>

                <Card sx={{mt: 1, p: 1, maxWidth: "550px"}} key={template.id}>
                    <CardActionArea component={Link} to={`/templates/${template.id}`}>
                        <CardHeader
                            title={<Typography variant="h6">{template.name}</Typography>}
                        />
                        <CardContent>
                            <Stack spacing={2} direction="row">
                                <Chip label={"Created: " + new Date(template.date).toLocaleDateString()}
                                      color="primary"/>
                                <Chip label={"Version: " + template.version} color="primary"/>
                                <Chip label={"Type: " + template.type} color="primary"/>
                            </Stack>

                        </CardContent>
                    </CardActionArea>
                </Card>
            )}

            <Highlight>
                {JSON.stringify(templates, null, 2)}
            </Highlight>
        </Box>
    );
};

export default withAuthenticationRequired(Templates, {
    onRedirecting: () => <LoadingGIF/>,
});
