import Box from "@mui/material/Box";
import {Collapse, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, {useState} from "react";


export default function CollapsibleSection({title, titleVariant, mt, children, border, id}) {

    const [collapsed, setCollapsed] = useState(id ? !!localStorage.getItem(`section_${id}_collapsed`) : false);
    return (
        <Box sx={{border: border ? 1 : 0, borderColor: 'grey.200', borderRadius: 2, mt: mt ? mt : 1}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: 1, mb: 1,
                ml: border ? 2 : 0, mr: border ? 2 : 0,
                cursor: 'pointer'
            }}>
                <Typography variant={titleVariant ? titleVariant : "h6"} component="span" sx={{mt: 1, width:"100%"}} onClick={() => {
                    setCollapsed((prev) => {
                        if (id) localStorage.setItem(`section_${id}_collapsed`, "" + !prev);
                        return !prev
                    });
                }}>
                    {title}
                </Typography>
                <IconButton color="primary" aria-label="expand section" component="span"
                            onClick={() => {
                                setCollapsed((prev) => {
                                    if (id) localStorage.setItem(`section_${id}_collapsed`, "" + !prev);
                                    return !prev
                                });
                            }}>
                    {collapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </Box>
            <Collapse in={collapsed}>
                <Box>
                    {children}
                </Box>
            </Collapse>
        </Box>
    )
}
