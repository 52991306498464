import React from "react";
import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import Speech from "../wizard/Speech";
import {v4 as uuidv4} from "uuid";
import {Stack} from "@mui/material";

const FAQs = () => {

    const {t, i18n} = useTranslation(['faq']);

    function speech(text) {
        return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
    }

    return (<>
        <Typography component="h1" variant="h3" color="primary" fontWeight={600} mt={1} gutterBottom>
            {t("title")}{speech(t('title'))}
        </Typography>

        <Typography variant="h6" sx={{mt: 2}}>
            {t("general.title")}{speech(t('general.title'))}
        </Typography>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`general-1-content`}
                              id={`general-1-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("general.question1")}</Typography>{speech(t('general.question1'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {t("general.answer1")}{speech(t('general.answer1'))}
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`general-2-content`}
                              id={`general-2-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("general.question2")}</Typography>{speech(t('general.question2'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("general.answer2_1")}{speech(t('general.answer2_1'))}</li>
                    <li>{t("general.answer2_2")}{speech(t('general.answer2_2'))}</li>
                    <li>{t("general.answer2_3")}{speech(t('general.answer2_3'))}</li>
                    <li>{t("general.answer2_4")}{speech(t('general.answer2_4'))}</li>
                    <li>{t("general.answer2_5")}{speech(t('general.answer2_5'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`general-3-content`}
                              id={`general-3-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("general.question3")}</Typography>{speech(t('general.question3'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("general.answer3_1")}{speech(t('general.answer3_1'))}</li>
                    <li>{t("general.answer3_2")}{speech(t('general.answer3_2'))}</li>
                    <li>{t("general.answer3_3")}{speech(t('general.answer3_3'))}</li>
                    <li>{t("general.answer3_4")}{speech(t('general.answer3_4'))}</li>
                    <li>{t("general.answer3_5")}{speech(t('general.answer3_5'))}</li>
                    <li>{t("general.answer3_6")}{speech(t('general.answer3_6'))}</li>
                    <li>{t("general.answer3_7")}{speech(t('general.answer3_7'))}</li>
                    <li>{t("general.answer3_8")}{speech(t('general.answer3_8'))}</li>
                    <li>{t("general.answer3_9")}{speech(t('general.answer3_9'))}</li>
                    <li>{t("general.answer3_10")}{speech(t('general.answer3_10'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`general-4-content`}
                              id={`general-4-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("general.question4")}</Typography>{speech(t('general.question4'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("general.answer4")}{speech(t('general.answer4'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`general-5-content`}
                              id={`general-5-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("general.question5")}</Typography>{speech(t('general.question5'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("general.answer5_1")}{speech(t('general.answer5_1'))}</li>
                    <li>{t("general.answer5_2")}{speech(t('general.answer5_2'))}</li>
                    <li>{t("general.answer5_3")}{speech(t('general.answer5_3'))}</li>
                    <li>{t("general.answer5_4")}{speech(t('general.answer5_4'))}</li>
                    <li>{t("general.answer5_5")}{speech(t('general.answer5_5'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`general-6-content`}
                              id={`general-6-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("general.question6")}</Typography>{speech(t('general.question6'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("general.answer6")}{speech(t('general.answer6'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>

        <Typography variant="h6" sx={{mt: 2}}>
            {t("anonymity.title")}{speech(t('anonymity.title'))}
        </Typography>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`anonymity-1-content`}
                              id={`anonymity-1-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("anonymity.question1")}</Typography>{speech(t('anonymity.question1'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("anonymity.answer1_1")}{speech(t('anonymity.answer1_1'))}</li>
                    <li>{t("anonymity.answer1_2")}{speech(t('anonymity.answer1_2'))}</li>
                    <li>{t("anonymity.answer1_3")}{speech(t('anonymity.answer1_3'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`anonymity-2-content`}
                              id={`anonymity-2-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("anonymity.question2")}</Typography>{speech(t('anonymity.question2'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("anonymity.answer2")}{speech(t('anonymity.answer2'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`anonymity-3-content`}
                              id={`anonymity-3-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("anonymity.question3")}</Typography>{speech(t('anonymity.question3'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("anonymity.answer3")}{speech(t('anonymity.answer3'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`anonymity-4-content`}
                              id={`anonymity-4-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("anonymity.question4")}</Typography>{speech(t('anonymity.question4'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("anonymity.answer4_1")}{speech(t('anonymity.answer4_1'))}</li>
                    <li>{t("anonymity.answer4_2")}{speech(t('anonymity.answer4_2'))}</li>
                    <li>{t("anonymity.answer4_3")}{speech(t('anonymity.answer4_3'))}</li>
                    <li>{t("anonymity.answer4_4")}{speech(t('anonymity.answer4_4'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`anonymity-5-content`}
                              id={`anonymity-5-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("anonymity.question_5")}</Typography>{speech(t('anonymity.question_5'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("anonymity.answer5")}{speech(t('anonymity.answer5'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Typography variant="h6" sx={{mt: 2}}>
            {t("followUp.title")}{speech(t('followUp.title'))}
        </Typography>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`followUp-1-content`}
                              id={`followUp-1-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("followUp.question1")}</Typography>{speech(t('followUp.question1'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("followUp.answer1")}{speech(t('followUp.answer1'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`followUp-2-content`}
                              id={`followUp-2-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("followUp.question2")}</Typography>{speech(t('followUp.question2'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("followUp.answer2")}{speech(t('followUp.answer2'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`followUp-3-content`}
                              id={`followUp-3-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("followUp.question3")}</Typography>{speech(t('followUp.question3'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("followUp.answer3")}{speech(t('followUp.answer3'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`followUp-4-content`}
                              id={`followUp-4-header`}>
                <Stack justifyContent="start" direction="row" alignItems="center">
                    <Typography>{t("followUp.question4")}</Typography>{speech(t('followUp.question4'))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="ul">
                    <li>{t("followUp.answer4")}{speech(t('followUp.answer4'))}</li>
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>);
};

export default FAQs;
